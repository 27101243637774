import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';

class Sistemavc extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/sistemavc/'){
			this.setState({preview: false});
			this.props.setProjectName('Sistema VC');
			$('body').addClass('case-expanded hash-sistemavc');
			$(window).scrollTop($('#case-sistemavc').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-sistemavc');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-sistemavc');
		}
	}

	more = () => {
    return(
      <Fragment>
        <style dangerouslySetInnerHTML={{__html: `
          #case-sistemavc .screen1 .bg, #case-sistemavc .screen1 .bg div { height: 430px; }
          #case-sistemavc .screen1 .bg { top: auto; bottom: 0; }
          #case-sistemavc .screen1 .bg div { background: #f4f4f4; }
          #case-sistemavc .screen1 .content { padding-bottom: 135px; }
          #case-sistemavc .screen1 .image1 { width: 555px; height: 167px; margin: -55px 0 0 -80px; float: left; }
          #case-sistemavc .screen1 .image2 { width: 500px; height: 207px; margin: 240px 0 0 -70px; float: left; }
          #case-sistemavc .screen1 .text0 { width: 280px; margin: -95px 0 0 -50px; float: left; }
          #case-sistemavc .screen1 .text1 { width: 380px; margin: 105px 0 0 230px; float: left; }
          #case-sistemavc .screen1 .image3 { width: 252px; height: 258px; margin: 105px -260px 0 165px; float: left; }
          #case-sistemavc .screen1 .image4 { width: 859px; height: 706px; margin: -175px 0 0 -860px; float: right; right: 40%; z-index: 2; }
          #case-sistemavc .screen1 .image5 { width: 1168px; height: 1163px; margin: 220px -1170px 0 0; float: left; left: 40%; }
          #case-sistemavc .screen1 .text2 { width: 280px; margin: 660px 0 0 -70px; float: left; }
          #case-sistemavc .screen1 .screenshot1, #case-sistemavc .screen1 .screenshot2, #case-sistemavc .screen1 .screenshot3, #case-sistemavc .screen1 .screenshot4 { border: 0; border-radius: 0; float: left; }
          #case-sistemavc .screen1 .screenshot1 { width: 488px; height: 365px; margin: -440px 0 0 200px; }
          #case-sistemavc .screen1 .screenshot2 { width: 461px; height: 366px; margin: -165px 0 0 -230px; }
          #case-sistemavc .screen1 .screenshot3 { width: 492px; height: 369px; margin: 45px 0 0 -130px; }
          #case-sistemavc .screen1 .text3 { width: 330px; margin: 85px -330px 0 170px; float: left; }
          #case-sistemavc .screen1 .screenshot4 { width: 487px; height: 308px; margin: 100px 0 0 -100px; z-index: 0; }
          #case-sistemavc .screen1 .image6 { width: 605px; height: 413px; margin: -80px 0 0 -200px; float: left; }
          #case-sistemavc .screen2 .bg, #case-sistemavc .screen2 .bg div { height: 157px; }
          #case-sistemavc .screen2 .bg { top: auto; bottom: 0; }
          #case-sistemavc .screen2 .bg div { width: 220px; left: 50%; margin-left: -360px; }
          #case-sistemavc .screen2 .image1 { width: 381px; height: 949px; position: absolute; right: 0; top: 485px; }
          #case-sistemavc .screen2 .content { padding-bottom: 170px; }
          #case-sistemavc .screen2 .text1 { width: 275px; float: left; margin: 65px 0 0 0; }
          #case-sistemavc .screen2 .screenshot1 { width: 998px; height: 698px; margin: -310px -1000px 0 190px; float: left; }
          #case-sistemavc .screen2 .screenshot2 { width: 998px; height: 698px; margin: 310px -1000px 0 -610px; float: left; }
          #case-sistemavc .screen2 .screenshot3 { width: 998px; height: 698px; margin: 965px -1000px 0 -355px; float: left; }
          #case-sistemavc .screen2 .image2 { width: 990px; height: 542px; position: absolute; top: 80px; right: 50%; margin-right: 260px; }
          #case-sistemavc .screen2 .graph1 { border-style: solid; border-width: 0 95px 164px 95px; border-color: transparent transparent #f2f2f2 transparent; width: 0; height: 0; position: absolute; top: 845px; right: 50%; margin-right: 205px; }
          #case-sistemavc .screen2 .graph2 { border-style: solid; border-width: 62px 0 62px 107px; border-color: transparent transparent transparent #a6cd39; width: 0; height: 0; position: absolute; bottom: 65px; left: 50%; }
          #case-sistemavc .screen2 .graph3 { border-style: solid; border-width: 28px 48px 28px 0; border-color: transparent #b6edf2 transparent transparent; width: 0; height: 0; position: absolute; bottom: -70px; left: 50%; margin-left: 430px; }
          #case-sistemavc .screen2 .text2 { width: 330px; margin: 130px 0 70px 260px; float: left; }
          #case-sistemavc .screen2 .screenshot4, #case-sistemavc .screen2 .screenshot5, #case-sistemavc .screen2 .screenshot6 { float: right; right: 50%; }
          #case-sistemavc .screen2 .screenshot4 { width: 310px; height: 1849px; margin: -345px -555px 0 0; }
          #case-sistemavc .screen2 .screenshot5 { width: 310px; height: 1313px; margin: 0 -125px 0 0; }
          #case-sistemavc .screen2 .screenshot6 { width: 310px; height: 1032px; margin: 640px 80px 0 0; }
          @media all and (max-width: 900px) {
            #case-sistemavc .screen1 .text0, #case-sistemavc .screen2 .image1, #case-sistemavc .screen2 .graph, #case-sistemavc .screen2 .screenshot4 { display: none; }
            #case-sistemavc .screen1 .bg, #case-sistemavc .screen1 .bg div { height: 215px; }
            #case-sistemavc .screen1 .content { padding-bottom: 80px; }
            #case-sistemavc .screen1 .image1 { width: 277.5px; height: 83.5px; margin: 25px 0 0 -33px; }
            #case-sistemavc .screen1 .image2 { width: 250px; height: 103.5px; margin: 60px 0 0 0; }
            #case-sistemavc .screen1 .text1 { width: 85%; margin: 10px 0 0 20px; z-index: 2; }
            #case-sistemavc .screen1 .image3 { width: 126px; height: 129px; margin: -72px 15px 0 0; float: right; }
            #case-sistemavc .screen1 .image4 { width: 429.5px; height: 353px; margin: -70px 0 0 -430px; right: 0; }
            #case-sistemavc .screen1 .image5 { width: 292px; height: 290.55px; margin: 200px -590px 0 0; left: 50%; }
            #case-sistemavc .screen1 .text2 { width: 80%; margin: 310px 0 0 20px; }
            #case-sistemavc .screen1 .screenshot1 { width: 244px; height: 182.5px; margin: -25px 0 0 0; }
            #case-sistemavc .screen1 .screenshot2 { width: 230.5px; height: 183px; margin: 150px 0 0 -300px; }
            #case-sistemavc .screen1 .screenshot3 { width: 246px; height: 184.5px; margin: 300px -250px 0 -100px; }
            #case-sistemavc .screen1 .text3 { width: 85%; margin: -380px 0 0 10px; border-radius: 15px 15px 0 15px; -webkit-transform-origin: 100% 100%; transform-origin: 100% 100%; }
            #case-sistemavc .screen1 .text3:before { border-width: 0 3px 4px 0; border-radius: 0 0 1px 0; width: 22px; height: 40px; -webkit-transform: skewY(45deg); transform: skewY(45deg); top: auto; bottom: -16px; left: auto; right: -3px; }
            #case-sistemavc .screen1 .screenshot4 { width: 243.5px; height: 154px; margin: -70px 0 0 0; }
            #case-sistemavc .screen1 .image6 { width: 302.5px; height: 206.5px; margin: 50px 0 0 -20px; }
            #case-sistemavc .screen2 .bg, #case-sistemavc .screen2 .bg div { height: 78.5px; }
            #case-sistemavc .screen2 .bg div { width: 110px; left: 10%; margin-left: 0; }
            #case-sistemavc .screen2 .content { padding-bottom: 30px; }
            #case-sistemavc .screen2 .clear1 { z-index: 2; }
            #case-sistemavc .screen2 .text1 { width: 85%; margin: 260px 0 60px 20px; border-radius: 15px 15px 0 15px; -webkit-transform-origin: 100% 100%; transform-origin: 100% 100%; }
            #case-sistemavc .screen2 .text1:before { border-width: 0 3px 4px 0; border-radius: 0 0 1px 0; width: 22px; height: 40px; -webkit-transform: skewY(45deg); transform: skewY(45deg); top: auto; bottom: -16px; left: auto; right: -3px; }
            #case-sistemavc .screen2 .screenshot1 { width: 499px; height: 349px; margin: -555px -500px 0 100px; }
            #case-sistemavc .screen2 .screenshot2 { width: 499px; height: 349px; margin: 0 50px 0 -500px; float: right; }
            #case-sistemavc .screen2 .screenshot3 { width: 499px; height: 349px; margin: 330px -500px 0 50px; }
            #case-sistemavc .screen2 .image2 { width: 495px; height: 271px; top: -120px; right: 5%; margin-right: 0; }
            #case-sistemavc .screen2 .text2 { width: 85%; margin: 25px 0 75px 20px; border-radius: 15px 15px 0 15px; -webkit-transform-origin: 100% 100%; transform-origin: 100% 100%; }
            #case-sistemavc .screen2 .text2:before { border-width: 0 3px 4px 0; border-radius: 0 0 1px 0; width: 22px; height: 40px; -webkit-transform: skewY(45deg); transform: skewY(45deg); top: auto; bottom: -16px; left: auto; right: -3px; }
            #case-sistemavc .screen2 .screenshot5 { width: 155px; height: 656.5px; margin: 0 -165px 0 0; }
            #case-sistemavc .screen2 .screenshot6 { width: 155px; height: 516px; margin: -50px 10px 0 0; }
            #case-sistemavc .clear3{z-index:3;}
          }
        `}} />

        <div className="screen screen1">
          <div className="bg parallax-back"><div></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image1" style={{backgroundImage: `url('/cases/img/sistemavc-img1.png')`}}></div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/sistemavc-img2.png')`}}></div>
              <div className="text bubble right text0 parallax-front">
                <div className="in">
                  <b>Задача: разработка фирменного стиля и корпоративного сайта.</b>
                </div>
              </div>
            </div>
            <div className="clear clear3">
              <div className="text bubble right-bottom text1 parallax-front">
                <div className="in">
                  Идея трансформации, многогранности и последовательности цифровых технологий отражается в элементах фирменного стиля.
                </div>
              </div>
              <div className="image image3" style={{backgroundImage: `url('/cases/img/sistemavc-img3.png')`}}></div>
            </div>
            <div className="clear">
              <div className="image image4" style={{backgroundImage: `url('/cases/img/sistemavc-img4.png')`}}></div>
              <div className="image image5" style={{backgroundImage: `url('/cases/img/sistemavc-img5.jpg')`}}></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  Название компании – «Sistema VC», и элементы фирменного стиля собираются в единую систему.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/sistemavc-img6.jpg')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/sistemavc-img7.jpg')`}}></div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/sistemavc-img8.jpg')`}}></div>
              <div className="text bubble left text3 parallax-front">
                <div className="in">
                  Мы разработали десятки слайдов презентации для венчурного фонда, в дополнение к фирменному стилю.
                </div>
              </div>
              <div className="screenshot screenshot4" style={{backgroundImage: `url('/cases/img/sistemavc-img9.jpg')`}}></div>
            </div>
            <div className="clear">
              <div className="image image6" style={{backgroundImage: `url('/cases/img/sistemavc-img10.png')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/sistemavc-img19.png')`}}></div></div>
          <div className="image image1" style={{backgroundImage: `url('/cases/img/sistemavc-img14.jpg')`}}></div>
          <div className="content narrow">
            <div className="clear clear1">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  Такой системный подход мы перенесли и на сайт
                </div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/sistemavc-img11.jpg')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/sistemavc-img12.jpg')`}}></div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/sistemavc-img13.jpg')`}}></div>
            </div>
            <div className="clear">
              <div className="image image2" style={{backgroundImage: `url('/cases/img/sistemavc-img15.png')`}}></div>
              <div className="graph graph1"></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  Для удобства взаимодействия с интерфейсом в мобильной версии используется упрощенная анимация.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="graph graph2"></div>
              <div className="graph graph3"></div>
              <div className="screenshot-phone screenshot4" style={{backgroundImage: `url('/cases/img/sistemavc-img16.jpg')`}}>
                <div style={{backgroundImage: `url('/cases/img/sistemavc-img16.jpg')`}} />
              </div>
              <div className="screenshot-mob screenshot5" style={{backgroundImage: `url('/cases/img/sistemavc-img17.jpg')`}}></div>
              <div className="screenshot-mob screenshot6" style={{backgroundImage: `url('/cases/img/sistemavc-img18.jpg')`}}></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-sistemavc">
				<style dangerouslySetInnerHTML={{__html: `
					#case-sistemavc .screen0{display:block;position:relative;}
					#case-sistemavc .screen0 .bg{height: 100vh;}
					#case-sistemavc .screen0 .bg div { background-size: contain; width: 591px; max-width: 90%; left: auto; right: 0; }
					#case-sistemavc .screen0 .content { height: 100%; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); }
					#case-sistemavc .screen0 .logo { width: 276px; height: 65px; position: absolute; left: 50%; top: 40%; -webkit-transform: translateY(-50%) translateX(-50%); transform: translateY(-50%) translateX(-50%); }
					#case-sistemavc .screen0 .logo div { background-position: 50% 50%; background-repeat: no-repeat; background-size: contain; width: 100%; height: 100%; }
					#case-sistemavc .screen0 .text { width: 210px; height: 60%; top: 30%; margin-left: -80px; }
					@media all and (max-width: 648px) {
						#case-sistemavc .screen0 .bg{height: 100%;}
						#case-sistemavc .screen0 .bg div { background-position: 50% 90%; }
						#case-sistemavc .screen0 .logo { width: 138px; height: 32.5px; top: 15%; left: 7.5%; -webkit-transform: none; transform: none; }
						#case-sistemavc .screen0 .text { height: 30%; top: 30%; margin-left: 7.5%; }
						#case-sistemavc .screen0 .text .title { line-height: 22px; font-size: 20px; }
					}
				`}} />
				<Link to="/projects/sistemavc/" className="screen screen0 h100">
				<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/sistemavc-img0.png')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/sistemavc-logo.png')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Sistema VC – венчурный фонд.</div>
								<p>Занимается инвестициями<br/>в технологические компании на стадии роста.</p>
							</div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>

  

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/botanica/" }
				title= { this.props.next ? this.props.next.name: "Botanica"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/botanica.jpg"}
			/>

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Sistema VC</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sistemavc)