import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Armani extends Component {
	state = {
		preview: true,
    play: false
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/armani/'){
			this.setState({preview: false});
			this.props.setProjectName('armani');
			$('body').addClass('case-expanded hash-armani');
			$(window).scrollTop($('.armani').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-armani');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-armani');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render(){
    return(
      <div className='case'>
        <div className="case-content">
          <div className="armani">
            <div className="armani-img1">
              <img src="/cases/img/armani/logo.svg" />
            </div>
            <div className="armani-box">
              <img className="shadow armani-img2" src="/cases/img/armani/img2.webp" />
              <div className="text bubble left parallax-front parallax-up armani-text1">
                <div className="in">Сайт отражает суть подхода маэстро Джорджио Армани: вневременная элегантность и внимательность к каждой детали проекта.</div>
              </div>
              <img className="armani-img3" src="/cases/img/armani/img3.webp" />
            </div>
            <div className="armani-box">
              <img className="shadow armani-img4" src="/cases/img/armani/img4.webp" />
              <img className="armani-img5" src="/cases/img/armani/img5.webp" />
            </div>
            <div className="armani-img6"></div>
            <div className="armani-box">
              <img className="shadow armani-img7" src="/cases/img/armani/img7.webp" />
              <img className="armani-img8" src="/cases/img/armani/img8.webp" />
              <img className="armani-img9" src="/cases/img/armani/img9.webp" />
            </div>
            <img className="shadow armani-img10" src="/cases/img/armani/img10.webp" />
            <div className="armani-img11"></div>
            
            <div className="armani-video">
              <div className="armani-video__in">
                <img src="/cases/img/armani/video.webp" />
                <video poster="/cases/img/armani/poster.webp" ref="video">
                  <source src="/cases/img/armani/video.mp4" type="video/mp4" />
                </video>
                {this.state.play || <div className="video__play" onClick={this.handlePlay} />}
                <div className="text bubble left-top parallax-front parallax-up armani-text1">
                  <div className="in">Видео–демонстрация работы сайта</div>
                </div>
              </div>
            </div>
            <div className="armani-box armani-box-end">
              <img className="armani-img12" src="/cases/img/armani/img12.webp" />
              <img className="armani-img17" src="/cases/img/armani/img17.webp" />
              <div className="armani-img14-wrap">
                <div className="text bubble right parallax-front parallax-up armani-text1">
                  <div className="in">Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство</div>
                </div>
                <img className="shadow armani-img14" src="/cases/img/armani/img14.webp" />
              </div>
              <div className="armani-img13-wrap">
                <img className="shadow armani-img13" src="/cases/img/armani/img13.webp" />
              </div>
              <div className="armani-img16-wrap">
                <img className="shadow armani-img16" src="/cases/img/armani/img16.webp" />
              </div>
            </div>
          </div>
        </div>


        
      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/afimall/"  }
				title= { this.props.next ? this.props.next.name: "Афимолл Сити + Маша и Медведь"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/afimall.webp"}
			/>
        <HelmetSite title={`Armani / Casa Эксклюзивные резиденции`} />
      </div>
    )
  }

  handlePlay = () => {
    this.setState({play: true})
    this.refs.video.play()
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Armani)