import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import $ from 'jquery';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import HomeWave from './HomeWave';
import Dog from './Dog';
import {hit} from '../metrics';
import FormBtn from "../helpers/form-btn";
import Alts from '../../components/Alts'

class Home extends Component{

	componentDidMount(){

		this.props.setPageId('home');

		setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
    
    hit(window.location.pathname);
	}

	componentWillUnmount(){
		$('body').removeClass('fadepage');
	}

	render(){
		return(
      <Fragment>
        <Alts />
        {/*<Dog />*/}
        <HomeWave/>
				<FormBtn />
      </Fragment>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Home)