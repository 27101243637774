import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class F92 extends Component {
	state = {
		preview: true
	}

  handleScroll(){
    $('.animate-scroll-element').each(function(){
      const top = $(this).offset().top - window.innerHeight/2;
      const scr = $(window).scrollTop();

      if(scr >= top){
        $(this).addClass('active');
      }
      else{
        $(this).removeClass('active');
      }
    });
  }

	componentDidMount(){
		if(this.props.pathname == '/projects/f92/'){
			this.setState({preview: false});
			this.props.setProjectName('f92');
			$('body').addClass('case-expanded hash-f92');
			$(window).scrollTop($('.f92').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('f92');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);

    window.addEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-f92');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
    window.removeEventListener('scroll', this.handleScroll);
  }

  render(){
    return(
      <div className='case'>
        <div className="case-content">
          <div className="f92">
            <div className="f92__bg1">
              <img src="/cases/img/f92/logo.svg" />
            </div>

            <div className="f92_img2">
              <img className="shadow" src="/cases/img/f92/img2.webp" />
              <div className="animate-scroll-element last"><img className="shadow" src="/cases/img/f92/img3.webp" /></div>

              <div className="text bubble left parallax-front parallax-up">
                <div className="in">Сайт для одних из самых высоких офисов в Москве. Офисы на 92 этаже башни Федерация</div>
              </div>
            </div>

            <img className="f92_img3" src="/cases/img/f92/img4.webp" />

            <div className="f92_img4">
              <img src="/cases/img/f92/img5.webp" className="animate-scroll-element" />
            </div>

            <div className="f92_img5">
              <img src="/cases/img/f92/img6.webp" />
              <img className="shadow" src="/cases/img/f92/img7.webp" />
              <div className="text bubble right parallax-front parallax-up">
                <div className="in">Впечатляющие панорамы и виды погружают в атмосферу работы на высоте 330 метров</div>
              </div>
            </div>

            <div className="f92_img6">
              <img className="shadow" src="/cases/img/f92/img8.webp" />
              <img src="/cases/img/f92/img9.webp" />
            </div>

            <div className="f92_img4 f92_img4-6">
              <img src="/cases/img/f92/img10.webp" className="animate-scroll-element" />
            </div>

            <div className="f92_img7">
              <div className="f92_img7-in">
                <img src="/cases/img/f92/img11.webp" />
              </div>
              <div className="f92_img7-in">
                <img src="/cases/img/f92/img12.webp" />
                <img src="/cases/img/f92/img14.webp" />
              </div>
              <div className="f92_img7-in">
                <img src="/cases/img/f92/img13.webp" />
              </div>

              <div className="text bubble right parallax-front parallax-up">
                <div className="in">Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство</div>
              </div>
            </div>
          </div>
        </div>



      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/bsg/"  }
				title= { this.props.name ? this.props.next.name: "BCG Woman Talks"   } 
				img={ this.props.name ? this.props.next.name:"/cases/img/next/bsg.webp"}
			/>
        <HelmetSite title={`Федерация 92`} />
      </div>
    )
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(F92)