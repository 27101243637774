import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Sistemavc extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/sistemavc/'){
			this.setState({preview: false});
			this.props.setProjectName('Sistema VC');
			$('body').addClass('case-expanded hash-sistemavc');
			$(window).scrollTop($('#case-sistemavc').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-sistemavc');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-sistemavc');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <style dangerouslySetInnerHTML={{__html: `
          
        `}} />

        <div className="screen screen1">
          <div className="bg parallax-back"><div></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image1" style={{backgroundImage: `url('/cases/img/sistemavc/img1.webp')`}}></div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/sistemavc/img2.webp')`}}></div>
              <div className="text bubble right text0 parallax-front">
                <div className="in">
                  <b>Задача: разработка фирменного стиля и корпоративного сайта.</b>
                </div>
              </div>
            </div>
            <div className="clear clear3">
              <div className="text bubble right-bottom text1 parallax-front">
                <div className="in">
                  Идея трансформации, многогранности и последовательности цифровых технологий отражается в элементах фирменного стиля.
                </div>
              </div>
              <div className="image image3" style={{backgroundImage: `url('/cases/img/sistemavc/img3.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="image image4" style={{backgroundImage: `url('/cases/img/sistemavc/img4.webp')`}}></div>
              <div className="image image5" style={{backgroundImage: `url('/cases/img/sistemavc/img5.webp')`}}></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  Название компании – «Sistema VC», и элементы фирменного стиля собираются в единую систему.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/sistemavc/img6.webp')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/sistemavc/img7.webp')`}}></div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/sistemavc/img8.webp')`}}></div>
              <div className="text bubble left text3 parallax-front">
                <div className="in">
                  Мы разработали десятки слайдов презентации для венчурного фонда, в дополнение к фирменному стилю.
                </div>
              </div>
              <div className="screenshot screenshot4" style={{backgroundImage: `url('/cases/img/sistemavc/img9.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="image image6" style={{backgroundImage: `url('/cases/img/sistemavc/img10.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/sistemavc/img19.webp')`}}></div></div>
          <div className="image image1" style={{backgroundImage: `url('/cases/img/sistemavc/img14.webp')`}}></div>
          <div className="content narrow">
            <div className="clear clear1">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  Такой системный подход мы перенесли и на сайт
                </div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/sistemavc/img11.webp')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/sistemavc/img12.webp')`}}></div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/sistemavc/img13.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="image image2" style={{backgroundImage: `url('/cases/img/sistemavc/img15.webp')`}}></div>
              <div className="graph graph1"></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  Для удобства взаимодействия с интерфейсом в мобильной версии используется упрощенная анимация.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="graph graph2"></div>
              <div className="graph graph3"></div>
              <div className="screenshot-phone screenshot4" style={{backgroundImage: `url('/cases/img/sistemavc/img16.webp')`}}>
                <div style={{backgroundImage: `url('/cases/img/sistemavc/img16.webp')`}} />
              </div>
              <div className="screenshot-mob screenshot5" style={{backgroundImage: `url('/cases/img/sistemavc/img17.webp')`}}></div>
              <div className="screenshot-mob screenshot6" style={{backgroundImage: `url('/cases/img/sistemavc/img18.webp')`}}></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-sistemavc">
				<Link to="/projects/sistemavc/" className="screen screen0 h100">
				<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/sistemavc/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/sistemavc/logo.webp')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Sistema VC – венчурный фонд.</div>
								<p>Занимается инвестициями<br/>в технологические компании на стадии роста.</p>
							</div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/botanica/" }
				title= { this.props.next ? this.props.next.name: "ЖК “Ботаника”"} 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/botanica.webp" }
			/>
      <HelmetSite title="Systema VC" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Sistema VC</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sistemavc)