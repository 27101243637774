import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Dellin extends Component {
	videoRef = React.createRef();

	state = {
		preview: true,
		play: false,
	};

	componentDidMount() {
		$(".ahead-img1").on("mousemove", function(e) {
			var x = (e.pageX * 100) / $(window).width();
			var y = (e.pageY * 100) / $(window).height();
			$(".ahead-ya1,.ahead-ya2").css({ left: x + "%", top: y + "%" });
			$(".ahead-hn1,.ahead-hn2").css({
				transform: "translate(-" + x / 10 + "%," + y / 8 + "%)",
			});
			// $('.ahead-ya1,.ahead-ya2').css({'transform': 'translate(-'+(x/2)+'%,'+(y/2)+'%)'});
		});

		if (this.props.pathname == "/projects/ahead/") {
			this.setState({ preview: false });
			this.props.setProjectName("Ahead");
			$("body").addClass("case-expanded hash-ahead");
			$(window).scrollTop($("#case-ahead").offset().top);
			this.props.changeCase(null);
		} else {
			this.setState({ preview: true });
			$("body").removeClass("hash-ahead");
		}
		window.scrollTo(0, 0);
		hit(window.location.pathname);

		setTimeout(() => {
			$("body").addClass("fadepage");
		}, 100);

		window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		// create the smooth scroller FIRST!
		let smoother = window.ScrollSmoother.create({
			smooth: 2,
			effects: true,
			smoothTouch: false,
			normalizeScroll: true,
		});

		window.gsap.set(".square", { transformOrigin: "center	" });
		window.gsap.set(".square1", { transformOrigin: "center	" });
		window.gsap.set(".square2", { transformOrigin: "botton	" });
		let numOfRotations = 1.1;
		let numOfRotations1 = 1.1;
		let numOfRotations2 = 1.1;

		const line = window.gsap.utils.toArray(".line");

		line.forEach((line) => {
			window.gsap.to(line, {
				scrollTrigger: {
					invalidateOnRefresh: true,
					trigger: line,
					scrub: true,
					start: "0 100%",
					end: "200% 0",
				},
				left: -1200,
			});
		});

		var rotate = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square", {
				rotation: () => 30 * numOfRotations,
				duration: 2,
				ease: "none",
			});

		var rotate1 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper1",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square1", {
				rotation: () => 40 * numOfRotations1,
				duration: 2,
				ease: "none",
			});

		var rotate2 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper2",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square2", {
				rotation: () => -60 * numOfRotations2,
				duration: 2,
				ease: "none",
			});
	}

	componentDidUpdate() {
		if (this.props.pathname == "/projects/") {
			$("body").removeClass("case-expanded hash-ahead");
		}

		if (this.state.play) {
			if (this.videoRef.current) {
				this.videoRef.current.play();

				this.videoRef.current.onended = () => {
					this.setState({ play: false });
				};
			}
		}
	}

	componentWillUnmount() {
		$("body").removeClass("fadepage");
	}

	render() {
		return (
			<div id="smooth-wrapper" className="case ahead crimea dellin">
				<div
					id="smooth-content"
					className="case-content "
					ref={(el) => (this.scene = el)}
				>
					<div className="logoWrap">
						<img className="logo-dellin" src="/cases/img/dellin/logo.svg" />
					</div>

					<div className="ahead-img1">
					<img className="ahead-ys" src="/cases/img/dellin/ys.svg" />
						<img className="ahead-flov1" src="/cases/img/dellin/flov1.svg" />
						<img className="ahead-flov2" src="/cases/img/dellin/flov2.svg" />
            <div className="mobBg" style={{ backgroundImage: "url('/cases/img/dellin/img1.webp')"}}></div>
						<img className="ahead-hn1" src="/cases/img/dellin/hn1.svg" />
						<img className="ahead-hn2" src="/cases/img/dellin/hn2.svg" />
						<div className="ahead-main-wrap" style={{ backgroundImage: "url('/cases/img/dellin/main1.svg')"}}>
							<div className="ahead-ya1-wrap">
								<img className="ahead-ya1" src="/cases/img/dellin/ya1.svg" />
							</div>
							<div className="ahead-ya2-wrap">
								<img className="ahead-ya2" src="/cases/img/dellin/ya2.svg" />
							</div>
			
						</div>
					</div>
					<div className="ahead-box1 ">
						<img
							className="ahead-img1-2 square2"
							src="/cases/img/dellin/img1-2.svg"
						/>
						<img
							className="shadow ahead-img2"
							src="/cases/img/dellin/img2.webp"
						/>
						<img
							data-speed="0.9"
							className="ahead-img3"
							src="/cases/img/dellin/img3.webp"
						/>
						<img
							className="shadow ahead-img5"
							src="/cases/img/dellin/img5.webp"
						/>

						<div className="text bubble left parallax-front parallax-up">
							<div className="in">
								Вот, так поздравляли с гендерными праздниками своих сотрудников
								в компании <span>Деловые Линии!</span>
								<br />
								<br /> Эти стремительные персонажи - агенты, у каждого задание:
								поздравить клиента, кто быстрее и лучше?! А, ну?!
							</div>
						</div>
						<div className="text bubble right bubl-2 parallax-front parallax-up">
							<div className="in">
								Внимание, даже, к мелочам! “Заглушка” с анимацией, очень
								интересно, что же дальше будет? :)
							</div>
						</div>
					</div>
					<div className="dellin-box2">
						<div className="text bubble bubble-45 left parallax-front parallax-up">
							<div className="in">
								Строгий и внимательный агент проверит правильное заполнение!
							</div>
						</div>
						<img
							className=" dellin-point square1"
							src="/cases/img/dellin/point.svg"
						/>
						<img
							className=" dellin-car square"
							src="/cases/img/dellin/car.svg"
						/>
						<img
							data-speed="0.9"
							className=" dellin-back"
							src="/cases/img/dellin/back-1.webp"
						/>
						<img
							className="shadow dellin-img6"
							src="/cases/img/dellin/img6.webp"
						/>
						<img
							className="shadow dellin-img7"
							src="/cases/img/dellin/img7.webp"
						/>
						<img
							className="shadow dellin-img8"
							src="/cases/img/dellin/img8.webp"
						/>
					</div>
					<div className="dellin-box3">
						<div className="text bubble right bubl-3 parallax-front parallax-up">
							<div className="in">
								Надо постараться, чтобы получить приз! А какие призы, ммммм!
							</div>
						</div>
						<img
							data-speed="0.9"
							className=" dellin-back-2"
							src="/cases/img/dellin/back-2.webp"
						/>
						<img
							className=" dellin-point2"
							src="/cases/img/dellin/point2.svg"
						/>
						<img
							className="shadow dellin-img9"
							src="/cases/img/dellin/img9.webp"
						/>
						<img
							className="shadow dellin-img10"
							src="/cases/img/dellin/img10.webp"
						/>
						<img
							className="shadow dellin-img11"
							src="/cases/img/dellin/img11.webp"
						/>
					</div>
					<div className="cto-slider">
						<div className="slider ">
							<div className="line">
								<div className="item">
									<img src="/cases/img/dellin/h10.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h11.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h1.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h2.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h3.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h4.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h5.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h6.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h7.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h8.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h9.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h10.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h11.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h1.svg" />
								</div>
								<div className="item">
									<img src="/cases/img/dellin/h2.svg" />
								</div>
							</div>
						</div>
					</div>
					<div className="ahead-imgs">
						<div>
							<img src="/cases/img/dellin/img12.webp" />
							<img
								className=" dellin-car2 square"
								src="/cases/img/dellin/car-2.svg"
							/>
						</div>
						<div className="crimea-midl-inm">
							<img src="/cases/img/dellin/img13.webp" />
						</div>
						<div>
							<img src="/cases/img/dellin/img14.webp" />
						</div>
					</div>

	

            <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/crimea/"  }
				title= { this.props.next ? this.props.next.name: "Крымская Резиденция"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/crimea.webp"}
			/>
				</div>

				<HelmetSite title={`Деловые Линии - Дело 0823`} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dellin);
