import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import Contact from './Contact';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import {hit} from '../metrics';


class ContactEmpty extends Component{

	componentDidMount(){
		this.props.setPageId('contact');
		setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
    hit(window.location.pathname);
	}

	componentWillUnmount(){
		$('body').removeClass('fadepage');
	}

	render(){
		return <Contact />;
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ContactEmpty)