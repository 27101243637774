import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Fabcab extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/fabcab/'){
			this.setState({preview: false});
			this.props.setProjectName('Fab cab');
			$('body').addClass('case-expanded hash-fabcab');
			$(window).scrollTop($('#case-fabcab').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-fabcab');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-fabcab');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-fabcab">

        <div className="screen screen1">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/fabcab/bg1.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble left parallax-front">
              <div className="in">
                <p>Логотип и реклама для лучшего сервиса такси во всей юго-восточной Азии</p>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/fabcab/bg2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble right parallax-front">
              <div className="in">
                <p>Логотип состоит из написания, которое может меняться и даже менять шрифт части “fab”</p>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/fabcab/bg3.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble right parallax-front">
              <div className="in">
                <p>Варианты написания дополняются различными символами целей поездки на такси</p>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/fabcab/bg4-2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble left parallax-front">
              <div className="in">
                <p>Можно использовать различные цвета фона</p>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="screen5-bg" />
          <div className="clear">
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item item1"><span style={{backgroundImage: `url('/cases/img/fabcab/sl1.webp')`}}></span></div>
                <div className="item item2"><span style={{backgroundImage: `url('/cases/img/fabcab/sl2.webp')`}}></span></div>
                <div className="item item3"><span style={{backgroundImage: `url('/cases/img/fabcab/sl3.webp')`}}></span></div>
                <div className="item item4"><span style={{backgroundImage: `url('/cases/img/fabcab/sl4.webp')`}}></span></div>
                <div className="item item5"><span style={{backgroundImage: `url('/cases/img/fabcab/sl5.webp')`}}></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/fabcab/bg6-3.webp')`}}></div></div>
        </div>
        <div className="screen screen7">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/fabcab/bg7.webp')`}}></div></div>
        </div>
        <div className="screen screen8">
          <img src="/cases/img/fabcab/bg8.webp" />
        </div>
        <div className="screen screen9">
          <img className="img1" src="/cases/img/fabcab/bg9-1.webp" />
          <img className="img2" src="/cases/img/fabcab/bg9-2.webp" />
        </div>
			</div>

 
      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/polyanka/"}
				title= { this.props.next ? this.props.next.name: "Полянка 44"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/polyanka.webp" }
			/>

      <HelmetSite title="FabCab" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Fab cab</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Fabcab)