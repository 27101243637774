import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Fsklider extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/fsklider/'){
			this.setState({preview: false});
			this.props.setProjectName('ФСК Лидер');
			$('body').addClass('case-expanded hash-lider');
			$(window).scrollTop($('#case-lider').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-lider');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-lider');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p><b>Проект, который объединил всё. Разработка сайта для девелопера ФСК Лидер.</b></p>
                  <p>Нам нужно было показать жилые и коммерческие объекты застройщика, помочь покупателям легко сделать выбор и забронировать квартиру, а также подать информацию для инвесторов и прессы.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/fsklider/img1.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/fsklider/img2.webp')`}}></div></div>
              <div className="text bubble left-top text2 parallax-front">
                <div className="in">
                  <p><b>Главная страница</b></p>
                  <p>Дизайнеры последовали принципу «ритмично и логично». Ведь при выборе квартиры важно не запутать покупателя.</p>
                  <p>На сайте 2 меню – основное и второстепенное, по степени важности разделов для покупателя.</p>
                  <p>На первой странице мы выделили новостройки, проработали инструменты для выбора квартиры: краткий и подробный фильтр, интерактивную карту, список домов с фото и основной информацией о жилье.</p>
                </div>
              </div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/fsklider/img3.webp')`}}></div></div>
              <div className="text bubble left text3 parallax-front">
                <div className="in">
                  <p><b>Страница дома</b></p>
                  <p>Мы решили совместить имиджевую сторону объекта с информационной. Поэтому после яркого рендера дома мы структурированно подали всё самое важное: выбор квартиры, стоимость, сроки, площадь, расположение, инфраструктуру, отделку, галерею.</p>
                  <p>Поиск квартиры пошаговый: от генплана до плана этажа. В любом месте можно вернуться к любому этапу подбора.</p>
                </div>
              </div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/fsklider/img4.webp')`}}></div></div>
              <div className="screenshot screenshot5"><div style={{backgroundImage: `url('/cases/img/fsklider/img5.webp')`}}></div></div>
              <div className="text bubble left text4 parallax-front">
                <div className="in">
                  Мы разработали 40 макетов внутренних страниц сайта. В процессе выработали правило для подачи информации: легко, но ёмко и доступно.
                </div>
              </div>
              <div className="screenshot screenshot6"><div style={{backgroundImage: `url('/cases/img/fsklider/img6.webp')`}}></div></div>
            </div>
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/fsklider/img7.webp')`}}></div></div>
            <div className="image image2"><div style={{backgroundImage: `url('/cases/img/fsklider/img8.webp')`}}></div></div>
            <div className="image image3"><div style={{backgroundImage: `url('/cases/img/fsklider/img9.webp')`}}></div></div>
            <div className="image image4"><div style={{backgroundImage: `url('/cases/img/fsklider/img10.webp')`}}></div></div>
            <div className="image image5"><div style={{backgroundImage: `url('/cases/img/fsklider/img11.webp')`}}></div></div>
            <div className="image image6"><div style={{backgroundImage: `url('/cases/img/fsklider/img12.webp')`}}></div></div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="content narrow">
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-1.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-2.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-3.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-4.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-5.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-6.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-7.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-8.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-9.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-10.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/fsklider/img13-11.webp')`}}></div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/fsklider/img16.webp')`}}></div></div>
              <div className="text bubble left-top text1 parallax-front">
                <div className="in">
                  Не будем скрывать, мы любим иконки. Для этого проекта мы сделали их более 30, в цветах и стиле новой концепции.
                </div>
              </div>
              <div className="screenshot-mob screenshot2"><div style={{backgroundImage: `url('/cases/img/fsklider/img17.webp')`}}></div></div>
              <div className="screenshot-mob screenshot3"><div style={{backgroundImage: `url('/cases/img/fsklider/img18.webp')`}}></div></div>
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  Сайт адаптируется ко всем устройствам: монитору компьютера, планшету и смартфону.
                </div>
              </div>
            </div>
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/fsklider/img14.webp')`}}></div></div>
            <div className="image image2"><div style={{backgroundImage: `url('/cases/img/fsklider/img15.webp')`}}></div></div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-lider">
				<Link to="/projects/fsklider/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/fsklider/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/fsklider/logo.webp')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">ФСК Лидер</div>
								<p>
									<b>ФСК Лидер входит в десятку крупнейших застройщиков страны по версии Forbes и РБК.</b>
									<br/><b>ФСК Лидер – это:</b>
								</p>
								<p>
									5,5 млн кв. км застройки
									<br/>22 жилых комплекса
									<br/>7,5 тыс мест в детских садах и школах
									<br/>11 лет на рынке недвижимости
								</p>
							</div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">ФСК Лидер</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Fsklider)