import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {hit} from '../../metrics';

import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';

import style from './style.css';

class X5values extends Component{

  componentDidMount(){
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage');
    }, 100);
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
  render(){
    return(
    <div className='case'>
      <div className="case-content">
        <div className="hunter">
          <div className="hunter__box hunter__box1">
            <div className="hunter__bg" />
            <div className="hunter__logo" />
          </div>

          <div className="hunter__box hunter__box2">
            <img src="/cases/hunter/2.webp" />
            <div className="text bubble right parallax-front text1 parallax-up">
              <div className="in">Нами был разработан логотип и фирменный стиль для дома отдыха с возможностью охоты</div>
            </div>
          </div>

          <div className="hunter__box hunter__box3">
            <img src="/cases/hunter/3.webp" />
          </div>

          <div className="hunter__box hunter__box4">
            <img src="/cases/hunter/4.webp" />
          </div>

          <div className="hunter__box hunter__box5">
            <img className="shadow" src="/cases/hunter/5.webp" />
            <div className="text bubble left parallax-front text1 parallax-up">
              <div className="in">Сайт поддерживает и развивает заданный фирменный стиль.  На сайте подробно рассказано об усадьбе.</div>
            </div>
            <div className="hunter__icon" />

          </div>

          <div className="hunter__box hunter__box6">
            <img className="shadow" src="/cases/hunter/6.webp" />
            <div className="hunter__icon" />
          </div>

          <div className="hunter__box hunter__box7">
            <img className="shadow" src="/cases/hunter/7.webp" />
          </div>

          <div className="hunter__bg2"></div>

          <div className="hunter__box hunter__box8">
            <img src="/cases/hunter/8.webp" />
            <div className="text bubble right parallax-front text1 parallax-up">
              <div className="in">Адаптация сайта под мобильные устройства полностью поддерживает функционал.</div>
            </div>
          </div>
        </div>

      </div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/tvz/"   }
				title= { this.props.next ? this.props.next.name: "Тульский винокуренный завод"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/tvz.webp"}
			/>


      <HelmetSite title={`Усадьба охотника`} />

    </div>
    )
  }
}

export default X5values;