import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';

import AboutDesctope from './AboutDesctope';
import AboutMobile from './AboutMobile';
import {hit} from '../metrics';

class About extends Component{

	state = {
		windowWidth: window.innerWidth
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		this.props.setPageId('about');
		setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
    hit(window.location.pathname);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
		$('body').removeClass('right-side left-side fadepage');
	}

	handleResize = () => {
    if(window.innerWidth != this.state.windowWidth){
      this.setState({
        windowWidth: window.innerWidth
      });
    }
	}

	render(){
		return this.state.windowWidth < 900 ? <AboutMobile/> : <AboutDesctope/>
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(About)