import React, { useEffect } from 'react';
import $ from 'jquery'
import "jquery.transit"

const Alt3 = () => {

  useEffect(() => {
    $('.alt-contacts video').on('timeupdate', function(){
      const w =  $('.alt-contacts video').width()

      $('.alt-contacts-chel').toggleClass('active')

      $('.alt-contacts-chel div').css({x: this.currentTime * w / this.duration})
    })
  },[]);

  return(
    <div className="alt-contacts">
      <div>
        <video playsInline loop autoPlay muted>
          <source src="/img/alts/path.mp4" type="video/mp4" />
        </video>
        <div className="alt-contacts-chel">
          <div>
            <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.87939 5.58743C7.41985 5.58743 8.67311 4.33418 8.67311 2.79371C8.67311 1.25325 7.41985 0 5.87939 0C4.33893 0 3.08594 1.25325 3.08594 2.79371C3.08594 4.33418 4.33893 5.58743 5.87939 5.58743Z" fill="black"/>
              <path d="M11.0879 13.3636L8.17924 6.79052C8.02996 6.45381 7.64504 6.22668 7.27069 6.24808C7.25342 6.24679 7.23691 6.24292 7.21913 6.24292H4.84694H4.54143H4.26685C3.8974 6.24292 3.45653 6.46903 3.241 6.76938L1.03047 9.84928C0.808228 10.1592 0.780641 10.6622 0.967817 10.9942L2.75759 14.1716C2.9409 14.4972 3.37738 14.7522 3.75096 14.7522H3.75431L3.70223 17.556C3.69579 17.9053 3.62695 18.489 3.55192 18.8304L2.67947 22.8033C2.62507 23.0503 2.67406 23.295 2.81663 23.492C2.95946 23.6892 3.1768 23.8117 3.42869 23.8369L3.83759 23.8779C3.86904 23.881 3.9005 23.8826 3.93169 23.8826C4.39911 23.8826 4.82658 23.5415 4.92584 23.0887L5.79855 19.116C5.88053 18.743 5.96252 18.1299 5.98134 17.7493L6.0476 16.418L6.28711 17.8298C6.34744 18.1858 6.41319 18.7698 6.4333 19.1312L6.66095 23.1921C6.68673 23.6526 7.04948 23.9999 7.50453 23.9999C7.55532 23.9999 7.6074 23.9955 7.66025 23.9865L8.06503 23.9163C8.55385 23.8315 8.92897 23.3594 8.90139 22.8639L8.67399 18.8025C8.65285 18.4269 8.59536 17.8195 8.5456 17.449L7.99206 13.3105V11.407L9.29946 14.2595C9.42141 14.5256 9.68773 14.6975 9.97778 14.6975C10.0974 14.6975 10.2175 14.6679 10.3253 14.6119L10.7216 14.4062C11.0931 14.2134 11.2571 13.7457 11.0879 13.3636ZM3.76798 12.2439L2.96926 10.9427C2.87412 10.788 2.88237 10.5114 2.98601 10.3623L3.76798 9.24031V12.2439Z" fill="black"/>
            </svg>
            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.8415 12.1353L11.9214 10.4501C11.7341 10.3421 11.5078 10.0546 11.4469 9.84712L10.5424 6.76974C10.5199 6.69276 10.4858 6.62404 10.444 6.56358C10.3125 6.32178 10.059 6.15747 9.7653 6.15747L5.90438 6.3241C5.73516 6.33134 5.57783 6.3954 5.44892 6.50158C5.42309 6.51734 5.39622 6.53258 5.37297 6.55041L2.7495 8.57375C2.44647 8.80755 2.27674 9.2829 2.36354 9.65568L3.40233 14.125C3.48888 14.4981 3.84591 14.7796 4.23213 14.7796C4.25822 14.7796 4.28457 14.7784 4.31066 14.7758L4.69844 14.737C4.90614 14.7161 5.0937 14.6107 5.21254 14.4474C5.33163 14.2841 5.37478 14.0733 5.33112 13.8692L4.59252 10.4191C4.55376 10.238 4.65193 9.97009 4.79867 9.85719L5.26601 9.49732L5.50627 15.0372C5.50653 15.0432 5.50834 15.0486 5.50886 15.0545L4.44242 17.4199C4.30808 17.7178 3.97069 18.1303 3.70563 18.3213L1.20539 20.1214C0.802634 20.4112 0.65047 20.991 0.858694 21.4415L1.20074 22.1806C1.33714 22.4754 1.61073 22.6586 1.91506 22.6586C2.08918 22.6586 2.26046 22.5994 2.4103 22.4881L5.7114 20.0299C6.04027 19.785 6.44586 19.3084 6.63471 18.9444L8.28268 15.7704C8.29585 15.7448 8.30412 15.7174 8.31497 15.6908L8.42993 15.6859L11.1273 23.1226C11.3177 23.6475 11.8256 24.0004 12.3911 24.0004C12.5254 24.0004 12.6582 23.98 12.7858 23.9394L12.9522 23.8865C13.2819 23.7818 13.5467 23.5553 13.698 23.2484C13.8492 22.9415 13.8675 22.5932 13.7497 22.2682L10.9092 14.4376C10.8961 14.4014 10.878 14.3684 10.8622 14.334L10.771 12.2326L13.9027 13.8594C14.0127 13.9168 14.1367 13.947 14.2615 13.947C14.5514 13.947 14.8167 13.7902 14.9538 13.5378L15.1401 13.1947C15.3427 12.8224 15.2086 12.3471 14.8415 12.1353Z" fill="black"/>
              <path d="M7.40927 5.59914C7.44983 5.59914 7.49091 5.59836 7.53199 5.59655C8.27885 5.56426 8.96863 5.24262 9.47394 4.69158C9.97926 4.14054 10.2397 3.42544 10.2074 2.67858C10.1407 1.13601 8.8516 -0.0642436 7.2894 0.0026671C6.54253 0.0349599 5.85276 0.356596 5.34744 0.90764C4.84212 1.45894 4.58172 2.17378 4.61401 2.92064C4.67911 4.42265 5.90701 5.59914 7.40927 5.59914Z" fill="black"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Alt3