import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import $ from 'jquery';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import {hit} from '../metrics';
import FormBtn from "../helpers/form-btn";
// import Alts from '../Alts';
import PostList from './PostList';

import posts from './posts.js';

import "./style.css";

class NotFormat extends Component{

	componentDidMount(){

		this.props.setPageId('notformat');

		$('body').addClass('page-notformat tags-expanded hash-notformat');

		setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
    
		window.scrollTo(0, 0);

    hit(window.location.pathname);
	}

	componentWillUnmount(){
		$('body').removeClass('fadepage');
	}

	render(){

		return(
      <Fragment>
        {/* <Alts /> */}
        <PostList posts={posts}/>
				<FormBtn />
      </Fragment>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NotFormat)