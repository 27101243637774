import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Soyuz extends Component {
	videoRef = React.createRef();

	state = {
		preview: true,
		play: false,
	};

	componentDidMount() {
		if (this.props.pathname == "/projects/soyuz/") {
			this.setState({ preview: false });
			this.props.setProjectName("Soyuz");
			$("body").addClass("case-expanded hash-soyuz");
			$(window).scrollTop($("#case-soyuz").offset().top);
			this.props.changeCase(null);
		} else {
			this.setState({ preview: true });
			$("body").removeClass("hash-soyuz");
		}
		window.scrollTo(0, 0);
		hit(window.location.pathname);

		setTimeout(() => {
			$("body").addClass("fadepage");
		}, 100);

		window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		// create the smooth scroller FIRST!
		let smoother = window.ScrollSmoother.create({
			smooth: 2, // seconds it takes to "catch up" to native scroll position
			effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
		});

		var moveLogo = window.ScrollTrigger.batch(".soyuz-img1", {
			// start: mobile ? '0 80%' : '0 20%',
			start: '0 5%',
			onEnter: (batch) => batch[0].classList.add('animated'),
			onLeaveBack: (batch) => batch[0].classList.remove('animated'),
		});

		var moveImgs = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".soyuz-img-box1",
					scrub: true,
					start: "center bottom",
					end: "center top",
				},
			}, 0)
			.from(".soyuz-img5-1", {
				y: '28%'
			}, 0)
			.from(".soyuz-img5-2", {
				y: '18%'
			}, 0)
			.from(".soyuz-img5-3", {
				y: '24%'
			}, 0)
			.from(".soyuz-img5-4", {
				y: '18%'
			}, 0)
			.from(".soyuz-img5-5", {
				y: '26%'
			}, 0)
			.from(".soyuz-img5-6", {
				y: '17%'
			}, 0);

		window.gsap.set(".square", { transformOrigin: "center	" });
		window.gsap.set(".square1", { transformOrigin: "center	" });
		window.gsap.set(".square2", { transformOrigin: "center	" });

		var rotate = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".soyuz-circle-wrapper1",
					scrub: 2,
					start: "top bottom",
					end: "bottom top",
				},
			})
			.from(".soyuz-circle1-1", {
				rotation: '+=180',
				duration: 2,
				ease: "none",
			}, 0)
			.from(".soyuz-circle1-2", {
				rotation: '-=180',
				duration: 2,
				ease: "none",
			}, 0)
			.from(".soyuz-circle1-3", {
				rotation: '+=180',
				duration: 2,
				ease: "none",
			}, 0);

		// var rotate2 = window.gsap
		// 	.timeline({
		// 		scrollTrigger: {
		// 			trigger: ".wrapper2",
		// 			scrub: 0.2,
		// 			start: "top center",
		// 			end: "bottom top",
		// 		},
		// 	})
		// 	.to(".square2", {
		// 		rotation: () => 360 * numOfRotations2,
		// 		duration: 2,
		// 		ease: "none",
		// 	});
	}

	componentDidUpdate() {
		if (this.props.pathname == "/projects/") {
			$("body").removeClass("case-expanded hash-ahead");
		}

		if (this.state.play) {
			if (this.videoRef.current) {
				this.videoRef.current.play();

				this.videoRef.current.onended = () => {
					this.setState({ play: false });
				};
			}
		}
	}

	componentWillUnmount() {
		$("body").removeClass("fadepage");
	}

	render() {
		return (
			<div id="smooth-wrapper" className="case soyuz">
				<div
					id="smooth-content"
					className="case-content "
					ref={(el) => (this.scene = el)}
				>
					<div
						className="soyuz-img1"
						style={{ backgroundImage: "url('/cases/img/soyuz/img1_back.webp')" }}
					>
						<div class="soyuz-img1-svg">
							<svg viewBox="0 0 1392 215" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M304.834 107.344C304.834 32.981 356.785 0 460.066 0c103.28 0 155.543 32.981 155.543 107.344 0 74.364-52.263 107.656-155.543 107.656-103.281 0-155.232-33.292-155.232-107.656zm71.861 0c0 41.072 21.154 46.983 83.371 46.983 62.528 0 83.681-5.911 83.681-46.983 0-41.382-21.153-46.671-83.681-46.671-62.217 0-83.371 5.29-83.371 46.671zM230.204 64.096H126.923c-33.286 0-54.75 10.579-54.75 41.382 0 31.114 21.464 46.36 54.75 46.36h149.321l-46.04 59.74H126.923C53.818 211.578 0 165.84 0 105.478 0 44.494 53.818 3.423 126.923 3.423h149.321l-46.04 60.673zM935.77 0c-84.533 0-134.657 22.113-150.077 70.91H734.2V3.422h-71.86v208.155h71.86v-69.319h51.037C800.086 192.2 850.355 215 935.77 215c103.28 0 155.54-33.292 155.54-107.656C1091.31 32.981 1039.05 0 935.77 0zm0 154.327c-62.217 0-83.371-5.911-83.371-46.983 0-41.382 21.154-46.671 83.371-46.671 62.529 0 83.68 5.29 83.68 46.671 0 41.072-21.151 46.983-83.68 46.983zm452.19-93.031c0 24.58-13.38 37.648-27.69 43.249 18.04 5.289 31.73 21.469 31.73 45.737 0 35.16-18.67 61.296-86.48 61.296h-186.34l45.73-59.74h140.92c10.89 0 14.31-3.422 14.31-9.645 0-6.845-4.05-10.268-16.49-10.268h-125.06l37.02-49.472h88.97c8.09 0 14.63-.933 14.63-9.956 0-7.468-7.47-8.401-13.07-8.401h-176.39l46.36-60.673h127.23c51.33 0 84.62 19.29 84.62 57.873z" fill="#fff"></path>
							</svg>
						</div>
						<span style={{ backgroundImage: "url('/cases/img/soyuz/img1_front.webp')" }}></span>
					</div>

					<div className="soyuz-box soyuz-box1">
						<img
							className="soyuz-img2 shadow"
							src="/cases/img/soyuz/img2.webp"
						/>
						<img 
							className="soyuz-img3" 
							src="/cases/img/soyuz/img3.webp" 
						/>
						<div className="text text1 bubble right parallax-front parallax-up">
							<div className="in">
								Жилой комплекс бизнес-
								класса со&nbsp;сложившейся 
								уникальной спортивной 
								инфраструктурой.
							</div>
						</div>
					</div>
							
					<div className="soyuz-box soyuz-box2">
						<img
							className="soyuz-img4 shadow"
							src="/cases/img/soyuz/img4.webp"
						/>
						<div className="soyuz-img-box1">
							<div className="in">
								<div className="soyuz-img5-1">
									<img src="/cases/img/soyuz/img5-1.webp" />
								</div>
								<div className="soyuz-img5-2">
									<img src="/cases/img/soyuz/img5-2.webp" />
								</div>
									<div className="soyuz-img5-3">
								<img src="/cases/img/soyuz/img5-3.webp" />
								</div>
								<div className="soyuz-img5-4">
									<img src="/cases/img/soyuz/img5-4.webp" />
								</div>
								<div className="soyuz-img5-5">
									<img src="/cases/img/soyuz/img5-5.webp" />
								</div>
								<div className="soyuz-img5-6">
									<img src="/cases/img/soyuz/img5-6.webp" />
								</div>
							</div>
							<img
								className="soyuz-img5 shadow"
								src="/cases/img/soyuz/img5.webp"
							/>
						</div>

						<div className="text text2 bubble left-bottom parallax-front parallax-up">
							<div className="in">
								В дизайне активно используется 
								фотосессия, как в&nbsp;виде статичных 
								имиджевых изображений, так 
								и&nbsp;в&nbsp;виде аниматиков.
							</div>
						</div>
					</div>

					<div className="soyuz-video-wrap" style={{ backgroundImage: "url('/cases/img/soyuz/sposter.webp')" }}>
						<div className="soyuz-video">
							<video playsInline autoPlay poster="/cases/img/soyuz/sposter.webp" muted >
								<source src="/cases/img/soyuz/svideo2.mp4" type="video/mp4" />
							</video>
						</div>
						<div className="soyuz-video-in">
							найти себя в спорте, <br />
							обрести новое увлечение, 
							сплотить вокруг него 
							всю&nbsp;семью
						</div>
					</div>

					<div className="soyuz-box soyuz-box3">
						<img
							className="soyuz-img6 shadow"
							src="/cases/img/soyuz/img6.webp"
						/>
						<img
							className="soyuz-img7"
							src="/cases/img/soyuz/img7.webp"
						/>
						<img
							className="soyuz-img8 shadow"
							src="/cases/img/soyuz/img8.webp"
						/>
					</div>

					<div className="soyuz-box soyuz-box4">
						<img
							className="soyuz-img9 shadow"
							src="/cases/img/soyuz/img9.webp"
						/>
						<div className="soyuz-circle-wrapper1">
							<span className="soyuz-circle1-1"></span>
							<span className="soyuz-circle1-2"></span>
							<span className="soyuz-circle1-3"></span>
							<img
								className="soyuz-img10-1"
								src="/cases/img/soyuz/img10-1.svg"
							/>
						</div>
						<img
							className="soyuz-img10"
							src="/cases/img/soyuz/img10.svg"
						/>
						<div className="text text3 bubble left-bottom parallax-front parallax-up">
							<div className="in">
								Сайт насыщен ненавязчивой 
								скролл–анимацией, которая 
								ставит дополнительные 
								акценты на важную 
								информацию.
							</div>
						</div>
					</div>

					<div className="soyuz-img-cover1">
						<div
							data-speed="0.8"
							className="soyuz-img11"
							style={{ backgroundImage: "url('/cases/img/soyuz/img11.webp')" }}
						></div>
					</div>

					<div className="soyuz-video-wrap2">
						<div className="soyuz-video">
							<video
								playsInline
								ref={this.videoRef}
								poster="/cases/img/soyuz/poster.webp"
								muted
							>
								<source src="/cases/img/soyuz/video.mp4" type="video/mp4" />
							</video>
							{!this.state.play && (
								<img
									className="soyuz-video-button"
									src="/cases/img/soyuz/play.svg"
									onClick={() => this.setState({ play: true })}
								/>
							)}
							<img className="soyuz-img12" src="/cases/img/soyuz/img12.webp" />
						</div>
					</div>

					<div className="soyuz-box soyuz-box5">
						<div class="left">
							<div className="text text5 bubble right parallax-front parallax-up">
								<div className="in">
									Так выглядит сайт в смартфоне. Адаптация происходит под любое
									устройство
								</div>
							</div>
							<img src="/cases/img/soyuz/img15.webp" />
						</div>
						<div className="center">
							<img src="/cases/img/soyuz/img13.webp" />
							<img src="/cases/img/soyuz/img14.webp" />
						</div>
						<div class="right">
							<img src="/cases/img/soyuz/img16.webp" />
						</div>
					</div>
   
		  <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/pp/"  }
				title= { this.props.next ? this.props.next.name: "Преображенская площадь"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/pp.jpg"}
			/>
				</div>

				<HelmetSite title={`Союз`} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Soyuz);
