import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Bsg extends Component {
	state = {
		preview: true
	}

  handleScroll(){
    $('.animate-scroll-element').each(function(){
      const top = $(this).offset().top - window.innerHeight/2;
      const scr = $(window).scrollTop();

      if(scr >= top){
        $(this).addClass('active');
      }
      else{
        $(this).removeClass('active');
      }
    });
  }

	componentDidMount(){
		if(this.props.pathname == '/projects/bsg/'){
			this.setState({preview: false});
			this.props.setProjectName('bsg');
			$('body').addClass('case-expanded hash-bsg');
			$(window).scrollTop($('.bsg').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('bsg');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);

    window.addEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-bsg');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
    window.removeEventListener('scroll', this.handleScroll);
  }

  render(){
    return(
      <div className='case'>
        <div className="case-content">
          <div className="bsg">
            <div className="bsg-img1"></div>

            <div className="bsg-box">
              <img className="shadow bsg-img2" src="/cases/img/bsg/img2.webp" />
              <img className="shadow bsg-img3" src="/cases/img/bsg/img3.webp" />
              <img className="bsg-img6" src="/cases/img/bsg/img4.webp" />

              <img className="bsg-img4" src="/cases/img/bsg/img5.webp" />

              <div className="bsg-circle1" />
              <div className="bsg-circle2" />

              <div className="text bubble right parallax-front parallax-up">
                <div className="in">Лендинг пейдж для конференции BCG Woman Talks.<br /><br /> Яркие цвета и простые формы, делаем тему конференции максимально доступной. Добро пожаловать!</div>
              </div>
            </div>

            <div className="bsg-img5"></div>

            <div className="bsg-box2">
              <img className="shadow bsg-img7" src="/cases/img/bsg/img9.webp" />
              <div className="text bubble right-bottom parallax-front parallax-up">
                <div className="in">Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство</div>
              </div>

              <div className="bsg-box2-in">
                <img src="/cases/img/bsg/img10.webp" />
                <img src="/cases/img/bsg/img11.webp" />
                <img src="/cases/img/bsg/img12.webp" />
              </div>

              <div className="bsg-box2-circle1"></div>
              <div className="bsg-box2-circle2"></div>
              <div className="bsg-box2-circle3"></div>
            </div>
          </div>
        </div>

        {/*<CaseFooter to="/projects/gals/" title="Галс Девелопмент" img="/cases/img/next/gals.jpg" />*/}
  

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/sistemavc/"  }
				title= { this.props.name ? this.props.next.name: "Systema VC"   } 
				img={ this.props.name ? this.props.next.name:"/cases/img/next/sys.webp"}
			/>
        <HelmetSite title={`ВSG`} />
      </div>
    )
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Bsg)