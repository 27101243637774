import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {Helmet} from 'react-helmet';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import {hit} from '../metrics';

class SourcesIndex extends Component{

	componentDidMount(){
    window.document.body.classList.add('fadepage')
    window.document.body.classList.add('fadepage')
    this.props.setPageId('sources');
    
    $(window).unbind('scroll').scroll(() => {
      const scr = $(window).scrollTop();
      if(scr > 0){
				$('body').addClass('fixed');
			}
			else{
				$('body').removeClass('fixed');
      }
    });
    hit(window.location.pathname);
	}

	play = (id) => {
		if(this.refs[id].readyState === 4){
			this.refs[id].play()
		}
	}

	pause = (id) => {
		if(this.refs[id].readyState === 4){
			this.refs[id].pause()
		}
	}

	render(){
		return(
			<section className="intro sources sources_index">
        <Helmet>
          <title>' Акценты ' Amio</title>
        </Helmet>
				<div className="tiles case">
					<div className="column column1">
            {/*<div className="item item1" onMouseEnter={() => this.play('video0')} onMouseLeave={() => this.pause('video0')}>
							<Link to="/accents/apps/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg1.jpg)'}}>
								<video loop ref="video0" ><source type="video/mp4" src="/video/projects-bg1.mp4"/></video>
							</div>
							<div className="title">Apps</div>
						</div>*/}
						<div className="item item1" onMouseEnter={() => this.play('video5')} onMouseLeave={() => this.pause('video5')}>
							<Link to="/accents/audit/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg7.jpg)'}}>
								<video ref="video5"><source type="video/mp4" src="/video/projects-bg7.mp4"/></video>
							</div>
							<div className="title"><b>Аудит</b><br />контекстной рекламы</div>
						</div>

						<div className="item item2" onMouseEnter={() => this.play('video1')} onMouseLeave={() => this.pause('video1')}>
							<Link to="/accents/marketing/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg6.jpg)'}}>
							  <video loop ref="video1"><source type="video/mp4" src="/video/projects-bg6.mp4"/></video>
							</div>
							<div className="title">Комплексный маркетинг<br/>в <b>недвижимости</b></div>
						</div>
					</div>
					<div className="column column2">
						<div className="item item1" onMouseEnter={() => this.play('video2')} onMouseLeave={() => this.pause('video2')}>
							<Link to="/accents/404/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg4.jpg)'}}>
								<video loop ref="video2"><source type="video/mp4" src="/video/projects-bg4.mp4"/></video>
							</div>
							<div className="title">404</div>
						</div>
						<div className="item item2" onMouseEnter={() => this.play('video3')} onMouseLeave={() => this.pause('video3')}>
							<Link to="/accents/ccp/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg5.jpg)'}}>
								<video loop ref="video3"><source type="video/mp4" src="/video/projects-bg5.mp4"/></video>
							</div>
							<div className="title">C<b>CP</b></div>
						</div>
					</div>
					<div className="column column3">
						<div className="item item1" onMouseEnter={() => this.play('video4')} onMouseLeave={() => this.pause('video4')}>
							<Link to="/accents/reality/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg3.jpg)'}}>
								<video loop ref="video4"><source type="video/mp4" src="/video/projects-bg3.mp4"/></video>
							</div>
							<div className="title">дополненная<br/><b>реальность</b></div>
						</div>


            <div className="item item2" onMouseEnter={() => this.play('video0')} onMouseLeave={() => this.pause('video0')}>
							<Link to="/accents/banks/"></Link>
							<div className="bg" style={{backgroundImage: 'url(/video/projects-bg1.jpg)'}}>
								<video loop ref="video0" ><source type="video/mp4" src="/video/projects-bg1.mp4"/></video>
							</div>
							<div className="title"><b>б</b>ан<b>к</b>и</div>
						</div>
            

					</div>
				</div>
			</section>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SourcesIndex)