import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class ComCity extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/comcity/'){
			this.setState({preview: false});
			this.props.setProjectName('Comcity');
			$('body').addClass('case-expanded hash-comcity');
			$(window).scrollTop($('#case-comcity').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-comcity');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-comcity');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/comcity/img1.webp')`}}></div></div>
          <div className="image image1" style={{backgroundImage: `url('/cases/img/comcity/img2.webp')`}}></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text text0">
                <div className="in">
                  <div className="title">Comcity</div>
                  <p>Comcity — офисный парк класса А:</p>
                  <ul>
                    <li>430 000 кв. м площадь аренды</li>
                    <li>45 га благоустроенной территории</li>
                    <li>12 офисных зданий</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p><b>Перед нами стояла задача разработать сайт офисного парка.</b></p>
                  <p><b>Главная страница.</b>
                  <br/>Атмосферные полноэкранные видео мы собрали в слайдер, где рассказываем о главных преимуществах.</p>
                </div>
              </div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/comcity/img3.webp')`}}></div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/comcity/img4.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back" ><div style={{backgroundImage: `url('/cases/img/comcity/img5.webp')`}}></div></div>
          <div className="image image1" style={{backgroundImage: `url('/cases/img/comcity/img6.webp')`}}></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  Инфраструктура и торговая галерея – в виде визуальных интерактивных планов, чтобы быстро найти нужное на огромной площади офисного парка и прилегающих гектарах окрестностей.
                </div>
              </div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/comcity/img7.webp')`}}></div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/comcity/img8.webp')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/comcity/img9.webp')`}}></div>
              <div className="text bubble left-bottom text2 parallax-front">
                <div className="in">
                  Прозрачная структура и простая навигация позволяют пользователю свободно ориентироваться на сайте.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/comcity/img10.webp')`}}></div>
            <div style={{backgroundImage: `url('/cases/img/comcity/img11.webp')`}}></div>
          </div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/comcity/img12.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="image image1" style={{backgroundImage: `url('/cases/img/comcity/img13.webp')`}}></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/comcity/img14.webp')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/comcity/img15.webp')`}}></div>
              <div className="text bubble right-top text1 parallax-front">
                <div className="in">
                  Всю информацию о комплексе мы подкрепляем яркими фотографиями и схемами.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="graph"></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-comcity">
				<Link to="/projects/comcity/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/comcity/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/comcity/logo.webp')`}}></div></div>
					</div>
				</Link>
				{this.more()}
			</div>

  
      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/kakumae/" }
				title= { this.props.next ? this.props.next.name: "Haven Kakumäe"} 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/kakumae.webp"}
			/>
      <HelmetSite title="Comcity" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Comcity</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComCity)