import React, { Component, Fragment } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Plus extends Component{
  componentDidMount(){
    if(this.props.pathname == '/projects/plus/'){
      this.setState({preview: false});
      this.props.setProjectName('Plus');
      $('body').addClass('case-expanded hash-plus');
      $(window).scrollTop($('#case-plus').offset().top);
      this.props.changeCase(null);
    }
    else{
      this.setState({preview: true});
      $('body').removeClass('hash-plus');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage');
    }, 100);
  }

  componentDidUpdate(){
    if(this.props.pathname == '/projects/'){
      $('body').removeClass('case-expanded hash-plus');
    }
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
  render(){
    return(
    <div className='case'>
      <div className="case-content" id="case-plus">
        <img src="/cases/img/plus/img1.svg" className="plus-img1" />
        <div className="plus-box1">
          <img data-lazy="/cases/img/plus/img3.webp" className="plus-img2" />
          <img data-lazy="/cases/img/plus/img2.svg" className="plus-img3" />

          <div className="text bubble right parallax-front parallax-up">
            <div className="in">
              В&nbsp;логотипе мы использовали контраст форм. Округлые и&nbsp;мягкие буквы создают мягкий стиль, который противопоставляется острым символам плюса и&nbsp;углам, которые часто используются в&nbsp;архитектуре.
            </div>
          </div>
        </div>

        <div className="plus-box2" data-lazy="/cases/img/plus/img4.webp">
          <div className="text bubble left parallax-front parallax-up">
            <div className="in">
              Контраст и&nbsp;жесткость достигаются с&nbsp;помощью шрифта и&nbsp;логотипа чёрного цвета, которые ярко выделяются на белом фоне.
            </div>
          </div>
        </div>

        <div className="plus-box3" data-lazy="/cases/img/plus/img5.webp">
          <div className="text bubble right parallax-front parallax-up">
            <div className="in">
              В&nbsp;некоторых случаях можно использовать сокращённую версию логотипа состоящую только из знака.
            </div>
          </div>
        </div>

        <div className="plus-box4" data-lazy="/cases/img/plus/img6.webp" />
        <div className="plus-box5" data-lazy="/cases/img/plus/img7.webp" />

        <div className="plus-box6" data-lazy="/cases/img/plus/img8.webp">
          <div className="text bubble left-bottom parallax-front parallax-up">
            <div className="in">
              Двустороннюю карточку можно изготавливать из дизайнерской путём склейки. Логотип оборотной стороны можно фольгировать.
            </div>
          </div>
        </div>

        <div className="plus-box7">
          <div>
            <img data-lazy="/cases/img/plus/img9.webp" className="shadow" />

            <div className="text bubble right-bottom parallax-front parallax-up">
              <div className="in">
              Сайт является продолжением и развитием фирменного стиля, он динамично и понятно предлагает информацию пользователю.
              </div>
            </div>
          </div>
          <div>
            <div className="text bubble left parallax-front parallax-up">
              <div className="in">
                Разрабатывая дизайн мы стремились передать динамику фирменного стиля, максимально показать движение вперёд, трансформацию, положительные изменения.
              </div>
            </div>

            <img data-lazy="/cases/img/plus/img10.webp" className="shadow" />
          </div>

          <img data-lazy="/cases/img/plus/img11.svg" />
        </div>

        <div className="plus-box8" data-lazy="/cases/img/plus/img12.webp" />

        <div className="plus-box9">
          <div>
            <img data-lazy="/cases/img/plus/img13.webp" className="plus-box9-img1" />
            <img data-lazy="/cases/img/plus/img15.webp" className="shadow plus-box9-img2" />
            <img data-lazy="/cases/img/plus/img17.svg" className="plus-box9 plus-box9-img3" />
          </div>
          <div>
            <img data-lazy="/cases/img/plus/img14.webp" className="shadow plus-box9-img4" />
          </div>
          <img data-lazy="/cases/img/plus/img16.webp" className="shadow plus-box9-img5" />
        </div>

        <div className="plus-box10" data-lazy="/cases/img/plus/img18.webp" />

        <div className="plus-box11">
          <div>
            <div className="text bubble right parallax-front parallax-up">
              <div className="in">
              Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство
              </div>
            </div>
            <img data-lazy="/cases/img/plus/img19.webp" className="plus-box11-img1" />
          </div>
          <div>
            <img data-lazy="/cases/img/plus/img20.webp" className="plus-box11-img2" />
            <img data-lazy="/cases/img/plus/img21.webp" className="plus-box11-img3" />
          </div>
          <div>
            <img data-lazy="/cases/img/plus/img22.webp" className="plus-box11-img4" />
          </div>
        </div>
      </div>


      <CaseFooter 
        to="/projects/x5table" 
        title="X5 Все за одним столом!" 
        img="/cases/img/next/x5table_next.webp" 
      />
      <HelmetSite title="ЖК Детали" />
    </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plus)