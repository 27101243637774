import React, { Component,  } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'jquery.transit';
import * as PIXI from 'pixi.js-legacy';
 import data from "../../store/projects"
 import {withRouter} from 'react-router-dom';
 import PropTypes from 'prop-types';
class CaseFooter extends Component{




propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  componentDidMount(){
 
    /*****/
    const size = [1440, 500];
    const speed = 4;
    let x = 5;
    let y = 5;
    let ishover = false;
    const offset = [0, 0];
    const offsetValue = 700;
    const body = document.querySelector('.casefoote__conv');
    window.appfootercase = new PIXI.Application({width: size[0], height: size[1], transparent: true, antialias: true});
    body.appendChild(window.appfootercase.view);

    window.appfootercase.loader.add('img', this.nextArr.imgCaseBottom?this.nextArr.imgCaseBottom: this.props.img)
    window.appfootercase.loader.add('filter', '/img/circlefilter.png')
      .load((loader, resources) => {
      const img = new PIXI.Sprite(resources.img.texture);
      const displacementSprite = new PIXI.Sprite(resources.filter.texture);
      displacementSprite.width = size[0];
      displacementSprite.height = size[1];
      displacementSprite.width = offsetValue;
      displacementSprite.height = offsetValue;
      const displacementFilter = new PIXI.filters.DisplacementFilter(displacementSprite);
      img.x = 0;
      img.y = 0;

      window.appfootercase.stage.filters = [displacementFilter];
      window.appfootercase.stage.addChild(img);
      window.appfootercase.stage.addChild(displacementSprite);

      window.appfootercase.ticker.add(() => {
        if(ishover){
          displacementSprite.x = x;
          displacementSprite.y = y;
          offset[0] = offsetValue/2;
          offset[1] = offsetValue/2;

          if(offset[0] < offsetValue/2){
            offset[0] += speed;
          }
          if(offset[1] < offsetValue/2){
            offset[1] += speed;
          }
        }
        else{
          if(x <= 0){
            displacementSprite.x += -speed;
          }
          else{
            displacementSprite.x += speed;
          }
          if(y <= 0){
            displacementSprite.y += -speed;
          }
          else{
            displacementSprite.y += speed;
          }

          if(offset[0] > 0){
            offset[0] -= speed;
          }
          if(offset[1] > 0){
            offset[1] -= speed;
          }
        }
      });
    });

    const canvas = $('.casefooter__img');
    canvas.mousemove(function(e){
      ishover = true;
      var _x = $(this).offset().left;
      var _y = $(this).offset().top;
      x = e.pageX - _x - offset[0];
      y = e.pageY - _y - offset[1];

      $('.casefooter__arrows').css({x: e.pageX - _x, y: e.pageY - _y});
    })

    canvas.mouseout(function(e){
      ishover = false;
      //$('.casefooter__arrows').removeClass('active');
    })
    /*****/
  }

  componentWillUnmount(){
    // if(window.appfootercase){
    //   window.appfootercase.destroy(true, true);
    //   //window.appfootercase == null;
    // }
  }

   href = window.location.pathname.split("/")[2]


   next = function () {

    if(!window.location.pathname.includes("projects-new")) return false
    
    var nextValue = []
    var tempArr = data.data.filter((item) => item.category.includes(this.href))
    var indexArr = 0
    tempArr.forEach((element, index) => { if (element.url.includes(window.location.pathname.split("/")[3]) ) { 
      var tempIndex = 1
      if( index === tempArr.length - 1){
        tempIndex = 1
      }else{
        if(tempArr[index + 1].url.includes("marketing")){
          tempIndex  = 2
        }
      }
      nextValue = tempArr[index +  tempIndex ]; indexArr = index + tempIndex;  } });
    var obj = Object.assign({}, nextValue)

    if(indexArr === undefined){
      nextValue = data.data[0]
      obj = Object.assign({}, nextValue)
      obj.url = `/projects-new/${this.href}${tempArr[0].url}`
    }else{
      if(indexArr == tempArr.length){
        obj = tempArr[0]
        obj.url = `/projects-new/${this.href}${tempArr[0].url}`
      }else{
        obj.url = `/projects-new/${this.href}${tempArr[indexArr].url}`
      }

    }
    return obj
  }
  nextArr = this.next()

  render(){

    return(
      <Link to={this.nextArr.url ? this.nextArr.url : this.props.to} className="casefooter">
        <div className="casefooter__title">{`Следующий кейс: ${this.nextArr.name ?this.nextArr.name   :this.props.title}`}</div>
        <div className="casefooter__img" id="page-wrap">
          <div className="casefooter__arrows" />
          <div className="casefoote__conv"  />
        </div>
      </Link>
    )
  }
}

export default CaseFooter;