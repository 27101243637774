import React, { Component } from 'react';
import $ from 'jquery';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Voshod extends Component{
  videoRef = React.createRef();

  state = {
    play: false
  };

  componentDidMount(){
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage');
    }, 100);

    window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

    window.gsap.to('.voshod__box1-bg', {
      scrollTrigger: {
        trigger: '.voshod__box1',
        start: 'top top',
        end: '+=' + window.innerHeight,
        scrub: 2,
      },
      y: '-10%',
    });

    window.gsap.to('.voshod__box3-bg', {
      scrollTrigger: {
        trigger: '.voshod__box3',
        start: 'top bottom',
        end: '+=' + window.innerHeight * 1.5,
        scrub: 2,
      },
      scale: 1,
    });

    window.gsap.to('.voshod__box3-img1', {
      scrollTrigger: {
        trigger: '.voshod__box3',
        start: 'top bottom',
        end: '+=' + window.innerHeight * 1.5,
        scrub: 2,
      },
      scale: 1.1,
    });

    window.gsap.to('.voshod__box4-mini > div > div', {
      scrollTrigger: {
        trigger: '.voshod__box4-mini',
        start: 'top bottom',
        end: '+=' + window.innerHeight * 1.5,
        scrub: 2,
      },
      x: '10%',
    });

    window.gsap.to('.voshod__box5 > div', {
      scrollTrigger: {
        trigger: '.voshod__box5',
        start: 'top bottom',
        end: '+=' + window.innerHeight * 2,
        scrub: 2,
      },
      y: '-20%',
    });
  }

  
  componentDidUpdate(){
    if(this.state.play){
      if(this.videoRef.current){
        this.videoRef.current.play()

        this.videoRef.current.onended = () => {
          this.setState({play: false})
        }
      }
    }
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
  render(){
    return(
      <div className='case'>
        <div className="case-content voshod">
          <div className="voshod__box1">
            <div className="voshod__box1-bg" style={{ backgroundImage: `url(/cases/img/voshod/img2.webp)` }}></div>
            <img src="/cases/img/voshod/img1.svg" />
          </div>

          <div className="voshod__box2-wrap">
            <img data-lazy="/cases/img/voshod/img5.svg" className="voshod__box2-wrap-img1" />
            <img data-lazy="/cases/img/voshod/img6.svg" className="voshod__box2-wrap-img2" />
            <div className="voshod__box2">
              <div>
                <img data-lazy="/cases/img/voshod/img3.webp" className="shadow" />

                <div className="text bubble left parallax-front parallax-up">
                  <div className="in">
                  Безупречно выстроенный дизайн на главном элементе фирменного стиля — круге и его антониме, прямоугольнике. Ничего лишнего, только самое главное.
                  </div>
                </div>
              </div>
              <div>
                <img data-lazy="/cases/img/voshod/img4.webp" className="shadow" />

                <div className="text bubble left parallax-front parallax-up">
                  <div className="in">
                  Необычное решение с вертикальным расположением меню поддерживает строгое, очень геометрическое звучание дизайна
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="voshod__box3">
            <div className="voshod__box3-bg" data-lazy="/cases/img/voshod/img9.webp"></div>

            <img data-lazy="/cases/img/voshod/img7.svg" className="voshod__box3-img1" />
            <img data-lazy="/cases/img/voshod/img8.svg" className="voshod__box3-img2" />
          </div>

          <div className="voshod__box4-wrap">
            <img data-lazy="/cases/img/voshod/img5.svg" className="voshod__box4-wrap-img1" />
            <img data-lazy="/cases/img/voshod/img10.svg" className="voshod__box4-wrap-img2" />

            <div className="voshod__box4">
              <div>
                <img data-lazy="/cases/img/voshod/img12.webp" className="shadow" />

                <div className="text bubble right parallax-front parallax-up">
                  <div className="in">
                  Безупречно выстроенный дизайн на главном элементе фирменного стиля — круге и его антониме, прямоугольнике. Ничего лишнего, только самое главное.
                  </div>
                </div>
              </div>
              <div>
                <img data-lazy="/cases/img/voshod/img11.webp" className="shadow" />

                <div className="text bubble left parallax-front parallax-up">
                  <div className="in">
                  Необычное решение с вертикальным расположением меню поддерживает строгое, очень геометрическое звучание дизайна
                  </div>
                </div>
              </div>
            </div>

            <div className="voshod__box4-mini">
              <div>
                <div style={{ backgroundImage: `url(/cases/img/voshod/img13.webp)` }}></div>
              </div>
              <div>
                <div style={{ backgroundImage: `url(/cases/img/voshod/img13.webp)` }}></div>
              </div>
            </div>
          </div>

          <div className="voshod__box5">
            <div>
              <div data-lazy="/cases/img/voshod/img14.webp"></div>
            </div>
          </div>

          <div className="voshod-video-wrap">
            <div className="voshod-video">
              <video playsInline ref={this.videoRef} poster="/cases/img/voshod/poster.webp" muted>
                <source src="/cases/img/voshod/video.mp4" type="video/mp4" />
              </video>
              {!this.state.play && <img className="voshod-video-button" src="/cases/img/ahead/button.svg" onClick={() => this.setState({play: true})} />}
              <img src="/cases/img/voshod/mac.webp" />
            </div>
          </div>

          <div className="voshod__box6">
            <div>
              <div className="text bubble right parallax-front parallax-up">
                <div className="in">
                Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство
                </div>
              </div>
              <img data-lazy="/cases/img/voshod/img15.webp" className="voshod__box6-img1" />
            </div>
            <div>
              <img data-lazy="/cases/img/voshod/img16.webp" className="voshod__box6-img2" />
              <img data-lazy="/cases/img/voshod/img17.webp" className="voshod__box6-img3" />
            </div>
            <div>
              <img data-lazy="/cases/img/voshod/img18.webp" className="voshod__box6-img4" />
            </div>
          </div>
        </div>
        <CaseFooter 
          to="/projects/ahead" 
          title="Ahead" 
          img="/cases/img/next/ahead_next.webp" 
        />
        <HelmetSite title="ЖК Детали" />
      </div>
    )
  }
}

export default Voshod