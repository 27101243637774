import React from "react";
import ym from "react-yandex-metrika";
import { Link } from "react-router-dom";
const HomeMarketing = ({active3}) => {

  if(active3) {
    return(
      <div
        onClick={() => {
          ym("reachGoal", "click_on_banner3");
          return true;
        }}
        className="scroll-item_100 market-place"
      >
        <Link to="/accents/marketing/" className="projectlist__item big">
          <div className="projectlist__bg">
            <div className="projectlist__text">
              Маркетинг в недвижимости
            </div>
            <video className="video3" playsInline autoPlay muted loop>
              <source
                src="/cases/img/home/market3.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="projectlist__title">
            Маркетинг в недвижимости
          </div>
          <div className="market-stroke-wrap">
            <div className="market-stroke">
              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость звонка
              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость звонка
              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость звонка
              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость звонка
              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость звонка
              &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость
              звонка&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
              недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бесконечно
              низкая стоимость звонка
            </div>{" "}
          </div>
        </Link>
      </div>
    )
  }

  return(
    <div
      onClick={() => {
        ym("reachGoal", "click_on_banner4");
        return true;
      }}
      className="scroll-item_50 market-place market-place2"
    >
      <Link to="/accents/marketing/" className="projectlist__item big">
        <div className="projectlist__bg">
          <div className="projectlist__text">
            Маркетинг в недвижимости
          </div>
          <video className="video4" playsInline autoPlay muted loop>
            <source
              src="/cases/img/home/market4.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="projectlist__title">
          Маркетинг в недвижимости
        </div>
        <div className="market-stroke-wrap">
          <div className="market-stroke">
            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости
            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости
            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости
            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости
            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости
            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Космическая
            скорость
            реакции&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
            недвижимости
          </div>{" "}
        </div>
      </Link>
    </div>
  )
}

export default HomeMarketing;