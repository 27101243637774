import React, {Component, Fragment} from 'react';
import SourcesMarketingForm from "../Sources/SourcesMarketingForm";
import EmailForm from "../Sources/mail-form";
import TenderForm from "../Sources/tender-form";

class FormBtn extends Component {
  state = {
    popup: false,
    tab: 1
  }



  render() {
    const {tab, popup} = this.state

    return (

      <Fragment>
        <div onClick={() => this.setState({popup: true})} className="form-btn">
          <span>Мы на связи</span>
          {/* <span>Мы на связи ⚡️</span> */}
        </div>

        <div className={`contacts-call-me-popup forms-modal  ${tab===3 ? 'forms-modal2' : ''} ${popup ? 'active' : ''}`}>
          <div className="tab-nav">
            <div data-active={tab === 1} onClick={() => this.setState({tab: 1})}><span>Мы сами перезвоним</span></div>
            <div data-active={tab === 2} onClick={() => this.setState({tab: 2})}><span>Отправить весточку</span></div>
            <div data-active={tab === 3} onClick={() => this.setState({tab: 3})}><span>Пригласить в тендер</span></div>
          </div>
          {tab === 1 && <div className="forms-modal__in"><SourcesMarketingForm close={() => this.setState({popup: false})} callback={true} /></div>}
          {tab === 2 && <div className="forms-modal__in"><EmailForm close={() => this.setState({popup: false})} /></div>}
          {tab === 3 && <div className="forms-modal__in"><TenderForm close={() => this.setState({popup: false})} /></div>}
          <div className="contacts-call-me-popup__close" onClick={() => this.setState({popup: false})}></div>
        </div>
      </Fragment>
    );
  }
}

export default FormBtn;