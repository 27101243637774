import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Link, NavLink, Switch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useRef } from 'react';
import $ from "jquery";
import Eph from "../Projects/eph"
import data from "../../store/projects"
import Ryadom from '../Projects/ryadom';
import Ikforum from '../Projects/ikforum';
import ComCity from '../Projects/comcity';
import Kakumae from '../Projects/kakumae';
import Sistemavc from '../Projects/sistemavc';
import Efimovbor from '../Projects/efimovbor';
import Imeretinka from '../Projects/imeretinka';
import Fsklider from '../Projects/fsklider';
import Polyanka from '../Projects/polyanka';
import Tushino from '../Projects/tushino';
import X5 from '../Projects/x5';
import Video from '../Projects/video';
import Activity from '../Projects/activity';
import X5ny from '../Projects/x5ny';
import Greenpolza from '../Projects/greenpolza';
import Fabcab from '../Projects/fabcab';
import Aresidence from '../Projects/aresidence';
import Torpedo from '../Projects/torpedo';
import GeneralInvest from '../Projects/generalinvest';
import Botanica from '../Projects/botanica';
import X5happy from '../Projects/x5happy';
import Gals from '../Projects/gals';
import Schastye from '../Projects/schastye';
import Iskra from '../Projects/iskra';
import Ingrad from '../Projects/ingrad';
import X5values from '../Projects/X5values';
import Westgarden from '../Projects/westgarden';
import Notfound from '../Projects/Notfound';
import Error from '../Error';
import Hunter from '../Projects/Hunter';
import Academy from '../Projects/academy';
import Atm from '../Projects/atm';
import Tvz from '../Projects/tvz';
import F92 from '../Projects/f92';
import Bsg from '../Projects/bsg';
import Afimall from '../Projects/afimall';
import Self from '../Projects/self';
import Vsn from '../Projects/vsn';
import Kms from '../Projects/kms';
import Vrb from '../Projects/vrb';
import Rep from '../Projects/rep';
import Armani from '../Projects/armani';
import Dh from '../Projects/dh';
import Mts from '../Projects/mts';
import Cto from '../Projects/cto';
import Ahead from '../Projects/ahead';
import Сrimea from '../Projects/crimea';
import Genesis from '../Projects/genesis';
import Soyuz from '../Projects/soyuz';
import Dellin from '../Projects/dellin';
import Target from '../Projects/target';
import Scandipakk from '../Projects/scandipakk';
import Pp from '../Projects/pp';
import { hit } from '../metrics';
import Profit from '../Projects/profit'
import Metropolia from '../Projects/metropolia';
import Voshod from '../Projects/voshod'
import SistemavcTest from "../Projects/_back-project-files/SistemavcTest";
import Emotion from '../Projects/emotion'
import Detali from '../Projects/detali'
import Plus from '../Projects/plus'
import X5table from '../Projects/x5table'


const ProjectsAll = (props) => {




  const href = props.match.params["id"]
  const _ = require("lodash");



  const helper = useRef(null);
  const helper2 = useRef(null);
  const [arr, setArr] = useState([]);




  const [active, setActive] = useState([]);
  const [active2, setActive2] = useState([]);


  var lastScrollTop = 0;

  const _onScroll = () => {
    let helper_offset = null;
    let helper2_offset = null;

    const st = window.pageYOffset || document.documentElement.scrollTop;
    const downscroll = st > lastScrollTop;

    if (helper.current) {
      helper_offset = $(helper.current).offset().top;
    }

    if (helper2.current) {
      helper2_offset = $(helper2.current).offset().top;
    }


    lastScrollTop = st <= 0 ? 0 : st;
    const self = this;

    $(".scroll-line").each(function (e) {

      if (helper_offset) {
        if ($(this).offset().top <= helper_offset && downscroll) {
          $(this).addClass("active")
          $(this).removeClass("active2")
        }
      }
      if (helper2_offset) {
        if ($(this).offset().top > helper2_offset && !downscroll) {
          $(this).removeClass("active")
          $(this).addClass("active2")
        }
      }
    });
  };


  useEffect(() => {
    var tempArr = data.data.filter((item) => item.category.includes(href))

    setArr(_.chunk(tempArr, 5))
  }, [href])

 

  useEffect(() => {
    console.log($("body"))
    $("body").addClass("fadepage")
    setTimeout(() => {

      $("body").addClass("fadepage");
      $(".body").addClass("page-projects");
      $(".body").addClass("new-page-projects");
    }, 100);
    window.addEventListener("scroll", _onScroll);




    $('body').addClass('page-projects tags-expanded page-projects-new');

    $(window).unbind('scroll').bind('scroll', function () {

      let scr = $(document).scrollTop();
      let ww = $(window).width();
      let wh = $(window).height();
      let ww2 = ww + ww;
      let ww4 = ww2 + ww2;
      let wh05 = wh * 0.5;
      let wmin = scr + wh * 0.3;
      let wm = scr + wh * 0.5;
      let wmax = scr + wh * 0.7;
      let cs = $('section.intro.projects .case:visible');

      if (scr > 0) {
        $('body').addClass('fixed');
      }
      else {
        $('body').removeClass('fixed');
      }

      cs.each(function () {
        let c = $(this);
        let ct = c.offset().top;
        let ch = c.outerHeight();
        let cb = ct + ch;
        let sc = c.find('.screen' + (c.hasClass('preview') ? '0' : ''));

        if (sc.find('.parallax-back:visible').length) {
          sc.find('.parallax-back:visible').each(function () {
            let b = $(this);
            let bh = b.outerHeight();
            let bt = b.offset().top;
            let bb = bt + bh;
            b.toggleClass('fixed', scr >= bt);
            let v = b.find('video');
            if (v.length && v.find('source').attr('src')) {
              v.toggleClass('invisible', scr + wh < bt || scr > bb);
              if (scr + wh >= bt && scr <= bb) {
                document.getElementById(v.attr('id')).play();
              }
              else {
                document.getElementById(v.attr('id')).pause();
              }
            }
          });
        }

        if (scr + wh05 >= ct && scr + wh05 <= cb) {
          if (!c.hasClass('preview')) {
            if (c.find('.parallax-front:visible').length) {
              c.find('.parallax-front:visible').each(function () {
                let s = $(this);
                let isBubble = s.hasClass('bubble');
                if (isBubble) {
                  wmin = wmax;
                }
                let isLoadedImage = s.attr('data-text')
                if (isLoadedImage) {
                  return;
                }
                let sh = s.outerHeight();
                let st = s.offset().top;
                let sm = st + sh * 0.3;
                s.toggleClass('parallax-up', sm < wmin).toggleClass('parallax-down', sm > wmax);
              });
            }

            if (c.find('.parallax-side:visible').length) {
              c.find('.parallax-side:visible').each(function () {
                let s = $(this);
                let sd = s.find('div');
                let sw = sd.outerWidth();
                let sh = s.outerHeight();
                let st = s.offset().top;
                let sm = st + sh * 0.5;
                if (sw > ww) {
                  let x = (sm - wm) * 100 / ww;
                  x = Math.max(Math.min(x, 25), -25);
                  sd.css({ '-webkit-transform': 'translateX(' + x + '%)', 'transform': 'translateX(' + x + '%)' });
                }
              });
            }

            if (c.find('.parallax-slider:visible').length) {
              c.find('.parallax-slider:visible').each(function () {
                let s = $(this);
                let sd = s.find('.line');
                let sw = sd.outerWidth();
                let sh = s.outerHeight();
                let st = s.offset().top;
                let sm = st + sh * 0.5;
                if (sw < ww4) {
                  let d = ww4 - sw;
                  let si = sd.find('.item');
                  let l = si.length;
                  let iw = sw / l;
                  let n = Math.ceil(d / iw);
                  for (let i = 0; i < n; i++) {
                    let a = si.eq(i % l);
                    if (typeof (a) != 'undefined') {
                      if ($(this).hasClass('invert')) {
                        a.clone().prependTo(sd);
                      }
                      else {
                        a.clone().appendTo(sd);
                      }
                    }
                  }
                  sw = sd.outerWidth();
                  sd.css({ 'margin-left': (- sw * 0.5) + 'px' });
                }
                let x = (sm - wm) * 100 / ww;
                x = Math.max(Math.min(x, 25), -25);

                if ($(this).hasClass('invert')) {
                  sd.css({ '-webkit-transform': 'translateX(-' + x + '%)', 'transform': 'translateX(-' + x + '%)' });
                }
                else {
                  sd.css({ '-webkit-transform': 'translateX(' + x + '%)', 'transform': 'translateX(' + x + '%)' });
                }
              });
            }


          }
        }
      });
    });

    setTimeout(() => {
      $('body').addClass('fadepage');
    }, 100);
    hit(window.location.pathname);
  }, [])




  return (
    <Fragment>
      <header className="header-projects-new new-project">
        <menu className="main">
          <NavLink to="/projects/all/" onClick={() => this.props.changeCase(null)}>Создаём</NavLink>
          <NavLink to="/advertising/">Продвигаем</NavLink>
          <NavLink to="/accents/">Акценты</NavLink>
          <NavLink to="/about/">О нас</NavLink>
          <NavLink to="/contacts/">Контакты</NavLink>
        </menu>

        <div className="sources-tags-overlay">
          <nav className="tags sources-tags">
            <NavLink to="/accents/banks/"><span data-index="banks">Банки</span></NavLink>
            <NavLink to="/accents/marketing/"><span data-index="marketing">Комплексный маркетинг в недвижимости</span></NavLink>
            <NavLink to="/accents/404/"><span data-index="404">404</span></NavLink>
            <NavLink to="/accents/ccp/"><span data-index="ccp">CCP</span></NavLink>
            <NavLink to="/accents/reality/"><span data-index="reality">Дополненная реальность</span></NavLink>
            <NavLink to="/accents/audit/"><span data-index="audit">Аудит контекстной рекламы</span></NavLink>
          </nav>
        </div>

        <nav className="tags projects-tags">
          <NavLink className={href === "all" ? "active" : ""} to="/projects-new/all/"><span>Всё</span></NavLink>
          <NavLink className={href === "web" ? "active" : ""} to="/projects-new/web/"><span>Сайты</span></NavLink>
          <NavLink className={href === "branding" ? "active" : ""} to="/projects-new/branding/"><span>Брендинг</span></NavLink>
          <NavLink to="/projects/apps/"><span>Приложения</span></NavLink>
        </nav>

        <Link to="/" className="logo"><i></i></Link>
      </header>


      {/* <div className="promote__top-bottom-button" onClick={() => this.props.openPopup(true)}><span>Мы на связи</span><img src="/img/promote/lightning_promote.svg" /></div> */}

      <div className="projectlist projects-loading projectlist-new ">
        <Helmet>
          <title>Проекты ' Amio</title>
        </Helmet>

        {/*className="display-none"*/}

        {<div className={href + " list-wrap"}>
          {arr.map((item, index) => (
            (<>
              {(index % 2 != 0) && <div
                className={
                  active.includes(0)
                    ? "projectlist__line scroll-line active"
                    : active2.includes(0)
                      ? "projectlist__line scroll-line active2"
                      : "projectlist__line scroll-line"
                }
              >
                <div>
                  <div className="projectlist__line projectlist__line_z">
                    <div>
                      <Link to={`/projects-new/${href}${item[0].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[0].img}')` }} className="projectlist__bg" data-id="45">
                          {item[0].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[0].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[0].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[0].name}</div>
                        {item[0].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[0].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>
                    {item.length > 1 && <div className="scroll-item_100">
                      <Link to={`/projects-new/${href}${item[1].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[1].img}')` }} className="projectlist__bg" data-id="46">

                          {item[1].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[1].img}
                              type="video/mp4"
                            />
                          </video>}
               
                          <div className="projectlist__text">{item[1].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[1].name}</div>
                        {item[1].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[1].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>}
                  </div>
                  <div className="projectlist__line">
                    {item.length > 2 && <div className="scroll-item_100">
                      <Link to={`/projects-new/${href}${item[2].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[2].img}')` }} className="projectlist__bg" data-id="42">
                          {item[2].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[2].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[2].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[2].name}</div>
                        {item[2].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[2].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>}
                    {item.length > 3 && <div className="scroll-item_200">
                      <Link to={`/projects-new/${href}${item[3].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[3].img}')` }} className="projectlist__bg" data-id="43">
                          {item[3].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[3].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[3].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[3].name}</div>
                        {item[3].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[3].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>}
                  </div>
                </div>
                {item.length > 4 && <div className="scroll-item_50">
                  <Link to={`/projects-new/${href}${item[4].url}/`} className="projectlist__item big">
                    <div style={{ backgroundImage: `url('${item[4].img}')` }} className="projectlist__bg" data-id="41">
                      {item[4].img.includes("mp4") && <video
                        playsInline
                        autoPlay
                        poster="/cases/img/home/46_poster.jpg"
                        muted
                        loop
                      >
                        <source
                          src={item[4].img}
                          type="video/mp4"
                        />
                      </video>}
                      <div className="projectlist__text">
                        {item[4].dsc}
                      </div>
                    </div>
                    <div className="projectlist__title">{item[4].name}</div>
                    {item[4].dsc.length > 150 && <div className="market-stroke-wrap">
                      <div dangerouslySetInnerHTML={{ __html: item[4].dsc }} className="market-stroke">
                      </div>
                    </div>}
                  </Link>
                </div>}
              </div>}
              {index % 2 == 0 && <div
                className={
                  active.includes(1)
                    ? "projectlist__line scroll-line active"
                    : active2.includes(1)
                      ? "projectlist__line scroll-line active2"
                      : "projectlist__line scroll-line"
                }
              >
                <div className="scroll-item_50">
                  <Link to={`/projects-new/${href}${item[0].url}/`} className="projectlist__item big">
                    <div style={{ backgroundImage: `url('${item[0].img}')` }} className="projectlist__bg" data-id="36">
                      {item[0].img.includes("mp4") && <video
                        playsInline
                        autoPlay
                        poster="/cases/img/home/46_poster.jpg"
                        muted
                        loop
                      >
                        <source
                          src={item[0].img}
                          type="video/mp4"
                        />
                      </video>}
                      <div className="projectlist__text">{item[0].dsc}</div>
                    </div>
                    <div className="projectlist__title">{item[0].name}</div>
                    {item[0].dsc.length > 150 && <div className="market-stroke-wrap">
                      <div dangerouslySetInnerHTML={{ __html: item[0].dsc }} className="market-stroke">
                      </div>
                    </div>}
                  </Link>
                </div>
                <div>
                  <div className="projectlist__line">
                    {item.length > 1 && <div>
                      <Link to={`/projects-new/${href}${item[1].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[1].img}')` }} className="projectlist__bg" data-id="35">
                          {item[1].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[1].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[1].dsc}</div>
                        </div>
                        <div className="projectlist__title">
                          {item[1].name}
                        </div>
                        {item[1].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[1].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>}
                    {item.length > 2 && <div className="scroll-item_200">
                      <Link to={`/projects-new/${href}${item[2].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[2].img}')` }} className="projectlist__bg" data-id="44">
                          {item[2].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[2].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[2].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[2].name}</div>
                        {item[2].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[2].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>}
                  </div>
                  <div className="projectlist__line">
                    {item.length > 3 && <div className="scroll-item_100">
                      <Link to={`/projects-new/${href}${item[3].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[3].img}')` }} className="projectlist__bg" data-id="33">
                          {item[3].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[3].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[3].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[3].name}</div>
                        {item[3].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[3].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>
                    </div>}
                    <div className="scroll-item_200">
                      {item.length > 4 && <Link to={`/projects-new/${href}${item[4].url}/`} className="projectlist__item">
                        <div style={{ backgroundImage: `url('${item[4].img}')` }} className="projectlist__bg" data-id="34">
                          {item[4].img.includes("mp4") && <video
                            playsInline
                            autoPlay
                            poster="/cases/img/home/46_poster.jpg"
                            muted
                            loop
                          >
                            <source
                              src={item[4].img}
                              type="video/mp4"
                            />
                          </video>}
                          <div className="projectlist__text">{item[4].dsc}</div>
                        </div>
                        <div className="projectlist__title">{item[4].name}</div>
                        {item[4].dsc.length > 150 && <div className="market-stroke-wrap">
                          <div dangerouslySetInnerHTML={{ __html: item[4].dsc }} className="market-stroke">
                          </div>
                        </div>}
                      </Link>}
                    </div>
                  </div>
                </div>
              </div>}
            </>)
          ))}
        </div>} 
        <Fragment>

          <Switch>
            <section className="intro projects">
              <Route render={() => <Eph  />} path={`/projects-new/${href}/eph/`} />
              <Route render={() => <Voshod  />} path={`/projects-new/${href}/voshod/`} />
              <Route render={() => <Ryadom  />} path={`/projects-new/${href}/ryadom/`} />
              <Route render={() => <Ikforum  />} path={`/projects-new/${href}/ikforum/`} />
              <Route render={() => <ComCity  />} path={`/projects-new/${href}/comcity/`} />
              <Route render={() => <Kakumae  />} path={`/projects-new/${href}/kakumae/`} />
              <Route render={() => <Sistemavc  />} path={`/projects-new/${href}/sistemavc/`} />
              <Route render={() => <Efimovbor  />} path={`/projects-new/${href}/efimovbor/`} />
              <Route render={() => <Imeretinka  />} path={`/projects-new/${href}/imeretinka/`} />
              <Route render={() => <Fsklider  />} path={`/projects-new/${href}/fsklider/`} />
              <Route render={() => <Polyanka  />} path={`/projects-new/${href}/polyanka/`} />
              <Route render={() => <Tushino  />} path={`/projects-new/${href}/tushino/`} />
              <Route render={() => <X5  />} path={`/projects-new/${href}/x5/`} />
              <Route render={(props) => <Video next={0} {...props} />} path={`/projects-new/${href}/video/`} />
              <Route render={() => <Activity  />} path={`/projects-new/${href}/activity/`} />
              <Route render={() => <X5ny  />} path={`/projects-new/${href}/x5ny/`} />
              <Route render={() => <Greenpolza  />} path={`/projects-new/${href}/greenpolza/`} />
              <Route render={() => <Fabcab  />} path={`/projects-new/${href}/fabcab/`} />
              <Route render={() => <Aresidence  />} path={`/projects-new/${href}/aresidence`} />
              <Route render={() => <Torpedo  />} path={`/projects-new/${href}/torpedo/`} />
              <Route render={() => <GeneralInvest  />} path={`/projects-new/${href}/generalinvest/`} />
              <Route render={() => <Botanica  />} path={`/projects-new/${href}/botanica/`} />
              <Route render={() => <Ingrad  />} path={`/projects-new/${href}/ingrad/`} />
              <Route render={() => <X5happy  />} path={`/projects-new/${href}/x5happy/`} />
              <Route render={() => <Gals  />} path={`/projects-new/${href}/gals/`} />
              <Route render={() => <Schastye  />} path={`/projects-new/${href}/schastye/`} />
              <Route render={() => <Notfound  />} path={`/projects-new/${href}/notfound/`} />
              <Route render={() => <SistemavcTest  />} path={`/projects-new/${href}/testtest/`} />
              <Route render={() => <Iskra  />} path={`/projects-new/${href}/iskra/`} />
              <Route render={() => <Iskra  />} path={`/projects-new/${href}/ingrad/`} />
              <Route render={() => <X5values  />} path={`/projects-new/${href}/x5values/`} />
              <Route render={() => <Westgarden  />} path={`/projects-new/${href}/westgarden/`} />
              <Route render={() => <Hunter  />} path={`/projects-new/${href}/hunter-estate/`} />
              <Route render={() => <Academy  />} path={`/projects-new/${href}/academy/`} />
              <Route render={() => <Atm  />} path={`/projects-new/${href}/atm-online/`} />
              <Route render={() => <Tvz  />} path={`/projects-new/${href}/tvz/`} />
              <Route render={() => <Kms  />} path={`/projects-new/${href}/kms/`} />
              <Route render={() => <Vrb  />} path={`/projects-new/${href}/vrb/`} />
              <Route render={() => <F92  />} path={`/projects-new/${href}/f92/`} />
              <Route render={() => <Bsg  />} path={`/projects-new/${href}/bsg/`} />
              <Route render={() => <Afimall  />} path={`/projects-new/${href}/afimall/`} />
              <Route render={() => <Self  />} path={`/projects-new/${href}/self/`} />
              <Route render={() => <Vsn  />} path={`/projects-new/${href}/vsn/`} />
              <Route render={() => <Rep  />} path={`/projects-new/${href}/rep/`} />
              <Route render={() => <Armani  />} path={`/projects-new/${href}/armani/`} />
              <Route render={() => <Dh  />} path={`/projects-new/${href}/dh/`} />
              <Route render={() => <Mts  />} path={`/projects-new/${href}/mts/`} />
              <Route render={() => <Cto  />} path={`/projects-new/${href}/cto/`} />
              <Route render={() => <Ahead  />} path={`/projects-new/${href}/ahead/`} />
              <Route render={() => <Genesis  />} path={`/projects-new/${href}/genesis/`} />
              <Route render={() => <Сrimea  />} path={`/projects-new/${href}/crimea/`} />
              <Route render={() => <Soyuz  />} path={`/projects-new/${href}/soyuz/`} />
              <Route render={() => <Dellin  />} path={`/projects-new/${href}/dellin/`} />
              <Route render={() => <Target  />} path={`/projects-new/${href}/target/`} />
              <Route render={() => <Scandipakk  />} path={`/projects-new/${href}/scandipakk/`} />
              <Route render={() => <Metropolia  />} path={`/projects-new/${href}/metropolia/`} c />
              <Route render={() => <Pp  />} path={`/projects-new/${href}/pp/`} />
              <Route render={() => <Profit  />} path={`/projects-new/${href}/profit/`} />
              <Route render={() => <Emotion  />} path={`/projects-new/${href}/emotion/`} />
              <Route render={() => <Detali  />} path={`/projects-new/${href}/detali/`} />
              <Route render={() => <Plus  />} path={`/projects-new/${href}/plus/`} />
              <Route render={() => <X5table />} path={`/projects-new/${href}/x5table/`} />
            </section>
          </Switch>

        </Fragment>




        <div className="sroll-helper" ref={helper} />
        <div className="sroll-helper2" ref={helper2} />
      </div>

    </Fragment>

  )
}

export default ProjectsAll 