import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Imeretinka extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/imeretinka/'){
			this.setState({preview: false});
			this.props.setProjectName('Имеретинский');
			$('body').addClass('case-expanded hash-imeretinka');
			$(window).scrollTop($('#case-imeretinka').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-imeretinka');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-imeretinka');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/imeretinka/img1.webp')`}}></div></div>
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/imeretinka/img8.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/imeretinka/img6.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <b>Дизайн сайта мы построили на геометрических фигурах: ромбах, треугольниках, косых.</b>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/imeretinka/img2.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/imeretinka/img3.webp')`}}></div></div>
              <div className="text bubble right-top text2 parallax-front">
                <div className="in">
                  Главная страница даёт ключевую информацию о курорте, в том числе эмоциональную.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/imeretinka/img4.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/imeretinka/img5.webp')`}}></div></div>
              <div className="text bubble right2 text3 parallax-front">
                <div className="in">
                  Интерактивные планы местности позволяют изучить район, забронировать или купить недвижимость.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/imeretinka/img10.webp')`}}></div></div>
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/imeretinka/img11.webp')`}}></div></div>
          <div className="content narrow">
            <div className="image image3"><div style={{backgroundImage: `url('/cases/img/imeretinka/img9.webp')`}}></div></div>
            <div className="clear">
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/imeretinka/img12.webp')`}}></div></div>
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  Мобильную версию мы реализовали<br/>с использованием технологии RESS.
                </div>
              </div>
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/imeretinka/img13.webp')`}}></div></div>
              <div className="screenshot-mob screenshot2"><div style={{backgroundImage: `url('/cases/img/imeretinka/img14.webp')`}}></div></div>
              <div className="screenshot-mob screenshot3"><div style={{backgroundImage: `url('/cases/img/imeretinka/img15.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/imeretinka/img8.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/imeretinka/img16.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  Для курорта<br/>мы разработали приложение для iOS.
                </div>
              </div>
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/imeretinka/img17.webp')`}}></div></div>
              <div className="screenshot-phone screenshot2"><div style={{backgroundImage: `url('/cases/img/imeretinka/img18.webp')`}}></div></div>
              <div className="screenshot-phone screenshot3"><div style={{backgroundImage: `url('/cases/img/imeretinka/img19.webp')`}}></div></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-imeretinka">
				<Link to="/projects/imeretinka/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/imeretinka/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/imeretinka/logo.svg')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Имеретинский –<br/>курортный район<br/>на берегу Чёрного моря</div>
								<ul>
									<li>196 номеров в отеле</li>
									<li>2700 апартаментов</li>
									<li>10 минут до Сочи</li>
								</ul>
							</div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/schastye/" }
				title= { this.props.next ? this.props.next.name: "Счастье"} 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/sch.webp"}
			/>
      <HelmetSite title="Имеретинский" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Имеретинский</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Imeretinka)