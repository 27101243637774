import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Tvz extends Component {
	state = {
		preview: true,
    play: false
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/tvz/'){
			this.setState({preview: false});
			this.props.setProjectName('tvz');
			$('body').addClass('case-expanded hash-tvz');
			$(window).scrollTop($('.tvz').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-tvz');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-tvz');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render(){
    return(
      <div className='case'>
        <div className="case-content">
          <div className="tvz">
            <div className="tvz__bg1">
              <img src="/cases/img/tvz/logo.svg" />
            </div>

            <div className="tvz_img2">
              <img className="shadow" src="/cases/img/tvz/img2.webp" />
              <img className="last" src="/cases/img/tvz/img3.webp" />

              <div className="text bubble left parallax-front parallax-up">
                <div className="in">В дизайне сайта мы отразили качество и натуральность продукции. <br /><br />А в первом экране нас встречает видео раскрывающее суть производства.</div>
              </div>
            </div>

            <div className="tvz_img3">
              <img src="/cases/img/tvz/img5.webp" />
              <img className="shadow" src="/cases/img/tvz/img4.webp" />

              <div className="text bubble right parallax-front parallax-up">
                <div className="in">У каждого напитка собственная страница, рассказывающая о всех особенностях вкуса</div>
              </div>
            </div>

            <div className="tvz_img4"><img src="/cases/img/tvz/img7.webp" /></div>

            <div className="tvz_img5">
              <img className="shadow" src="/cases/img/tvz/img8.webp" />
              <img className="shadow last" src="/cases/img/tvz/img9.webp" />
              <img className="dec" src="/cases/img/tvz/img13.webp" />
              <div className="text bubble left parallax-front parallax-up">
                <div className="in">Сайт  наполнен имиджевыми страницами, подробно раскрывающими полезную и интересную информацию о заводе</div>
              </div>
            </div>

            <img className="tvz_img6 shadow" src="/cases/img/tvz/img10.webp" />

            <div className="tvz_img7"><img src="/cases/img/tvz/img12.webp" /></div>

            {/*<div className="tvz_img8">
              <div className="tvz_img8-in"><img src="/cases/img/tvz/img14.png" /></div>
              <div className="text bubble right-top parallax-front parallax-up">
                <div className="in">Видео–демонстрация работы сайта</div>
              </div>
            </div>*/}
            <div className="academy__box academy__box10">
              {/* <img src="/cases/academy/10.png" /> */}
              <img src="/cases/img/tvz/mac.webp" />
              <div className="video__box">
                <div>
                  {/* <img src="/cases/academy/video.png" /> */}
                  <img src="/cases/academy/video.webp" />
                  {/*<iframe src="https://www.youtube.com/embed/9yAVO3zDQL8"></iframe>*/}
                  <video poster="/cases/img/tvz/poster.webp" ref="video">
                    <source src="/cases/img/tvz/video.mp4" type="video/mp4" />
                  </video>
                  {this.state.play || <div className="video__play" onClick={this.handlePlay} />}
                </div>
              </div>
              <div className="text bubble right-top parallax-front text1 parallax-up">
                <div className="in">Видео–демонстрация работы сайта</div>
              </div>
            </div>

            <div className="tvz_img9">
              <img className="tvz_img9-1" src="/cases/img/tvz/img15.webp" />
              <img className="tvz_img9-2" src="/cases/img/tvz/img16.webp" />
              <img className="tvz_img9-3" src="/cases/img/tvz/img17.webp" />

              <img className="tvz_img9-dec" src="/cases/img/tvz/img19.webp" />
            </div>

            <div className="tvz_img10"></div>

            <div className="tvz_img11">
              <img className="shadow" src="/cases/img/tvz/img20.webp" />
              <div className="text bubble left parallax-front parallax-up">
                <div className="in">Видео–демонстрация работы сайта</div>
              </div>
            </div>

            <div className="tvz_img12">
              <img className="shadow" src="/cases/img/tvz/img21.webp" />
              <img className="dec" src="/cases/img/tvz/img22.webp" />
            </div>
          </div>
        </div>

  

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/kms/"  }
				title= { this.props.next ? this.props.next.name: "Кузнецкий Мост 12"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/kms.webp" }
			/>
        <HelmetSite title={`Тульский Винокуренный Завод`} />
      </div>
    )
  }

  handlePlay = () => {
    this.setState({play: true})
    this.refs.video.play()
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tvz)