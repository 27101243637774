import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Kakumae extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/kakumae/'){
			this.setState({preview: false});
			this.props.setProjectName('Haven Kakumäe');
			$('body').addClass('case-expanded hash-kakumae');
			$(window).scrollTop($('#case-kakumae').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-kakumae');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-kakumae');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="bg parallax-back">
          <div style={{backgroundImage: `url('/cases/img/kakumae/img1.webp')`}}></div>
          </div>
          <div className="content narrow">
            <div className="clear">
              <div className="text middle text0">
                <div className="in">
                  <div className="title">Haven Kakumae &mdash;</div>
                  <p>морской центр в Таллине, Эстония</p>
                  <ul>
                    <li>Пристань Балтийского моля</li>
                    <li>Апартаменты Премиум класса</li>
                    <li>Яхтенный спорт</li>
                    <li>Семейный отдых</li>
                  </ul>
                </div>
                <div className="image image1" style={{backgroundImage: `url('/cases/img/kakumae/img2.webp')`}}></div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/kakumae/img3.webp')`}}></div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/kakumae/img4.webp')`}}></div>
              <div className="text bubble right2 text1 parallax-front">
                <div className="in">
                  <p><b>Проект, который разбудил в нас романтиков.</b></p>
                  <p>Перед нами стояла приятная задача — разработать сайт для тех, кто любит море, яхтинг и комфортный отдых.</p>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  <p>На страницах сайта мы разместили большое количество полноэкранных атмосферных имиджей.</p>
                  <p>Мы нарисовали 97 морских иконок. Дизайнеры хотели довести до сотни, но их вовремя остановили.</p>
                </div>
              </div>
              <div className="image image3" style={{backgroundImage: `url('/cases/img/kakumae/img5.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/kakumae/img6.webp')`}}></div>
              <div className="image image4" style={{backgroundImage: `url('/cases/img/kakumae/img7.webp')`}}></div>
              <div className="text bubble right text3 parallax-front">
                <div className="in">
                  <p>Информацию мы подаём структурированно, дозированно, тщательно насыщая текст имиджами.</p>
                  <p>Мы стремились создать интерфейс, где ничего не мешает воспринимать информацию: легкий, прозрачный, свежий.</p>
                </div>
              </div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/kakumae/img8.webp')`}}></div>
              <div className="image image5" style={{backgroundImage: `url('/cases/img/kakumae/img9.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot4" style={{backgroundImage: `url('/cases/img/kakumae/img10.webp')`}}></div>
              <div className="text bubble left text4 parallax-front">
                <div className="in">
                  <b>Яркая галерея, разделённая на фотоальбомы по тематикам.</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="content narrow">
            <div className="image image1" style={{backgroundImage: `url('/cases/img/kakumae/img11.webp')`}}></div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/kakumae/img12.webp')`}}></div>
          </div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble right-bottom text0 parallax-front">
                <div className="in">
                  Мы адаптировали сайт под все типы устройств: экран компьютера, смартфоны и планшеты.
                </div>
              </div>
              <div className="screenshot-mob screenshot1" style={{backgroundImage: `url('/cases/img/kakumae/img13.webp')`}}></div>
              <div className="screenshot-mob screenshot2" style={{backgroundImage: `url('/cases/img/kakumae/img14.webp')`}}></div>
              <div className="screenshot-phone screenshot3" style={{backgroundImage: `url('/cases/img/kakumae/img15.webp')`}}>
                <div style={{backgroundImage: `url('/cases/img/kakumae/img15.webp')`}} />
              </div>
              <div className="image image1" style={{backgroundImage: `url('/cases/img/kakumae/img16.webp')`}}></div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/kakumae/img17.webp')`}}></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <b>Сайт марины «HAVEN KAKUMAE» мы сделали в трёх языковых версиях.</b>
                </div>
              </div>
              <div className="text bubble right-bottom text2 parallax-front">
                <div className="in">
                  <b>... и ветер был попутный в наших парусах!</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>

			<div className="case-content" id="case-kakumae">
				<Link to="/projects/kakumae/" className="screen screen0 h100">
					<div className="bg parallax-back">
						<div style={{backgroundImage: `url('/cases/img/kakumae/img0.webp')`}}></div>
					</div>
					<div className="content narrow">
						<div className="logo">
							<div style={{backgroundImage: `url('/cases/img/kakumae/logo.svg')`}}></div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>

      <HelmetSite title="Haven Kakumäe" />

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/vrb" }
				title= { this.props.next ? this.props.next.name: "Врубеля 4"} 
				img={ this.props.next? this.props.next.imgCaseBottom:"/cases/img/next/vrb.webp"}
			/>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Kakumae)