const mapStateToProps = (state) => {
	return {
		version: state.version,
		pageId: state.pageId,
		projectName: state.projectName,
		caseType: state.caseType,
        popup: state.popup
	}
}

export default mapStateToProps;