import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class X5ny extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/x5ny/'){
			this.setState({preview: false});
			this.props.setProjectName('Х5 - Корзина доброты');
			$('body').addClass('case-expanded hash-x5ny');
			$(window).scrollTop($('#case-x5ny').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-x5ny');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-x5ny');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen1" style={{backgroundImage: `url('/cases/img/x5ny/bg2.webp')`}}>
          <div className="content narrow">
            <div className="image image1">
              <div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img1.webp')`}}></div>
              <div className="clear">
                <div className="text bubble left text text1 parallax-front">
                  <div className="in">
                  Cоздан новогодний портал для 250 000 сотрудников <a href="https://www.x5.ru/" target="_blank">компании Х5</a>. Игры, конкурсы, подарки, музыка, кино, вкусняшки, возможность нарисовать уникальную открытку и отправить друзьям и близким, конечно, новогоднее настроение прилагается!
                  </div>
                </div>
              </div>
            </div>
            <div className="image image2"><div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img2.webp')`}}></div></div>
            <div className="image image3">
              <div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img4.webp')`}}></div>
              <div className="clear">
                <div className="text bubble right text text2 parallax-front">
                  <div className="in">
                  Каждый раздел портала - это<br/>
                  неповторимая и запоминающаяся<br/>
                  интерактивная яркая картинка!<br/>
                  Волшебно!<br/>
                  Волшебно!
                  </div>
                </div>
              </div>
            </div>
            <div className="image image4"><div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img5.webp')`}}></div></div>
            <div className="image image5"><div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img6.webp')`}}></div></div>
            <div className="image image6">
              <div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img7.webp')`}}></div>
              <div className="clear">
                <div className="text bubble right text text2 parallax-front">
                  <div className="in">
                    Создать шедевр самому?!<br/>Легко!
                  </div>
                </div>
              </div>
            </div>
            <div className="image image7"><div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img8.webp')`}}></div></div>
            <div className="image image8"><div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img9.webp')`}}></div></div>
            <div className="image image9"><div className="shadow-image" style={{backgroundImage: `url('/cases/img/x5ny/img10.webp')`}}></div></div>
          </div>
        </div>
        <div className="screen2">
          <div className="content narrow">
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5ny/img11.webp')`}}></div></div>
            <div className="image image2">
              <div style={{backgroundImage: `url('/cases/img/x5ny/img12.webp')`}}></div>
              <div className="clear">
                <div className="text bubble right text text1 parallax-front">
                  <div className="in">
                  Разработан пакет материалов, способствующих продвижению портала: наклейки, плакаты!
                  </div>
                </div>
              </div>
            </div>
            <div className="image image3"><div style={{backgroundImage: `url('/cases/img/x5ny/img14.webp')`}}></div></div>
            <div className="image image4"><div style={{backgroundImage: `url('/cases/img/x5ny/img15.webp')`}}></div></div>
          </div>

          
          <div className="clear">
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item item1"><span style={{backgroundImage: `url('/cases/img/x5ny/img18.svg')`}}></span></div>
                <div className="item item2"><span style={{backgroundImage: `url('/cases/img/x5ny/img19.svg')`}}></span></div>
                <div className="item item3"><span style={{backgroundImage: `url('/cases/img/x5ny/img20.svg')`}}></span></div>
                <div className="item item4"><span style={{backgroundImage: `url('/cases/img/x5ny/img21.svg')`}}></span></div>
                <div className="item item5"><span style={{backgroundImage: `url('/cases/img/x5ny/img22.svg')`}}></span></div>
                <div className="item item6"><span style={{backgroundImage: `url('/cases/img/x5ny/img23.svg')`}}></span></div>
                <div className="item item7"><span style={{backgroundImage: `url('/cases/img/x5ny/img24.svg')`}}></span></div>
                <div className="item item8"><span style={{backgroundImage: `url('/cases/img/x5ny/img25.svg')`}}></span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen3">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5ny/img26.webp')`}}></div></div>
        </div>
        <div className="screen4">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5ny/img27.webp')`}}></div></div>
          <div className="image image2">
            <div className="clear">
              <div className="text bubble left-bottom text text1 parallax-front">
                <div className="in">
                Динамичный видеоролик,<br/>глаз не оторвать! ;)
                </div>
              </div>
            </div>
            <div style={{backgroundImage: `url('/cases/img/x5ny/img28.webp')`}}>
              <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/LNpg-KJenPk" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
          </div>
        </div>
        <div className="screen5">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5ny/img31.webp')`}}></div></div>
          <div className="image image2"><div style={{backgroundImage: `url('/cases/img/x5ny/img32.webp')`}}></div></div>
          <div className="image image3"><div style={{backgroundImage: `url('/cases/img/x5ny/img33.webp')`}}></div></div>
        </div>
        <div className="screen6">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5ny/img34.webp')`}}></div></div>
        </div>

      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-x5ny">
				<div to="/projects/x5ny/" className="screen screen0 h100" style={{backgroundImage: `url('/cases/img/x5ny/bg.webp')`}}>
					<Link to="/projects/x5ny/" className="screen0-lnk"></Link>
					{this.more()}
				</div>
			</div>


   

    <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/x5values/"  }
				title= { this.props.next ? this.props.next.name: "Корпоративные ценности Х5" } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/x5value.webp"}
			/>
      <HelmetSite title="Помощник Деда Мороза" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Х5 - Корзина доброты</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(X5ny)