import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class X5 extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/x5/'){
			this.setState({preview: false});
			this.props.setProjectName('Х5 - Корзина доброты');
			$('body').addClass('case-expanded hash-x5');
			$(window).scrollTop($('#case-x5').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-x5');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-x5');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="content narrow">
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img2.webp')`}}></div></div>
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <b>Задача была сложная, но приятная: сделать сайт, наполненный добром и теплом.</b>
                  <br/>Было важно донести простоту и прозрачность схемы помощи малоимущим. Поэтому на главной странице в уютно-игровой форме мы показали, кому и как можно сделать пожертвование.
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img3.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img4-1.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img4-2.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img4-3.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img4-4.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img4-5.webp')`}}></div></div>
                </div>
              </div>
              <div className="text bubble left-top text2 parallax-front">
                <div className="in">
                  <b>И нам понравился этот добрый игровой подход.</b>
                  Мы проработали все страницы сайта в таком стиле, подготовили красочную презентацию и пошли к клиенту. Концепцию утвердили, и мы даже почти не волновались.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img5.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img6.webp')`}}></div></div>
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <b>Иконки, инфографика, персонажи, рисунки</b>
                  <br/>– всё это мы тщательно проработали. Вдохновлённые дизайнеры даже переборщили с сердечками, но мы уговорили их убрать лишнее и отложить до 14 февраля.
                </div>
              </div>
              <div className="image image1" ><div style={{backgroundImage: `url('/cases/img/x5korzina/img7.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img8.webp')`}}></div></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  <b>Такую серьёзную и важную тему помощи мы подаём легко и ярко.</b>
                  <br/>Так же легко, как сделать пожертвование на сайте, и так же ярко, как будут сиять глаза тех, кому пришла помощь.
                </div>
              </div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/x5korzina/img9.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/x5korzina/img10.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-1.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-2.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-3.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-4.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-5.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-6.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-7.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-8.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-9.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-10.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-11.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img11-12.webp')`}}></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="content narrow">
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img12.webp')`}}></div></div>
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <b>Мы адаптировали сайт под любые мобильные устройства.</b>
                </div>
              </div>
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img13.webp')`}}></div></div>
              <div className="screenshot-mob screenshot2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img14.webp')`}}></div></div>
              <div className="screenshot-mob screenshot3 parallax-front"><div style={{backgroundImage: `url('/cases/img/x5korzina/img15.webp')`}}></div></div>
              <div className="image image2">
                <div style={{backgroundImage: `url('/cases/img/x5korzina/img16_1.webp')`}}></div>
                <div style={{backgroundImage: `url('/cases/img/x5korzina/img16.webp')`}}></div>
              </div>
            </div>
            <div className="clear">
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/x5korzina/img17.webp')`}}></div></div>
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  <b>Ещё была задача рассказать сотрудникам компании X5, как стать волонтёрами проекта «Корзина доброты».</b> Мы подготовили сценарий, сделали дизайн всех сцен и смонтировали добрый видеоролик. Настроение взяли с сайта
                </div>
              </div>
              <div className="video video1">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/xqzdBIPiIW0?rel=0&amp;showinfo=0"></iframe>
              </div>
            </div>
            <div className="clear">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-1.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-2.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-3.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-4.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-5.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-6.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-7.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/x5korzina/img18-8.webp')`}}></div></div>
                </div>
              </div>
              <div className="text bubble left-top text3 parallax-front">
                <div className="in">
                  <b>20</b> персонажей мы создали в ходе проекта. Каждый из них выполняет свою миссию и имеет свой характер.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="text bubble right text4 parallax-front">
                <div className="in">
                  <b>Одного ролика<br/>для волонтёров оказалось мало.</b> Мы сделали ещё один – там менее, чем за 2 минуты рассказали об онлайн-пожертвованиях на сайте «Корзины доброты».
                </div>
              </div>
              <div className="video video2">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Z6gI6QR3j7o?rel=0&amp;showinfo=0"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="content narrow">
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img19.webp')`}}></div></div>
            <div className="image image2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img20.webp')`}}></div></div>
            <div className="image image3"><div style={{backgroundImage: `url('/cases/img/x5korzina/img21.webp')`}}></div></div>
            <div className="clear">
              <div className="text middle text1">
                <div className="in">
                  Рекламная кампания
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img22.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img23.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/x5korzina/img24.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="content narrow">
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img25.webp')`}}></div></div>
            <div className="image image2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img26.webp')`}}></div></div>
            <div className="image image3"><div style={{backgroundImage: `url('/cases/img/x5korzina/img27.webp')`}}></div></div>
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <b>Запустили таргетированную рекламу</b>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/x5korzina/img28.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/x5korzina/img29.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/x5korzina/img30.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  <b>Контекстную рекламу<br/>и рекламу в социальных сетях</b>
                </div>
              </div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/x5korzina/img31.webp')`}}></div></div>
              <div className="screenshot screenshot5"><div style={{backgroundImage: `url('/cases/img/x5korzina/img32.webp')`}}></div></div>
              <div className="screenshot screenshot6"><div style={{backgroundImage: `url('/cases/img/x5korzina/img33.webp')`}}></div></div>
              <div className="screenshot screenshot7"><div style={{backgroundImage: `url('/cases/img/x5korzina/img34.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble left-top text3 parallax-front">
                <div className="in">
                  <b>Наверное, никто не поспорит, что в этот проект невозможно было не влюбиться!</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-x5">
				<Link to="/projects/x5/" className="screen screen0 h100">
					<div className="bg parallax-front"><div style={{backgroundImage: `url('/cases/img/x5korzina/img0.webp')`}}></div></div>
					<div className="image image1 parallax-front"><div style={{backgroundImage: `url('/cases/img/x5korzina/img1.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/x5korzina/logo.svg')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Корзина доброты</div>
								<p>«Корзина доброты» – социальный проект компании X5 retail group для помощи малообеспеченным семьям и пенсионерам ...проект, в который мы влюбились.</p>
							</div>
						</div>
					</div>
					<div className="image image2"><div style={{backgroundImage: `url('/cases/img/x5korzina/logo2.webp')`}}></div></div>
				</Link>
				{this.more()}
			</div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/activity/" }
				title= { this.props.next ? this.props.next.name: "Активности"} 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/activity.webp"}
			/>
      <HelmetSite title="Корзина доброты" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Х5 - Корзина доброты</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(X5)