export default {
    data : [
        {
          name: 'Ahead',
          img: '/cases/img/home/45.jpg',
          imgCaseBottom: '/cases/img/ahead/img1.webp',
          url: '/ahead',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
      
        },
        {
          name: 'VSN Realty',
          img: '/cases/img/home/vsn_new.jpg',
          imgCaseBottom: '/cases/img/next/vsn.webp',
          url: '/vsn',
          category: ['all', 'web' ,'branding'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Кузнецкий Мост 12',
          img: '/cases/img/home/37.jpg',
          imgCaseBottom: '/cases/img/next/kms.webp',
          url: '/kms',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Genesis',
          img: '/cases/img/home/46_video.mp4',
          imgCaseBottom: '/cases/img/next/genesis.webp',
          url: '/genesis',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'ЖК Союз у метро Ботанический сад',
          img: '/cases/img/home/soyuzn.jpg',
          imgCaseBottom: '/cases/img/next/soyuz_next.jpg',
          url: '/soyuz',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Скандипакк',
          img: '/cases/img/home/skandipak.webp',
          imgCaseBottom: '/cases/img/next/scandipakk.webp',
          url: '/scandipakk/',
          category: ['all', 'web','branding'] ,
          dsc: 'Полный комплекс работ по ребрендингу всемирно известной компании по производству одноразовой упаковки для продуктов питания.',
        },
        {
          name: 'Armani / Casa',
          img: '/cases/img/home/36.jpg',
          imgCaseBottom: '/cases/img/next/armani.webp',
          url: '/armani/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Х5. Цифровая академия',
          img: '/cases/img/home/29.jpg',
          imgCaseBottom: '/cases/img/next/x5value.png',
          url: '/x5values/',
          category: ['all', 'web' ,'branding']  ,
          dsc: 'Создание фирменного стиля',
        },
        {
          name: 'Преображенская площадь | Regions',
          img: '/cases/img/home/pp.webp',
          imgCaseBottom: '/cases/img/next/pp.webp',
          url: '/pp',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Афимолл Сити + Маша и Медведь',
          img: '/cases/img/home/35.jpg',
          imgCaseBottom: '/cases/img/next/afimall.webp',
          url: '/afimall/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
       
        {
          name: 'Digital Heroes',
          img: '/cases/img/home/42.jpg',
          imgCaseBottom: '/cases/img/next/dh.webp',
          url: '/dh',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Маркетинг в недвижимости',
          img: '/cases/img/home/market2.mp4',
          imgCaseBottom: '/cases/img/home/market4.mp4',
          url: '/accents/marketing/',
          category: ['all', 'web', 'branding'] ,
          dsc: `&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж
          &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество
          продаж&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Бескрайнее
          количество продаж`,
        },
        {
          name: 'Корпоративные ценности Х5',
          img: '/cases/img/home/26.jpg',
          imgCaseBottom: '/cases/img/next/x5values/',
          url: '/dh',
          category: ['all', 'branding'] ,
          dsc: 'Визуализация корпоративных ценностей для лидера российской розницы',
        },
        {
          name: 'Репинское',
          img: '/cases/img/home/repinskoe.webp',
          imgCaseBottom: '/cases/img/next/rep.webp',
          url: '/rep',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Крымская Резеденция',
          img: '/cases/img/home/47.jpg',
          imgCaseBottom: '/cases/img/next/crimea.webp',
          url: '/crimea/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Маркетинг в недвижимости',
          img: '/cases/img/home/market3.mp4',
          imgCaseBottom: '/cases/img/home/market4.mp4',
          url: '/accents/marketing/',
          category: ['all', 'web', 'branding'] ,
          dsc: `&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости`,
        },
        
        {
          name: 'Галс Девелопмент',
          img: '/cases/img/home/27.jpg',
          imgCaseBottom: '/cases/img/next/gals.jpg',
          url: '/gals/',
          category: ['all', 'web'] ,
          dsc: 'Создание корпоративного сайта',
        },
        {
          name: 'FabCab',
          img: '/cases/img/home/17.jpg',
          imgCaseBottom: '/cases/img/next/fabcab.jpg',
          url: '/fabcab/',
          category: ['all', 'branding','branding'] ,
          dsc: 'Логотип и продвижение лучшего сервиса такси во всей юго-восточной Азии',
        },
        {
          name: 'Happy X5',
          img: '/cases/img/home/22_2.jpg',
          imgCaseBottom: '/cases/img/next/x5happy.jpg',
          url: '/x5happy/',
          category: ['all', 'web'] ,
          dsc: 'Создание и продвижение праздничных активностей для сотрудников в интернете',
        },
        {
          name: 'Маркетинг в недвижимости',
          img: '/cases/img/home/market1.mp4',
          imgCaseBottom: '',
          url: '/accents/marketing/',
          category: ['all', 'web', 'brandingg'] ,
          dsc: `&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;
          Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Маркетинг в
          недвижимости&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Звездная
          команда&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp; Маркетинг в
          недвижимости`,
        },

        {
          name: 'Искра-Парк',
          img: '/cases/img/home/25.jpg',
          imgCaseBottom: '/cases/img/next/iskra.jpg',
          url: '/iskra/',
          category: ['all', 'web'] ,
          dsc: 'Видео-сайт для квартала на Ленинградском проспекте',
        },
            {
          name: 'Полянка 44',
          img: '/cases/img/home/10.jpg',
          imgCaseBottom: '/cases/img/next/polyanka.jpg',
          url: '/polyanka/',
          category: ['all', 'web'] ,
          dsc: 'Сайт для уникального комплекса элитных особняков в историческом центре Москвы',
        },
        {
          name: 'Торпедо',
          img: '/cases/img/home/19.jpg',
          imgCaseBottom: '/cases/img/next/torpedo.jpg',
          url: '/torpedo/',
          category: ['all', 'web'] ,
          dsc: 'Сайт для легендарного футбольного клуба «Торпедо»',
        },
        {
          name: 'General Invest',
          img: '/cases/img/home/20.jpg',
          imgCaseBottom: '/cases/img/next/generalinvest.jpg',
          url: '/generalinvest/',
          category: ['all', 'web'] ,
          dsc: 'Дизайн сайта инвестиционной компании с богатой историей и итальянскими корнями',
        },
        {
          name: 'Зеленая польза',
          img: '/cases/img/home/16.jpg',
          imgCaseBottom: '/cases/img/next/polza.webp',
          url: '/greenpolza/',
          category: ['all', 'web'] ,
          dsc: 'Визуальная концепция и создание материалов для уникального продукта Банка Home Credit',
        },
        {
          name: 'Помощник Деда Мороза',
          img: '/cases/img/home/13.jpg',
          imgCaseBottom: '/cases/img/next/x5ny.jpg',
          url: '/x5ny/',
          category: ['all', 'web'] ,
          dsc: 'Специальный новогодний портал для X5 Retail Group.  ',
        },
        {
          name: 'Ефимов Бор',
          img: '/cases/img/home/7_2.jpg',
          imgCaseBottom: '/cases/img/next/efimovbor.webp',
          url: '/efimovbor/',
          category: ['all', 'web' ,'branding'] ,
          dsc: 'Комплексный проект по созданию и продвижению бренда отеля  ',
        },
        {
          name: 'ATM online',
          img: '/cases/img/home/31.jpg',
          imgCaseBottom: '/cases/img/next/atm.webp',
          url: '/atm-online/',
          category: ['all', 'web'] ,
          dsc: 'ATM online ',
        },
        {
          name: 'Счастье',
          img: '/cases/img/home/24.jpg',
          imgCaseBottom: '/cases/img/next/sch.jpg',
          url: '/schastye/',
          category: ['all', 'web'] ,
          dsc: 'Сайт для зонтичного бренда ',
        },
        {
          name: 'Видео',
          img: '/cases/img/home/12.jpg',
          imgCaseBottom: '/cases/img/next/video.jpg',
          url: '/video/',
          category: ['all', 'web'] ,
          dsc: 'Самое важное из искусств в нашем исполнении',
        },
        {
          name: 'Корзина доброты',
          img: '/cases/img/home/14.jpg',
          imgCaseBottom: '/cases/img/next/x5korz.webp',
          url: '/x5/',
          category: ['all', 'web'] ,
          dsc: 'Социальный проект X5 Retail Group',
        },
        {
          name: 'Активности',
          img: '/cases/img/home/15.jpg',
          imgCaseBottom: '/cases/img/next/activity.jpg',
          url: '/activity/',
          category: ['all', 'web'] ,
          dsc: 'Игры, конкурсы, активности',
        },
        {
          name: 'Eastern Property Holdings',
          img: '/cases/img/home/1.jpg',
          imgCaseBottom: '/cases/img/next/eph.webp',
          url: '/eph/',
          category: ['all', 'web'] ,
          dsc: 'Корпоративный сайт для швейцарского инвестиционного холдинга',
        },
        {
          name: 'Рядом',
          img: '/cases/img/home/2.jpg',
          imgCaseBottom: '/cases/img/next/ryadom.jpg',
          url: '/ryadom/',
          category: ['all', 'web'] ,
          dsc: 'Сайт для агентства недвижимости, которое всегда рядом',
        },
        {
          name: 'ИК “Форум”',
          img: '/cases/img/home/3.jpg',
          imgCaseBottom: '/cases/img/next/ikforum.jpg',
          url: '/ikforum/',
          category: ['all', 'web' ,'branding'] ,
          dsc: 'Фирменный стиль и корпоративный сайт для инвестиционной компании',
        },
        {
          name: 'Врубеля 4',
          img: '/cases/img/home/39.jpg',
          imgCaseBottom: '/cases/img/next/vrb.webp',
          url: '/vrb',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'A-Residence',
          img: '/cases/img/home/18.jpg',
          imgCaseBottom: '/cases/img/next/aresidence.web',
          url: '/aresidence/',
          category: ['all', 'web'] ,
          dsc: 'Сайт для ансамбля клубных особняков',
        },
        {
          name: 'Haven Kakumäe',
          img: '/cases/img/home/5.jpg',
          imgCaseBottom: '//cases/img/next/kakumae.webp',
          url: '/kakumae/',
          category: ['all', 'web'] ,
          dsc: 'Сайт для уникальной марины в Финском заливе',
        },
        {
          name: 'Усадьба охотника',
          img: '/cases/img/home/30.jpg',
          imgCaseBottom: '/cases/img/home/30.jpg',
          url: '/hunter-estate/',
          category: ['all', 'web' ,'branding'] ,
          dsc: 'Фирменный стиль и сайт',
        },
        {
          name: 'Тульский винокуренный завод',
          img: '/cases/img/home/32.jpg',
          imgCaseBottom: '/cases/img/next/tvz.webp',
          url: '/tvz/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'МТС Новый Год',
          img: '/cases/img/home/43.jpg',
          imgCaseBottom: '/cases/img/next/mts_next.webp',
          url: '/mts/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Systema VC',
          img: '/cases/img/home/6.jpg',
          imgCaseBottom: '/cases/img/next/sys.webp',
          url: '/sistemavc/',
          category: ['all', 'web','branding'] ,
          dsc: 'Разработка фирменного стиля для венчурного фонда АФК «СИСТЕМА»',
        },
        {
          name: 'Деловые Линии',
          img: '/cases/img/home/49.jpg',
          imgCaseBottom: '/cases/img/next/dellin.webp',
          url: '/dellin/',
          category: ['all', 'web'] ,
          dsc: 'Деловые Линии - Дело 0823',
        },
        {
          name: 'BCG Woman Talks',
          img: '/cases/img/home/34.jpg',
          imgCaseBottom: '/cases/img/next/bsg.webp',
          url: '/bsg/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'X5 СТО',
          img: '/cases/img/home/44.jpg',
          imgCaseBottom: '/cases/img/next/bsg.webp',
          url: '/cto/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'SELF Парковый квартал',
          img: '/cases/img/home/41new.webp',
          imgCaseBottom: '/cases/img/next/self.webp',
          url: '/self',
          category: ['all', 'web','branding'] ,
          dsc: 'Полный комплекс работ по созданию бренда для жилого комплекса',
        },
        {
          name: 'ЖК “Ботаника”',
          img: '/cases/img/home/21.jpg',
          imgCaseBottom: '/cases/img/next/botanica.webp',
          url: '/botanica/',
          category: ['all', 'web'] ,
          dsc: 'Создание сайта дома премиум-класса, расположенного в самом центре Петроградской стороны',
        },
        {
          name: 'Федерация 92',
          img: '/cases/img/home/33.jpg',
          imgCaseBottom: '/cases/img/next/f92.webp',
          url: '/f92/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Инград',
          img: '/cases/img/home/23.jpg',
          imgCaseBottom: '/cases/img/next/ingrad.webp',
          url: '/ingrad/',
          category: ['all', 'web'] ,
          dsc: 'Создание дизайна корпоративного сайта',
        },
        {
          name: 'ЖК "West Garden"',
          img: '/cases/img/home/28.jpg',
          imgCaseBottom: '/cases/img/next/westgarden.webp',
          url: '/westgarden/',
          category: ['all', 'web'] ,
          dsc: 'Уникальный проект сочетающий преимущества жизни на природе в Москве.',
        },

        {
          name: 'Ipoteka Profit',
          img: '/cases/img/home/profit.webp',
          imgCaseBottom: '/cases/img/home/48.mp4',
          url: '/profit/',
          category: ['all', 'web','branding'] ,
          dsc: 'Разработка нейминга, фирменного стиля, лендинга',
        },
        {
          name: '#ПРИЛОЖЕНИЯ',
          img: '/cases/img/home/48.mp4',
          imgCaseBottom: '/cases/img/next/westgarden.webp',
          url: '/apps/',
          category: ['all', 'web'] ,
          dsc: 'Уникальный проект сочетающий преимущества жизни на природе в Москве.',
        },
        {
          name: 'Target Point',
          img: '/cases/img/home/target.webp',
          imgCaseBottom: '/cases/img/next/target_point.jpg',
          url: '/target/',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Метрополия',
          img: '/cases/img/home/metropolia.webp',
          imgCaseBottom: '/cases/img/next/me.webp',
          url: '/metropolia',
          category: ['all', 'web'] ,
          dsc: 'Сайт',
        },
        {
          name: 'ЖК «Детали»',
          img: '/cases/img/home/detali_home.svg',
          imgCaseBottom: '/cases/img/next/detali_next.webp',
          url: '/detali',
          category: ['all', 'web','branding'] ,
          dsc: 'Сайт',
        },
        {
          name: 'Emotion',
          img: '/cases/img/home/emotion.webp',
          imgCaseBottom: '/cases/img/next/emotion.webp',
          url: '/emotion',
          category: ['all', 'web','branding'] ,
          dsc: 'Брендинг',
        },
        {
          name: 'Plus',
          img: '/cases/img/home/plus.svg',
          imgCaseBottom: '/cases/img/next/plus.webp',
          url: '/plus',
          category: ['all', 'web'] ,
          dsc: 'Брендинг',
        },
        {
          name: 'Voshod',
          img: '/cases/img/home/voshod.svg',
          imgCaseBottom: '/cases/img/next/voshod_next.webp',
          url: '/voshod',
          category: ['all', 'web' ] ,
          dsc: 'Брендинг',
        },
        {
          name: 'X5table',
          img: '/cases/img/home/x5table.svg',
          imgCaseBottom: '/cases/img/next/x5table_next.png',
          url: '/x5table',
          category: ['all', 'web' ] ,
          dsc: 'Брендинг',
        },
      ]
  }
  