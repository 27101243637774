import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {hit} from '../../metrics';

import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';

import style from './style.css';

class X5values extends Component{
  state = {
    play: false
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage resize-page');
    }, 100);
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage resize-page');
  }
  
  render(){
    return(
    <div className='case'>
      <div className="case-content">
        <div className="atm">
          <div className="atm__box atm__box1">
            <div className="atm__bg" style={{backgroundImage: 'url(/cases/atm/1.webp)'}} />
            <div className="atm__logo" />
          </div>

          <div className="atm__box atm__box2">
            <img className="shadow" src="/cases/atm/2.webp" />
            <div className="text bubble left parallax-front text1 parallax-up">
              <div className="in">Web - сайт для ATM online. Компания предлагает различные кредитные продукты в нескольких странах  Юго-Восточной Азии</div>
            </div>
          </div>

          <div className="atm__box atm__box3">
            <div className="atm__bg" style={{backgroundImage: 'url(/cases/atm/3.webp)'}} />
            <div className="atm__icon"></div>
          </div>

          <div className="atm__box atm__box4">
            <img className="shadow" src="/cases/atm/4.webp" />
          </div>

          <div className="atm__box atm__box5">
            <img src="/cases/atm/5.webp" />
            <div className="text bubble right parallax-front text1 parallax-up">
              <div className="in">Георафия представлена интерактивной картой</div>
            </div>
          </div>

          <div className="atm__box atm__box6">
            <img src="/cases/atm/6.webp" />
          </div>

          <div className="atm__box atm__box7">
            <img className="shadow" src="/cases/atm/7.webp" />
          </div>

          <div className="atm__box atm__box8">
            <img src="/cases/atm/8.webp" />
          </div>

          <div className="atm__box atm__box9">
            <img src="/cases/atm/9.webp" />
            <div className="text bubble right-bottom parallax-front text1 parallax-up">
              <div className="in">Калькулятор для инвесторов позволяет наглядно увидеть рост финансовых вложений</div>
            </div>
          </div>
          <div className="academy__box academy__box10">
            <img src="/cases/academy/10.webp" />
            <div className="video__box">
              <div>
                <img src="/cases/academy/video.webp" />
                {/*<iframe src="https://www.youtube.com/embed/9yAVO3zDQL8"></iframe>*/}
                <video poster="/cases/atm/poster.webp" ref="video">
                  <source src="/cases/atm/video.mp4" type="video/mp4" />
                </video>
                {this.state.play || <div className="video__play" onClick={this.handlePlay} />}
              </div>
            </div>
            <div className="text bubble right-top parallax-front text1 parallax-up">
              <div className="in">На скринкасте можно посмотреть интересные приёмы анимации</div>
            </div>
          </div>
          <div className="academy__box academy__box11">
            <img src="/cases/academy/11.webp" />
          </div>
          <div className="atm__box atm__box10">
            <div className="atm__bg" style={{backgroundImage: 'url(/cases/atm/10.webp)'}} />
          </div>
          <div className="atm__box atm__box11">
            <img src="/cases/atm/11.webp" />
          </div>
        </div>

      </div>

   

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/schastye/" }
				title= { this.props.next ? this.props.next.name: "Счастье"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/sch.webp"}
			/>
      <HelmetSite title={`ATM online`} />

    </div>
    )
  }

  handlePlay = () => {
    this.setState({play: true})
    this.refs.video.play()
  }
}

export default X5values;