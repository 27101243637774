import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Genesis extends Component {

  videoRef = React.createRef();

  state = {
    preview: true,
    play: false
  };

	componentDidMount(){
		if(this.props.pathname == '/projects/genesis/'){
			this.setState({preview: false});
			this.props.setProjectName('Genesis');
			$('body').addClass('case-expanded hash-genesis');
			$(window).scrollTop($('#case-genesis').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-genesis');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-genesis');
		}

    if(this.state.play){
      if(this.videoRef.current){
        this.videoRef.current.play()

        this.videoRef.current.onended = () => {
          this.setState({play: false})
        }
      }
    }
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render() {
    return (
      <div className="case genesis">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="genesis-img1" style={{ backgroundImage: "url('/cases/img/genesis/img1.webp')"}}>

            <img src="/cases/img/genesis/logo.svg" />
            <video playsInline autoPlay poster="/cases/img/genesis/video1.webp" muted loop>
              <source src="/cases/img/genesis/video1.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="genesis-box1">
            <img className="shadow genesis-img3" src="/cases/img/genesis/img3.webp" />
            <img className="genesis-img2" src="/cases/img/genesis/img2.svg" />
            <img className="shadow genesis-img4" src="/cases/img/genesis/img4.webp" />
            <img className="genesis-img5" src="/cases/img/genesis/img5.svg" />

            <div className="text bubble right parallax-front parallax-up text1">
              <div className="in">
                Сайт инвестиционной компании в сфере недвижимости с широким набором инструментов для инвестора.
              </div>
            </div>
            <div className="text bubble left-top parallax-front parallax-up text2">
              <div className="in">
              Современность, масштабность и технологичность компании передаётся  через бескрайние пейзажи интегрированные в актуальный технократичный дизайн с обилием интересных анимационных подходов.
              </div>
            </div>
          </div>
          <div className="genesis-img6" style={{ backgroundImage: "url('/cases/img/genesis/img6.webp')"}}></div>
          <div className="genesis-box2">
            <img className="genesis-img7" src="/cases/img/genesis/img7.webp" />
            <img className="shadow genesis-img8" src="/cases/img/genesis/img8.webp" />
            <img className="shadow genesis-img10" src="/cases/img/genesis/img10.webp" />
            <img className="genesis-img9" src="/cases/img/genesis/img9.svg" />
            <img className="genesis-img11" src="/cases/img/genesis/img11.webp" />

          </div>
          <div className="genesis-img12" style={{ backgroundImage: "url('/cases/img/genesis/img12.webp')"}}>
            <video playsInline autoPlay poster="/cases/img/genesis/video2.webp" muted loop>
              <source src="/cases/img/genesis/video2.mp4" type="video/mp4" />
            </video>
          </div>

          <div className="genesis-video-wrap">
            <div className="genesis-video">
              {/*<video playsInline ref={this.videoRef} poster="/cases/img/genesis/video2.jpg" muted>
                <source src="/cases/img/genesis/video2.mp4" type="video/mp4" />
              </video>*/}
              {/*!this.state.play &&*/ <img className="genesis-video-button" src="/cases/img/ahead/button.svg" onClick={() => this.setState({play: true})} />}
              <img src="/cases/img/genesis/video.webp" />
            </div>
          </div>

          <div className="genesis-imgs">
            <div>
              <div className="text bubble right parallax-front parallax-up">
                <div className="in">
                Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство
                </div>
              </div>
              <img src="/cases/img/genesis/img13.webp" />
            </div>
            <div>
              <img src="/cases/img/genesis/img14.webp" />
              <img src="/cases/img/genesis/img15.webp" />
            </div>
            <div>
              <img src="/cases/img/genesis/img16.webp" />
            </div>
          </div>
        </div>

        <CaseFooter
          to="/projects/self"
          title="SELF Парковый квартал"
          img="/cases/img/next/self.webp"
        />

        
     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/self"  }
				title= { this.props.name ? this.props.next.name: "SELF Парковый квартал"  } 
				img={ this.props.name ? this.props.next.name:"/cases/img/next/self.webp"}
			/>
        <HelmetSite title={`Genesis`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Genesis);
