import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Schastye extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/schastye/'){
			this.setState({preview: false});
			this.props.setProjectName('Галс');
			$('body').addClass('case-expanded hash-schastye');
			$(window).scrollTop($('#case-schastye').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-schastye');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-schastye');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-schastye">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/schastye/bg1.webp')`}} />
          </div>
        </div>
        <div className="screen screen2">
          <img src="/cases/img/schastye/bg2-1.webp" className="img1 shadow" />
          <img src="/cases/img/schastye/bg2-2.webp" className="img2 shadow" />
          <img src="/cases/img/schastye/bg2-3.webp" className="img3 shadow" />

          <div className="text bubble left parallax-front text1">
            <div className="in">
            Сайт зонтичного бренда для застройщика «Лидер-Инвест». Большой объём информации мы подали легко и доступно
            </div>
          </div>

          <div className="text bubble left2 parallax-front text2">
            <div className="in">
            Строгие корпоративные цвета и простые элементы управления делают поиск недвижимости лёгким и понятным
            </div>
          </div>

          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/schastye/bg3.webp')`}} />
          </div>
        </div>
        <div className="screen screen3">
          <img src="/cases/img/schastye/bg4.webp" className="img1" />
          <div className="text bubble right parallax-front text1">
            <div className="in">
            Так выглядит сайт на мобильных устройствах
            </div>
          </div>
          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/schastye/bg5.webp')`}} />
          </div>
        </div>
			</div>

  

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/video/"  }
				title= { this.props.next ? this.props.next.name: "Видео"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/video.webp"}
			/>

      <HelmetSite title="Счастье" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Счастье</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Schastye)