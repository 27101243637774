import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as d3 from "d3";
import $ from 'jquery';
import {Helmet} from 'react-helmet';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';

import SourcesMarketingForm from '../Sources/SourcesMarketingForm'

let google = window.google;
const ymaps = window.ymaps;

class Contact extends Component{

	state = {
		center: [55.794897,37.711775],
		office: [55.794897,37.710675],
		metro: [55.795341,37.711973],
    popup: false
	}

	componentDidMount(){
		const self = this;
		
		let Gmap = {
			init: function() {
				Gmap.createMap();
			},
			createMap: function() {

				ymaps.ready(function () {
					Gmap.myMap = new ymaps.Map('map', {
						center: [self.state.center[0], self.state.center[1]],
						zoom: 17,
						controls: []
					});

					Gmap.office = new ymaps.Placemark([self.state.office[0], self.state.office[1]], {
						hintContent: '',
						balloonContent: '',
						iconContent: ''
					}, {
						iconLayout: 'default#image',
						iconImageHref: '/img/mobile/office.png',
						iconImageSize: [0, 0],
						iconImageOffset: [0, 0],
						iconContentOffset: [5, 5]
					});
	
					Gmap.myMap.geoObjects.add(Gmap.office);
					Gmap.myMap.events.add('boundschange', function (e) {
						Gmap.createLine();
					});
					Gmap.myMap.events.add('sizechange', function (e) {
						Gmap.createLine();
					});

					let timer = setInterval(() => {

						let classElement = $('#map').children().attr('class');
						classElement = classElement.substr(0, classElement.length - 4);

						if($('.'+classElement+'-image').offset()){
							clearInterval(timer);
							Gmap.createLine();
						}
					}, 100);

					$(window).resize(() => {
						setTimeout(() => {
							Gmap.myMap.container.fitToViewport();
						}, 500);
						
					});
					
				});
			},
			createLine: function() {
				let addr = $('section.intro.contacts .address');
				if (addr.length === 1) {
					if (d3) {
						//console.log(Gmap.office.geometry.getBounds()[0])
						const projection = Gmap.myMap.options.get('projection');
						const coords = Gmap.myMap.converter.globalToPage(
							projection.toGlobalPixels(
								// географические координаты
								Gmap.office.geometry.getBounds()[0],
								Gmap.myMap.getZoom()
							)
						);

						let p1 = {left: Math.floor(coords[0]), top: Math.floor(coords[1])};
						let p2 = addr.offset();
						let w = Math.round(p2.left - p1.left - 15);
						let h = Math.round(p2.top - p1.top + addr.height() * 0.5);
						let s = 3;
						if (!Gmap.svg) {
							d3.select('#map').append('svg');
							Gmap.svg = d3.select('#map svg');
							Gmap.svg
								.attr('width', 10)
								.attr('height', 10)
							Gmap.svg.append('circle')
								.attr('stroke', 'none')
								.attr('fill', '#000')
								.attr('r', 5)
								.attr('cx', 0)
								.attr('cy', 0);
							Gmap.svg.append('path');
							Gmap.path = d3.select('#map path');
							Gmap.path
								.attr('stroke', '#000')
								.attr('stroke-width', s)
								.attr('fill', 'none');
						}
						Gmap.svg
							.attr('style', 'top: ' + Math.round(p1.top) + 'px; left: ' + Math.round(p1.left) + 'px;');
						Gmap.path
							.attr('d', 'M0,0Q0,' + h + ',' + w + ',' + h);
					}
				}
			}
    };
		Gmap.init();
	}

	eventPhone = () => {
		if(window.gtag){
			window.gtag('event', 'click_telephone');
		}
	}

	eventEmail = () => {
		if(window.gtag){
			window.gtag('event', 'click_email');
		}
	}

	render(){
		return(
      <React.Fragment>
        <div className={this.state.popup ? 'contacts-call-me-popup active' : 'contacts-call-me-popup'}>
          <div><SourcesMarketingForm close={() => this.setState({popup: false})} /></div>

          <div className="contacts-call-me-popup__close" onClick={() => this.setState({popup: false})}></div>
        </div>
        <div className="page-bg contacts-container">

          <Helmet>
            <title>Контакты ' Amio</title>
          </Helmet>

          <a href="https://t.me/we_amio" target="_blank" className="contects-tg">
            <img src="/img/tg.svg" />
            <div>А ещё у нас есть <span>Telegram-канал</span></div>
          </a>

          <section className="intro contacts">

            <div className="links">
              Проложить маршрут <span className="nowrap"><a href="https://www.google.com.do/maps/dir//Преображенская+пл.,+6,+Москва,+Россия,+107061" target="_blank">Google Map</a> или <a href="https://yandex.ru/maps/213/moscow/house/preobrazhenskaya_ploshchad_6/Z04YcQZhSEAHQFtvfXt4dXRmZQ==/?ll=37.711150%2C55.794852&rtext=~55.794831%2C37.711150&rtt=auto&ruri=~&z=16" target="_blank">Яндекс.Карты</a></span>
            </div>
            <div className="address">
              Россия,<br/> 
              г. Москва,<br/> 
              Преображенская пл, 6<br/>
              <a href="tel:+7 495 921-41-70" className="phone" onClick={this.eventPhone}>+7 495 921-41-70</a><br/>
              <a href="mailto:amio@amio.ru" onClick={this.eventEmail}>amio@amio.ru</a>
              <div><span className="contacts-call-me-button" onClick={() => this.setState({popup: true})}>Перезвоним сами!</span></div>
            </div>
          </section>

          <div className="map" id="map"></div>
        </div>
      </React.Fragment>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contact)