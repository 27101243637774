import React, { Component } from 'react';
import {Helmet} from 'react-helmet';

class HelmetSite extends Component{
  render(){
    return(
      <Helmet>
        <title>{this.props.title} ' Проекты ' Amio</title>
      </Helmet>
    )
  }
}

export default HelmetSite;