import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Mts extends Component {
  state = {
    preview: true,
  };

	componentDidMount(){
		if(this.props.pathname == '/projects/cto/'){
			this.setState({preview: false});
			this.props.setProjectName('X5 СТО');
			$('body').addClass('case-expanded hash-cto');
			$(window).scrollTop($('#case-cto').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-cto');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-cto');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render() {
    return (
      <div className="case cto">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="cto-img1" style={{ backgroundImage: "url('/cases/img/cto/img1.webp')"}}></div>
          <div className="cto-box">
            <div className="text bubble left parallax-front parallax-up cto-text1">
              <div className="in">
                Увлекательные истории для компании Х5 Group, целых 6 штук с десятками уникальных и смешных иллюстраций.
              </div>
            </div>

            <img className="cto-img4" src="/cases/img/cto/img4.webp" />

            <img className="cto-img2 shadow" src="/cases/img/cto/img2.webp" />
            <div className="cto-img3-wrap">
              <img className="cto-img3 shadow" src="/cases/img/cto/img3.webp" />

              <img className="cto-img5" src="/cases/img/cto/img5.webp" />
              <img className="cto-img6" src="/cases/img/cto/img6.webp" />

              <div className="text bubble right parallax-front parallax-up cto-text2">
                <div className="in">
                  Все 6 лендингов оформлены ввиде красивой анимации персонажей.
                </div>
              </div>
            </div>
            <div className="cto-img7-wrap">
              <img className="cto-img11" src="/cases/img/cto/img11.webp" />

              <img className="cto-img7 shadow" src="/cases/img/cto/img7.webp" />
              <img className="cto-img8 shadow" src="/cases/img/cto/img8.webp" />
              <div className="cto-img9-wrap">
                <img className="cto-img9 shadow" src="/cases/img/cto/img9.webp" />

                <div className="text bubble left parallax-front parallax-up cto-text3">
                  <div className="in">
                  Интерактив!<br />Не заскучаешь!
                  </div>
                </div>
              </div>
              <div className="cto-img10-wrap">
                <img className="cto-img26" src="/cases/img/cto/img26.webp" />
                <img className="cto-img10 shadow" src="/cases/img/cto/img10.webp" />
              </div>
            </div>

            <div className="cto-slider">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><img src="/cases/img/cto/h1.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h2.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h3.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h4.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h5.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h6.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h7.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h8.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h9.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h10.svg" /></div>
                  <div className="item"><img src="/cases/img/cto/h11.svg" /></div>
                </div>
              </div>
            </div>

            <div className="cto-img12-wrap">
              <img className="cto-img18" src="/cases/img/cto/img18.webp" />
              <img className="cto-img12 shadow" src="/cases/img/cto/img12.webp" />
            </div>
            <div className="cto-img13-wrap">
              <img className="cto-img19" src="/cases/img/cto/img19.webp" />
              <img className="cto-img13 shadow" src="/cases/img/cto/img13.webp" />
            </div>
            <div className="cto-img14-wrap">
              <img className="cto-img20" src="/cases/img/cto/img20.webp" />
              <img className="cto-img14 shadow" src="/cases/img/cto/img14.webp" />
            </div>
            <div className="cto-img15-wrap">
              <img className="cto-img21" src="/cases/img/cto/img21.webp" />
              <img className="cto-img15 shadow" src="/cases/img/cto/img15.webp" />
              <img className="cto-img16 shadow" src="/cases/img/cto/img16.webp" />
              <img className="cto-img17 shadow" src="/cases/img/cto/img17.webp" />
            </div>

            <div className="cto-img22-wrap">
              <img className="cto-img22" src="/cases/img/cto/img22.webp" />

              <img className="cto-img23" src="/cases/img/cto/img23.webp" />

              <div className="text bubble left parallax-front parallax-up cto-text4">
                <div className="in">
                Занимательный анонс ввиде комикса!
                </div>
              </div>
            </div>

            <img className="cto-img25" src="/cases/img/cto/img25.webp" />
          </div>
        </div>

        {/*<CaseFooter
          to="/projects/vsn"
          title="VSN Realty"
          img="/cases/img/next/vsn.jpg"
        />*/}
      

        
     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/f92/"  }
				title= { this.props.next ? this.props.next.name: "Федерация 92"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/f92.webp"}
			/>
        <HelmetSite title={`X5 СТО`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mts);
