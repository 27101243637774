import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import mobileDetection from '../helpers/mobileDetection';
import mousePageXY from '../helpers/mousePageXY';

import $ from 'jquery';
import {hit} from '../metrics';
import {Helmet} from 'react-helmet';

const Source_reality = {
	init: function() {
		Source_reality.headerInit();
	},
	headerInit: function() {
		Source_reality.header = $('.reality-header');
		Source_reality.hand = $('.reality-hand');
		Source_reality.handle = $('.reality-house-handle');
		Source_reality.house = $('.reality-house');

		if (!mobileDetection.any()) {
			Source_reality.header.bind('mouseenter', function() {
				setTimeout(function() {
					Source_reality.header.addClass('hover');
				}, 250);
			}).bind('mousemove', function(e) {
				Source_reality.startFollowCursor(e);
			}).bind('mouseleave', function() {
				Source_reality.header.removeClass('hover');
				setTimeout(function() {
					Source_reality.stopFollowCursor();
				}, 10);
			});
		}
	},
	stopFollowCursor: function() {
		Source_reality.hand.css({'-webkit-transform': 'translate3d(95vw, 0, 0)', 'transform': 'translate3d(95vw, 0, 0)'});
		Source_reality.handle.css({'-webkit-transform': 'translate3d(0, 0, 0)', 'transform': 'translate3d(0, 0, 0)'});
	},
	startFollowCursor: function(e) {
		let pos = mousePageXY(e).x;
		let prc = 100 * pos / $(window).width();
		let handPrc = Math.max(Math.min(prc, 95), 15);
		Source_reality.hand.css({'-webkit-transform': 'translate3d(' + handPrc + 'vw, 0, 0)', 'transform': 'translate3d(' + handPrc + 'vw, 0, 0)'});
		if (handPrc >= 15 && handPrc <= 95) {
			var handlePrc = (70 - (handPrc - 15) * 0.875);
			Source_reality.handle.css({'-webkit-transform': 'translate3d(' + handlePrc + '%, 0, 0)', 'transform': 'translate3d(' + handlePrc + '%, 0, 0)'});
		}
		if (Source_reality.house.hasClass('hidden')) {
			if (handPrc >= 50 && handPrc <= 70) {
				Source_reality.house.removeClass('hidden');
			}
		}
	}
};
class SourcesReality extends Component{

	componentDidMount(){
		this.props.setPageId('sources-reality');
		$('body').addClass('page-sources tags-expanded hash-reality');
		Source_reality.init();
    window.scrollTo(0, 0);
    hit(window.location.pathname);
    window.addEventListener('scroll', this.scroll);
	}

	componentWillUnmount(){
		$('body').removeClass('page-sources tags-expanded hash-reality');
    window.removeEventListener('scroll', this.scroll);
	}

  scroll = () => {
    if($(window).scrollTop() > 0){
      $('body').addClass('fixed');
    }
    else{
      $('body').removeClass('fixed');
    }
  }

	render(){
		const settings = {
			slidesToShow: 3, 
			slidesToScroll: 3,
			dots: false,
			infinite: true
		};

		return(
			<section className="intro sources sources_reality">
        <Helmet>
          <title>Дополненная реальность ' Акценты ' Amio</title>
        </Helmet>
				<div className="case-content">
					<div className="reality-header">
						<div className="reality-header-bg" style={{backgroundImage: `url('/sources/img/reality/header.jpg')`}}></div>
						<div className="reality-title js-parallax fade-in-up">Дополненная<br/>реальность</div>
						<div className="reality-hand">
							<div className="reality-hand-bg" style={{backgroundImage: `url('/sources/img/reality/hand.png')`}}></div>
							<div className="reality-house-wrap">
								<div className="reality-house-handle">
									<div className="reality-house-bg" style={{backgroundImage: `url('/sources/img/reality/house-bg.jpg')`}}></div>
									<div className="reality-house hidden" style={{backgroundImage: `url('/sources/img/reality/house.png')`}}></div>
								</div>
							</div>
						</div>
					</div>
					
					<div className="reality-section">
						<div className="content">
							<div className="reality-section-bg" style={{backgroundImage: `url('/sources/img/reality/feature3.png')`}}></div>
							<div className="reality-feature">
								<div className="reality-feature-image" style={{backgroundImage: `url('/sources/img/reality/feature1.jpg')`}}>
									<div className="reality-feature-image-in" style={{backgroundImage: `url('/sources/img/reality/feature2.jpg')`}}></div>
								</div>
								<div className="reality-feature-text">
									<div className="reality-title js-parallax fade-in-up">Возможности<br/>Приложения</div>
									<ul className="js-parallax fade-in-up">
										<li>Размещение объекта в натуральную величину по Geo-координатам</li>
										<li>Размещение объекта в натуральную величину перед собой. Когда небо в тучах и плохой сигнал GPS, можно установить здание на выбранное место.</li>
										<li>Размещение уменьшенного макета объекта на горизонтальной плоскости, на столе, например.</li>
										<li>Просмотр 3D модели объекта на экране устройства (Для устройств младше Iphone 6)</li>
										<li>Взаимодействие с виртуальным объектом с помощью тачскрина</li>
										<li>Добавление общих информационных разделов о проекте</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					
					<div className="reality-footer js-parallax fade-in-up">
						<div className="reality-footer-bg" style={{backgroundImage: `url('/sources/img/reality/footer.jpg')`}}></div>
						<Link to="/contacts/">Свяжитесь с нами</Link>
					</div>
				</div>
			</section>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SourcesReality)