import $ from 'jquery';

const lazyLoad = () => {
  const elements = $('[data-lazy]');
  elements.each(function() {
		let el = $(this);
		let elTop = el.offset().top;
		let scrTop = $(document).scrollTop();
		let wHeight = $(window).height();

    if (elTop <= scrTop + (wHeight)) {
			if (!el.hasClass('lazy-loaded')) {
        if (el[0].tagName === 'IMG') {
					el.attr('src', el.data('lazy'))
        } else {
					el[0].style.backgroundImage = `url('${el.data('lazy')}')`
        }
				let lazyBubble = el.data('bubble');
				let textElements = $(`[data-text=${lazyBubble}]`);
				if (textElements.length) {
					textElements.each(function() {
						$(this).removeAttr('data-text');
					})
				}
				if (elTop <= scrTop + (wHeight * 0.8)) {
					el.addClass('lazy-loaded');
				}
      }
    }
  })
}

export default lazyLoad;