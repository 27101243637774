import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Self extends Component {
  state = {
    preview: true,
  };

  handleScroll() {
    $(".animate-scroll-element").each(function () {
      const top = $(this).offset().top - window.innerHeight / 2;
      const scr = $(window).scrollTop();

      if (scr >= top) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  }

  componentDidMount() {
    $(window).on("mousemove", function (e) {
      var w = $(window).width();
      var h = $(window).height();
      var offsetX = 0.5 - e.screenX / w;
      var offsetY = 0.5 - e.screenY / h;

      $(".parallax").each(function (i, el) {
        var offset = parseInt($(el).data("offset"));

        var translate =
          "translate3d(" +
          Math.round(offsetX * offset) +
          "px," +
          Math.round(offsetY * offset) +
          "px, 0px)";

        $(el).css({
          "-webkit-transform": translate,
          transform: translate,
          "moz-transform": translate,
        });
      });
    });


    var video = document.getElementById("video-vrb");
    $(".but-vrb").click(function () {
      $(".but-vrb").toggleClass("min");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
    if (this.props.pathname == "/projects/self/") {
      this.setState({ preview: false });
      this.props.setProjectName("bsg");
      $("body").addClass("case-expanded hash-self");
      $(window).scrollTop($(".self").offset().top);
      this.props.changeCase(null);
    } else {
      this.setState({ preview: true });
      $("body").removeClass("self");
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $("body").addClass("fadepage");
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.pathname == "/projects/") {
      $("body").removeClass("case-expanded hash-self");
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fadepage");
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div className="case">
        <div className="case-content">
          <div className="self-head">
            <div className="self_top">
              <img className="self_img6" src="/cases/img/self/self_img6.webp" />
              <img className="self_img5" src="/cases/img/self/self_img5.webp" />
              <img className="self_img4" src="/cases/img/self/self_img4.webp" />
              <img className="self_img3 parallax" data-offset="30" src="/cases/img/self/self_img3.svg" />
              <img className="self_img2 parallax" data-offset="50" src="/cases/img/self/self_img2.svg" />
              <div className="self_img1">
                <img src="/cases/img/self/self_img1.svg" />
              </div>
            </div>
          </div>
          <div className="self-block11">
            <div className=" self-bubble11 text text1 bubble right  parallax-front parallax-up active">
              <div className="in">
                Нами был разработан логотип, фирменный стиль.
              </div>
            </div>
            <img className="self-img2" src="/cases/img/self/img2.webp"></img>
            <img className="self-img3" src="/cases/img/self/img3.webp"></img>
          </div>
          <div className="self-blocl22">
            <div className=" self-bubble12 text text1 bubble right  parallax-front parallax-up active">
              <div className="in">
                Электронные носители (шаблон презентации, баннеры, email и т.д.
              </div>
            </div>
            <img className="self-img4" src="/cases/img/self/img4.webp"></img>
            <img className="self-img5" src="/cases/img/self/img5.webp"></img>
            <img className="self-img6" src="/cases/img/self/img6.webp"></img>
            <img className="self-img7 parallax" data-offset="30" src="/cases/img/self/img7.webp"></img>
          </div>
          <div className="self-block33">
            <img className="self-img8" src="/cases/img/self/img8n.webp"></img>
          </div>
          <div className="self-block44">
            <img className="self-img10" src="/cases/img/self/img10.webp"></img>
            <img className="self-img9 parallax" data-offset="50" src="/cases/img/self/img9.webp"></img>
            <img className="self-img11" src="/cases/img/self/img11.webp"></img>
          </div>
          <div className="self-block55">
            <img className="self-img12" src="/cases/img/self/img12.webp"></img>
          </div>
          <div className="self-block66">
            <div className=" self-bubble13 text text1 bubble left  parallax-front parallax-up active">
              <div className="in">Яркая и красивая сувенирная продукция</div>
            </div>
            <img className="self-img13" src="/cases/img/self/img13.webp"></img>
          </div>
          <div className="self-block77">
            <img className="self-img14" src="/cases/img/self/img14.webp"></img>
            <img className="self-img15" src="/cases/img/self/img15.webp"></img>
          </div>
          <div className="self-block88">
            <div className=" self-bubble14 text text1 bubble right  parallax-front parallax-up active">
              <div className="in">Сайт</div>
            </div>
            <img className="self-img16" src="/cases/img/self/img16.webp"></img>
            <img className="self-img17" src="/cases/img/self/img17.webp"></img>
          </div>
          <div className="self-block99">
            <img className="self-img18" src="/cases/img/self/img18.webp"></img>
            <img className="self-img19 parallax" data-offset="50" src="/cases/img/self/img19.webp"></img>
            <img className="self-img20" src="/cases/img/self/img20.webp"></img>
          </div>
          <div className="vrb-block8">
            <img className="self-img21 parallax" data-offset="30" src="/cases/img/self/img21.webp"></img>
            <div className=" vrb-bubble445 text text1 bubble left  parallax-front parallax-up active">
              <div className="in">
                Видеопрезентация проекта даст полное представление о подходе и
                технологиях
              </div>
            </div>
            <div
              className="video-brb"
              style={{ backgroundImage: `url('/cases/img/vrb/img14.png')` }}
            >
              <div className="video-in-vrb">
                <div
                  className="but-vrb rep-but"
                  style={{ backgroundImage: `url('/cases/img/vrb/img16.svg')` }}
                ></div>
                <video
                  id="video-vrb"
                  poster="/cases/img/self/poster.webp"
                  loop
                  preload="metadata"
                >
                  <source
                    src="/cases/img/self/video.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
          </div>
        </div>

 

      <CaseFooter 
				to={ this.props.next ? this.props.next.url:   "/projects/dh" }
				title= { this.props.name ? this.props.next.name: "Digital Heroes"  } 
				img={ this.props.name ? this.props.next.name:"/cases/img/next/dh.webp"}
			/>
        <HelmetSite title={`Парковый квартал SELF`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Self);
