import React, { Component } from 'react';
import { connect } from 'react-redux';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import {hit} from '../metrics';

import $ from 'jquery';
import {Helmet} from 'react-helmet';

const Source_404 = {
	init: function() {
		$('.js-parallax').removeClass('animate animated');
		
		Source_404.parallax();
	},
	parallax: function(e) {
		if($('.l-trigger').length) {
			let scroll_top = $(window).scrollTop();
			let trigger_offset = $('.l-trigger').offset().top;
			$('.js-parallax').each(function() {
				let el = $(this);
				let offset = el.offset().top;
				if( +(offset - scroll_top) < trigger_offset - scroll_top) {
					el.addClass('animate');
					(function(elem) {
						setTimeout(function() { elem.addClass('animated') }, 1000);
					})(el);
				}
			});
		}
	}
};
class Sources404 extends Component{

	componentDidMount(){
		this.props.setPageId('sources-404');
		$('body').addClass('page-sources tags-expanded hash-404');
		Source_404.init();
    window.scrollTo(0, 0);
    hit(window.location.pathname);
    window.addEventListener('scroll', this.scroll);
	}

	componentWillUnmount(){
		$('body').removeClass('page-sources tags-expanded hash-404');
    window.removeEventListener('scroll', this.scroll);
	}

  scroll = () => {
    Source_404.parallax()
    if($(window).scrollTop() > 0){
      $('body').addClass('fixed');
    }
    else{
      $('body').removeClass('fixed');
    }
  }

	render(){
		return(
			<section className="intro sources sources_404">
        <Helmet>
          <title>404 ' Акценты ' Amio</title>
        </Helmet>
				<div className="case-content">
					<div className="l-bg">
						<div>
							<span className="test-pulse c-pulse--pos1 js-parallax"></span>
							<span className="c-pulse c-pulse--small c-pulse--pos2 js-parallax"></span>
							<span className="c-pulse c-pulse--big c-pulse--pos3 js-parallax"></span>
							<span className="c-pulse c-pulse--small c-pulse--pos4 js-parallax"></span>
							<span className="c-pulse c-pulse--small c-pulse--pos5 js-parallax"></span>
							<span className="c-pulse c-pulse--small2 c-pulse--pos6 js-parallax"></span>
							<span className="c-pulse c-pulse--big c-pulse--pos7 js-parallax"></span>
							<span className="c-pulse c-pulse--big2 c-pulse--pos8 js-parallax"></span>
							<span className="c-pulse c-pulse--small2 c-pulse--pos9 js-parallax"></span>
              <span className="c-pulse c-pulse--big c-pulse--pos10 js-parallax"></span>
							<span className="c-pulse c-pulse--small c-pulse--pos11 js-parallax"></span>
							<span className="c-pulse c-pulse--big2 c-pulse--pos12 js-parallax"></span>
							<span className="c-pulse c-pulse--small2 c-pulse--pos13 js-parallax"></span>
              <span className="c-pulse c-pulse--big c-pulse--pos14 js-parallax"></span>
						</div>
					</div>
					<div className="l-sections">
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__middle">
									<span className="c-pulse c-pulse--red c-pulse--pos0 js-parallax"></span>
									<div className="c-block404 js-parallax">
										<div className="c-block404__title">
											<b>404</b>
											<span>неОШИБКА</span>
											ИЗБРАННОЕ
										</div>
										<div className="c-block404__text"> 
											<p>Ошибка — это то, что выбивается из ряда однообразных и скучных правильностей. </p> <p>Но зачастую грань между «правильностью» и «ошибочностью» столь тонка, что их невозможно различить.</p>
										</div>
									</div>
								</div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-1.jpg')`}}></div>
									<div className="text bubble left text1  js-parallax">
										<div className="in">
											<div><span>Не на того нарвались</span> <a href="/" target="_blank">ИК «Форум»</a></div>
										</div>
									</div>
								</div>
							</div>
						</section>

						<section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-2.jpg')`}}></div>
									<div className="text bubble right-top text1  js-parallax">
										<div className="in">
											<div><span>Не на того нарвались</span> <a href="/" target="_blank">ИК «Форум»</a></div>
										</div>
									</div>
									<div className="text bubble left text2 js-parallax">
										<div className="in">
											<div><span>Упс... уплыли</span> <a href="/" target="_blank">Arena Russia</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section mt-232">
								<div className="c-section__middle"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-3.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Волк и яйца? </span> <a href="/" target="_blank">Корзина Доброты</a></div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-4.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Ищем по следу</span> <a href="/" target="_blank">Рядом</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__small2"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-5.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Ошиблись</span> <a href="/" target="_blank">Ханты-Мансийский Банк</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__middle"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-6.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Заблудились</span> <a href="/" target="_blank">Ефимов Бор</a></div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax c-image-block c-image-block--small" style={{backgroundImage: `url('/sources/img/404-7.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Оплошали</span> <a href="/" target="_blank">Multiline</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__small2"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax c-image-block c-image-block--small" style={{backgroundImage: `url('/sources/img/404-8.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Косячнули</span> <a href="/" target="_blank">Comcity</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__middle"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-9.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Уснувшая страница </span> <a href="/" target="_blank">сайт Василисы Володиной</a></div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax c-image-block c-image-block--small2" style={{backgroundImage: `url('/sources/img/404-10.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Аномалия </span> <a href="/" target="_blank">сайт нашей группы Amio</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__small2"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-11.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Потерялись</span> <a href="/" target="_blank">Октябрьское поле</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__middle"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-12.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>SOS</span> <a href="/" target="_blank">Haven Kukumae</a></div>
										</div>
									</div>
								</div>
							</div>
						</section>

            <section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/error/n1.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Не тот этаж</span> <a href="/" target="_blank">ЖК “А-Residence”</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__middle"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/error/n2.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Save our souls</span> <a href="/" target="_blank">ЖК “Петровская Доминанта”</a></div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax c-image-block c-image-block--small" style={{backgroundImage: `url('/sources/img/error/n3.jpg')`}} ></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Не стоило шуметь </span> <a href="/" target="_blank">Помощник Деда Мороза</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__small2"></div>
							</div>
						</section>
            <section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/error/n4.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Не тот этаж</span> <a href="/" target="_blank">ЖК “А-Residence”</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
            <section className="l-section">
							<div className="c-section">
                <div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/error/n7.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Округленная ошибка </span> <a href="/" target="_blank">БЦ “Петровский форт”</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
								<div className="c-section__big">
									<div className="c-image-block js-parallax c-image-block c-image-block--small" style={{backgroundImage: `url('/sources/img/error/n9.jpg')`}} ></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Не в те ворота </span> <a href="/" target="_blank">ФК “Торпедо”</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__small2"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
                <div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-14.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>На прицеле </span> <a href="/" target="_blank">Усадьба Охотника</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>
						<section className="l-section">
							<div className="c-section">
                <div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-15.jpg')`}}></div>
									<div className="text bubble right text1 js-parallax">
										<div className="in">
											<div><span>Позаботились об искусстве</span> <a href="/" target="_blank">Врубеля 4</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>

            <section className="l-section">
							<div className="c-section">
                <div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-ahead.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Запутались в архитектуре </span> <a href="/" target="_blank">Ahead</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>

            <section className="l-section">
							<div className="c-section">
                <div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-genesis.png')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Оказались в пустыне </span> <a href="/" target="_blank">Genesis</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>

            <section className="l-section">
							<div className="c-section">
                <div className="c-section__small"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-ppn.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Пробуем вновь <br />ЖК “Преображен&shy;ская площадь” </span> <a href="/" target="_blank">Преображенская Площадь</a></div>
										</div>
									</div>
								</div>
								<div className="c-section__middle"></div>
							</div>
						</section>

            {/* <section className="l-section">
							<div className="c-section">
								<div className="c-section__middle"></div>
								<div className="c-section__big">
									<div className="c-image-block js-parallax" style={{backgroundImage: `url('/sources/img/404-soyuz.jpg')`}}></div>
									<div className="text bubble left text1 js-parallax">
										<div className="in">
											<div><span>Отбиваем мячик
												на&nbsp;корте ЖК&nbsp;Союз</span> 
												<a href="/" target="_blank">Союз</a></div>
										</div>
									</div>
								</div>
                <div className="c-section__small"></div>
							</div>
						</section> */}

					</div>
				</div>
			</section>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sources404)