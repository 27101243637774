import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Error extends Component{
	render(){
		return(
    <div className="page-404">
      <div className="page-bg">
        <video autoPlay="true" muted="muted" loop="loop" id="page-video" poster="/video/noise.jpg">
          <source type="video/mp4" src="/video/noise.mp4" />
        </video>
      </div>
      <div className="body">
        <div className="content">
          <img src="/img/404.png" />
          Страница исчезла
          <br/><br/>
          <Link to="/">Вернуться на главную страницу</Link>
        </div>
      </div>
    </div>
		)
	}
}

export default Error