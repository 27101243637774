import React, { Component } from 'react';
import { connect } from 'react-redux';

import $ from 'jquery';
import { paper, Path, Point } from 'paper';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';

class HomeWave extends Component{

	componentDidMount(){
		this.create();
	}

	create = () => {
		
		const canvas = document.getElementById('indexCanvas');
		paper.setup(canvas);

		let values = {
			friction: 0.8,
			timeStep: 0.01,
			amount: paper.view.bounds.width <= 900 ? 10 : 30,
			distance:[
				20,20,22,25,28,
				30,35,40,45,50,
				55,60,55,45,40,
				35,30,25,20,15,
				10,10,10,10,10,
				20,20,20,20,20,30
			],
			mass: 10,
			count: 0,
			colorIndex:0,
			colorBg: ['#5da7ae', '#faa9df', '#2d7d8f', '#f8d098', '#4b788f', '#a1de93', '#f03861', '#cfee91', '#76a665', '#7a4579', '#53cde2', '#0081c6', '#f5eee6', '#ff7f50', '#fff7c2', '#5a92af', '#ffedc6', '#ffaf87', '#ef4339', '#fda856', '#a1c45a', '#ea9085', '#dfe2fe', '#5e63b6', '#f9f8eb', '#ece493', '#7494D5', '#6dc3c4', '#ffdfd3', '#c8d48b'],
			colorLine: ['#ffebbc', '#810749', '#8ded8e', '#003e21', '#e4de66', '#70a1d7', '#fecea8', '#092a35', '#265961', '#ffff8f', '#005792', '#abedd8', '#c86b85', '#bde4f4', '#a02a63', '#9cd9de', '#96dae4', '#0d7e83', '#ffdbc5', '#1a554f', '#fff9e0', '#6e5773', '#8e98f5', '#f5c7f7', '#5c8d89', '#84a1be', '#E4E6E8','#f3e78c', '#957dad', '#575e32'],
			colorAnimate: true
		};
		values.invMass = 1 / values.mass;
		
		let path1, springs, path2, springs2, path3;
		let size = {
			width: paper.view.bounds.width * 2,
			height: paper.view.bounds.height
		}
		
		let Spring = function(a, b, strength, restLength) {
			this.a = a;
			this.b = b;
			this.restLength = restLength || 80;
			this.strength = strength ? strength : 0.55;
			this.mamb = values.invMass * values.invMass;
		};
		
		Spring.prototype.update = function() {
			
			let delta = new Point(this.b.x - this.a.x, this.b.y - this.a.y)
			let dist = delta.length;

			let normDistStrength = (dist - this.restLength) / (dist * this.mamb) * this.strength;
			delta.y *= normDistStrength * values.invMass * 0.2;

			if (!this.a.fixed){
				this.a.y += delta.y;
			}
				
			if (!this.b.fixed){
				this.b.y -= delta.y;
			}
		};
		
		function createPath() {

			let strength = 0.1;

			var segments = [new Point(0, 0), new Point(paper.view.bounds.width * 2, 0), new Point(paper.view.bounds.width * 2, paper.view.bounds.height * 2), new Point(0, paper.view.bounds.width * 2)];
			path3 = new Path({
				segments: segments,
				fillColor: '#ffffff'
			});

			path1 = new Path({
				fillColor: '#000000'
			});


			path2 = new Path({
				fillColor: '#ffffff'
			});

			springs = [];
			springs2 = [];

			let distance = 0;
			let distanceMax = paper.view.bounds.width < 1000 ? 100 : 300;
			let distanceDirection = 'plus';

			for (let i = 0; i <= values.amount; i++) {

				if(distance >= distanceMax ){
					distanceDirection = 'minus';
				}
				if(distance <= -distanceMax ){
					distanceDirection = 'plus';
				}

				if(distanceDirection === 'plus'){
					distance += 100;
				}
				if(distanceDirection === 'minus'){
					distance -= 100;
				}
				
				let segment1 = path1.add(new Point(i / values.amount * size.width, 0.5 * size.height + distance));
				let point1 = segment1.point;

				let segment2 = path2.add(new Point(i / values.amount * size.width, 0.5 * size.height + distance));
				let point2 = segment2.point;

				if (i == 0 || i == values.amount){
					point1.y += size.height;
					point2.y += size.height;
				}
					
				point1.px = point1.x;
				point1.py = point1.y;

				point2.px = point2.x;
				point2.py = point2.y;
				
				point1.fixed = i < 2 || i > values.amount - 2;
				point2.fixed = i < 2 || i > values.amount - 2;

				if (i > 0) {
					let spring1 = new Spring(segment1.previous.point, point1, strength);
					springs.push(spring1);

					let spring2 = new Spring(segment2.previous.point, point2, strength);
					springs2.push(spring2);
				}
			}
			path1.position.x -= size.width / 4;
			path2.position.x -= size.width / 4;
			
		}
		

		createPath();

		paper.view.onResize = function(){

			if (path1){
				path1.remove();
			}
			if (path2){
				path2.remove();
			}
			if (path3){
				path3.remove();
			}

			size = {
				width: paper.view.bounds.width * 2,
				height: paper.view.bounds.height
			}

			values.amount = paper.view.bounds.width <= 648 ? 10 : 30;

			createPath();

		}

		paper.view.onMouseMove = function(event) {
			move(event.point, 6, 6, 48);
		}

		function move(point, d1, d2, d3){

			let location1 = path1.getNearestLocation(point);
			let segment1 = location1.segment;
			let point1 = segment1.point;
		
			if (!point1.fixed && location1.distance < size.height / d1) {
				let y = point.y;
				point1.y += (y - point1.y) / d2;
				if (segment1.previous && !segment1.previous.fixed) {
					let previous = segment1.previous.point;
					previous.y += (y - previous.y) / d3;
				}
				if (segment1.next && !segment1.next.fixed) {
					let next = segment1.next.point;
					next.y += (y - next.y) / d3;
				}
			}
			
			let location2 = path2.getNearestLocation(point);
			let segment2 = location2.segment;
			let point2 = segment2.point;
		
			if (!point2.fixed && location2.distance < size.height / d1) {
				let y = point.y;
				point2.y += (y - point2.y) / d2;
				if (segment2.previous && !segment2.previous.fixed) {
					let previous = segment2.previous.point;
					previous.y += (y - previous.y) / d3;
				}
				if (segment2.next && !segment2.next.fixed) {
					let next = segment2.next.point;
					next.y += (y - next.y) / d3;
				}
			}
		}

		paper.view.onFrame = function(event) {
			updateWave(path1, path2);
		}

		if(indexInterval){
			clearInterval(indexInterval);
		}

		let indexInterval = setInterval(function(){
			setColor();
		}, 5000);

		$('#indexCanvas').unbind().click(function(e){
			setColor();
		});
		
		function setColor(){
			if(values.colorAnimate){
				values.colorAnimate = false;
				var speed = 300;

				path1.tweenTo(
					{ fillColor: values.colorLine[values.colorIndex] },
					{
						duration: speed,
						easing: 'easeInCubic'
					}
				);
	
				path2.tweenTo(
					{ fillColor: values.colorBg[values.colorIndex] },
					{
						duration: speed,
						easing: 'easeInCubic'
					}
				);
	
				path3.tweenTo(
					{ fillColor: values.colorBg[values.colorIndex] },
					{
						duration: speed,
						easing: 'easeInCubic'
					}
				);
	
				values.colorIndex++;
				if(values.colorIndex >= values.colorBg.length){
					values.colorIndex = 0;
				}
				
				let innerTimer = setTimeout(() => {
					values.colorAnimate = true;
					clearTimeout(innerTimer);
				}, speed * 2);

				
			}
		}
		
		function updateWave(path1, path2) {

			let force = 1 - values.friction * values.timeStep * values.timeStep;

			for (let i = 0, l = path1.segments.length; i < l; i++) {

				const coef = values.distance[i];

				let point1 = path1.segments[i].point;
				let dy1 = (point1.y - point1.py) * force;
				point1.py = point1.y;
				point1.y = Math.max(point1.y + dy1, 0);

				let point2 = path2.segments[i].point;
				point2.py = point1.py;
				point2.y = point1.y + coef;
			}
		
			for (let j = 0, l = springs.length; j < l; j++) {
				springs[j].update();
			}

			for (let j = 0, l = springs2.length; j < l; j++) {
				springs2[j].update();
			}

			path1.smooth({ type: 'continuous' });
			path2.smooth({ type: 'continuous' });
		}
	}

	render(){
		return(
			<canvas id="indexCanvas" data-paper-resize/>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeWave)