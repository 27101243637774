import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Dh extends Component {
  state = {
    preview: true,
  };

  handleScroll() {
    $(".animate-scroll-element").each(function () {
      const top = $(this).offset().top - window.innerHeight / 2;
      const scr = $(window).scrollTop();

      if (scr >= top) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  }

  componentDidMount() {
    if (this.props.pathname == "/projects/dh/") {
      this.setState({ preview: false });
      this.props.setProjectName("bsg");
      $("body").addClass("case-expanded hash-dh");
      $(window).scrollTop($(".vsn").offset().top);
      this.props.changeCase(null);
    } else {
      this.setState({ preview: true });
      $("body").removeClass("dh");
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $("body").addClass("fadepage");
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.pathname == "/projects/") {
      $("body").removeClass("case-expanded hash-dh");
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fadepage");
    window.removeEventListener("scroll", this.handleScroll);
    if (window.parallaxInstance) {
      window.parallaxInstance.disable();
    }
  }

  render() {
    return (
      <div className="case dh">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="dh-bg1">
            <img className="dh-bg1__img3" src="/cases/img/dh/img3.webp" />
            <div className="dh-bg1__in">
              <img className="dh-bg1__img2" src="/cases/img/dh/img2.webp" />
              <img className="dh-bg1__img1" src="/cases/img/dh/img1.webp" />
            </div>
          </div>

          <img className="dh-bg1__img4 shadow" src="/cases/img/dh/img4.webp" />
          <div className="dh-bg1__img5-wrapper">
            <img className="dh-bg1__img5 shadow" src="/cases/img/dh/img5.webp" />
            <div className="dh-bg1__img6"><img src="/cases/img/dh/img6.webp" /></div>
            <div className="dh-bg1__img7"><img src="/cases/img/dh/img7.webp" /></div>

            <div className="text bubble right parallax-front parallax-up text1">
              <div className="in">
                Специальности связаны с IT.
                Была необходимость совместить
                новый фирменный стиль компании с спецификой подразделения: это джунгли и цифровизация
              </div>
            </div>
          </div>
          <div className="dh-bg1__img9-wrapper">
            <img className="dh-bg1__img9 shadow" src="/cases/img/dh/img5.webp" />
            <div className="dh-bg1__img8"><img src="/cases/img/dh/img8.webp" /></div>
          </div>

          <div className="dh-bg1__img15-wrapper">

            <div className="text bubble right parallax-front parallax-up text2">
              <div className="in">
              Каждая специальность&nbsp;- это персонаж из животного мира, обитатель джунглей!
              </div>
            </div>

            <img className="dh-bg1__img15" src="/cases/img/dh/img15.webp" />
            <div className="dh-bg1__img15-in">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><img src="/cases/img/dh/img10.webp" /></div>
                  <div className="item"><img src="/cases/img/dh/img11.webp" /></div>
                  <div className="item"><img src="/cases/img/dh/img12.webp" /></div>
                  <div className="item"><img src="/cases/img/dh/img13.webp" /></div>
                  <div className="item"><img src="/cases/img/dh/img14.webp" /></div>
                </div>
              </div>
            </div>
          </div>

          <div className="dh-bg1__img16">
            <img className="shadow" src="/cases/img/dh/img16.webp" />
          </div>
          <div className="dh-bg1__img18">
            <img className="shadow" src="/cases/img/dh/img18.webp" />
            <div className="text bubble right parallax-front parallax-up text3">
              <div className="in">
                Интереснейший тест, чтобы определить для какой специальности подходит осискатель, что за зверь в этих цифровых джунглях?!
              </div>
            </div>
          </div>
          <img className="dh-bg1__img19" src="/cases/img/dh/img19.webp" />
        </div>
				
    

     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/mts"  }
				title= { this.props.next ? this.props.next.name: "МТС Новый Год"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/mts_next.webp"}
			/>
        <HelmetSite title={`Клубный дом Kuznetsky Most 12`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dh);
