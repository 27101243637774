import React, { Component, Fragment } from 'react';
import $ from 'jquery';

class Dog extends Component{

  state = {
    play: window.location.hash === '#dog'
  }

	componentDidMount(){
    $(window).mousemove(function(e){
      const w = window.innerWidth / 3;
      const h = window.innerHeight - window.innerHeight/3;
      if(e.clientX >= w && e.clientX <= window.innerWidth && e.clientY >= h){
        $('.dog').addClass('active');
      }
      else{
        $('.dog').removeClass('active');
      }
    });

    if(window.location.hash === '#dog'){
      var promise = document.querySelector('#dog-video').play();

      if (promise !== undefined) {
        promise.then(_ => {

        }).catch(error => {

        });
      }
    }
  }
  
  openVideo = () => this.setState({play: true});
  closeVideo = () => this.setState({play: false});

	render(){
		return(
      <Fragment>
        <div className={this.state.play ? 'dog-popup active' : 'dog-popup'}>
          <div className="dog-popup__close" onClick={this.closeVideo} />
          {this.state.play && (
            <video id="dog-video" width="400" height="300" autoPlay loop controls>
              <source src="/video/Master_s.mp4" type="video/mp4" />
            </video>
          )}
        </div>
        <div className="dog" onClick={this.openVideo} />
      </Fragment>
		)
	}
}

export default Dog;