import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Plus extends Component{
  componentDidMount(){
    if(this.props.pathname == '/projects/x5table/'){
      this.setState({preview: false});
      this.props.setProjectName('X5table');
      $('body').addClass('case-expanded hash-x5table');
      $(window).scrollTop($('#case-x5table').offset().top);
      this.props.changeCase(null);
    }
    else{
      this.setState({preview: true});
      $('body').removeClass('hash-x5table');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage');
    }, 100);
  }

  componentDidUpdate(){
    if(this.props.pathname == '/projects/'){
      $('body').removeClass('case-expanded hash-x5table');
    }
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
  render(){
    return(
    <div className='case'>
      <div className="case-content x5table">

        <div className="x5table-box1">
          <img src="/cases/img/x5table/img1.svg" className="x5table-box1__img1" />
          <div className="text bubble right parallax-front parallax-up">
            <div className="in">
            Новогодний адвент-календарь
            для компании X5 Group. <br /><br />

            Каждый день открывается новое “блюдо” -
            новая онлайн-активность. Все сотрудники огромной компании собрались за одним столом. По-настоящему дружный
            и веселый Новый год!
            </div>
          </div>

          <img data-lazy="/cases/img/x5table/img2.webp" className="x5table-box1__img2 shadow" />
          <img data-lazy="/cases/img/x5table/img3.webp" className="x5table-box1__img3 shadow" />
          <img data-lazy="/cases/img/x5table/img4.svg" className="x5table-box1__img4" />
        </div>

        <div className="x5table-box2">
          <img data-lazy="/cases/img/x5table/img5.webp" className="shadow" />
        </div>
        <div className="x5table-box3">
          <img data-lazy="/cases/img/x5table/img6.webp" className="shadow" />
        </div>
        <div className="x5table-box4">
          <img data-lazy="/cases/img/x5table/img7.webp" className="shadow" />
          <img data-lazy="/cases/img/x5table/img8.svg" />
          <img data-lazy="/cases/img/x5table/img9.svg" />
          <img data-lazy="/cases/img/x5table/img14.webp" className="shadow" />
        </div>
        <div className="x5table-box5">
          <img data-lazy="/cases/img/x5table/img15.webp" className="shadow" />
          <img data-lazy="/cases/img/x5table/img10.svg" />
          <img data-lazy="/cases/img/x5table/img11.svg" />
          <img data-lazy="/cases/img/x5table/img12.svg" />
          <img data-lazy="/cases/img/x5table/img13.svg" />
        </div>

        <div className="slider parallax-slider x5table-slider">
          <div className="line">
            <div className="item item1"><div style={{backgroundImage: `url('/cases/img/x5table/slide1.svg')`}}></div></div>
            <div className="item item2"><div style={{backgroundImage: `url('/cases/img/x5table/slide2.svg')`}}></div></div>
            <div className="item item3"><div style={{backgroundImage: `url('/cases/img/x5table/slide3.svg')`}}></div></div>
            <div className="item item4"><div style={{backgroundImage: `url('/cases/img/x5table/slide4.svg')`}}></div></div>
            <div className="item item5"><div style={{backgroundImage: `url('/cases/img/x5table/slide5.svg')`}}></div></div>
          </div>
        </div>

        <div className="x5table-box6">
          <img data-lazy="/cases/img/x5table/img16.webp" className="shadow" />
          <img data-lazy="/cases/img/x5table/img23.svg" />
          <img data-lazy="/cases/img/x5table/img22.svg" className="x5table-box-element" />
        </div>

        <div className="x5table-box7">
          <img data-lazy="/cases/img/x5table/img17.webp" className="shadow" />
        </div>

        <div className="x5table-box8">
          <img data-lazy="/cases/img/x5table/img18.webp" className="shadow" />
          <img data-lazy="/cases/img/x5table/img24.svg" />
          <img data-lazy="/cases/img/x5table/img25.svg" />
          <img data-lazy="/cases/img/x5table/img22.svg" className="x5table-box-element" />
        </div>

        <div className="x5table-box9">
          <img data-lazy="/cases/img/x5table/img19.webp" className="shadow" />
          <img data-lazy="/cases/img/x5table/img22.svg" className="x5table-box-element x5table-box-element1" />
          <img data-lazy="/cases/img/x5table/img22.svg" className="x5table-box-element x5table-box-element2" />
        </div>

        <div className="x5table-box10">
          <img data-lazy="/cases/img/x5table/img20.webp" className="shadow" />
        </div>

        <div className="x5table-box11">
          <img data-lazy="/cases/img/x5table/img21.webp" />
          <img data-lazy="/cases/img/x5table/img26.svg" />
          <img data-lazy="/cases/img/x5table/img22.svg" className="x5table-box-element" />
        </div>

        <div className="x5table-box12" data-lazy="/cases/img/x5table/img27.svg"></div>

        <div className="x5table-box13-wrap">
          <img data-lazy="/cases/img/x5table/img32.svg" className="x5table-box13-wrap-img1" />
          <img data-lazy="/cases/img/x5table/img33.svg" className="x5table-box13-wrap-img2" />
          <img data-lazy="/cases/img/x5table/img34.svg" className="x5table-box13-wrap-img3" />
          <img data-lazy="/cases/img/x5table/img35.svg" className="x5table-box13-wrap-img4" />
          <img data-lazy="/cases/img/x5table/img22.svg" className="x5table-box-element" />

          <div className="x5table-box13">
            <div>
              <img data-lazy="/cases/img/x5table/img28.webp" className="x5table-box13-img1" />
            </div>
            <div>
              <img data-lazy="/cases/img/x5table/img29.webp" className="x5table-box13-img2" />
              <img data-lazy="/cases/img/x5table/img30.webp" className="x5table-box13-img3" />
            </div>
            <div>
              <img data-lazy="/cases/img/x5table/img31.webp" className="x5table-box13-img4" />
            </div>
          </div>
        </div>

      </div>

      <CaseFooter 
        to="/projects/voshod" 
        title="Voshod" 
        img="/cases/img/next/voshod_next.webp" 
      />
      <HelmetSite title="X5 Все за одним столом!" />
    </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plus)