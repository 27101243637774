import React, { Component, useRef } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";
import Parallax from "parallax-js";
import simpleParallax from "simple-parallax-js";

class Vrb extends Component {
  state = {
    preview: true,
  };

  handleScroll() {
    const scr = $(window).scrollTop();
    $(".animate-scroll-element").each(function () {
      const top = $(this).offset().top - window.innerHeight / 2;
      if (scr >= top) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });

    $('.vrb-zoom-bgs').each(function(){
      const top = $(this).offset().top - window.innerHeight / 2;

      if (scr >= top) {
        $(this).addClass("active");
      }
    });
  }

  componentDidMount() {
    var video = document.getElementById("video-vrb");
    $(".but-vrb").click(function () {
      $(".but-vrb").toggleClass("min");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
    var image = document.getElementsByClassName("");
    new simpleParallax(image, {
      scale: 1.5,
    });

    $(".kms-block6").hover(function () {
      $(".iner1").removeClass("iner-active1");
      $(".iner2").removeClass("iner-active2");
      $(".iner3").removeClass("iner-active3");
      $(".iner4").removeClass("iner-active4");
      $(".iner6").removeClass("iner-active6");
      $(this).css("left", "16%");
    });

    $(window).on("mousemove", function (e) {
      var w = $(window).width();
      var h = $(window).height();
      var offsetX = 0.5 - e.pageX / w;
      var offsetY = 0.5 - e.pageY / h;

      $(".parallax").each(function (i, el) {
        var offset = parseInt($(el).data("offset"));
        var translate =
          "translate3d(" +
          Math.round(offsetX * offset) +
          "px," +
          Math.round(offsetY * offset) +
          "px, 0px)";

        $(el).css({
          "-webkit-transform": translate,
          transform: translate,
          "moz-transform": translate,
        });
      });
    });

    if (this.props.pathname == "/projects/vrb/") {
      this.setState({ preview: false });
      this.props.setProjectName("bsg");
      $("body").addClass("case-expanded hash-vrb");
      $(window).scrollTop($(".vsn").offset().top);
      this.props.changeCase(null);
    } else {
      this.setState({ preview: true });
      $("body").removeClass("vrb");
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $("body").addClass("fadepage");
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.pathname == "/projects/") {
      $("body").removeClass("case-expanded hash-vrb");
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fadepage");
    window.removeEventListener("scroll", this.handleScroll);
    if (window.parallaxInstance) {
      window.parallaxInstance.disable();
    }
  }

  render() {
    return (
      <div className="case vrb">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="vrb-after"></div>
          <div className="vrb-head">
            <img className="vrb-img1" src="/cases/img/vrb/img1.webp"></img>
          </div>
          <div className="logo">
            <img className="vrb-img2" src="/cases/img/vrb/img2.svg"></img>
          </div>
          <div className="vrb-block1">
            <div className=" vrb-bubble1 text text1 bubble right parallax-front parallax-up active">
              <div className="in">
                Сайт не только знакомит с домом, но и погружает в красочный мир
                художника и московского модерна
              </div>
            </div>
            <img
              className="vrb-img3 shadow"
              src="/cases/img/vrb/img3.webp"
            ></img>
            <img className="vrb-img4" src="/cases/img/vrb/img4.webp"></img>
          </div>
          <div className="vrb-block2">
            <img
              className="vrb-img5 shadow"
              src="/cases/img/vrb/img5.webp"
            ></img>
            <img className="vrb-img6" src="/cases/img/vrb/img6.webp"></img>
          </div>
          <div className="vrb-block3">
            <div className="vrb-zoom-bgs">
              <div style={{ backgroundImage: `url('/cases/img/vrb/img7.webp')`}} />
            </div>
          </div>
          <div className="vrb-block4">
            <div className=" vrb-bubble2 text text1 bubble left-bottom  parallax-front parallax-up active">
              <div className="in">
                Кроме творчества великого художника мы знакомимся искусством в
                московской архитектуре
              </div>
            </div>
            <img
              className="vrb-img8 shadow"
              src="/cases/img/vrb/img8.webp"
            ></img>
            <img className="vrb-img9" src="/cases/img/vrb/img9.webp"></img>
          </div>
          <div className="vrb-block5">
            <div className=" vrb-bubble3 text text1 bubble right-bottom  parallax-front parallax-up active">
              <div className="in">
                В подборе квартир мы объединили визуальный выбор и подбор по
                параметрам
              </div>
            </div>
            <img
              className="vrb-img10 shadow"
              src="/cases/img/vrb/img10.webp"
            ></img>
            <img className="vrb-img11" src="/cases/img/vrb/img11.webp"></img>
          </div>
          <div className="vrb-block6">
            <img
              className="vrb-img12 shadow"
              src="/cases/img/vrb/img12.webp"
            ></img>
          </div>
          <div className="vrb-block7">
            <div className="vrb-zoom-bgs">
              <div style={{ backgroundImage: `url('/cases/img/vrb/img13.webp')`}} />
            </div>
          </div>
          <div className="vrb-block8">
            <div className=" vrb-bubble4 text text1 bubble right-top  parallax-front parallax-up active">
              <div className="in">Видео–демонстрация работы сайта</div>
            </div>
            <div
              className="video-brb"
              style={{ backgroundImage: `url('/cases/img/vrb/img14.webp')` }}
            >
              <div className="video-in-vrb">
                <div
                  className="but-vrb"
                  style={{ backgroundImage: `url('/cases/img/vrb/img16.svg')` }}
                ></div>
                <video
                  id="video-vrb"
                  poster="/cases/img/vrb/img15.webp"
                  loop
                  preload="metadata"
                >
                  <source
                    src="/cases/img/vrb/video.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
          </div>
          <div className="vrb-block9 flex-9">
            <div className=" vrb-bubble5 text text1 bubble left  parallax-front parallax-up active">
              <div className="in">
                Так выглядит сайт в смартфоне. Адаптация происходит под любое
                устройство
              </div>
            </div>

            <div className="vrb-block9-flex">
              <img
                className="vrb-img16 img16-reb shadow"
                src="/cases/img/vrb/img17.webp"
              ></img>
              <div className="vrb-img9-in">
                <img
                  className="vrb-img18 shadow"
                  src="/cases/img/vrb/img18.webp"
                ></img>
                <img
                  className="vrb-img21 shadow"
                  src="/cases/img/vrb/img21.webp"
                ></img>
              </div>
              <img
                className="vrb-img20 shadow"
                src="/cases/img/vrb/img20.webp"
              ></img>
            </div>
          </div>
        </div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/vsn"  }
				title= { this.props.next ? this.props.next.name: "VSN Realty"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/vsn.webp"}
			/>

        <HelmetSite title={`ЖК «ВРУБЕЛЯ, 4»`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vrb);
