import React, { useEffect } from 'react';
import { SVG } from '@svgdotjs/svg.js'

const Alt4 = () => {

  const data = [
    [64, 490],
    [401, 614],
    [778, 543],
    [1056, 353],
    [1095, 177],
    [1349, 346],
    [1395, 853],
    [1505, 632],
    [1376, 178],

    [230, 796],
    [385, 846],
    [496, 759],

    [793, 806],
    [1148, 642]
  ]

  const create = (draw, from, to, offsetFrom, offsetTo, withStart = true, withEnd = false, text = "", textPos, text2 = "", text2Pos) => {
    const line = draw.line(from[0], from[1], to[0], to[1]).attr({ 'stroke': '#000' })
    line.animate(3000).plot(from[0] + offsetFrom[0], from[1] + offsetFrom[1], to[0] + offsetTo[0], to[1] + offsetTo[1])//.loop(3, true)

    if(withStart){
      const dot = draw.circle(12).move(from[0]-6, from[1]-6)
      dot.animate(3000).move(from[0]-6 + offsetFrom[0], from[1]-6 + offsetFrom[1])//.loop(3, true)
    }

    if(withEnd){
      const dot2 = draw.circle(12).move(to[0]-6, to[1]-6)
      dot2.animate(3000).move(to[0]-6 + offsetTo[0], to[1]-6 + offsetTo[1])//.loop(3, true)
    }

    if(text != ""){
      const txt = draw.text(text).move(textPos[0], textPos[1])
      txt.animate(3000).move(textPos[0] + offsetFrom[0], textPos[1] + offsetFrom[1])//.loop(3, true)
    }

    if(text2 != ""){
      const txt = draw.text(text2).move(text2Pos[0], text2Pos[1])
      txt.animate(3000).move(text2Pos[0] + offsetTo[0], text2Pos[1] + offsetTo[1])//.loop(3, true)
    }
  }

  useEffect(() => {
    const draw = SVG().addTo('.alt-stars').size(1640, 1050)
    document.querySelector('.alt-stars svg').setAttribute("viewBox","0 0 1640 1050")

    create(draw, data[0], data[1], [30, -30], [-10, 20], true, false, "Видеореклама", [data[0][0] - 5, data[0][1] - 35])
    create(draw, data[1], data[2], [-10, 20], [30, -30], true, false, "Поведенческая\nреклама", [data[1][0] - 35, data[1][1] - 75])
    create(draw, data[2], data[3], [30, -30], [-10, 20], true, false, "контекстная\nреклама", [data[2][0] + 25, data[2][1] + 15])
    create(draw, data[3], data[4], [-10, 20], [30, -30], true, false, "Создание\nlanding page", [data[3][0] - 125, data[3][1] - 45])
    create(draw, data[4], data[5], [30, -30], [-10, 20], true, false, "создание\nсайтов", [data[4][0] - 100, data[4][1] - 25])
    create(draw, data[5], data[6], [-10, 20], [30, -30], true, false, "продвижение\nв социальных\nсетях", [data[5][0] - 140, data[5][1]])
    create(draw, data[6], data[7], [30, -30], [-10, 20], true, false, "настройка\nаналитики", [data[6][0] - 115, data[6][1] - 15])
    create(draw, data[7], data[8], [-10, 20], [30, -30], true, true, "видеоролики\nи банеры", [data[7][0] + 10, data[7][1] - 55], "PR", [data[8][0] + 10, data[8][1] - 25])

    create(draw, data[9], data[1], [30, -30], [-10, 20], true, false, "банерная\nреклама", [data[9][0] - 95, data[9][1] - 15])
    create(draw, data[10], data[9], [-10, 20], [30, -30], true, false, "тизерная\nреклама", [data[10][0] + 15, data[10][1] + 10])
    create(draw, data[11], data[10], [20, 30], [-10, 20], true, false, "копирайтинг", [data[11][0] + 10, data[11][1] - 30])
    create(draw, data[11], data[1], [20, 30], [-10, 20], false, false)
    create(draw, data[12], data[2], [-10, 20], [30, -30], true, false, "креативные\ndigital стратегии", [data[12][0] - 70, data[12][1] + 20])
    create(draw, data[13], data[3], [10, -20], [-10, 20], true, false, "ведение страниц\nв социальных\nсетях", [data[13][0] - 5, data[13][1] + 25])
    create(draw, data[13], data[12], [10, -20], [-10, 20], false, false)

    return () => {
      document.querySelector('.alt-stars svg').remove()
    }
  },[]);

  return(
    <div className="alt-stars"></div>
  )
}

export default Alt4