import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Vsn extends Component {
  state = {
    preview: true,
  };

  handleScroll() {
    $(".animate-scroll-element").each(function () {
      const top = $(this).offset().top - window.innerHeight / 2;
      const scr = $(window).scrollTop();

      if (scr >= top) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  }

  componentDidMount() {
    $(window).on("mousemove", function (e) {
      var w = $(window).width();
      var h = $(window).height();
      var offsetX = 0.5 - e.screenX / w;
      var offsetY = 0.5 - e.screenY / h;

      $(".vsn__p img").each(function (i, el) {
        var offset = parseInt($(el).data("offset"));
        var translate =
          "translate3d(" +
          Math.round(offsetX * offset) +
          "px," +
          Math.round(offsetY * offset) +
          "px, 0px)";

        $(el).css({
          "-webkit-transform": translate,
          transform: translate,
          "moz-transform": translate,
        });
      });
    });


    var video = document.getElementById("video-vrb");
    $(".but-vrb").click(function () {
      $(".but-vrb").toggleClass("min");
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
    if (this.props.pathname == "/projects/vsn/") {
      this.setState({ preview: false });
      this.props.setProjectName("bsg");
      $("body").addClass("case-expanded hash-vsn");
      $(window).scrollTop($(".vsn").offset().top);
      this.props.changeCase(null);
    } else {
      this.setState({ preview: true });
      $("body").removeClass("vsn");
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $("body").addClass("fadepage");
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.pathname == "/projects/") {
      $("body").removeClass("case-expanded hash-vsn");
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fadepage");
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div className="case">
        <div className="case-content vsn-wrapper">

          <div className="vsn_img1" style={{ backgroundImage: `url('/cases/img/vsn/vsn_img5.webp')` }}>
            <img className="vsn_img2" src="/cases/img/vsn/vsn_img2.svg" />
            <div className="vsn_img3-wrap">
              <img className="vsn_img3" src="/cases/img/vsn/vsn_img1.svg" />

              <div className="vsn_img3-box">
                <div>
                  <img src="/cases/img/vsn/vsn_img4.webp" />
                  <img src="/cases/img/vsn/vsn_img3.svg" />
                </div>
              </div>
            </div>
          </div>
          <img className="vsn-img2" src="/cases/img/vsn/img2.webp"></img>
          <div className="vsn-bubble1 text text1 bubble right parallax-front parallax-up active">
            <div className="in">
              Разработали бренд-стратегию
              <br />
              агентства VSN Realty
            </div>
          </div>

          <img className="vsn-img8" src="/cases/img/vsn/img8.webp"></img>
          <div className="vrb-block8 vsn-block-video">
            <div className="vsn-bubble-video text text1 bubble left  parallax-front parallax-up active">
              <div className="in">
                Видео, демонстрирующее наглядно, идею, которая была вложена
              </div>
            </div>
            <div
              className="video-brb video-vsn"
              style={{ backgroundImage: `url('/cases/img/vrb/img14.webp')` }}
            >
              <div className="video-in-vrb">
                <div
                  className="but-vrb rep-but"
                  style={{ backgroundImage: `url('/cases/img/vrb/img16.svg')` }}
                ></div>
                <video
                  id="video-vrb"
                  poster="/cases/img/vsn/img3.webp"
                  loop
                  preload="metadata"
                >
                  <source
                    src="/cases/img/vsn/video.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
          </div>
          <img className="vsn-img5" src="/cases/img/vsn/img5.webp"></img>
          <div className="vsn-bubble2 text text1 bubble right parallax-front parallax-up active">
            <div className="in">
              Нами был разработан логотип,
              <br />
              фирменный стиль.
            </div>
          </div>
          <img className="vsn-img6" src="/cases/img/vsn/img6.webp"></img>
          <img className="vsn-img7" src="/cases/img/vsn/img7.webp"></img>
          <div className="vsn-bubble3 text text1 bubble right parallax-front parallax-up active">
            <div className="in">
              Эффектная печатная
              <br />
              продукция
            </div>
          </div>
          <img className="vsn-img9" src="/cases/img/vsn/img9.webp"></img>
          <img className="vsn-img10" src="/cases/img/vsn/img10.webp"></img>
          <img className="vsn-img11" src="/cases/img/vsn/img11.webp"></img>
          <div className="vsn-bubble4 text text1 bubble left parallax-front parallax-up active">
            <div className="in">
              Премьерное подразделение
              <br />
              имеет свой стиль
            </div>
          </div>
          <img className="vsn-img12" src="/cases/img/vsn/img12.webp"></img>
          <img className="vsn-img13" src="/cases/img/vsn/img13.webp"></img>
          <img className="vsn-img14" src="/cases/img/vsn/img14.webp"></img>
          <img className="vsn-img15" src="/cases/img/vsn/img15.webp"></img>
          <img className="vsn-img16" src="/cases/img/vsn/img16.webp"></img>
          <div className="vsn-bubble5 text text1 bubble left parallax-front parallax-up active">
            <div className="in">
              Премьерное подразделение
              <br />
              имеет свой стиль
            </div>
          </div>
          <img className="vsn-img17" src="/cases/img/vsn/img17.webp"></img>
          <img className="vsn-img18" src="/cases/img/vsn/img18.webp"></img>
          <img className="vsn-img19" src="/cases/img/vsn/img19.webp"></img>
          <div className="vsn-bubble6 text text1 bubble right parallax-front parallax-up active">
            <div className="in">Стиль во всём</div>
          </div>
          <img className="vsn-img20" src="/cases/img/vsn/img20.webp"></img>
          {/*<img className="vsn-img21" src="/cases/img/vsn/img21.png"></img>*/}
          <div className="vsn__p">
            <div>
              <img className="vsn__p1" src="/cases/img/vsn/vsn_p1.webp" data-offset="30" />
              <img className="vsn__p2" src="/cases/img/vsn/vsn_p2.webp" data-offset="50" />
            </div>
          </div>
        </div>

  

     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/tvz/"  }
				title= { this.props.next? this.props.next.name: "Тульский винокуренный завод"   } 
				img={ this.props.next? this.props.next.imgCaseBottom:"/cases/img/next/tvz.webp"}
			/>
        <HelmetSite title={`Компания VSN Realty`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vsn);
