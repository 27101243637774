import React, { Component, useEffect, useState } from "react";
import $ from "jquery";
import { hit } from "../../metrics";

import HelmetSite from "../HelmetSite";
import "./style.css";
import Desctope from "./Desctope";
import Content from './Content'

const   Profit = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)

  const resize = () => {
    setIsMobile(window.innerWidth < 1000)
  }

  useEffect(() => {

    hit(window.location.pathname);

    $("body").addClass("fadepage");

    window.addEventListener('resize', resize)

    return () => {
      $("body").removeClass("fadepage");

      window.removeEventListener('resize', resize)
    }
  },[]);

  if(isMobile){
    return (
      <div className="profit">
        <div className="case-content">
          <HelmetSite title={`Ipoteka Profit`} />
          <Content />
        </div>
      </div>
    )
  }

  return(
    <>
      <HelmetSite title={`Ipoteka Profit`} />
      <Desctope />
    </>
  )
}

export default Profit