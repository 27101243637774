import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Activity extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname === '/projects/activity/'){
			this.setState({preview: false});
			this.props.setProjectName('Активности');
			$('body').addClass('case-expanded hash-activity');
			$(window).scrollTop($('#case-activity').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-activity');
    }
    
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname === '/projects/'){
			$('body').removeClass('case-expanded hash-activity');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>

        <div className="screen screen1">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/activity/img2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p>Онлайн-игра для МТС в рамках спецпроекта на Mail.ru. 3 волшебных мира, 9 уровней и масса приключений по дороге! Лучшие выиграли iPhone, а 100 первых участников - красные шерстяные носки от МТС.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img3.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img4.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img5.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img6.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img7.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img8.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img9.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p>Установи мировой рекорд в заплыве скроллингом! Крути колесо мышки как можно сильней, и будешь первым! Специальный game-проект М.Видео к Олимпийским играм в Лондоне.</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img10.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/activity/img11.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/activity/img12.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img13.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img14.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img15.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p>Социальная игра «Построй свой Up! Квартал» для компании ФСК «Лидер».</p>
                  <p>Участник строит свой дом и заселяет друзьями из социальных сетей. Чем больше друзей, тем выше дом.</p>
                  <p>Победитель получил сертификат на 500 000 рублей на покупку квартиры.</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img16.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/activity/img17.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p>Интерактивный проект для Samsung в рамках спецпроекта Mail.ru, где пользователю предлагается собрать букет и отправить его 8 марта.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img18.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img19.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img20.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img21.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/activity/img9.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/activity/img22.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img8.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p>Специальный GameПроект М. Видео, посвященный Чемпионату по футболу 2012. Возможность поучаствовать в самой большой web-Волне в поддержку нашей сборной.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img23.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  <p>Каждый участник снимал себя на камеру по подсказкам, и потом получалась большая интерактивная волна. В поддержку Наших!</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img24.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img25.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/activity/img26.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img8.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img6.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p>Узнай, что хотят друзья на Новый Год. Мощный мультимедийно-социальный проект.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img27.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  <p>Как приятно получить звонок от Деда Мороза, который спросит тебя, что ты хочешь!</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img28.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/activity/img11.webp')`}}></div></div>
              <div className="image image4"><div style={{backgroundImage: `url('/cases/img/activity/img29.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="image image5"><div style={{backgroundImage: `url('/cases/img/activity/img14.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/activity/img30.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen7">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p>Проверьте, сможете ли Вы выдержать атаку квартирных хозяев, не растратив при этом всю свою зарплату.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img31.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img32.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  <p>Новая ипотечная редакция популярной игры.</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img33.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img34.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/activity/img35.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen8">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/activity/img36.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img37.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img8.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p>Что будет, если взмахнуть мечом и разрубить холодильник ровно пополам?</p>
                  <p>Будет скидка!</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img38.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img20.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen9">
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/activity/img39.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/activity/img8.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/activity/img17.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p>Любимые герои в новом измерении! Если Маша и Медведь вдруг выпрыгнут из экрана – не пугайтесь. Это новая 3D технология.</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/activity/img40.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/activity/img6.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/activity/img41.webp')`}}></div></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-activity">
				<Link to="/projects/activity/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/activity/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="text logo">
							<div className="image image0"><div style={{backgroundImage: `url('/cases/img/activity/img1.webp')`}}></div></div>
							<div className="title">Активности</div>
						</div>
						<div className="text text1">
							В нашей компании отличный <span className="nowrap">видео-продакшн</span> который занимается монтажом отснятого видео, отрисовкой мультфильмов, созданием обучающих роликов и озвучкой.
						</div>
					</div>
				</Link>
				{this.more()}
			</div>



     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/ikforum/" }
				title= { this.props.next ? this.props.next.name: "ИК “Форум”" } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/ikforum.jpg"}
			/>
      <HelmetSite title="Активности" />

			<Link to="/projects/" className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<div className="all-title">Все проекты</div>
				<div className="title">Активности</div>
				<div className="tags"><span>медиа</span><span>активности</span></div>
			</Link>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Activity)