import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Mts extends Component {
  state = {
    preview: true,
  };

	componentDidMount(){
		if(this.props.pathname == '/projects/mts/'){
			this.setState({preview: false});
			this.props.setProjectName('МТС Новый Год');
			$('body').addClass('case-expanded hash-mts');
			$(window).scrollTop($('#case-mts').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-mts');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-mts');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render() {
    return (
      <div className="case mts">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="mts-bg1" style={{ backgroundImage: "url('/cases/img/mts/img1.webp')"}}></div>
          <div className="mts-box">

            <img className="mts-bg2" src="/cases/img/mts/bg1.webp" />
            <img className="mts-bg3" src="/cases/img/mts/bg2.webp" />
            <img className="mts-bg4" src="/cases/img/mts/bg3.webp" />

            <img className="mts-img2 shadow" src="/cases/img/mts/img2.webp" />
            <img className="mts-img3 shadow" src="/cases/img/mts/img3.webp" />
            <div className="mts-img4-wrap">
              <img className="mts-img4 shadow" src="/cases/img/mts/img4.webp" />

              <div className="text bubble left parallax-front parallax-up mts-text2">
                <div className="in">
                Надо быстро подобрать сотруднику номер для нашего Косолапыча.
                <img src="/cases/img/mts/cursor2.webp" />
                </div>
              </div>
            </div>
            <img className="mts-img5 shadow" src="/cases/img/mts/img5.webp" />
            <img className="mts-img6 shadow" src="/cases/img/mts/img6.webp" />

            <div className="text bubble left parallax-front parallax-up mts-text1">
              <div className="in">
              Новогодняя игра для компании МТС с кучей призов.<br/><br/>
              Яркая и празничная, простые условия, забавные персонажи.
              <img src="/cases/img/mts/cursor1.webp" />
              </div>
            </div>
          </div>

          <div className="mts-slider">
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item"><img src="/cases/img/mts/h1.svg" /></div>
                <div className="item"><img src="/cases/img/mts/h2.svg" /></div>
                <div className="item"><img src="/cases/img/mts/h3.svg" /></div>
                <div className="item"><img src="/cases/img/mts/h4.svg" /></div>
                <div className="item"><img src="/cases/img/mts/h5.svg" /></div>
                <div className="item"><img src="/cases/img/mts/h6.svg" /></div>
                <div className="item"><img src="/cases/img/mts/h7.svg" /></div>
              </div>
            </div>
          </div>

          <div className="mts-mbox-wrap">
            <img className="mts-bg5" src="/cases/img/mts/bg5.webp" />
            <img className="mts-bg6" src="/cases/img/mts/bg6.webp" />
            <img className="mts-bg7" src="/cases/img/mts/bg7.webp" />
            <img className="mts-bg8" src="/cases/img/mts/bg8.webp" />
            <img className="mts-bg13" src="/cases/img/mts/img13.webp" />

            <div className="mts-mbox">
              <div>
                <div className="text bubble right parallax-front parallax-up mts-text3">
                  <div className="in">
                  Мобильная версия данной игры так же выглядит органично на небольшом экране.
                  <img src="/cases/img/mts/cursor1.webp" />
                  </div>
                </div>

                <img className="mts-mbox-img shadow" src="/cases/img/mts/img7.webp" />
                <img className="mts-mbox-img shadow" src="/cases/img/mts/img10.webp" />
              </div>
              <div>
                <img className="mts-mbox-img shadow" src="/cases/img/mts/img8.webp" />
                <div className="mts-img11-wrap">
                  <img className="mts-mbox-img shadow" src="/cases/img/mts/img11.webp" />
                  <img className="mts-img12" src="/cases/img/mts/img12.svg" />
                </div>
              </div>
              <div>
                <img className="mts-mbox-img shadow" src="/cases/img/mts/img9.webp" />
              </div>
            </div>
          </div>
        </div>

        
     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/armani"  }
				title= { this.props.next ? this.props.next.name: "Armani / Casa" } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/armani.webp"}
			/>
        <HelmetSite title={`МТС Новый Год`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mts);
