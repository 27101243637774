import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {hit} from '../../metrics';

import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';

import style from './style.css';

class X5values extends Component{
  state = {
    play: false
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage resize-page');
    }, 100);
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage resize-page');
  }
  
  render(){
    return(
    <div className='case'>
      <div className="case-content">
        <div className="academy">
          <div className="academy__box academy__box1">
            <div className="academy__bg" style={{backgroundImage: 'url(/cases/academy/1.webp)'}} />
            <div className="academy__logo" />
            <div className="academy__logo2" />
          </div>

          <div className="academy__box academy__box2">
            <img src="/cases/academy/2.webp" />
            <div className="text bubble right parallax-front text1 parallax-up">
              <div className="in">Проект компании X5 делающий цифровые технологии понятнее и&nbsp;ближе. Мы разработали полный пакет фирменного стиля.</div>
            </div>
          </div>

          <div className="academy__box academy__box3">
            <div className="academy__bg" style={{backgroundImage: 'url(/cases/academy/3.webp)'}} />
          </div>

          <div className="academy__box academy__box4">
            <img src="/cases/academy/4.webp" />
            <div className="text bubble left-top parallax-front text1 parallax-up">
              <div className="in">В логотипе использует двоичный код означающий букву “А”.</div>
            </div>
          </div>

          <div className="academy__box academy__box5">
            <img src="/cases/academy/5.webp" />
            <div className="text bubble right parallax-front text1 parallax-up">
              <div className="in">Мы разработали много носителей стиля, включая имиджевые материалы, баннеры и современную графику </div>
            </div>
          </div>

          <div className="academy__box academy__box6">
            <div className="academy__bg" style={{backgroundImage: 'url(/cases/academy/6.webp)'}} />
          </div>

          <div className="academy__box academy__box7">
            <img src="/cases/academy/7.webp" />
            <div className="text bubble right-top parallax-front text1 parallax-up">
              <div className="in">Сделали расширенный <br/> шаблон презентации</div>
            </div>
          </div>

          <div className="academy__box academy__box8">
            <div className="academy__bg" style={{backgroundImage: 'url(/cases/academy/8.webp)'}} />
          </div>

          <div className="academy__box academy__box9">
            <div className="academy__bg" style={{backgroundImage: 'url(/cases/academy/9.webp)'}} />
          </div>
          <div className="academy__box academy__box10">
            <img src="/cases/academy/10.webp" />
            <div className="video__box">
              <div>
                <img src="/cases/academy/video.webp" />
                <video poster="/cases/academy/video-poster.webp" ref="video">
                  <source src="/cases/academy/video.mp4" type="video/mp4" />
                </video>
                {this.state.play || <div className="video__play" onClick={this.handlePlay} />}
              </div>
            </div>
            <div className="text bubble right-top parallax-front text1 parallax-up">
              <div className="in">Также нами разработан большой гайд по съемке учебных и репортажных видеоматериалов.</div>
            </div>
          </div>
          <div className="academy__box academy__box11">
            <img src="/cases/academy/11.webp" />
          </div>
          <div className="academy__box academy__box12">
            <img className="shadow" src="/cases/academy/12.webp" />
          </div>
          <div className="academy__box academy__box13">
            <img className="shadow" src="/cases/academy/13.webp" />
          </div>
          <div className="academy__box academy__box14">
            <img src="/cases/academy/14.webp" />
          </div>
        </div>

      </div>

    

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/gals/"  }
				title= { this.props.next ? this.props.next.name: "Галс Девелопмент"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/gals.jpg"}
			/>

      <HelmetSite title={`Цифровая Академия`} />

    </div>
    )
  }

  handlePlay = () => {
    this.setState({play: true})
    this.refs.video.play()
  }
}

export default X5values;