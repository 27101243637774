import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {hit} from '../../metrics';

import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';

import style from './style.css';

class X5values extends Component{

	componentDidMount(){
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-x5values">
        <div className="screen screen1">
          <div>
            <img src="/cases/img/x5values/bg1.webp" />
          </div>
          <div>
            <img src="/cases/img/x5values/bg2.webp" />
          </div>
        </div>

        <div className="img-wrap img-wrap1">   
          <div className="text bubble right parallax-front">
            <div className="in">
              Для компании Х5  мы разработали специальное оформление офиса напоминающее о ценностях компании
            </div>
          </div>
          <img src="/cases/img/x5values/bg3.webp" className="img-in1" />
          <img src="/cases/img/x5values/bg4.webp" className="img-in2" />
        </div>

        <div className="img-wrap img-wrap2"></div>

        <div className="img-wrap img-wrap3">
          <img src="/cases/img/x5values/bg6.webp" className="img-in3" />

          <div className="text bubble left parallax-front">
            <div className="in">
            Наши сообщения забавные и не скучные. Подбадривают и напоминают о том, что мы классные
            </div>
          </div>
        </div>

        <div className="img-wrap img-wrap4">
          <img src="/cases/img/x5values/bg7.webp" className="img-in4" />
        </div>

        <div className="screen2">
          <div>
            <img src="/cases/img/x5values/bg8.webp" />
          </div>
          <div>
            <img src="/cases/img/x5values/bg9.webp" />
          </div>
        </div>

        <div className="img-wrap img-wrap5">
          <img src="/cases/img/x5values/bg10.webp" className="img-in5" />

          <div className="text bubble left parallax-front">
            <div className="in">
            Помогают экономить ресурсы и беречь природу
            </div>
          </div>

          <img src="/cases/img/x5values/bg11.webp" className="img-in6" />
        </div>

        <div className="img-wrap img-wrap6">
          <img src="/cases/img/x5values/bg12.webp" className="img-in7" />

          <div className="text bubble right parallax-front">
            <div className="in">
            Ценности напоминают о&nbsp;необходимости взаимодействия с коллегами
            </div>
          </div>
        </div>

        <div className="slider parallax-slider slider1">
          <div className="line">
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img1.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img2.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img3.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img4.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img5.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img6.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img7.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img8.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img9.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/img10.webp')`}}></div></div>
          </div>
        </div>

        <div className="img-wrap img-wrap7">
          <img src="/cases/img/x5values/bg14.webp" className="img-in8" />
          <img src="/cases/img/x5values/bg15.webp" className="img-in9" />
          <img src="/cases/img/x5values/bg16.webp" className="img-in10" />
        </div>

        <div className="img-wrap img-wrap8" />

        <div className="img-wrap img-wrap9">
          <div className="text bubble right parallax-front">
            <div className="in">
            Для ценностей используются абсолютно разные носители: от стен до подвесных конструкций
            </div>
          </div>

          <img src="/cases/img/x5values/bg18.webp" className="img-in11" />
          <img src="/cases/img/x5values/bg19.webp" className="img-in12" />
        </div>


        <div className="img-wrap img-wrap10">
          <div>
            <img src="/cases/img/x5values/bg20.webp" />
          </div>
          <div>
            <img src="/cases/img/x5values/bg21.webp" />
          </div>
        </div>

        <div className="img-wrap img-wrap11">
          <img src="/cases/img/x5values/bg23.webp" className="img-in13" />
          <img src="/cases/img/x5values/bg22.webp" className="img-in14" />
        </div>

        <div className="img-wrap img-wrap12">
          <div>
            <img src="/cases/img/x5values/bg24.webp" />
          </div>
          <div>
            <img src="/cases/img/x5values/bg25.webp" />
          </div>
        </div>

        <div className="img-wrap img-wrap13">
          <img src="/cases/img/x5values/bg26.webp" />

          <div className="text bubble left parallax-front">
            <div className="in">
            Разработали календарь настроений показывающий настроение коллеги
            </div>
          </div>
        </div>

        <div className="slider parallax-slider slider2">
          <div className="line">
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i1.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i2.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i3.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i4.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i5.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i6.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i7.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i8.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i9.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i10.webp')`}}></div></div>
          </div>
        </div>

        <div className="slider parallax-slider invert slider2">
          <div className="line">
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i1.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i2.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i3.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i4.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i5.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i6.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i7.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i8.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i9.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5values/i10.webp')`}}></div></div>
          </div>
        </div>

      </div>



      
      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/atm-online/"  }
				title= { this.props.next ? this.props.next.name: "ATM online"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/atm.webp"}
			/>

      <HelmetSite title="X5. Корпоративные ценности" />
		</div>
		)
	}
}

export default X5values;