import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class GeneralInvest extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/generalinvest/'){
			this.setState({preview: false});
			this.props.setProjectName('General Invest');
			$('body').addClass('case-expanded hash-generalinvest');
			$(window).scrollTop($('#case-generalinvest').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-aresidence');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-generalinvest');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-generalinvest">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div data-lazy="/cases/img/generalinvest/bg1.webp" />
          </div>
        </div>
        <div className="screen screen2">
          <div className="text bubble right parallax-front text1" data-text="bg1">
            <div className="in">
              <p>Сайт инвестиционной компании с богатой историей, поддержанной искусством ренессанса</p>
            </div>
          </div>
          <div className="text bubble left parallax-front text2" data-text="bg1">
            <div className="in">
              <p>Весь сайт отражает многовековой опыт европейских инвестиций</p>
            </div>
          </div>
          <img data-lazy="/cases/img/generalinvest/bg2.webp" data-bubble="bg1" />
        </div>
        <div className="screen screen3">
          <div className="bg">
            <div data-lazy="/cases/img/generalinvest/bg3.webp" />
          </div>
        </div>
        <div className="screen screen4">
          <img data-lazy="/cases/img/generalinvest/bg4.webp" />
        </div>
        <div className="screen screen5">
          <div className="text bubble left parallax-front" data-text="bg5">
            <div className="in">
              <p>Сайт адаптируется под любые мобильные устройства</p>
            </div>
          </div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/r2NcpPcXZnU?rel=0&amp;showinfo=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
          <img data-lazy="/cases/img/generalinvest/bg5.webp" data-bubble="bg5" />
        </div>
        <div className="screen screen6">
          <div className="screen5-bg" />
          <div className="clear">
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item item1"><span data-lazy="/cases/img/generalinvest/bg6-1.webp"></span></div>
                <div className="item item2"><span data-lazy="/cases/img/generalinvest/bg6-2.webp"></span></div>
                <div className="item item3"><span data-lazy="/cases/img/generalinvest/bg6-3.webp"></span></div>
                <div className="item item4"><span data-lazy="/cases/img/generalinvest/bg6-4.webp"></span></div>
              </div>
            </div>
          </div>
        </div>
			</div>


 
     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/x5ny/" }
				title= { this.props.next ? this.props.next.name: "Помощник Деда Мороза"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/x5ny.webp"}
			/>
      <HelmetSite title="General Invest" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">General Invest</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralInvest)