import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Greenpolza extends Component{

	state = {
		preview: true
	}

	componentDidMount(){

		if(this.props.pathname == '/projects/greenpolza/'){
			this.setState({preview: false});
			this.props.setProjectName('Зеленая польза');
			$('body').addClass('case-expanded hash-greenpolza');
			$(window).scrollTop($('#case-greenpolza').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-greenpolza');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-greenpolza');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	render(){
		return(
			<div className={this.state.preview ? 'case' : 'case'}>
				<div className="case-content" id="case-greenpolza">
					{this.more}
				</div>

      

       <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/torpedo/"  }
				title= { this.props.name ? this.props.next.name: "Торпедо"    } 
				img={ this.props.name ? this.props.next.name:"/cases/img/next/torpedo.webp"}
			/>
        <HelmetSite title="Зеленая польза" />

				<div className="case-info">
					{/*<div className="next-title">Следующий проект</div>*/}
					<Link to="/projects/" className="all-title">Все проекты</Link>
					<div className="title">Зеленая польза</div>
					<div className="tags"><span>сайт</span><span>финансы</span></div>
				</div>
			</div>
		)
	}

	get more() {
		return (
			<div>
				<div className="screen screen0 screen_more h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/greenpolza/img0.webp')`}}></div></div>
					<div className="content">
						<div className="bg2" style={{backgroundImage: `url('/cases/img/greenpolza/img2.webp')`}}><div></div></div>
						<div className="screen0-text">
							<div className="screen0-text__title">Зелёная Польза</div>
							Дебетовая карта банка Хоум Кредит из биопластика, ориентированная на интересы людей, ведущих здоровый образ жизни, вегетарианцев и всех тех, кому не безразличен окружающий мир.
						</div>
					</div>
				</div>
				<div className="screen screen1">
					<div className="content">
						<div className="bubble-abs">
							<div className="text bubble right parallax-front">
								<div className="in">
									<div className="bubble__title">Сделали сайт</div>
									При создании сайта мы стремились показать всю полезность использования этой карты, применив в качестве иллюстраций свежие и полезные овощи и фрукты
								</div>
							</div>
						</div>
						<div className="image" style={{backgroundImage: `url('/cases/img/greenpolza/img3.webp')`}}></div>
					</div>
				</div>
				<div className="screen screen2">
					<div className="content">
						<div className="image_abs" style={{backgroundImage: `url('/cases/img/greenpolza/img4.webp')`}}></div>
						<div className="image2" style={{backgroundImage: `url('/cases/img/greenpolza/img5.webp')`}}></div>
						<div className="bubble-abs">
							<div className="text bubble left-top parallax-front">
								<div className="in">
									<div className="bubble__title">Главная страница</div>
									<p>Дизайнеры позаботились о лёгкой подаче информации. Ярко выделенные цифры и преимущества карты, красочные иллюстрации, продуманная инфографика – всё это способствует хорошему восприятию информации.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="screen screen3">
					<div className="content">
						<div className="image" style={{backgroundImage: `url('/cases/img/greenpolza/img6.webp')`}}></div>
					</div>
				</div>
				<div className="screen screen4">
					<div className="content">
						<div className="image2"></div>
						<div className="bubble-abs">
							<div className="text bubble left-bottom parallax-front">
								<div className="in">
									<div className="bubble__title">Конверт</div>
									Также, для рассылки карт клиентам, был разработан конверт, в который помещается информационное письмо и, собственно, сама карта.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="screen screen5">
					<div className="content">
						<div className="image" style={{backgroundImage: `url('/cases/img/greenpolza/img7.webp')`}}></div>
					</div>
				</div>
				<div className="screen screen6">
					<div className="content">
						<div className="image2" style={{backgroundImage: `url('/cases/img/greenpolza/img8.webp')`}}></div>
						<div className="bubble-abs">
							<div className="text bubble right-bottom parallax-front">
								<div className="in">
									<div className="bubble__title">Конверт</div>
									Также, для рассылки карт клиентам, был разработан конверт, в который помещается информационное письмо и, собственно, сама карта.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="screen screen7">
					<div className="content">
						<div className="image image_2" style={{backgroundImage: `url('/cases/img/greenpolza/img10.webp')`}}></div>
						<div className="image image_1" style={{backgroundImage: `url('/cases/img/greenpolza/img9.webp')`}}></div>
					</div>
				</div>
				<div className="screen screen8">
					<div className="content">
						<div className="image2"></div>
						<div className="bubble-abs">
							<div className="text bubble left-bottom parallax-front">
								<div className="in">
									В самом письме мы показали, насколько удобно и полезно пользоваться картой, и в каких категоряих возможно её использование.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="screen screen9">
					<div className="content">
						<div className="image image_1" style={{backgroundImage: `url('/cases/img/greenpolza/img11.webp')`}}></div>
						<div className="image image_2" style={{backgroundImage: `url('/cases/img/greenpolza/img12.webp')`}}></div>
					</div>
				</div>
				<div className="screen screen10" style={{backgroundImage: `url('/cases/img/greenpolza/img13.webp')`}}>
					<div className="content">
						<div className="image2"></div>
						<div className="bubble-abs">
							<div className="text bubble right parallax-front">
								<div className="in">
									<div className="bubble__title">Наклейка на дверь</div>
									Для магазинов-партнеров была разработана наклейка на дверь
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="screen screen11">
					<div className="content">
						<div className="image image_2" style={{backgroundImage: `url('/cases/img/greenpolza/img15.webp')`}}></div>
						<div className="image image_1" style={{backgroundImage: `url('/cases/img/greenpolza/img14.webp')`}}></div>
						<div className="image2 image_3" style={{backgroundImage: `url('/cases/img/greenpolza/img16.webp')`}}></div>
						<div className="image2 image_4" style={{backgroundImage: `url('/cases/img/greenpolza/img17.webp')`}}></div>
						<div className="image2 image_5" style={{backgroundImage: `url('/cases/img/greenpolza/img18.webp')`}}></div>
						<div className="bubble-abs">
							<div className="text bubble left parallax-front">
								<div className="in">
									Мы адаптировали сайт под планшеты и смартфоны
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	get body() {
		return (
			<div>
				<Link to="/projects/greenpolza/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/greenpolza/img0.webp')`}}></div></div>
					<div className="content">
						<div className="bg2" style={{backgroundImage: `url('/cases/img/greenpolza/img2.webp')`}}><div></div></div>
					</div>
				</Link>
			</div>
		)
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Greenpolza)