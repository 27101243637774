import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Westgarden extends Component {
	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/westgarden/'){
			this.setState({preview: false});
			this.props.setProjectName('Westgarden');
			$('body').addClass('case-expanded hash-westgarden');
			$(window).scrollTop($('.westgarden').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-westgarden');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-westgarden');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render(){
    return(
      <div className='case'>
        <div className="case-content">
          <div className="westgarden">
            <div className="westgarden__bg1">
              <img src="/cases/img/westgarden/logo.webp" />
            </div>
            <div className="westgarden__box westgarden__box1">
              <img className="westgarden__bg2 shadow" src="/cases/img/westgarden/bg2.webp" />
              <img className="westgarden__bg2-img" src="/cases/img/westgarden/bg3.webp" />
  
              <div className="text bubble right parallax-front text1 parallax-up">
                <div className="in">Уникальный проект сочетающий преимущества жизни на природе с престижным распололежнием на западе столицы.</div>
              </div>
            </div>
            <div className="westgarden__box westgarden__box2">
              <img className="westgarden__bg3 shadow" src="/cases/img/westgarden/bg4.webp" />
              <img className="westgarden__bg3-img" src="/cases/img/westgarden/bg5.webp" />
  
              <div className="text bubble left parallax-front text1 parallax-up">
                <div className="in">Сайт полностью передает ощущение города-сада</div>
              </div>
            </div>
  
            <div className="westgarden__box westgarden__box3">
              <img className="westgarden__bg4 shadow" src="/cases/img/westgarden/bg6.webp" />
              <img className="westgarden__bg4-img" src="/cases/img/westgarden/bg7.webp" />
            </div>
  
            <img className="westgarden__bg5 shadow" src="/cases/img/westgarden/bg8.webp" />
  
            <div className="westgarden__bg6" />
  
            <div className="westgarden__box westgarden__box4">
              <div className="text bubble right parallax-front text1 parallax-up">
                <div className="in">Адаптация под мобильные устройства полностью сохраняет функцонал основного сайта</div>
              </div>
              <img className="westgarden__bg7" src="/cases/img/westgarden/bg10.webp" />
            </div>
          </div>
        </div>

  
        
     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/iskra/"  }
				title= { this.props.next ? this.props.next.name: "Искра-Парк"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/iskra.webp"}
			/>

        <HelmetSite title={`ЖК "West Garden"`} />
      </div>
    )
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Westgarden)