export default [
	// { Заполнение поста:
	// 	id: 1, - номер по порядку
	// 	title: "Концерт нашей группы&nbsp;“МИА”", - Заголовок
	// 	images: [ - список ссылок на изображения для галереи
	// 		"/img/notformat/img1-1.jpg", - первое изображение отображается на странице
	// 		"/img/notformat/img1-2.jpg",
	// 		"/img/notformat/img1-3.jpg"
	// 	]
	// 	images_small: [ - список ссылок на маленькие изображения для предзагрузки
	// 		"/img/notformat/img1-1.jpg", - первое изображение отображается на странице
	// 		"/img/notformat/img1-2.jpg",
	// 		"/img/notformat/img1-3.jpg"
	// 	],
	// 	preview: "/img/notformat/img3-9.jpg", - превью альбома (если нет, показавается первое изображение)
	// 	preview_small: "/img/notformat/img3-9.jpg" - превью для предзагрузки
	// },
	{
		id: 1,
		title: "Концерт нашей группы&nbsp;“МИА”",
		images: [
			"/img/notformat/img1-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img1-1.jpg"
		]
	},
	{
		id: 2,
		title: "Стрит-фото района Мода, Кадыкёй, Стамбул",
		images: [
			"/img/notformat/img2-1.jpg",
			"/img/notformat/img2-2.jpg",
			"/img/notformat/img2-3.jpg",
			"/img/notformat/img2-4.jpg",
			"/img/notformat/img2-5.jpg",
			"/img/notformat/img2-6.jpg",
			"/img/notformat/img2-7.jpg",
			"/img/notformat/img2-8.jpg",
			"/img/notformat/img2-9.jpg"
		],
		images_small: [
			"/img/notformat/small/img2-1.jpg",
			"/img/notformat/small/img2-2.jpg",
			"/img/notformat/small/img2-3.jpg",
			"/img/notformat/small/img2-4.jpg",
			"/img/notformat/small/img2-5.jpg",
			"/img/notformat/small/img2-6.jpg",
			"/img/notformat/small/img2-7.jpg",
			"/img/notformat/small/img2-8.jpg",
			"/img/notformat/small/img2-9.jpg"
		]
	},
	{
		id: 3,
		title: "Наши девушки в&nbsp;образах кошечек. Играли 8&nbsp;марта",
		images: [
			"/img/notformat/img3-1.jpg",
			"/img/notformat/img3-2.jpg",
			"/img/notformat/img3-3.jpg",
			"/img/notformat/img3-4.jpg",
			"/img/notformat/img3-5.jpg",
			"/img/notformat/img3-6.jpg",
			"/img/notformat/img3-7.jpg",
			"/img/notformat/img3-8.jpg",
			"/img/notformat/img3-9.jpg",
			"/img/notformat/img3-10.jpg",
			"/img/notformat/img3-11.jpg",
			"/img/notformat/img3-12.jpg",
			"/img/notformat/img3-13.jpg",
		],
		images_small: [
			"/img/notformat/small/img3-1.jpg",
			"/img/notformat/small/img3-2.jpg",
			"/img/notformat/small/img3-3.jpg",
			"/img/notformat/small/img3-4.jpg",
			"/img/notformat/small/img3-5.jpg",
			"/img/notformat/small/img3-6.jpg",
			"/img/notformat/small/img3-7.jpg",
			"/img/notformat/small/img3-8.jpg",
			"/img/notformat/small/img3-9.jpg",
			"/img/notformat/small/img3-10.jpg",
			"/img/notformat/small/img3-11.jpg",
			"/img/notformat/small/img3-12.jpg",
			"/img/notformat/small/img3-13.jpg",
		],
		preview: "/img/notformat/img3-9.jpg",
		preview_small: "/img/notformat/img3-9.jpg"
	},
	{
		id: 4,
		title: "Новый год 2022",
		images: [
			"/img/notformat/img4-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img4-1.jpg"
		]
	},
	{
		id: 5,
		title: "Очередная картина нашего арт-дира",
		images: [
			"/img/notformat/img5-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img5-1.jpg"
		]
	},
	{
		id: 6,
		title: "Котики азиатской части&nbsp;Стамбула",
		images: [
			"/img/notformat/img6-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img6-1.jpg"
		]
	},
	{
		id: 7,
		title: "Как мы делали подарок на&nbsp;новый 2022&nbsp;год",
		images: [
			"/img/notformat/img7-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img7-1.jpg"
		]
	},
	{
		id: 8,
		title: "Заголовок",
		images: [
			"/img/notformat/img1-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img1-1.jpg"
		]
	},
	{
		id: 9,
		title: "Заголовок",
		images: [
			"/img/notformat/img2-1.jpg",
			"/img/notformat/img2-2.jpg"
		],
		images_small: [
			"/img/notformat/small/img2-1.jpg",
			"/img/notformat/small/img2-2.jpg"
		]
	},
	{
		id: 10,
		title: "Заголовок",
		images: [
			"/img/notformat/img3-1.jpg",
			"/img/notformat/img3-2.jpg",
			"/img/notformat/img3-3.jpg",
			"/img/notformat/img3-4.jpg"
		],
		images_small: [
			"/img/notformat/small/img3-1.jpg",
			"/img/notformat/small/img3-2.jpg",
			"/img/notformat/small/img3-3.jpg",
			"/img/notformat/small/img3-4.jpg"
		]
	},
	{
		id: 11,
		title: "Заголовок",
		images: [
			"/img/notformat/img5-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img5-1.jpg"
		]
	},
	{
		id: 12,
		title: "Заголовок",
		images: [
			"/img/notformat/img4-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img4-1.jpg"
		]
	},
	{
		id: 13,
		title: "Заголовок",
		images: [
			"/img/notformat/img7-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img7-1.jpg"
		]
	},
	{
		id: 14,
		title: "Заголовок",
		images: [
			"/img/notformat/img6-1.jpg"
		],
		images_small: [
			"/img/notformat/small/img6-1.jpg"
		]
	}
]