import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Ryadom extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/ryadom/'){
			this.setState({preview: false});
			this.props.setProjectName('Рядом');
			$('body').addClass('case-expanded hash-ryadom');
			$(window).scrollTop($('#case-ryadom').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-ryadom');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-ryadom');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/ryadom/img1.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble middle left text1 parallax-front">
                <div className="in">
                  <p>Перед нами стояла задача по созданию сайта агентства.</p>
                  <p>Просто, ярко, необычно. </p>
                </div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/ryadom/img2.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="text bubble middle right text2 parallax-front">
                <div className="in">
                  Основа дизайн-концепции – переплетение  ярких фото и монохромных графических элементов. Главная страница –  основная презентация и агентства, и объектов недвижимости.
                </div>
              </div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/ryadom/img4.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg" style={{backgroundImage: `url('/cases/img/ryadom/img5.webp')`}}></div>
          <div className="content narrow">
            <div className="clear z2">
              <div className="text bubble middle left-bottom text1 parallax-front">
                <div className="in">
                  <p>Удобная навигация по странице объекта с фильтром, меню, 3D-визуализациями.</p>
                  <p>3D-план и шахматка с квартирами – для легкого выбора квартиры и понимания её расположения.</p>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="image" style={{backgroundImage: `url('/cases/img/ryadom/img6.webp')`}}></div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/ryadom/img8.webp')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/ryadom/img7.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/ryadom/img9.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="image" style={{backgroundImage: `url('/cases/img/ryadom/img10.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="text bubble middle right text1 parallax-front">
                <div className="in">
                  Галерею объекта разделили на подразделы, все фото и видео сгруппированы.
                </div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/ryadom/img11.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/ryadom/img12.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/ryadom/img13.webp')`}}></div></div>
              <div className="text bubble middle left-bottom text1 parallax-front">
                <div className="in">
                  RESS-технологию использовали<br/>для разработки мобильной версии.
                </div>
              </div>
              <div className="screenshot-mob screenshot2" style={{backgroundImage: `url('/cases/img/ryadom/img14.webp')`}}></div>
              <div className="screenshot-mob screenshot3" style={{backgroundImage: `url('/cases/img/ryadom/img15.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="content narrow">
            <div className="clear">
              <div className="image parallax-side"><div style={{backgroundImage: `url('/cases/img/ryadom/img16.webp')`}}></div></div>
              <div className="text bubble middle right-top text1 parallax-front">
                <div className="in">
                  А это соседи. Мы нарисовали множество персонажей, которые всегда РЯДОМ с пользователем на каждой странице.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-ryadom">
				<Link to="/projects/ryadom/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/ryadom/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/ryadom/logo.svg')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Рядом</div>
								<p>Агентство недвижимости, которое всегда рядом.</p>
							</div>
						</div>
						<div className="image" style={{backgroundImage: `url('/cases/img/ryadom/img3.webp')`}}></div>
					</div>
				</Link>
				{this.more()}
			</div>


     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/aresidence/" }
				title= { this.props.next  ? this.props.next.name: "A-Residence"} 
				img={ this.props.next  ? this.props.next.imgCaseBottom:"/cases/img/next/aresidence.webp"}
			/>
      <HelmetSite title="Рядом" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Рядом</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Ryadom)