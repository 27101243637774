import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Ikforum extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/ikforum/'){
			this.setState({preview: false});
			this.props.setProjectName('ИК Форум');
			$('body').addClass('case-expanded hash-ikforum');
			$(window).scrollTop($('#case-ikforum').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-ikforum');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-ikforum');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/ikforum/img1.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text middle text0">
                <div className="in">
                  <div className="title b30">ИК «Форум» — инвестиционная компания:</div>
                  <ul>
                    <li>Собственные разработки</li>
                    <li>Мгновенные сделки</li>
                    <li>Автоматизированные системы для торгов</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="text bubble middle left-bottom text1 parallax-front">
                <div className="in">
                  Нам нужно было выполнить комплекс работ: от фирменного стиля до разработки сайта для инвестиционной компании.
                </div>
              </div>
              <div className="image image1" style={{backgroundImage: `url('/cases/img/ikforum/img2.webp')`}}></div>
              <div className="image image2" style={{backgroundImage: `url('/cases/img/ikforum/img3.webp')`}}></div>
              <div className="image image3" style={{backgroundImage: `url('/cases/img/ikforum/img4.webp')`}}></div>
              <div className="text bubble middle right-bottom text3 parallax-front">
                <div className="in">
                  <p><b>Фирменный стиль</b></p>
                  <p> Концепцию проекта мы построили на основе названия компании: «Форум» - Римский форум - Античные скульптуры. Цветовые решения соответствуют тематике и стилю: белый, чёрный, тёмно-серый, золотой.</p>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="image image4" style={{backgroundImage: `url('/cases/img/ikforum/img5.webp')`}}></div>
              <div className="image image5" style={{backgroundImage: `url('/cases/img/ikforum/img6.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="text bubble middle left-top text2 parallax-front">
                <div className="in">
                  Образ льва подкрепляет эту концепцию. Мы сделали 3&nbsp;варианта использования логотипа.
                </div>
              </div>
              <div className="image image6" style={{backgroundImage: `url('/cases/img/ikforum/img7.webp')`}}></div>
              <div className="image image7" style={{backgroundImage: `url('/cases/img/ikforum/img8.webp')`}}></div>
              <div className="text bubble middle right-top text4 parallax-front">
                <div className="in">
                  Мы проработали шрифты, цвета, варианты применения фирменного стиля в визитках, буклетах, полиграфии, сувенирной продукции, презентациях, документах, электронных письмах. 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble middle right-bottom text1 parallax-front">
                <div className="in">
                  Все элементы фирменного стиля, примеры и правила их использования мы собрали в брендбук.
                </div>
              </div>
              <div className="image"><div style={{backgroundImage: `url('/cases/img/ikforum/img9.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="content narrow">
            <div className="clear">
              <div className="image image1" style={{backgroundImage: `url('/cases/img/ikforum/img10.webp')`}}></div>
              <div className="text bubble middle right text1 parallax-front">
                <div className="in">
                  А это выдержка из него: шаблон презентации в Power Point с использованием айдентики.
                </div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/ikforum/img11.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/ikforum/img23.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/ikforum/img12.webp')`}}></div>
              <div className="image image1" style={{backgroundImage: `url('/cases/img/ikforum/img13.webp')`}}></div>
              <div className="text bubble middle left text1 parallax-front">
                <div className="in">
                  <p><b>Разработка сайта для ИК «Форум»</b></p>
                  <p>Дизайн главной страницы сайта мы выдержали в рамках и правилах брендбука. Динамику и настроение создаёт фоновый видеоролик.</p>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="text bubble middle right2 text2 parallax-front">
                <div className="in">
                  Без лишних кликов:<br/>информацию по каждой стратегии мы представили в виде посадочной страницы.
                </div>
              </div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/ikforum/img14.webp')`}}></div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/ikforum/img15.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="image image2" style={{backgroundImage: `url('/cases/img/ikforum/img16.webp')`}}></div>
              <div className="image image3" style={{backgroundImage: `url('/cases/img/ikforum/img17.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="text bubble left-bottom  middle text3 parallax-front">
                <div className="in">
                  Сложную тему мы упрощаем для пользователя и приводим его к действию.
                </div>
              </div>
              <div className="screenshot screenshot4" style={{backgroundImage: `url('/cases/img/ikforum/img18.webp')`}}></div>
              <div className="image image4" style={{backgroundImage: `url('/cases/img/ikforum/img19.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="text bubble right text4 parallax-front">
                <div className="in">
                  Для индивидуальных стратегий дизайнеры выделили отдельную страницу. Для лучшего понимания мы используем имиджи. 
                </div>
              </div>
              <div className="image image5" style={{backgroundImage: `url('/cases/img/ikforum/img20.webp')`}}></div>
              <div className="screenshot screenshot5" style={{backgroundImage: `url('/cases/img/ikforum/img21.webp')`}}></div>
              <div className="screenshot screenshot6" style={{backgroundImage: `url('/cases/img/ikforum/img22.webp')`}}></div>
              <div className="text bubble middle left text5 parallax-front">
                <div className="in">
                  Серьёзный проект для серьёзных людей. Мы внимательно проработали дизайн и логику всех страниц. 
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="text bubble middle right text6 parallax-front">
                <div className="in">
                  Без исключений!
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>

			<div className="case-content" id="case-ikforum">

				<Link to="/projects/ikforum/" className="screen screen0 h100">
					<div className="bg parallax-back">
						<div style={{backgroundImage: `url('/cases/img/ikforum/img0.webp')`}}></div>
						<div style={{backgroundImage: `url('/cases/img/ikforum/video0.webp')`}}></div>
						{window.innerWidth > 648 ? <video loop="" muted="" id="ikforum-video0"><source type="video/mp4" src="/cases/img/ikforum/video0.mp4"/></video> : null}
					</div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/ikforum/logo.svg')`}}></div></div>
					</div>
				</Link>

				{this.more()}
			</div>

 
      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/eph/" }
				title= { this.props.name ? this.props.next.name: "Eastern Property Holdings"} 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/eph.webp"}
			/>
      <HelmetSite title="ИК “Форум”" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">ИК Форум</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Ikforum)