import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Botanica extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/botanica/'){
			this.setState({preview: false});
			this.props.setProjectName('Botanica');
			$('body').addClass('case-expanded hash-botanica');
			$(window).scrollTop($('#case-botanica').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-botanica');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-botanica');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-botanica">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/botanica/bg1-1.webp')`}} />
            <img src="/cases/img/botanica/bg1-2.webp" />
          </div>
        </div>
        <div className="screen screen2">
          <div className="text bubble right parallax-front">
            <div className="in">
              <p>ЖК Ботаника - дом класса премиум в самом центре Петроградской стороны</p>
            </div>
          </div>
          <img src="/cases/img/botanica/bg2-1.webp" className="img1 shadow" />
          <img src="/cases/img/botanica/bg2-2.webp" className="img2 shadow" />
          <img src="/cases/img/botanica/bg2-3.webp" className="img3 shadow" />
        </div>
        <div className="screen screen3">
          <div className="text bubble right parallax-front">
            <div className="in">
              <p>На сайте мы постарались передать ощущение лёгкого речного ветра с криками чаек и свежей зелени деревьев раннего лета.</p>
            </div>
          </div>
          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/botanica/bg3.webp')`}} />
          </div>
        </div>
        <div className="screen screen4">
          <div className="text bubble left parallax-front">
            <div className="in">
              <p>Сайт полностью адаптирован под любые устройства</p>
            </div>
          </div>
          <img src="/cases/img/botanica/bg4-1.webp" className="img1 shadow" />
          <img src="/cases/img/botanica/bg4-2.webp" className="img2" />
          <img src="/cases/img/botanica/bg4-3.webp" className="img3" />
          <div className="img4">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/cuaOm2M7xHI?rel=0&amp;showinfo=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            <img src="/cases/img/botanica/bg4-4.webp" />
          </div>
          <img src="/cases/img/botanica/bg4-5.webp" className="img5" />
        </div>
			</div>



    <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/x5happy/"  }
				title= { this.props.next ? this.props.next.name: "Happy X5"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/x5happy.webp" }
			/>
      <HelmetSite title="ЖК “Ботаника”" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">ЖК "Ботаника"</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Botanica)