import {
	PAGEID,
	VERSION,
	PROJECTNAME,
	CASE,
  POPUP
} from './const';

const mapDispatchToProps = (dispatch) => {
	return {
		setPageId: (pageId) => {
			dispatch({ type: PAGEID, pageId: pageId});
		},
		setVersion: (version) => {
			dispatch({ type: VERSION, version: version});
		},
		setProjectName: (name) => {
			dispatch({ type: PROJECTNAME, name: name});
		},
		changeCase: (name) => {
			dispatch({ type: CASE, name: name});
		},
    openPopup: value => {
			dispatch({ type: POPUP, name: value});
    }
	}
}

export default mapDispatchToProps;