import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Сrimea extends Component {
	videoRef = React.createRef();

	state = {
		preview: true,
		play: false,
	};

	componentDidMount() {
		if (this.props.pathname == "/projects/crimea/") {
			this.setState({ preview: false });
			this.props.setProjectName("Crimea");
			$("body").addClass("case-expanded hash-crimea");
			$(window).scrollTop($("#case-crimea").offset().top);
			this.props.changeCase(null);
		} else {
			this.setState({ preview: true });
			$("body").removeClass("hash-crimea");
		}
		window.scrollTo(0, 0);
		hit(window.location.pathname);

		setTimeout(() => {
			$("body").addClass("fadepage");
		}, 100);

		window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		// create the smooth scroller FIRST!
		let smoother = window.ScrollSmoother.create({
			smooth: 2, // seconds it takes to "catch up" to native scroll position
			effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
		});

		window.gsap.set(".square", { transformOrigin: "center" });
		window.gsap.set(".square1", { transformOrigin: "center" });
		window.gsap.set(".square2", { transformOrigin: "center" });
		let numOfRotations = 1.1;
		let numOfRotations1 = 1.05;
		let numOfRotations2 = 1;

		var rotate = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper1",
					scrub: 0.2,
					start: "top bottom",
					end: "bottom top",
				},
			})
			.to(".square", {
				rotation: () => 180 * numOfRotations,
				duration: 2.4,
				ease: "none",
			});

		var rotate1 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper1",
					scrub: 0.2,
					start: "top bottom",
					end: "bottom top",
				},
			})
			.to(".square1", {
				rotation: () => 180 * numOfRotations1,
				duration: 2.2,
				ease: "none",
			});

		var rotate2 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper1",
					scrub: 0.2,
					start: "top bottom",
					end: "bottom top",
				},
			})
			.to(".square2", {
				rotation: () => 180 * numOfRotations2,
				duration: 2,
				ease: "none",
			});
	}

	componentDidUpdate() {
		if (this.props.pathname == "/projects/") {
			$("body").removeClass("case-expanded hash-crimea");
		}

		if (this.state.play) {
			if (this.videoRef.current) {
				this.videoRef.current.play();

				this.videoRef.current.onended = () => {
					this.setState({ play: false });
				};
			}
		}
	}

	componentWillUnmount() {
		$("body").removeClass("fadepage");
	}

	render() {
		return (
			<div id="smooth-wrapper" className="case crimea">
				<div
					id="smooth-content"
					className="case-content "
					ref={(el) => (this.scene = el)}
				>
					<div
						className="crimea-img1"
						style={{ backgroundImage: "url('/cases/img/crimea/img1.webp')" }}
					>
						<img src="/cases/img/crimea/logo.svg" />
					</div>
					<div className="crimea-box1">
						<img
							className="shadow crimea-img2"
							src="/cases/img/crimea/img2.webp"
						/>
						<img className="crimea-img3" src="/cases/img/crimea/img3.webp" />
						<img
							className="shadow crimea-img5"
							src="/cases/img/crimea/img5.webp"
						/>
						<div className="crimea-img4 parallax-front parallax-up">
							<svg width="698" height="337" viewBox="0 0 698 337" fill="none">
								<clipPath id="logo-new1">
									<path
										id="1"
										d="M272.291 30.5205C249.127 68.8114 231.164 106.157 208.945 143.975C187.673 179.43 170.655 213.939 139.455 241.357C122.436 256.011 87.4546 273.03 58.6182 273.03C49.6364 273.03 38.2909 272.084 30.7273 267.83C11.8182 258.375 1.89091 231.902 13.2364 212.048C18.9091 202.121 25.5273 198.339 33.0909 197.866C53.8909 195.502 70.9091 230.957 33.5636 237.575C31.6727 244.193 37.3454 241.83 38.7636 242.302C52 240.884 67.1273 234.739 68.5455 218.193C69.9637 200.702 50.1091 189.83 29.7818 194.557C11.3455 198.811 0 215.357 0 233.321C0 250.339 15.1273 271.611 43.9636 275.393C81.7818 280.121 124.327 264.048 160.727 229.066C187.673 203.066 205.164 171.866 224.073 139.721C246.764 101.902 266.618 64.0841 291.2 27.6841C292.146 25.7932 276.073 23.9023 272.291 30.5205Z"
										fill="black"
									/>
								</clipPath>
								<clipPath id="logo-new2">
									<path
										id="2"
										className="stroke logo-2"
										d="M316.238 111.211C350.747 82.8475 374.384 41.2475 400.857 4.84752C405.584 -1.29794 424.493 -0.352486 418.82 8.15661C361.147 97.0294 296.856 140.993 209.875 167.938C179.62 177.393 137.547 183.066 120.056 149.029C106.82 123.502 116.275 97.5021 141.802 88.993C172.056 78.593 203.729 98.9203 219.802 128.702L209.875 131.538C205.147 121.138 196.638 105.538 187.184 97.9748C172.056 85.6839 137.547 87.1021 124.784 106.484C110.602 128.702 126.202 162.738 158.82 167.938C209.402 176.448 277.947 142.411 316.238 111.211Z"
										fill="#C4C4C4"
									/>
								</clipPath>
								<clipPath id="logo-new3">
									<path
										id="3"
										className="stroke logo-3"
										d="M267.548 243.212C239.657 209.648 226.42 170.884 209.875 131.648L209.5 130.5L219 127.5L219.802 128.812C241.548 169.466 248.166 208.702 277.475 245.575C307.257 282.921 354.53 307.975 401.802 315.539C415.781 317.713 448.336 318.663 470.359 308.596L475.548 313.175C400.857 340.593 315.766 300.412 267.548 243.212Z"
										fill="#C4C4C4"
									/>
								</clipPath>
								<clipPath id="logo-new4">
									<path
										id="4"
										className="stroke logo-4"
										d="M500.13 262.011C512.421 278.084 507.694 301.248 475.548 313.066L474.5 313.5L469.618 308.819C481.478 303.584 490.438 295.251 492.094 282.339C496.348 251.139 425.912 240.739 391.875 238.848C291.657 232.702 200.421 272.411 128.566 331.03C123.366 335.284 105.402 336.702 116.275 328.193C187.657 269.575 276.057 237.43 369.657 233.648C400.384 232.23 478.857 234.593 500.13 262.011Z"
										fill="#C4C4C4"
									/>
								</clipPath>
								<clipPath id="logo-new5">
									<path
										id="5"
										className="stroke logo-5"
										d="M389.222 108.484C371.731 146.775 352.349 176.084 333.44 209.175C309.331 249.829 285.695 288.593 254.495 329.247C247.877 337.756 263.477 339.647 267.731 332.084C311.222 258.338 353.768 184.593 397.731 111.32C403.404 101.393 393.477 100.447 389.222 108.484Z"
										fill="black"
									/>
								</clipPath>
								<clipPath id="logo-new6">
									<path
										id="6"
										className="stroke logo-6"
										d="M423.736 167.467C423.736 173.613 420.899 178.813 416.645 183.067L403.923 199.14C400.717 200.822 397.543 202.273 394.427 203.394C376.936 210.013 354.717 213.322 340.063 204.813C339.59 204.813 338.172 206.231 338.172 206.231L342.427 199.14C342.899 199.14 342.899 198.667 342.899 198.667C343.372 198.194 343.372 197.249 343.845 196.776C343.845 196.304 344.317 195.358 344.317 194.885C345.263 194.885 345.736 195.358 346.208 195.831C364.172 212.849 387.336 202.449 398.208 189.685C407.19 178.34 411.917 160.376 399.627 154.231C377.881 143.358 337.699 156.594 316.427 164.631C306.972 167.94 295.154 168.885 311.699 162.267C334.39 153.758 352.354 146.667 376.936 146.667C392.063 146.667 423.736 145.722 423.736 167.467Z"
										fill="#C4C4C4"
									/>
								</clipPath>
								<clipPath id="logo-new7">
									<path
										id="7"
										className="stroke logo-7"
										d="M543.335 185.067C557.044 187.904 550.899 208.231 577.372 205.395C585.881 204.449 583.044 209.177 576.426 209.177C566.026 209.649 560.353 208.704 552.79 201.14C545.226 193.104 546.172 186.958 533.408 189.795C528.681 190.74 523.481 194.522 518.753 196.413C504.572 203.031 483.772 209.649 479.99 188.849C478.099 178.922 487.081 159.067 463.917 163.795C447.209 167.136 424.225 188.933 402.795 199.832L416.644 183.177C426.099 176.086 437.917 166.158 447.372 161.431C458.717 155.758 480.463 150.558 489.917 163.322C497.481 173.249 478.572 196.413 502.681 195.467C510.717 195.467 528.208 181.758 543.335 185.067Z"
										fill="#C4C4C4"
									/>
								</clipPath>
								<clipPath id="logo-new8">
									<path
										id="8"
										className="stroke logo-8"
										d="M517.901 171.603C534.828 163.882 553.378 161.915 569.416 173.082C574.904 177.126 578.01 181.532 584.487 184.676C592.809 188.854 602.231 189.771 611.158 191.138C638.907 194.81 664.584 192.233 691.789 187.362C697.994 186.234 697.227 182.412 691.022 183.54C664.29 188.433 637.218 190.472 610.008 185.405C589.815 182.088 585.221 169.094 569.027 161.232C551.911 152.855 527.362 161.637 511.873 168.953C505.579 171.97 515.023 172.414 517.901 171.603Z"
										fill="black"
									/>
								</clipPath>
								<path
									clipPath="url(#logo-new5)"
									id="5"
									className="preloader-path"
									d="M389.222 108.484C371.731 146.775 352.349 176.084 333.44 209.175C309.331 249.829 285.695 288.593 254.495 329.247C247.877 337.756 263.477 339.647 267.731 332.084C311.222 258.338 353.768 184.593 397.731 111.32C403.404 101.393 393.477 100.447 389.222 108.484Z"
									fill="black"
								/>
								<path
									clipPath="url(#logo-new7)"
									id="7"
									className="preloader-path"
									d="M543.335 185.067C557.044 187.904 550.899 208.231 577.372 205.395C585.881 204.449 583.044 209.177 576.426 209.177C566.026 209.649 560.353 208.704 552.79 201.14C545.226 193.104 546.172 186.958 533.408 189.795C528.681 190.74 523.481 194.522 518.753 196.413C504.572 203.031 483.772 209.649 479.99 188.849C478.099 178.922 487.081 159.067 463.917 163.795C447.209 167.136 424.225 188.933 402.795 199.832L416.644 183.177C426.099 176.086 437.917 166.158 447.372 161.431C458.717 155.758 480.463 150.558 489.917 163.322C497.481 173.249 478.572 196.413 502.681 195.467C510.717 195.467 528.208 181.758 543.335 185.067Z"
									fill="#C4C4C4"
								/>
								<path
									clipPath="url(#logo-new1)"
									id="1"
									className="preloader-path"
									d="M272.291 30.5205C249.127 68.8114 231.164 106.157 208.945 143.975C187.673 179.43 170.655 213.939 139.455 241.357C122.436 256.011 87.4546 273.03 58.6182 273.03C49.6364 273.03 38.2909 272.084 30.7273 267.83C11.8182 258.375 1.89091 231.902 13.2364 212.048C18.9091 202.121 25.5273 198.339 33.0909 197.866C53.8909 195.502 70.9091 230.957 33.5636 237.575C31.6727 244.193 37.3454 241.83 38.7636 242.302C52 240.884 67.1273 234.739 68.5455 218.193C69.9637 200.702 50.1091 189.83 29.7818 194.557C11.3455 198.811 0 215.357 0 233.321C0 250.339 15.1273 271.611 43.9636 275.393C81.7818 280.121 124.327 264.048 160.727 229.066C187.673 203.066 205.164 171.866 224.073 139.721C246.764 101.902 266.618 64.0841 291.2 27.6841C292.146 25.7932 276.073 23.9023 272.291 30.5205Z"
									fill="black"
								/>
								<path
									clipPath="url(#logo-new8)"
									id="8"
									className="preloader-path"
									d="M517.901 171.603C534.828 163.882 553.378 161.915 569.416 173.082C574.904 177.126 578.01 181.532 584.487 184.676C592.809 188.854 602.231 189.771 611.158 191.138C638.907 194.81 664.584 192.233 691.789 187.362C697.994 186.234 697.227 182.412 691.022 183.54C664.29 188.433 637.218 190.472 610.008 185.405C589.815 182.088 585.221 169.094 569.027 161.232C551.911 152.855 527.362 161.637 511.873 168.953C505.579 171.97 515.023 172.414 517.901 171.603Z"
									fill="black"
								/>
								<path
									clipPath="url(#logo-new4)"
									id="4"
									className="preloader-path"
									d="M500.13 262.011C512.421 278.084 507.694 301.248 475.548 313.066L474.5 313.5L469.618 308.819C481.478 303.584 490.438 295.251 492.094 282.339C496.348 251.139 425.912 240.739 391.875 238.848C291.657 232.702 200.421 272.411 128.566 331.03C123.366 335.284 105.402 336.702 116.275 328.193C187.657 269.575 276.057 237.43 369.657 233.648C400.384 232.23 478.857 234.593 500.13 262.011Z"
									fill="#C4C4C4"
								/>
								<path
									clipPath="url(#logo-new6)"
									id="6"
									className="preloader-path"
									d="M423.736 167.467C423.736 173.613 420.899 178.813 416.645 183.067L403.923 199.14C400.717 200.822 397.543 202.273 394.427 203.394C376.936 210.013 354.717 213.322 340.063 204.813C339.59 204.813 338.172 206.231 338.172 206.231L342.427 199.14C342.899 199.14 342.899 198.667 342.899 198.667C343.372 198.194 343.372 197.249 343.845 196.776C343.845 196.304 344.317 195.358 344.317 194.885C345.263 194.885 345.736 195.358 346.208 195.831C364.172 212.849 387.336 202.449 398.208 189.685C407.19 178.34 411.917 160.376 399.627 154.231C377.881 143.358 337.699 156.594 316.427 164.631C306.972 167.94 295.154 168.885 311.699 162.267C334.39 153.758 352.354 146.667 376.936 146.667C392.063 146.667 423.736 145.722 423.736 167.467Z"
									fill="#C4C4C4"
								/>
								<path
									clipPath="url(#logo-new2)"
									id="2"
									className="preloader-path"
									d="M316.238 111.211C350.747 82.8475 374.384 41.2475 400.857 4.84752C405.584 -1.29794 424.493 -0.352486 418.82 8.15661C361.147 97.0294 296.856 140.993 209.875 167.938C179.62 177.393 137.547 183.066 120.056 149.029C106.82 123.502 116.275 97.5021 141.802 88.993C172.056 78.593 203.729 98.9203 219.802 128.702L209.875 131.538C205.147 121.138 196.638 105.538 187.184 97.9748C172.056 85.6839 137.547 87.1021 124.784 106.484C110.602 128.702 126.202 162.738 158.82 167.938C209.402 176.448 277.947 142.411 316.238 111.211Z"
									fill="#C4C4C4"
								/>
								<path
									clipPath="url(#logo-new3)"
									id="3"
									className="preloader-path"
									d="M267.548 243.212C239.657 209.648 226.42 170.884 209.875 131.648L209.5 130.5L219 127.5L219.802 128.812C241.548 169.466 248.166 208.702 277.475 245.575C307.257 282.921 354.53 307.975 401.802 315.539C415.781 317.713 448.336 318.663 470.359 308.596L475.548 313.175C400.857 340.593 315.766 300.412 267.548 243.212Z"
									fill="#C4C4C4"
								/>

								<path
									clipPath="url(#logo-new8)"
									className="stroke"
									id="stroke8"
									d="M511.5 176L523.5 170.5L532 168L543 165.5H553.5L564 168L574.5 174.5L582.5 181.5L594.5 188L607.5 191.5L621.5 193.5L634 194.5H645.5L658.5 193.5L669 192.5L678.5 191.5L689 190L696 188.5"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new7)"
									className="stroke"
									id="stroke7"
									d="M401 202L419.5 189L425 185L431 180.5L441.5 173L452.5 167.5L462.5 163.5H474.5L482 166.5L485.5 173V181.5V192L489.5 199L498.5 203L513 199.5L520 196L526.5 193L532 191.5L535.5 191H539.5L542.5 191.5L545.5 193L547.5 195.5L551 199L553.5 202.5L556.5 205.5L560.5 208L564 210L568.5 211L573.5 211.5L580 211L581.5 210.5"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new6)"
									className="stroke"
									id="stroke6"
									d="M305 170.5L316 166.5L330 161.5L343 157.5L356.5 154.5L367 153L378.5 152.5H387.5L399.5 154L408 157.5L412.5 162.5L415 170.5L411.5 181L408 187L403 195L395 201.5L386 206.5L375.5 210L362 211L351.5 208.5L346 206.5L343 204.5"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new5)"
									className="stroke"
									id="stroke5"
									d="M398.5 105.5L386 128L376 146L363.5 168L350.5 189L336.5 213L325 232L311.5 256L300 273.5L289.5 292L277.5 309.5L267.5 326L256 340.5"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new4)"
									className="stroke"
									id="stroke4"
									d="M471.5 315L479 311.5L489 304.5L495 296L499.5 286L498 276L490.5 265L482.5 259L470 253L458.5 249.5L444 246L429.5 243.5L411.5 241L390 240H367.5L345 241.5L325.5 243.5L306.5 246.5L294.5 249L281.5 252.5L268.5 255.5L247.5 262.5L228 270L210.5 277.5L181.5 292.5L160.5 305.5L147.5 314L130.5 326L126.5 329.5L114 338"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new3)"
									className="stroke"
									id="stroke3"
									d="M214 133L218 141L222 149L227.5 161.5L232.5 174.5L237.5 187L245.5 205L252.5 219L259.5 230.5L270 246L281.5 258.5L287 264L298 274C298 274 303.79 278.876 307.5 282L310 284L316 288L324 293L334.5 298.5L340 302L348.5 306L360.5 311L372.5 315L385.5 318.5L400.5 321.5L412 323L422 323.5H432L439 323L449.5 321.5L458 319.5L464 318L474 314.5"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new2)"
									className="stroke"
									id="stroke2"
									d="M419 0.5L395.5 33.5L375.5 58L358 79L339.5 98L323 113.5L313 121L303.5 128L294 134L282.5 140.5L271.5 146.5L263 150.5L254 154.5L247 157.5L242.5 159.5L237.5 161.5L232.5 163L219.5 167.5L207.5 171L191.5 174.5L177 176L154 174.5L138.5 167.5L128 159L120.5 147.5L117 135L117.5 123L120.5 113L127.5 103.5L136 98L145.5 94L154.5 92.5L165 92L175 94L184.5 98.5L194 105.5L203.5 116L210 126L213 131L211 134L216.5 131.5"
									stroke="black"
								/>
								<path
									clipPath="url(#logo-new1)"
									className="stroke"
									id="stroke1"
									d="M286 26.5L265.5 60.0001L252 84.5L237 109L223.5 132.5L208.5 159L194.5 184L181.5 202.5L163.5 226L151.5 238L140.5 247L130.5 254.5L114 264L100.5 270L84.5 275L68 278L59.5 278.5H48L38 276.5L28 272.5L17 265L9.5 255.5L6 246L4.5 237L5 227L8.5 217.5L13.5 210L19 205.5L26 201.5L34 200L46 201L55.5 207L61 215L62 223.5L58.5 232.5L52.5 237.5L45 241L33 244.5"
									stroke="black"
								/>
							</svg>
						</div>

						<div className="text bubble left parallax-front parallax-up">
							<div className="in">
								Создавая сайт мы стремились к максимальному эффекту присутствия
								и постарались наиболее полно передать атмосферу курорта
							</div>
						</div>
					</div>
					<div className="crimea-img6-cover">
						<div
							data-speed="0.8"
							className="crimea-img6"
							style={{ backgroundImage: "url('/cases/img/crimea/img6.webp')" }}
						></div>
					</div>
					<div className="crimea-box2">
						<img
							className="shadow crimea-img7"
							src="/cases/img/crimea/img7.webp"
						/>
						<div className="crimea-img8 wrapper wrapper1">
							<svg 
								className="square"
								width="324" 
								height="324" 
								viewBox="0 0 324 324" 
								fill="none" 
								xmlns="http://www.w3.org/2000/svg"
							>
								<path 
									d="M239.487 47.3198C293.109 47.3198 326.624 105.368 299.812 151.806L222.143 286.333C195.332 332.772 128.304 332.772 101.492 286.333L23.8235 151.807C-2.98781 105.368 30.5263 47.3198 84.1489 47.3198L239.487 47.3198Z" 
									stroke="#465052" 
									stroke-width="1.74144"
								/>
							</svg>
							<svg 
								className="square1"
								width="324" 
								height="324" 
								viewBox="0 0 324 324" 
								fill="none" 
								xmlns="http://www.w3.org/2000/svg"
							>
								<path 
									d="M286.321 101.5C332.76 128.311 332.76 195.339 286.321 222.151L151.794 299.82C105.356 326.631 47.3077 293.117 47.3077 239.494L47.3077 84.1562C47.3077 30.5336 105.356 -2.9805 151.794 23.8308L286.321 101.5Z" 
									stroke="#465052" 
									stroke-opacity="0.3" 
									stroke-width="1.74144"
								/>
							</svg>
							<svg 
								className="square2"
								width="324" 
								height="324" 
								viewBox="0 0 324 324" 
								fill="none" 
								xmlns="http://www.w3.org/2000/svg"
							>
								<path 
									d="M135.776 25.9361C173.693 -11.9808 238.437 5.36738 252.315 57.1628L292.52 207.208C306.398 259.003 259.002 306.399 207.207 292.521L57.1618 252.316C5.36638 238.438 -11.9818 173.694 25.9351 135.777L135.776 25.9361Z" 
									stroke="#465052" 
									stroke-opacity="0.1" 
									stroke-width="1.74144"
								/>
							</svg>

							{/* <svg
								className="square"
								width="298"
								height="298"
								viewBox="0 0 298 277"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M226.486 1.31981C280.108 1.31981 313.623 59.368 286.811 105.806L209.142 240.333C182.331 286.772 115.303 286.772 88.4915 240.333L10.8225 105.807C-15.9888 59.368 17.5253 1.3198 71.1479 1.3198L226.486 1.31981Z"
									stroke="#465052"
									stroke-width="1.74144"
								/>
							</svg>
							<svg
								className="square1"
								width="298"
								height="298"
								viewBox="0 0 277 298"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M240.322 88.4998C286.76 115.311 286.76 182.339 240.322 209.151L105.795 286.82C59.3568 313.631 1.3086 280.117 1.3086 226.494L1.3086 71.1562C1.3086 17.5336 59.3568 -15.9805 105.795 10.8308L240.322 88.4998Z"
									stroke="#465052"
									stroke-opacity="0.3"
									stroke-width="1.74144"
								/>
							</svg>
							<svg
								className="square2"
								width="292"
								height="292"
								viewBox="0 0 292 292"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M131.777 21.9361C169.693 -15.9808 234.438 1.36738 248.316 53.1628L288.521 203.208C302.399 255.003 255.003 302.399 203.208 288.521L53.1628 248.316C1.36734 234.438 -15.9809 169.694 21.936 131.777L131.777 21.9361Z"
									stroke="#465052"
									stroke-opacity="0.1"
									stroke-width="1.74144"
								/>
							</svg> */}
						</div>
						<img className="crimea-img8-1 shadow" src="/cases/img/crimea/img8-1.webp" />
						<img className="crimea-img8-2" src="/cases/img/crimea/img8-2.svg" />
						<img className="crimea-img9 shadow" src="/cases/img/crimea/img9.webp" />

						<div className="text bubble right-top  parallax-front parallax-up">
							<div className="in">
								В процессе редизайна сайта мы сделали рестайлинг логотипа. Знак
								стал уверенным и более изящным, в дескрипторе значительно
								улучшена читаемость.
							</div>
						</div>
					</div>
					<div className="crimea-img6-cover2">
						<div
							data-speed="0.8"
							className="crimea-img10"
							style={{ backgroundImage: "url('/cases/img/crimea/img10.webp')" }}
						></div>
					</div>
					<div className="crimea-video-wrap">
						<div className="crimea-video">
							<video
								playsInline
								ref={this.videoRef}
								poster="/cases/img/crimea/poster.webp"
								muted
							>
								<source src="/cases/img/crimea/video1.mp4" type="video/mp4" />
							</video>
							{!this.state.play && (
								<img
									className="crimea-video-button"
									src="/cases/img/button.svg"
									onClick={() => this.setState({ play: true })}
								/>
							)}
							<img src="/cases/img/mac2.webp" />
						</div>
					</div>
					<div className="crimea-imgs">
						<div>
							<div className="text bubble right parallax-front parallax-up">
								<div className="in">
									Так выглядит сайт в смартфоне. Адаптация происходит под любое
									устройство
								</div>
							</div>
							<img src="/cases/img/crimea/img11.webp" />
						</div>
						<div className="crimea-midl-inm">
							<img src="/cases/img/crimea/img12.webp" />
							<img src="/cases/img/crimea/img12_1.webp" />
						</div>
						<div>
							<img src="/cases/img/crimea/img13.webp" />
						</div>
					</div>
					<CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/dellin/"  }
				title= { this.props.next ? this.props.next.name: "Деловые Линии"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/dellin.webp"}
			/>
				</div>


				
   

				<HelmetSite title={`Сrimea-residence`} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Сrimea);
