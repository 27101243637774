import React, { useEffect, useState } from 'react';
import $ from 'jquery'

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel, Navigation, Lazy } from "swiper";
// import "../../../node_modules/swiper/swiper-bundle.min.css";
// import "../../../node_modules/swiper/modules/free-mode/free-mode.min.css";
// import "../../../node_modules/swiper/modules/scrollbar/scrollbar.min.css";
// import "../../../node_modules/swiper/modules/pagination/pagination.min.css";

// import '../../../node_modules/swiper/css';
// import '../../../node_modules/swiper/css/free-mode';
// import '../../../node_modules/swiper/css/mousewheel';
// import '../../../node_modules/swiper/css/navigation';

const Gallery = ({post, active, hide}) => {

	if (!post) return false;

  // useEffect(() => {
	// },[]);

	function createList() {
		return post.images.map(image => {
			return {
				url: image,
				loaded: false
			}
		})
	}

  return(
    <>
      <div className={`gallery-popup forms-modal ${active ? 'active' : ''}`}>
				<div className="gallery-popup__close" onClick={() => hide()}></div>
					<div className="gallery-popup__in">
						<Swiper
							direction={"horizontal"}
							slidesPerView={"auto"}
							freeMode={true}
							navigation={true}
							mousewheel={true}
							modules={[FreeMode, Navigation, Mousewheel]}
							className="gallery-swiper"
						>
							{createList().map((image, index) => (
								<SwiperSlide 
									className={`gallery-slide ${image.loaded ? 'loaded' : ''}`}
									key={`slide${index}`}
								>
									<img 
										src={image.url} alt="" 
										loading="lazy"
										onLoad={() => {
											image.loaded = true;
										}}
										onError={() => {
											image.loaded = false;
										}}
									/>
									{/* <img 
										className="post-item__image-small" 
										src={post.images_small[index]} 
										alt="" 
									/>
									<img 
										className="post-item__image lazy"
										data-src={image} 
										alt="" 
									/> */}
									<div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<div className="gallery-popup__title" dangerouslySetInnerHTML={{ __html: post.title }} />
			</div>
    </>
  )
}

export default Gallery;