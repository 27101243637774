import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './promote.css'

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import PromoteDesctope from './PromoteDesctope'
import PromoteMobile from './PromoteMobile'

class Promote extends Component{

  state = {
    mobile: window.innerWidth <= 1000
  }

  resize = () => this.setState({mobile: window.innerWidth <= 1000})

	componentDidMount(){
    window.addEventListener('resize', this.resize);
	}

	componentWillUnmount(){
    window.removeEventListener('resize', this.resize);
	}

	render(){
    if(this.state.mobile) return <PromoteMobile />

		return <PromoteDesctope />
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Promote)