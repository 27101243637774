
import { Cursors } from "./cursors";
const isTouchDevices = ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
export class Cursor2 extends Cursors{

  constructor(index) {
    super(index);
    this.speed = !isTouchDevices ? 0.02 : 1;
    this.delta = !isTouchDevices ? 0.5 : 0.04;
    this.init();
    this.loop();
  }

  setParamsParticles() {
    this.nbrParticles =  !isTouchDevices ? 100 : 100;
    this.radiusStart = 57;
    this.radiusDiff = 0;
    this.sorting = "desc";
    this.idGradient = "gradient";
    this.fillParticles = `url('#${this.idGradient}')`;
    this.gradientParticles = {
      color1: "#29fb15",
      color2: "#dce904",
    };
  }

  drawGradient() {
    return `<defs>
      <linearGradient id=${this.idGradient}>
        <stop offset="0%"  stop-color="${this.gradientParticles.color1}" />
        <stop offset="100%" stop-color="${this.gradientParticles.color2}" />
      </linearGradient>
    </defs>`
  }
}
