import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Efimovbor extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname === '/projects/efimovbor/'){
			this.setState({preview: false});
			this.props.setProjectName('Ефимов Бор');
			$('body').addClass('case-expanded hash-efimovbor');
			$(window).scrollTop($('#case-efimovbor').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-efimovbor');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname === '/projects/'){
			$('body').removeClass('case-expanded hash-efimovbor');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg0.webp')`}}></div></div>
          <div className="image image2">
            <div style={{backgroundImage: `url('/cases/img/efimovbor/bg2.webp')`}}></div>
            <div style={{backgroundImage: `url('/cases/img/efimovbor/bg3.webp')`}}></div>
          </div>
          <div className="content narrow">
            <div className="text middle text1">
              <div className="in">
                <div className="title">Лесной Курорт<br/>“Ефимов Бор”</div>
                <p>Самое курортное и самое лесное место для друзей</p>
              </div>
            </div>
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-1.webp')`}}></div></div>
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-2.webp')`}}></div></div>
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-3.webp')`}}></div></div>
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-4.webp')`}}></div></div>
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-5.webp')`}}></div></div>
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-6.webp')`}}></div></div>
                <div className="item" data-image=""><div style={{backgroundImage: `url('/cases/img/efimovbor/img1-7.webp')`}}></div></div>
              </div>
            </div>
            <div className="text middle text2">
              <div className="in">
                таблички
                <br/>брелоки
                <br/>указатели
                <br/>инструкции
                <br/>буклеты
                <br/>персонажи
                <span>+сайт</span>
              </div>
            </div>
            <div className="text bubble left text3 parallax-front">
              <div className="in">
                <b>Комплексный проект:</b>
                <br/>от создания полноценного фирменного стиля до разработки корпоративного сайта курорта. Проект, который научил нас отдыхать.
                <br/>Сначала мы съездили в Ефимов Бор, чтобы провести предпроектное исследование...
                <br/>А именно посмотреть территорию, искупаться, половить рыбу, набрать грибов, покормить белок и вкусно поесть.
                <br/>И под впечатлением мы приступили к работе.
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/efimovbor/bg4.webp')`}}></div>
            <div style={{backgroundImage: `url('/cases/img/efimovbor/bg4.webp')`}}></div>
          </div>
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg5.webp')`}}></div></div>
          <div className="image image2"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg5-2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble right2 text1 parallax-front">
              <div className="in">
                <b>Фирменный стиль.</b>
                <br/>Брелоки, таблички, буклеты, инструкции, меню для кафе, указатели для местности, приглашения и памятки, футболки и платки, схемы развлечений – всё, что есть на курорте, теперь оформлено в едином стиле и бренде.
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg6.webp')`}}></div></div>
          <div className="image image2"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg6-2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble right-top text1 parallax-front">
              <div className="in">
                <b>Кто такой Ефим?</b>
                <br/>Это главный персонаж курорта, дизайнеры ни минуты не сомневались, что им должен стать медведь. Для Ефима мы нарисовали лесных друзей: зайцев, лисиц и птиц.
                <br/>Всё это (включая Ефима с друзьями) мы оформили в брендбук.
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="content narrow">
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg8.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg9.webp')`}}></div></div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/efimovbor/img10.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/efimovbor/img11.webp')`}}></div></div>
              <div className="text bubble right2 text1 parallax-front">
                <div className="in">
                  <b>Корпоративный сайт</b>
                  <br/>Сайт мы разделили на 2 условные части: имиджевую, чтобы передать настроение и атмосферу на курорте, и функциональную, чтобы забронировать номер.
                </div>
              </div>
            </div>
            <div className="slider parallax-slider">
              <div className="line">
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-1.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-2.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-3.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-4.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-5.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-6.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/efimovbor/img7-7.webp')`}}></div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="image image1"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg13.webp')`}}></div></div>
          <div className="image image2"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg14.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble right-bottom text1 parallax-front">
                <div className="in">
                  Ефим счастлив, ведь сайт адаптируется ко всем устройствам: монитору компьютера, планшету и смартфону.
                </div>
              </div>
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/efimovbor/img15.webp')`}}></div></div>
              <div className="screenshot-mob screenshot2"><div style={{backgroundImage: `url('/cases/img/efimovbor/img16.webp')`}}></div></div>
              <div className="screenshot-mob screenshot3"><div style={{backgroundImage: `url('/cases/img/efimovbor/img17.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/efimovbor/img12.webp')`}}></div>
          </div>
          <div className="content narrow">
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/efimovbor/bg18.webp')`}}></div></div>
              <div className="text bubble left-bottom text1 parallax-front">
                <div className="in">
                  Медведь счастлив вдвойне – у него в iPhone стоит приложение «Ефимов бор», а в нём игра про него самого «Найди Ефима». А также вся информация, галерея, афиша и возможность сделать бронь!
                </div>
              </div>
              <div className="screenshot-mob screenshot1"><div style={{backgroundImage: `url('/cases/img/efimovbor/img19.webp')`}}></div></div>
              <div className="screenshot-phone screenshot2"><div style={{backgroundImage: `url('/cases/img/efimovbor/img20.webp')`}}></div></div>
              <div className="screenshot-phone screenshot3"><div style={{backgroundImage: `url('/cases/img/efimovbor/img21.webp')`}}></div></div>
              <div className="text bubble right-bottom text2 parallax-front">
                <div className="in">
                  После запуска проекта мы поехали в Ефимов бор и проверили, всё ли у нас получилось. И да, мы это сделали! Шашлык...
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-efimovbor">
				<Link to="/projects/efimovbor/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/efimovbor/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/efimovbor/logo.webp')`}}></div></div>
					</div>
				</Link>
				{this.more()}
			</div>

  

   <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/academy/" }
				title= { this.props.next ? this.props.next.name: "Цифровая Академия"} 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/academy.webp"}
			/>
      <HelmetSite title="Ефимов Бор" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Ефимов Бор</div>
				<div className="tags"><span>сайт</span><span>курорт</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Efimovbor)