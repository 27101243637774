import React from "react";
import { Helmet } from "react-helmet";
import ym from "react-yandex-metrika";
import { Link } from "react-router-dom";

const HomeTab3 = ({children, active, active2, active3}) => {
  return(
    <div className="projectlist-new-anim">
      <div className="projectlist projects-loading">
        <Helmet>
          <title>Проекты ' Amio</title>
        </Helmet>

        <div
          className={
            active.includes(0)
            ? "projectlist__line scroll-line active"
            : active2.includes(0)
            ? "projectlist__line scroll-line active2"
            : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line">
              <div>
                <Link to="/projects/scandipakk/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="50">
                    <div className="projectlist__text">Полный комплекс работ по ребрендингу всемирно известной компании по производству одноразовой упаковки для продуктов питания.</div>
                  </div>
                  <div className="projectlist__title">Скандипакк</div>
                </Link>
              </div>
              <div className="scroll-item_100">
                <Link to="/projects/x5values/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="26">
                    <div className="projectlist__text">
                      Визуализация корпоративных ценностей для лидера российской
                      розницы
                    </div>
                  </div>
                  <div className="projectlist__title">
                    Корпоративные ценности Х5
                  </div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link
                  to="/projects/sistemavc/"
                  className="projectlist__item f2"
                >
                  <div className="projectlist__bg" data-id="6">
                    <div className="projectlist__text">
                      Разработка фирменного стиля для венчурного фонда АФК
                      «СИСТЕМА»
                    </div>
                  </div>
                  <div className="projectlist__title">Systema VC</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/self" className="projectlist__item">
                  <div className="projectlist__bg" data-id="41">
                    <div className="projectlist__text">
                      Полный комплекс работ по созданию бренда для жилого комплекса
                    </div>
                  </div>
                  <div className="projectlist__title">SELF Парковый квартал</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="scroll-item_50">
            <Link to="/projects/vsn" className="projectlist__item big">
              <div className="projectlist__bg" data-id="40">
                <div className="projectlist__text">
                  Разработка бренд-стратегии, логотипа, фирменного стиля для
                  агентства VSN Realty
                </div>
              </div>
              <div className="projectlist__title">VSN Realty</div>
            </Link>
          </div>
        </div>

        <div
          className={
            active.includes(1)
              ? "projectlist__line scroll-line active"
              : active2.includes(1)
              ? "projectlist__line scroll-line active2"
              : "projectlist__line scroll-line"
          }
        >
          <div className="scroll-item_50">
            <Link to="/projects/academy/" className="projectlist__item big">
              <div className="projectlist__bg" data-id="29">
                <div className="projectlist__text">
                  Создание фирменного стиля
                </div>
              </div>
              <div className="projectlist__title">Х5. Цифровая академия</div>
            </Link>
          </div>
          <div>
            <div className="projectlist__line">
              <div>
                <Link
                  to="/projects/efimovbor/"
                  className="projectlist__item f5"
                >
                  <div className="projectlist__bg" data-id="7">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                      Комплексный проект по созданию и продвижению бренда отеля
                    </div>
                  </div>
                  <div className="projectlist__title">Ефимов Бор</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link
                  to="/projects/hunter-estate/"
                  className="projectlist__item f2"
                >
                  <div className="projectlist__bg" data-id="30">
                    <div className="projectlist__bg-first" />
                    <div className="projectlist__bg-last" />
                    <div className="projectlist__text">
                      Фирменный стиль и сайт
                    </div>
                  </div>
                  <div className="projectlist__title">Усадьба охотника</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_100">
                <Link to="/projects/ikforum/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="3">
                    <div className="projectlist__text">
                      Фирменный стиль и корпоративный сайт для инвестиционной
                      компании
                    </div>
                  </div>
                  <div className="projectlist__title">ИК “Форум”</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/fabcab/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="17">
                    <div className="projectlist__text">
                      Логотип и продвижение лучшего сервиса такси во всей
                      юго-восточной Азии
                    </div>
                  </div>
                  <div className="projectlist__title">FabCab</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            active.includes(2)
            ? "projectlist__line scroll-line active"
            : active2.includes(2)
            ? "projectlist__line scroll-line active2"
            : "projectlist__line scroll-line"
          }
        >
          <div>
            <div className="projectlist__line">
              <div className="scroll-item_200">
                <Link to="/projects/profit/" className="projectlist__item">
                  <div className="projectlist__bg" data-id="53">
                    <div className="projectlist__text">
                      Разработка нейминга, фирменного стиля, лендинга
                    </div>
                  </div>
                  <div className="projectlist__title">Ipoteka Profit</div>
                </Link>
              </div>
              <div className="scroll-item_100">
                <Link to="/projects/detali" className="projectlist__item">
                  <div className="projectlist__bg" data-id="56">
                    <div className="projectlist__text">
                    ЖК «Детали»
                    </div>
                  </div>
                  <div className="projectlist__title">ЖК «Детали»</div>
                </Link>
              </div>
            </div>
            <div className="projectlist__line">
              <div className="scroll-item_200">
                <Link to="/projects/emotion" className="projectlist__item">
                  <div className="projectlist__bg" data-id="57">
                    <div className="projectlist__text">
                      Брендинг
                    </div>
                  </div>
                  <div className="projectlist__title">Emotion</div>
                </Link>
              </div>
              <div className="scroll-item_200">
                <Link to="/projects/plus" className="projectlist__item">
                  <div className="projectlist__bg" data-id="58">
                    <div className="projectlist__text">
                      Брендинг
                    </div>
                  </div>
                  <div className="projectlist__title">Plus</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="scroll-item_50">

          </div>
        </div>

        {children}
      </div>
    </div>
  )
}

export default HomeTab3