import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';
import data from '../../../store/projects'
class Eph extends Component{

	state = {
		preview: true,
	}



	componentDidMount(){

 
		if(this.props.pathname == '/projects/eph/'){
			this.setState({preview: false});
      
			this.props.setProjectName('Eastern Property Holdings');
			$('body').addClass('case-expanded hash-eph');
			$(window).scrollTop($('#case-eph').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-eph');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-eph');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {

    return(
      
      <Fragment>
        <div className="screen screen1">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/eph/img1.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="dots">
                <span className="dot1"></span>
                <span className="dot2"></span>
                <span className="dot3"></span>
              </div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p><b>Перед нами стояла задача по созданию нового корпоративного сайта.</b></p>
                  <p>Проект, который заставил всю команду улучшить английский...</p>
                  <p>Дизайн-концепцию мы выдержали в корпоративных спокойных цветах. Минимализм и лёгкость, асимметрия и разноплановость – ключевые моменты, которые стали основной нового проекта.</p>

                </div>
              </div>
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/eph/img2.webp')`}}></div>
            </div>
            <div className="clear">
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  <p><b>Главная страница:</b></p>
                  <p>Нарушать правила... Чтобы было интересно и разнопланово, мы намеренно сделали страницы асимметричными и неритмичными. Большая разница в размерах шрифтов – для выделения акцентов и стильной типографики.</p>
                </div>
              </div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/eph/img3.webp')`}}></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/eph/img8.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left2 text1 parallax-front">
                <div className="in">
                  Особенности европейского стиля мы выражаем в деталях. Строгие архитектурные формы, лаконичность – дизайнеры активно используют имиджи для создания деловой и комфортной атмосферы.
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/eph/img4.webp')`}}></div>
              <div className="screenshot screenshot2" style={{backgroundImage: `url('/cases/img/eph/img5.webp')`}}></div>
              <div className="screenshot screenshot3" style={{backgroundImage: `url('/cases/img/eph/img6.webp')`}}></div>
            </div>
            <div className="image image1" style={{backgroundImage: `url('/cases/img/eph/img7.webp')`}}></div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1" style={{backgroundImage: `url('/cases/img/eph/img9.webp')`}}></div>
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p><b>Портфолио холдинга.</b></p>
                  <p>Мы уделили внимание и имиджевой части, и информационной. Поэтому сначала мы погружаем пользователя в атмосферу с помощью фотографии объекта, затем кратко подаём описание и расположение на карте.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/eph/img10.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot-mob screenshot1" style={{backgroundImage: `url('/cases/img/eph/img11.webp')`}}></div>
              <div className="screenshot-phone screenshot2"><div style={{backgroundImage: `url('/cases/img/eph/img12.webp')`}}></div></div>
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  Сайт адаптируется ко всем устройствам: монитору компьютера, планшету и смартфону.
                </div>
              </div>
              <div className="screenshot-mob screenshot3" style={{backgroundImage: `url('/cases/img/eph/img13.webp')`}}></div>
              <div className="screenshot-mob screenshot4" style={{backgroundImage: `url('/cases/img/eph/img14.webp')`}}></div>
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                  50+ макетов сделали дизайнеры
                  <br/>43 дня на весь проект
                  <br/>+1 English level
                </div>
              </div>
              <div className="screenshot-mob screenshot5" style={{backgroundImage: `url('/cases/img/eph/img15.webp')`}}></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>

			<div className="case-content" id="case-eph">
				<Link to="/projects/eph/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/eph/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/eph/logo.svg')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Eastern Property Holdings</div>
								<p>Корпоративный сайт для швейцарского инвестиционного холдинга</p>
							</div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/ryadom1/" }
				title= { this.props.next ? this.props.next.name: "Рядом"} 
				img={ this.props.next? this.props.next.imgCaseBottom: "/cases/img/next/ryadom.webp"}
			/>
      <HelmetSite title="Eastern Property Holdings" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Eastern Property Holdings</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Eph)