import React, { Component } from 'react';
import { connect } from 'react-redux';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';

import {default as SliderSlick} from "react-slick";
import "slick-carousel/slick/slick.css";

import $ from 'jquery';
import {hit} from '../metrics';
import {Helmet} from 'react-helmet';

class SourcesCcp extends Component{

	componentDidMount(){
		this.props.setPageId('sources-ccp');
		$('body').addClass('page-sources hash-ccp tags-expanded');
    window.scrollTo(0, 0);
    hit(window.location.pathname);
    window.addEventListener('scroll', this.scroll);
	}

	componentWillUnmount(){
		$('body').removeClass('page-sources hash-ccp tags-expanded');
    window.removeEventListener('scroll', this.scroll);
	}

  scroll = () => {
    if($(window).scrollTop() > 0){
      $('body').addClass('fixed');
    }
    else{
      $('body').removeClass('fixed');
    }
  }

	render(){
		const settings = {
			slidesToShow: 3, 
			slidesToScroll: 3,
			dots: false,
			infinite: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: true
					}
				}
			]
		};

		return(
			<section className="intro sources sources_ccp">
        <Helmet>
          <title>CCP ' Акценты ' Amio</title>
        </Helmet>
				<div className="case-content">
					<div className="l-sect-wrap">
						<section className="c-sect">
							<div className="c-block-main js-parallax fade-in-up"><span>Система управления <br/>digital-проектами <br/>и интернет-рекламой</span></div>
							<div className="c-block-secondary">
								<div className="c-block-secondary__icon1 js-parallax fade-in-up">Простое и интуитивно-понятное решение для управления digital-проектами и интернет-рекламой</div>
								<div className="c-block-secondary__icon2 js-parallax fade-in-up">Позволяет в режиме реального времени управлять временем, бюджетами, сроками, проектами и людьми</div>
							</div>
							<span className="ccp-arrow js-scrollto"></span>
						</section>
						<section className="c-sect c-sect--idx2 js-parallax fade-in-up">
							<div className="c-slider">
								<div>
									<SliderSlick {...settings} className="c-slider__inner">
										<div><div className="c-slider__item" style={{backgroundImage: `url('/sources/img/img3.jpg')`}}></div></div>
										<div><div className="c-slider__item" style={{backgroundImage: `url('/sources/img/img.jpg')`}}></div></div>
										<div><div className="c-slider__item" style={{backgroundImage: `url('/sources/img/img2.jpg')`}}></div></div>
									</SliderSlick>
								</div>
							</div>
						</section>
						<section className="c-sect l-icons-wrap js-parallax fade-in-up">
							<div className="c-icons">
								<div>
									<div className="c-icon c-icon--ico1"><div>Контроль и учет задач, <br/> времени и бюджетов</div></div>
									<div className="c-icon c-icon--ico2"><div>Распределение<br/> и приоритезация проектов</div></div>
									<div className="c-icon c-icon--ico3"><div>Анализ эффективности<br/>сотрудников</div></div>
									<div className="c-icon c-icon--ico4"><div>Анализ эффективности операционной <br/>и финансовой деятельности агентства</div></div>
								</div>
								<div>
									<div className="c-icon c-icon--ico5"><div>Интеграция<br/> c Google Apps</div></div>
									<div className="c-icon c-icon--ico6"><div>Интеграция c Direct.Yandex; <br/>Adwords.Google.com; Api.Vk.com;<br/> Api.Facebook.com</div></div>
									<div className="c-icon c-icon--ico7"><div>Управление правами доступа<br/> для менеджеров, исполнителей,<br/> руководителей и клиентов</div></div>
									<div className="c-icon c-icon--ico8"><div>Специализированные инструменты<br/> <span>для процесса создания и демонстрации</span> <br/>web-сайтов, баннеров</div></div>
								</div>
							</div>
						</section>
						<section className="c-sect js-parallax fade-in-up mobile-remove-animation">
							<div className="c-bottom">
								<div className="c-bottom__content"> 
									Протестировано <br/> на клиентах<br/> мирового уровня 
									<div className="qr-code"></div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</section>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SourcesCcp)