import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class X5happy extends Component{

  componentDidMount(){
    if(this.props.pathname == '/projects/x5happy/'){
      this.setState({preview: false});
      this.props.setProjectName('Happy X5');
      $('body').addClass('case-expanded hash-x5happy');
      $(window).scrollTop($('#case-x5happy').offset().top);
      this.props.changeCase(null);
    }
    else{
      this.setState({preview: true});
      $('body').removeClass('hash-x5happy');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $('body').addClass('fadepage');
    }, 100);
  }

  componentDidUpdate(){
    if(this.props.pathname == '/projects/'){
      $('body').removeClass('case-expanded hash-x5happy');
    }
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
  render(){
    return(
    <div className='case'>
      <div className="case-content" id="case-x5happy">


        <div className="screen1n" />
        <div className="screen1nn">
          <div className="text bubble left parallax-front">
            <div className="in">
              Каждый праздник в Х5 действительно праздник. Каждый праздник нужно поддержать активностью. Каждую активность придумали и создали мы.
            </div>
          </div>
          <img src="/cases/img/x5happy/23feb/1.webp" className="screen2n" />
        </div>
        <div className="feb23">
          <div className="feb23__box feb23__box2">
            <img src="/cases/img/x5happy/23feb/2.webp" className="shadow" />
            <div className="text bubble right parallax-front text1">
              <div className="in">
                Увлекательный онлайн-квест позволит почуствовать себя агентом Х5 и спасти свою коллегу, а заодно и весь мир...
              </div>
            </div>
          </div>
          <div className="feb23__box feb23__box3">
            <img src="/cases/img/x5happy/23feb/3.webp" className="shadow" />
          </div>
          <div className="feb23__box feb23__box4">
            <img src="/cases/img/x5happy/23feb/4.webp" />
          </div>
        </div>

        <div className="screen1nn">
          <img src="/cases/img/x5happy/n/nbg2.webp" className="screen2n" />
        </div>
        <div className="screen3n">
          <div className="text bubble left parallax-front text1">
            <div className="in">
              Все на ЗОЖ. В Х5 проходит неделя здоровья. Чтобы показать как это полезно и интеренсно мы сделали специальный сайт с кучей полезных штук.
            </div>
          </div>
          <div className="text bubble right parallax-front text2">
            <div className="in">
              Кроме сайта мы сделали прессволл, плакаты, листовки, стикеры и многое другое
            </div>
          </div>

          <img src="/cases/img/x5happy/n/nbg3.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/n/nbg4.webp" className="img2 shadow" />
          <img src="/cases/img/x5happy/n/nbg5.webp" className="img3 shadow" />
          <img src="/cases/img/x5happy/n/nbg6.webp" className="img4" />
        </div>


        <div className="slider parallax-slider slider1">
          <div className="line">
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n1.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n2.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n3.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n4.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n5.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n6.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n7.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n8.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n9.webp')`}}></div></div>
            <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/n10.webp')`}}></div></div>
          </div>
        </div>

        <div className="screen6n">
          <img src="/cases/img/x5happy/n/nbg8.webp" className="screen5n" />
          <img src="/cases/img/x5happy/n/nbg9.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/n/nbg10.webp" className="img2 shadow" />


          <div className="sr">
            <div className="text bubble left-top parallax-front text2">
              <div className="in">
              Сделали специальный набор стикеров для Telegram
              </div>
            </div>

            <div className="slider parallax-slider slider2">
              <div className="line">
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m1.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m2.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m3.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m4.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m5.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m6.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m7.webp')`}}></div></div>
                <div className="item"><div style={{backgroundImage: `url('/cases/img/x5happy/n/m8.webp')`}}></div></div>
              </div>
            </div>
          </div>


          <img src="/cases/img/x5happy/n/nbg12.webp" className="img3 shadow" />
          <img src="/cases/img/x5happy/n/nbg11.webp" className="img4" />

          <img src="/cases/img/x5happy/n/e1.webp" className="e1" />
          <img src="/cases/img/x5happy/n/e2.webp" className="e2" />

          <div className="text bubble right parallax-front text1">
            <div className="in">
            В день IT  к сотрудникам Х5 за помощью обратился IT-бок. Нужно было помочь ему уйти от разннобразных врагов и в награду за свои знания и умения получить призы.
            </div>
          </div>


          <div className="text bubble left parallax-front text3">
            <div className="in">
            И поселили IT-бока в офисах X5
            </div>
          </div>

        </div>

        <img src="/cases/img/x5happy/n/n23.webp" className="scr1n" />


        <div className="screen screen2">
          <img src="/cases/img/x5happy/bg2-1.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/bg2-2.webp" className="img2 shadow" />
          <img src="/cases/img/x5happy/bg2-3.webp" className="img3 shadow" />
          <img src="/cases/img/x5happy/bg2-4.webp" className="img4" />

          <div className="text bubble right parallax-front text1">
            <div className="in">
            Cоздан праздничный сайт
для сотрудников компании Х5. Здесь можно пройти увлекательный тест и узнать какой на самом деле ты защитник, с возможностью похвалиться и отправить оригинальное поздравление!
            </div>
          </div>
          <div className="text bubble left parallax-front text2">
            <div className="in">
            Самому создать анимированную
            открытку для своего друга!
            Круть!
            Волшебно!
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <img src="/cases/img/x5happy/bg4-2.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/bg4-3.webp" className="img2 shadow" />
          <img src="/cases/img/x5happy/bg4-6.webp" className="img3" />
          <img src="/cases/img/x5happy/bg4-4.webp" className="img4" />
          <img src="/cases/img/x5happy/bg4-5.webp" className="img5" />
          <div className="text bubble left parallax-front text1">
            <div className="in">
            День торговли очень важный день для компании Х5. В этот день мы решили вспомнить, а как всё было в 1966 году? Именно в этом году в СССР родился праздник, который мы отлично отметили.
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <img src="/cases/img/x5happy/bg3-2.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/bg3-3.webp" className="img3 shadow" />
          <div className="text bubble right parallax-front text1">
            <img src="/cases/img/x5happy/bg3-5.webp" />
            <div className="in">
            Праздник <b>8 марта</b> для компании Х5
            не остался незамеченным, благодаря
            еще одному интерактивному тесту,
            но уже на тему женского гадания.
            Невероятные предсказания!
            Это что-то!
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <img src="/cases/img/x5happy/bg5-2.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/bg5-3.webp" className="img2 shadow" />
          <div className="text bubble left parallax-front text1">
            <div className="in">
            Ресурс посвящен Дню Победы.
            Рассказать свою историю, 
            узнать интересные факты
            об этих тяжелых временах.
            </div>
          </div>
          <div className="text bubble right parallax-front text2">
            <div className="in">
            Фронтовые истории
            родственников сотрудников компании Х5
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <img src="/cases/img/x5happy/bg6-2.webp" className="img1 shadow" />
          <img src="/cases/img/x5happy/bg6-3.webp" className="img2 shadow" />
          <img src="/cases/img/x5happy/bg6-4.webp" className="img3 shadow" />

          <div className="text bubble left parallax-front text1">
            <div className="in">
            Так сотрудникам полюбились
            веселые тесты, что решено
            было создать страницу
            посвященную юбилею 
            логистической компании Х5
            </div>
          </div>
          <div className="text bubble right parallax-front text2">
            <div className="in">
            Результат всегда уникальный! Потрясающе!
            </div>
          </div>
        </div>
      </div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/ingrad/" }
				title= { this.props.next ? this.props.next.name: "Инград"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/ingrad.webp"}
			/>
      <HelmetSite title="Happy X5" />

      <div className="case-info">
        <Link to="/projects/" className="all-title">Все проекты</Link>
        <div className="title">Happy X5</div>
        <div className="tags"><span>сайт</span><span>финансы</span></div>
      </div>
    </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(X5happy)