
import React, { useEffect } from 'react';

import { Cursor2 } from './cursor2';

const HomeNew = () => {

  useEffect(() => {
    new Cursor2()
  },[])

  return(
    <div id="cursor-2"></div>
  )
}

export default HomeNew