import React, { Component, useRef } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";
import Parallax from "parallax-js";
import simpleParallax from "simple-parallax-js";

class Kms extends Component {
  state = {
    preview: true,
  };

  handleScroll() {
    $(".animate-scroll-element").each(function () {
      const top = $(this).offset().top - window.innerHeight / 2;
      const scr = $(window).scrollTop();

      if (scr >= top) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  }

  componentDidMount() {
    /*$(".kms-block6").hover(function () {
      $(".iner1").removeClass("iner-active1");
      $(".iner2").removeClass("iner-active2");
      $(".iner3").removeClass("iner-active3");
      $(".iner4").removeClass("iner-active4");
      $(".iner6").removeClass("iner-active6");
      $(this).css('left', '16%');
    });*/

    $(window).on("mousemove", function (e) {
      var w = $(window).width();
      var h = $(window).height();
      var offsetX = 0.5 - e.pageX / w;
      var offsetY = 0.5 - e.pageY / h;


      $(".kms-block6 .iner-iner").each(function(){
        var offset = parseInt($(this).data("offset"));

        var translate =
        "translate3d(" +
        Math.round(offsetX * offset) +
        "px," +
        "0px, 0px)";

        $(this).css({
          "-webkit-transform": translate,
          transform: translate,
          "moz-transform": translate,
        });
      });

      $(".parallax").each(function (i, el) {
        var offset = parseInt($(el).data("offset"));
        var translate =
          "translate3d(" +
          Math.round(offsetX * offset) +
          "px," +
          Math.round(offsetY * offset) +
          "px, 0px)";

        $(el).css({
          "-webkit-transform": translate,
          transform: translate,
          "moz-transform": translate,
        });
      });
    });

    if (this.props.pathname == "/projects/vsn/") {
      this.setState({ preview: false });
      this.props.setProjectName("bsg");
      $("body").addClass("case-expanded hash-vsn");
      $(window).scrollTop($(".vsn").offset().top);
      this.props.changeCase(null);
    } else {
      this.setState({ preview: true });
      $("body").removeClass("vsn");
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $("body").addClass("fadepage");
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.pathname == "/projects/") {
      $("body").removeClass("case-expanded hash-vsn");
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fadepage");
    window.removeEventListener("scroll", this.handleScroll);
    if (window.parallaxInstance) {
      window.parallaxInstance.disable();
    }
  }

  render() {
    return (
      <div className="case kms">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="kms-head">
            <img className="kms-img1" src="/cases/img/km/img1n.webp"></img>
            <img className="kms-img2" src="/cases/img/km/img2.svg"></img>
          </div>
          <div className="kms-block1 ">
            <div className=" km-bubble1 text text1 bubble left parallax-front parallax-up active">
              <div className="in">
                <p>
                  Этот сайт сделан специально для двух элитных пентхаусов
                  комплекса Kuznetsky Most 12.
                </p>
                <p>
                  Сам комплекс состоит из уникальных домов с богатой и очень
                  интересной историей. В дополнению к щедрому наследию над
                  внутренним убранством радотал дом Lalique.
                </p>
              </div>
            </div>
            <img
              className="kms-img3 shadow"
              data-offset="20"
              src="/cases/img/km/img3.webp"
              dataDepth="0.2"
            ></img>

            <img
              className="kms-img4"
              src="/cases/img/km/img4.svg"
              data-offset="45"
              dataDepth="0.1"
            ></img>
          </div>
          <div className="kms-block2">
            <img
              className="kms-img5 shadow "
              data-offset="60"
              src="/cases/img/km/img5.webp"
            ></img>
            <img
              className="kms-img6 parallax "
              src="/cases/img/km/img6.webp"
              data-offset="30"
            ></img>
            <img
              className="kms-img7 parallax "
              src="/cases/img/km/img7.svg"
              data-offset="20"
            ></img>
            <img
              className="kms-img8 parallax "
              src="/cases/img/km/img8.svg"
              data-offset="40"
            ></img>
          </div>
          <div className="kms-block3">
            <img className="kms-img1" src="/cases/img/km/img9.webp"></img>
          </div>
          <div className="kms-block4">
            <img
              className="kms-img10 shadow"
              src="/cases/img/km/img10.webp"
              data-offset="60"
            ></img>
            <img
              className="kms-img11 parallax "
              src="/cases/img/km/img11.webp"
              data-offset="20"
            ></img>
            <img
              className="kms-img12 parallax"
              src="/cases/img/km/img12.svg"
              data-offset="40"
            ></img>
            <img
              className="kms-img13 parallax"
              src="/cases/img/km/img13.webp"
              data-offset="80"
            ></img>
          </div>
          <div className="kms-block5">
            <div className=" km-bubble2 text text1 bubble right parallax-front parallax-up active">
              <div className="in">
                Чтобы сделать отсылку к игре света, мы сымитировали эффект
                преломления с помощью параллакса фрагментов вазы с вакханками
              </div>
            </div>
            <img
              className="kms-img14 shadow"
              src="/cases/img/km/img14.webp"
              data-offset="20"
            ></img>
            <img
              className="kms-img15 "
              src="/cases/img/km/img15.webp"
              data-offset="50"
            ></img>
            <img
              className="kms-img16 "
              src="/cases/img/km/img16.svg"
              data-offset="40"
            ></img>
            <img
              className="kms-img17 "
              src="/cases/img/km/img17.svg"
              data-offset="30"
            ></img>
          </div>
          <div className="kms-block6">
            <div className="block6-iner"></div>
            <div className="block6-iner">
              <div className="iner-iner iner-active1 iner1" data-offset="70">
                <img className="kms-img18 " src="/cases/img/km/img23.webp"></img>
              </div>
            </div>
            <div className="block6-iner">
              <div className="iner-iner iner-active2 iner2" data-offset="50">
                <img className="kms-img18 " src="/cases/img/km/img23.webp"></img>
              </div>
            </div>
            <div className="block6-iner">
              <div className="iner-iner iner-active3 iner3" data-offset="30">
                <img className="kms-img18 " src="/cases/img/km/img23.webp"></img>
              </div>
            </div>
            <div className="block6-iner">
              <div className="iner-iner iner-active4 iner4" data-offset="100">
                <img className="kms-img18 " src="/cases/img/km/img23.webp"></img>
              </div>
            </div>
            <div className="block6-iner">
              <div className="iner-iner iner-active6 iner6" data-offset="80">
                <img className="kms-img18 " src="/cases/img/km/img23.webp"></img>
              </div>
            </div>
          </div>
          <div className="kms-block7">
          <div className=" km-bubble2 km-bubble3 text text1 bubble right parallax-front parallax-up active">
              <div className="in">
              Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство
              </div>
            </div>
            <img
              className="kms-img19 shadow"
              src="/cases/img/km/img19.webp"
              data-offset="20"
            ></img>
            <img
              className="kms-img20 shadow"
              src="/cases/img/km/img20.webp"
              data-offset="40"
            ></img>
            <img
              className="kms-img21 shadow"
              src="/cases/img/km/img21.webp"
              data-offset="20"
            ></img>
            <img
              className="kms-img22"
              src="/cases/img/km/img22.svg"
              data-offset="60"
            ></img>
          </div>
        </div>



      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/rep/"  }
				title= { this.props.next ? this.props.next.name: "Клубный поселок «Репинское»"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/rep.webp" }
			/>
        <HelmetSite title={`Клубный дом Kuznetsky Most 12`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Kms);
