import React, { useEffect, useState } from 'react';

const Alt5 = () => {

  const [active, setActive] = useState(0)

  const data = [
    "/img/alts/char1.svg",
    "/img/alts/char2.svg",
    "/img/alts/char3.svg",
    "/img/alts/char4.svg",
    "/img/alts/char5.svg"
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      if(active < data.length - 1){
        setActive(active + 1)
      }
      else{
        setActive(0)
      }
    }, 400)

    return() => {
      if(timer){
        clearInterval(timer)
      }
    }
  },[active])

  return(
    <div className="alt-char">
      <div>
        {data.map((item, index) => <img key={`item${index}`} src={item} className={index == active ? 'active' : ''} />)}
      </div>
    </div>
  )
}

export default Alt5;