import React, { useEffect, useState } from 'react';

import $ from 'jquery'

import Gallery from './Gallery';

const PostList = ({posts}) => {
	const [colorIndex, setColorIndex] = useState(Number(localStorage.getItem('currentColor')) || 0);
  const [fontIndex, setFontIndex] = useState(Number(localStorage.getItem('currentFont')) || 0);
	
	const [galleryPost, setGalleryPost] = useState(null); 
	const [showGallery, setShowGallery] = useState(false); 
	
	const [rowLimit, setRowLimit] = useState(4);
	const [viewRows, setViewRows] = useState(4); 
	const [showMore, setShowMore] = useState(true); 

	let values = {
		timeStep: 800,
		timeAnimation: 3200,
		bgColor: ['#E1E6C7', '#BCD3DA', '#F8CDB4', '#C6B2BB', '#F7B2C0'],
		titleFont: ['baza-art', 'druzhok', 'garcia-marquez', 'pilowlava', 'tt-autonomous']
	};

	function getRandom(a, b, val) {
		let min = Math.floor(+a);
		let max = Math.floor(+b);
		const newVal = Math.floor(Math.random() * (max - min)) + min;
		// console.log(val, newVal);
		if (newVal === val) {
			getRandom(a, b, newVal);
		} else {
			return newVal;
		}
	}

  useEffect(() => {
		
		let changeColor = setInterval(() => {
			setColorIndex(getRandom(0, values.bgColor.length, colorIndex));
		}, values.timeStep);
		setTimeout(() => {
			clearInterval(changeColor);
			// console.log('colorIndex', colorIndex);
			localStorage.setItem('currentColor', String(colorIndex));
		}, values.timeAnimation);

		let changeFont = setInterval(() => {
			setFontIndex(getRandom(0, values.titleFont.length, fontIndex));
		}, values.timeStep);
		setTimeout(() => {
			clearInterval(changeFont);
			// console.log('fontIndex', fontIndex);
			localStorage.setItem('currentFont', String(fontIndex));
		}, values.timeAnimation);
  },[]);

  useEffect(() => {
		loadImages();
  },postList);

	function loadImages() {
		var lazyloadImages;    

		if ("IntersectionObserver" in window) {
			lazyloadImages = $(".lazy");
			var imageObserver = new IntersectionObserver(function(entries, observer) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						var image = entry.target;
						image.src = image.dataset.src;
						image.classList.remove("lazy");
						imageObserver.unobserve(image);
					}
				});
			});

			lazyloadImages.each(function() {
				imageObserver.observe(this);
			});
		} else {  
			var lazyloadThrottleTimeout;
			lazyloadImages = $(".lazy");
			
			function lazyload () {
				if(lazyloadThrottleTimeout) {
					clearTimeout(lazyloadThrottleTimeout);
				}    

				lazyloadThrottleTimeout = setTimeout(function() {
						var scrollTop = $(window).scrollTop();
						lazyloadImages.each(function() {
								var el = $(this);
								if(el.offset().top - scrollTop < window.innerHeight) {
									var url = el.attr("data-src");
									el.attr("src", url);
									el.removeClass("lazy");
									lazyloadImages = $(".lazy");
								}
						});
						if(lazyloadImages.length == 0) { 
							$(document).off("scroll");
							$(window).off("resize");
						}
				}, 20);
			}

			$(document).on("scroll", lazyload);
			$(window).on("resize", lazyload);
		}
	};
	
	function createPosts() {
		let rows = [];
		let rowIndex = 0;
		for (let i = 0; i < posts.length; i++) {
			let rowItem = [];
			
			if (rowIndex === 0 || rowIndex % 4 === 0) {
				rowItem.push(posts[i]);
			} else {
				rowItem.push(posts[i]);
				i++;
				if (posts[i]) rowItem.push(posts[i]);
			}

			rows.push(rowItem);
			rowIndex++;
		}
		// console.log('rows', rows);
		return rows;
	};
	
	const allPost = createPosts();

	function createViewPosts() {
		return allPost.slice(0, viewRows);
	};

	const postList = createViewPosts();

	function showGalery(id) {
		setGalleryPost(posts.filter(p => p.id === id)[0]);
		setTimeout(() => setShowGallery(true), 300);
	};
	function hideGalery() {
		setGalleryPost(null);
		setTimeout(() => setShowGallery(false), 300);
	};


  return(
    <>
      <div className="notformat" style={{backgroundColor: values.bgColor[colorIndex]}}>
				<div className={`notformat-title ${values.titleFont[fontIndex]}`} >
					<span>не</span>
					<div>формат</div>
				</div>
				<div className="notformat-content">
					<div className="post-list">

					{postList.map((row, rowIndex) => (
						<div className="post-row" key={`row${rowIndex}`}>

							{rowIndex  === 0 && <div className="post-item__text">
								Здесь всё, что не&nbsp;вписывается 
								в&nbsp;другие&nbsp;разделы, но очень 
								важно&nbsp;для&nbsp;нас
							</div>}

							{row.map((post, postIndex) => (
								<div 
									className="post-item" 
									key={`post${postIndex}`} 
									data-id={post.id} 
									onClick={() => showGalery(post.id)}
								>
									<img 
										className="post-item__image-small" 
										src={post.preview_small || post.images_small[0]} 
										alt="" 
									/>
									<img 
										className="post-item__image lazy"
										data-src={post.preview || post.images[0]} 
										alt="" 
									/>
									<div className="post-item__in">
										<div 
											className="post-item__title" 
											dangerouslySetInnerHTML={{ __html: post.title }} 
										/>

										{post.images.length > 1 
											&& <div className="post-item__button"> еще {post.images.length - 1} фото </div>
										}
									</div>
								</div>
							))}
						</div>
					))}

					<div className="list-actions">
						{allPost.length > viewRows  && <div className="button show-more" onClick={() => setViewRows(viewRows + rowLimit)}> Показать ещё </div>}
					</div>

					</div>
				</div>
			</div>
			<Gallery post={galleryPost} active={showGallery} hide={() => hideGalery()} />
    </>
  )
}

export default PostList;