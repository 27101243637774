import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Detali extends Component{

  videoRef = React.createRef();

  state = {
    preview: true,
    play: false
  };

	componentDidMount(){
		if(this.props.pathname == '/projects/detali/'){
			this.setState({preview: false});
			this.props.setProjectName('Detali');
			$('body').addClass('case-expanded hash-detali');
			$(window).scrollTop($('#case-detali').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-detali');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-detali');
		}

    
    if(this.state.play){
      if(this.videoRef.current){
        this.videoRef.current.play()

        this.videoRef.current.onended = () => {
          this.setState({play: false})
        }
      }
    }
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-detali">
        <img src="/cases/img/detali/img1.svg" className="detali-img1" />
        <div className="detali-img2">
          <img data-lazy="/cases/img/detali/img2.svg" />
          <div className="text bubble left parallax-front parallax-up">
            <div className="in">
            Логотип состоит из знака, написания 
            и дескриптора.<br /><br />
            Знак представляет собой совокупность различных деталей, которые объединяются в&nbsp;форму буквы D.
            </div>
          </div>
        </div>
        <div className="detali-img3">
          <img data-lazy="/cases/img/detali/img3.svg" />

          <div className="text bubble left parallax-front parallax-up">
            <div className="in">
            Графические элементы строятся на основе простейших, хорошо узнаваемых образов.
            </div>
          </div>
        </div>
        <div className="detali-img4" data-lazy="/cases/img/detali/img4.webp">
          <div className="text bubble right parallax-front parallax-up">
            <div className="in">
            В постерах с фотографиями используем фирменные ступени и&nbsp;дополнительные элементы в виде геометрических примитивов и&nbsp;иконок. Иконки поддерживают слоган и&nbsp;фото.
            </div>
          </div>
        </div>
        <div className="detali-img5">
          <img data-lazy="/cases/img/detali/img5.webp" />

          <div className="text bubble left-bottom parallax-front parallax-up">
            <div className="in">
            Фирменный стиль воплощает идею того, что совместное взаимодействие деталей формирует единую и&nbsp;цельную концепцию.<br /><br />
            Вместе эти детали формируют что-то большее и&nbsp;цельное, отражающее уникальность жилого комплекса.
            </div>
          </div>
        </div>
        <div className="detali-img6" data-lazy="/cases/img/detali/img6.webp"></div>

        <div className="detali-box1-wrap">
          <div className="text bubble left parallax-front parallax-up">
            <div className="in">
            Разрабатывая дизайн мы стремились передать динамику фирменного стиля, максимально показать движение вперёд, трансформацию, положительные изменения.
            </div>
          </div>

          <div className="detali-box1">
            <img data-lazy="/cases/img/detali/img7.webp" className="shadow" />
            <img data-lazy="/cases/img/detali/img8.webp" className="shadow" />
            <img data-lazy="/cases/img/detali/img9.svg" />
          </div>

          <div className="text bubble right-bottom parallax-front parallax-up">
            <div className="in">
            Сайт является продолжением и&nbsp;развитием фирменного стиля, он динамично и&nbsp;понятно предлагает информацию пользователю.
            </div>
          </div>
        </div>
        <div className="detali-img7" data-lazy="/cases/img/detali/img10.webp">
          <img data-lazy="/cases/img/detali/img11.svg" />
          <img data-lazy="/cases/img/detali/img12.svg" />
        </div>
        <div className="detali-box2">
          <div>
            <img data-lazy="/cases/img/detali/img13.webp" />
            <img data-lazy="/cases/img/detali/img15.webp" className="shadow" />
            <div>
              <img data-lazy="/cases/img/detali/img17.svg" />
            </div>
          </div>
          <div>
            <img data-lazy="/cases/img/detali/img14.webp" className="shadow" />
          </div>

          <img data-lazy="/cases/img/detali/img16.webp" className="shadow" />
        </div>
        <div className="detali-img8" data-lazy="/cases/img/detali/img18.webp"></div>

        <div className="detali-video-wrap">
          <div className="detali-video">
            <video playsInline ref={this.videoRef} poster="/cases/img/detali/poster.webp" muted>
              <source src="/cases/img/detali/video.mp4" type="video/mp4" />
            </video>
            {!this.state.play && <img className="detali-video-button" src="/cases/img/ahead/button.svg" onClick={() => this.setState({play: true})} />}
            <img src="/cases/img/detali/mac.webp" />
          </div>
        </div>

        <div className="detali-bottom-wrap">
          <img data-lazy="/cases/img/detali/img23.svg" />
          <div className="detali-bottom">
            <div>
              <div className="text bubble right parallax-front parallax-up">
                <div className="in">
                Так выглядит сайт в&nbsp;смартфоне. Адаптация происходит под любое устройство
                </div>
              </div>
              <img data-lazy="/cases/img/detali/img19.webp" className="detali-bottom-img1" />
            </div>
            <div>
              <img data-lazy="/cases/img/detali/img20.webp" className="detali-bottom-img2" />
              <img data-lazy="/cases/img/detali/img21.webp" className="detali-bottom-img3" />
            </div>
            <div>
              <div className="text bubble left-top parallax-front parallax-up">
                <div className="in">
                Видео–демонстрация работы сайта
                </div>
              </div>

              <img data-lazy="/cases/img/detali/img22.webp" className="detali-bottom-img4" />
            </div>
          </div>
        </div>
			</div>



      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/emotion/"  }
				title= { this.props.next ? this.props.next.name: "Emotion"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/emotion.webp" }
			/>
      <HelmetSite title="ЖК Детали" />
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Detali)