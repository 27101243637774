import React, { Component } from 'react';
import $ from 'jquery';
import 'jquery-touchswipe';
import {Helmet} from 'react-helmet';

import mousePageXY from '../helpers/mousePageXY';

class AboutDesctope extends Component{

	componentDidMount(){

		const body = $('body');

		const About = {
			isInited: false,
			isAnim: false,
			isMouseDown: false,
			isDragging: false,
			offsetPosition: 0,
			offsetDirection: 0,
			currentSlide: -1,
			init: function() {
				About.section = $('section.intro.about');
				About.slider = About.section.find('.slider');
				About.slides = About.section.find('.slide');
				
				About.nav = $('section.nav.about');
				About.nav.append('<div class="labels"></div>');
				About.labels = About.nav.find('.labels');
				
				About.createSlider();
				About.createCursor();
				
				About.setStart();
			},
			createSlider: function() {
				let len = About.slides.length;
				
				About.degree = Math.min(360 / (len + 1), 80);
				let deg = - About.degree;
				let labelHtml = '',
					backHtml = '<div class="splash"></div>';
				About.section.append('<div class="texts"></div>');
				About.texts = About.section.find('.texts');
				
				About.slides.each(function(ind) {
					let slide = $(this);
					let color = slide.data('color');

					let pos = slide.data('image-pos');
					let txt = slide.data('text-pos');
					let label = slide.data('label') || (ind + 1);
					labelHtml += '<div class="label" data-id="' + ind + '" style="-webkit-transform: rotate(' + (deg * ind) + 'deg); transform: rotate(' + (deg * ind) + 'deg);"><span>' + label + '</span></div>';
					backHtml += '<div class="back" data-id="' + ind + '" style="background-color: ' + color + '; left: 100%;"><div class="image" style="background-color: ' + color + '; left: calc(' + (pos) + 'vw);"><div></div></div></div>';
					slide.attr('data-id', ind).prepend('<div class="bg"><div style="width: ' + (pos) + '%;"></div><div style="width: ' + (70 - pos) + '%;"></div></div>');
					slide.find('.text').attr('data-id', ind).css({'left': txt + '%'}).appendTo(About.texts);
				});

				About.section.prepend(backHtml);
				About.backs = About.section.find('.back');
				About.labels.html(labelHtml);
				About.labels.find('.label').unbind('click').bind('click', function() {
					About.setSlide($(this).data('id'));
				});

				About.section.unbind('mousedown mousemove mouseup mouseleave').bind('mousedown mousemove mouseup mouseleave', function(e){
					if(e.type === 'mousedown'){
						About.isDragging = false;
						About.isMouseDown = true;
						About.cursorPosition = mousePageXY(e).x;
					}
					if(e.type === 'mousemove'){
						if (About.isMouseDown && !About.isAnim) {
							body.addClass('draggable');
							let pos = mousePageXY(e).x;
							let dif = About.cursorPosition - pos;
							let prc = dif / $(window).width();
							About.isDragging = Math.abs(dif) > 5;
							if (About.isDragging) {
								About.setPosition(prc);
							}
						}
					}
					if(e.type === 'mouseup'){
						About.isMouseDown = false;
						body.removeClass('draggable');
						let id = -1;
						if (!About.isDragging) {
							id = body.hasClass('left-side') && About.currentSlide > 0 ? About.currentSlide - 1 : body.hasClass('right-side') && About.currentSlide < About.slides.length - 1 ? About.currentSlide + 1 : -1;

              if(!body.hasClass('left-side') && !body.hasClass('right-side')){
                id = About.currentSlide < About.slides.length - 1 ? About.currentSlide + 1 : -1
              }
						}
						else {
							id = About.currentSlide + About.offsetPosition;
						}
						if (id != -1 && !About.isAnim) {
							About.setSlide(id);
   
						}
						About.isDragging = false;
					}
					if(e.type === 'mouseleave'){
						if (About.isDragging) {
							About.section.trigger('mouseup');
						}
					}
				});
			},
			createCursor: function() {
				About.section.find('.cursor').remove();

				About.section.append('<div class="cursor"><div class="next"></div><div class="prev"></div></div>');
				About.cursor = About.section.find('.cursor');

				About.section.add(About.nav).bind('mousemove', function(e) {
					e.preventDefault();
					About.cursor.show();
					About.followCursor(e);
				}).bind('mouseleave', function(e) {
					e.preventDefault();
					About.cursor.hide();
				});
			},
			followCursor: function (e) {
				let t = $(e.target).closest('.label');
				let pos = mousePageXY(e);
				let ww = $(window).width();
				About.cursor.css({'left': pos.x + 'px', 'top': pos.y + 'px'});
				body.toggleClass('left-side', pos.x <= ww * 0.4 || t.length === 1 && About.currentSlide > t.data('id')).toggleClass('right-side', pos.x >= ww * 0.6 || t.length === 1 && About.currentSlide < t.data('id'));
			},
			setStart: function() {
				About.currentSlide = -1;
				About.isAnim = true;
				About.section.addClass('starting');

				About.section.addClass('start');
				About.backs.removeClass('active');
				About.slider.removeAttr('style');
				About.texts.removeAttr('style');

				About.setImages(function() {
					About.section.removeClass('starting');
					About.isAnim = false;
					About.setSlide(About.slides.first().data('id'));
				});
			},
			setImages: function(callback) {
				let imgArr = [];
				About.slides.each(function() {
					let slide = $(this);
					let img = slide.data('image');
					if (img != '') {
						imgArr.push(img);
					}
				});
				
				About.slides.each(function() {
					let slide = $(this);
					let back = About.backs.filter('[data-id=' + slide.data('id') + ']');

					let img = slide.data('image');
					if (img != '') {
						back.find('.image div').css({'background-image': 'url(' + img + ')'});
						slide.removeAttr('data-image').removeAttr('data-image-mob');
					}
				});
				About.section.data('loaded', true);
				if (typeof(callback) === 'function') {
					callback();
				}
			},
			setSlide: function(id) {
				let isDragging = About.isDragging;
				if ((About.currentSlide != id || isDragging) && id != -1 && !About.isAnim) {
					About.isAnim = true;

					let isStart = About.section.hasClass('start');
					let backOld = About.backs.filter('[data-id=' + About.currentSlide + ']');
					let back = About.backs.filter('[data-id=' + id + ']');

					let slideOld = About.slides.filter('[data-id=' + About.currentSlide + ']');
					let slide = About.slides.filter('[data-id=' + id + ']');
					let offset = id > 0 ? - id * 100 : 0;
					About.cursor.toggleClass('begin', id == About.slides.first().attr('data-id')).toggleClass('end', id == About.slides.last().attr('data-id'));

					let deg = About.degree * id;
					let offset2 = offset + offset;
					let posOld = slideOld.data('image-pos') || 0;
					let pos = slide.data('image-pos');
					let speed = isStart ? 1000 : 300;
					About.labels
						.css({'-webkit-transform': 'rotate(' + deg + 'deg)', 'transform': 'rotate(' + deg + 'deg)'})
						.find('.label').removeClass('active').filter('[data-id=' + id + ']').addClass('active');
					if (isStart) {
						back.addClass('active');
					}
					if (!isDragging) {
						if (id > About.currentSlide) {
							var l1 = 0,
								r1 = (70 - posOld),
								l2 = (isStart ? 0 : posOld + 30),
								r2 = 0;
						}
						else {
							var l1 = 0,
								r1 = 0,
								l2 = 0,
								r2 = 100;
						}
						About.backs.addClass('invisible').css({'left': '100%', 'right': '0%'});
						if (id == About.currentSlide && About.offsetDirection < 0) {
							back.removeClass('invisible').css({'left': l1 + '%', 'right': r1 + '%'});
						}
						else {
							backOld.removeClass('invisible').css({'left': l1 + '%', 'right': r1 + '%'});
							back.removeClass('invisible').css({'left': l2 + '%', 'right': r2 + '%'});
						}
					}
					setTimeout(function() {
						if (id > About.currentSlide && About.offsetDirection >= 0) {
							var l1 = 0,
								r1 = (100 - pos),
								l2 = isStart ? 0 : pos,
								r2 = 0;
						}
						else {
							var l1 = (pos + 30),
								r1 = 0,
								l2 = 0,
								r2 = (isStart ? 0 : (70 - pos));
						}
						About.backs.removeClass('active');
						if (id == About.currentSlide && About.offsetDirection < 0) {
							back.addClass('active').css({'left': l1 + '%', 'right': r1 + '%'});
						}
						else {
							backOld.css({'left': l1 + '%', 'right': r1 + '%'});
							back.addClass('active').css({'left': l2 + '%', 'right': r2 + '%'});
						}
						About.currentSlide = id;
						setTimeout(function() {
							About.isAnim = false;
						}, 1100 - speed);
					}, speed);
					About.section.removeClass('start');
					About.slider.css({'-webkit-transform': 'translateX(' + offset + '%)', 'transform': 'translateX(' + offset + '%)'});
					About.texts.css({'-webkit-transform': 'translateY(' + offset2 + '%)', 'transform': 'translateY(' + offset2 + '%)'});
					About.slides.removeClass('active');
					slide.addClass('active');
				}
			},
			setPosition: function(percent) {
				if ((About.currentSlide > 0 || percent > 0) && (About.currentSlide < About.slides.length - 1 || percent < 0)) {
					let id = About.currentSlide + (percent > 0 ? 1 : percent < 0 ? -1 : 0);
					let slideOld = About.slides.filter('[data-id=' + About.currentSlide + ']');
					let slide = About.slides.filter('[data-id=' + id + ']');
					let backOld = About.backs.filter('[data-id=' + About.currentSlide + ']');
					let back = About.backs.filter('[data-id=' + id + ']');
					let posOld = slideOld.data('image-pos') || 0;
					let pos = slide.data('image-pos');
					let deg = About.degree * (About.currentSlide + percent);
					let offset = ((About.currentSlide > 0 ? - About.currentSlide : 0) - percent) * 100;
					let offset2 = offset + offset;
					let abs = Math.abs(percent);
					About.labels.css({'-webkit-transform': 'rotate(' + deg + 'deg)', 'transform': 'rotate(' + deg + 'deg)'});
					About.backs.addClass('invisible');
					backOld.removeClass('invisible');
					back.removeClass('invisible');
					if (id > About.currentSlide) {
						if (abs <= 0.3) {
							var l1 = 0,
								r1 = 70 - posOld,
								l2 = posOld + 30,
								r2 = 0;
						}
						else if (abs <= 0.5) {
							var l1 = 0,
								r1 = 70 - pos,
								l2 = pos + 30,
								r2 = 0;
						}
						else {
							var l1 = 0,
								r1 = 70 - pos,
								l2 = pos,
								r2 = 0;
							backOld.removeClass('active');
							back.addClass('active');
						}
					}
					else {
						if (abs <= 0.3) {
							var l1 = posOld,
								r1 = 0,
								l2 = 0,
								r2 = 100 - posOld;
						}
						else if (abs <= 0.5) {
							var l1 = 30,
								r1 = 0,
								l2 = 0,
								r2 = 70;
						}
						else {
							var l1 = pos + 30,
								r1 = 0,
								l2 = 0,
								r2 = 70 - pos;
							backOld.removeClass('active');
							back.addClass('active');
						}
					}
					backOld.css({'left': l1 + '%', 'right': r1 + '%'});
					back.css({'left': l2 + '%', 'right': r2 + '%'});
					About.slider.css({'-webkit-transform': 'translateX(' + offset + '%)', 'transform': 'translateX(' + offset + '%)'});
					About.texts.css({'-webkit-transform': 'translateY(' + offset2 + '%)', 'transform': 'translateY(' + offset2 + '%)'});
					About.offsetPosition = percent > 0.3 ? 1 : percent < -0.3 ? -1 : 0;
					About.offsetDirection = percent > 0 ? 1 : percent < 0 ? -1 : 0;
				}
				else {
					About.offsetPosition = 0;
				}
			}
		};

		About.init();
	}

	render(){
		return(

			<section className="intro about">
        <Helmet>
          <title>О нас ' Amio</title>
        </Helmet>

				<section className="nav about"></section>
				
				<div className="slider">
					<div className="slide" data-label="1" data-color="#ff3c04" data-image="/img/about-slide1.jpg" data-image-pos="16" data-text-pos="53" data-image-mob="/img/mobile/about-slide1.jpg" data-image-pos-mob="0" data-text-pos-mob="50">
						<div className="title"><div>Мы не <b>роботы</b></div></div>
						<div className="text"><div>
							а люди,
							<br/>но профессиональные и ответственные!
							<br/>Мы можем ошибаться, что свойственно людям,
							<br/>но готовы признавать свои ошибки и оперативно их исправлять.
						</div></div>
					</div>
					<div className="slide" data-label="2" data-color="#ff83a3" data-image="/img/about-slide2.jpg" data-image-pos="26" data-text-pos="53" data-image-mob="/img/mobile/about-slide2.jpg" data-image-pos-mob="11" data-text-pos-mob="58">
						<div className="title"><div>Мы не <b>звёзды</b></div></div>
						<div className="text"><div>
							Блеск кубков и грамот,
							<br/>полученных на рекламных
							<br/>фестивалях не ослепил нас.
							<br/>Мы такие же простые, как раньше!
						</div></div>
					</div>
					<div className="slide" data-label="3" data-color="#ffbd83" data-image="/img/about-slide3.jpg" data-image-pos="35" data-text-pos="53" data-image-mob="/img/mobile/about-slide3.jpg" data-image-pos-mob="19" data-text-pos-mob="64">
						<div className="title"><div>Мы не <b>плюшевые</b></div></div>
						<div className="text"><div>
							Мы имеем свою позицию и уверенность в том, что делаем. Но мы готовы слушать, и слушаем наших клиентов.
						</div></div>
					</div>
					<div className="slide" data-label="4" data-color="#7cbfd0" data-image="/img/about-slide4.jpg" data-image-pos="49" data-text-pos="4" data-image-mob="/img/mobile/about-slide4.jpg" data-image-pos-mob="34" data-text-pos-mob="7">
						<div className="title"><div>Мы не <b>иностранцы</b></div></div>
						<div className="text"><div>
							Хотя у нас, кроме россиян, работают и испанцы, и латыши, и итальянец. Но мы работаем в Москве, говорим с вами на одном языке.
						</div></div>
					</div>
					<div className="slide" data-label="5" data-color="#9a7ccc" data-image="/img/about-slide5.jpg" data-image-pos="59" data-text-pos="10" data-image-mob="/img/mobile/about-slide5.jpg" data-image-pos-mob="42" data-text-pos-mob="14">
						<div className="title"><div>Мы не <b>страшные</b></div></div>
						<div className="text"><div>
							А наоборот, даже очень милые и человечные. Не верите?!
							<br/>Приезжайте к нам в офис! 
							<br/>И мы утомим вас своим гостеприимством!
						</div></div>
					</div>
				</div>
			</section>
	
		)
	}
}

export default AboutDesctope;