import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Iskra extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/iskra/'){
			this.setState({preview: false});
			this.props.setProjectName('Искра-парк');
			$('body').addClass('case-expanded hash-iskra');
			$(window).scrollTop($('#case-iskra').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-iskra');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-iskra');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-iskra">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/iskra/bg1.webp')`}} />
          </div>
        </div>
        <div className="screen screen2">
          <img src="/cases/img/iskra/bg2-1.webp" className="img1 shadow" />
          <img src="/cases/img/iskra/bg2-2.webp" className="img2 shadow" />
          <img src="/cases/img/iskra/bg2-3.webp" className="img3 shadow" />

          <div className="text bubble right parallax-front text1">
            <div className="in">
            Свежий подход к представлению объекта – видеосайт. <br /> <br />
            Видео смонтировано из уникальных качественных полноформатных материалов.
            </div>
          </div>

          <div className="text bubble right parallax-front text2">
            <div className="in">
            Это история длиной в обычный будний день. Такое «путешествие во времени» ориентировано на эмоции и ощущения, и в то же время доступно раскрывает преимущества квартала.
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="mac">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/gn7fRidbTsg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </div>
          <img src="/cases/img/iskra/bg4-1.webp" className="img1 shadow" />
          <img src="/cases/img/iskra/bg4-2.webp" className="img2 shadow" />

          <div className="clear">
            <div className="text bubble left-top parallax-front text1">
              <div className="in">
              На сайте всегда доступны спецпредложения, а карточка лота сопровождается галереями, содержит ключевые характеристики и два вида планировок: с мебелью и без.
              </div>
            </div>

            <div className="slider parallax-slider">
              <div className="line">
                <div className="item item1"><span style={{backgroundImage: `url('/cases/img/iskra/img1.webp')`}}></span></div>
                <div className="item item2"><span style={{backgroundImage: `url('/cases/img/iskra/img2.webp')`}}></span></div>
                <div className="item item3"><span style={{backgroundImage: `url('/cases/img/iskra/img3.webp')`}}></span></div>
                <div className="item item4"><span style={{backgroundImage: `url('/cases/img/iskra/img4.webp')`}}></span></div>
                <div className="item item5"><span style={{backgroundImage: `url('/cases/img/iskra/img5.webp')`}}></span></div>
              </div>
            </div>
          </div>

          <div className="text bubble left parallax-front text2">
            <div className="in">
            Так выглядит сайт на мобильных устройствах.
            </div>
          </div>

          <img src="/cases/img/iskra/bg5-1.webp" className="img3" />
        </div>
			</div>



    <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/polyanka/" }
				title= { this.props.next ? this.props.next.name: "Полянка 44"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/polyanka.webp" }
			/>

      <HelmetSite title="Искра-Парк" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Искра-парк</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Iskra)