import {
	PAGEID,
	VERSION,
	PROJECTNAME,
	CASE,
  POPUP
} from './const';

let initialState = {
	version:null,
	pageId: null,
	projectName: null,
	caseType: null,
  popup: false
};

function reducer(state = initialState, action) {
	if(action.type === PAGEID){
		return {
			...state,
			pageId: action.pageId
		}
	}
	if(action.type === VERSION){
		return {
			...state,
			version: action.version
		}
	}
	if(action.type === PROJECTNAME){
		return {
			...state,
			projectName: action.name
		}
	}
	if(action.type === CASE){
		return {
			...state,
			caseType: state.caseType === action.name ? null : action.name
		}
	}
  if(action.type === POPUP){
		return {
			...state,
			popup: action.name
		}
	}
	else{
		return state;
	}
}

export default reducer;