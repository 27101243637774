import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Ahead extends Component {

  videoRef = React.createRef();

  state = {
    preview: true,
    play: false
  };

	componentDidMount(){

		if(this.props.pathname == '/projects/ahead/'){
			this.setState({preview: false});
			this.props.setProjectName('Ahead');
			$('body').addClass('case-expanded hash-ahead');
			$(window).scrollTop($('#case-ahead').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-ahead');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-ahead');
		}

    if(this.state.play){
      if(this.videoRef.current){
        this.videoRef.current.play()

        this.videoRef.current.onended = () => {
          this.setState({play: false})
        }
      }
    }
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render() {
    return (
      <div className="case ahead">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="ahead-img1" style={{ backgroundImage: "url('/cases/img/ahead/img1.webp')"}}>
            <img src="/cases/img/ahead/logo.svg" />
          </div>
          <div className="ahead-box1">
            <img className="shadow ahead-img2" src="/cases/img/ahead/img2.webp" />
            <img className="ahead-img3" src="/cases/img/ahead/img3.webp" />
            <img className="shadow ahead-img5" src="/cases/img/ahead/img5.webp" />
            <img className="ahead-img4" src="/cases/img/ahead/img4.webp" />

            <div className="text bubble left parallax-front parallax-up">
              <div className="in">
                Концептуальный, футуристичный и технологичный. Этот проект идельно сочетается с ритмом человека в городе. Все эти идеи мы транслировали через сайт.
              </div>
            </div>
          </div>
          <div className="ahead-img6" style={{ backgroundImage: "url('/cases/img/ahead/img6.webp')"}}>
            <img src="/cases/img/ahead/logo2.svg" />
          </div>
          <div className="ahead-box2">
            <img className="ahead-img7 shadow" src="/cases/img/ahead/img7.webp" />
            <img className="ahead-img8" src="/cases/img/ahead/img8.webp" />
            <img className="ahead-img9 shadow" src="/cases/img/ahead/img9.webp" />

            <div className="text bubble left-top parallax-front parallax-up">
              <div className="in">
              Главными героями истории становится человек и творчество
              </div>
            </div>
          </div>
          <div className="ahead-img10" style={{ backgroundImage: "url('/cases/img/ahead/img10.webp')"}}></div>
          <div className="ahead-video-wrap">
            <div className="ahead-video">
              <video playsInline ref={this.videoRef} poster="/cases/img/ahead/poster.webp" muted>
                <source src="/cases/img/ahead/video.mp4" type="video/mp4" />
              </video>
              {!this.state.play && <img className="ahead-video-button" src="/cases/img/ahead/button.svg" onClick={() => this.setState({play: true})} />}
              <img src="/cases/img/ahead/video.webp" />
            </div>
          </div>
          <div className="ahead-imgs">
            <div>
              <div className="text bubble right parallax-front parallax-up">
                <div className="in">
                Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство
                </div>
              </div>
              <img src="/cases/img/ahead/img11.webp" />
            </div>
            <div>
              <img src="/cases/img/ahead/img12.webp" />
            </div>
            <div>
              <img src="/cases/img/ahead/img13.webp" />
            </div>
          </div>
        </div>

        

        
     <CaseFooter 
				to={  "/projects/genesis/"   }
				title= { this.props.next ? this.props.next.name: "Genesis"   } 
				img={this.props.next  ? this.props.next.imgCaseBottom:"/cases/img/next/genesis.webp" }
			/>
        <HelmetSite title={`Ahead`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ahead);
