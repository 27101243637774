import React, { Component } from "react";
import { connect } from "react-redux";

import mapStateToProps from "../../store/mapStateToProps";
import mapDispatchToProps from "../../store/mapDispatchToProps";
import { default as SliderSlick } from "react-slick";
import Slider from "@material-ui/lab/Slider";
import { Helmet } from "react-helmet";

import $ from "jquery";

import "slick-carousel/slick/slick.css";
import { hit } from "../metrics";

class SourcesMobile extends Component {
	state = {
		value1: 0,
		value2: 0,
		value3: 0,
		value4: 0,
		value5: 0,
		value6: 0,
		value7: 0,
		value8: 0,
		value9: 0,
	};

	componentDidMount() {
    window.scrollTo(0, 0)
    
		window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		// create the smooth scroller FIRST!
		let smoother = window.ScrollSmoother.create({
			smooth: 2, // seconds it takes to "catch up" to native scroll position
			effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
		});

		// pin box-c when it reaches the center of the viewport, for 300px
		window.ScrollTrigger.create({
			trigger: ".box-c",
			pin: true,
			start: "center center",
			end: "+=300",
		});

		document.querySelector("button").addEventListener("click", (e) => {
			// scroll to the spot where .box-c is in the center.
			// parameters: element, smooth, position
			smoother.scrollTo(".box-c", true, "center center");

			// or you could animate the scrollTop:
			// gsap.to(smoother, {
			//  scrollTop: smoother.offset(".box-c", "center center"),
			//  duration: 1
			// });
		});
		//this.props.setPageId("sources-mobile");
		$("body").addClass("page-sources hash-mobile tags-expanded fadepage fixedd");

		$(window).scroll(function () {
			let scr = $(window).scrollTop();
			
			$(".js-check").each(function () {
				let el = $(this);
				let h = $(this).height();
				let t = $(this).offset().top - 200;
				let index = el.data("id");

				if (scr >= t && scr < t + h) {
				
					$(".source-mobile-bg > div")
						.eq(index)
						.addClass("active")
						.siblings()
						.removeClass("active");
				}
			});
		});
		if (document.documentElement.clientWidth > 1000) {
			$(".mobile-scroll-arrow").click(function () {
				$("html, body").animate(
					{ scrollTop: $(".slider-overlay").offset().top + 600 },
					500
				);
			});
		}else{
		

				$(".mobile-scroll-arrow").click(function () {
					$("html, body").animate(
						{ scrollTop: $(".slider-overlay").offset().top + 800 },
						500
					);
				});
		
	}



		window.scrollTo(0, 0);
		hit(window.location.pathname);

    this.scroll()
		window.addEventListener("scroll", this.scroll);
		// var self = this	
		$('button').each(function(i,elem) {

				$(elem).html(
					`<div class="counter count${i}"><div class="counter-curent">1</div><div> / </div><div class="count-all">8</div></div>`
				);
	

		});
		$(".count5 .count-all").html(this.refs.slider7.props.children.length)
		$(".count8 .count-all").html(this.refs.slider8.props.children.length)
		$(".count11 .count-all").html(this.refs.slider9.props.children.length)
		$(".count14 .count-all").html(this.refs.slider6.props.children.length)
		$(".count17 .count-all").html(this.refs.slider2.props.children.length)
		$(".count20 .count-all").html(this.refs.slider3.props.children.length)
		$(".count23 .count-all").html(this.refs.slider4.props.children.length)
		$(".count26 .count-all").html(this.refs.slider1.props.children.length)
		$(".count29 .count-all").html(this.refs.slider5.props.children.length)
	}



	scroll = () => {
		if (document.documentElement.clientWidth > 1000) {
			var  src = $(window).scrollTop()
			$('.source-mobile-bg div.active').css('background-position', `10px ${src*(-0.05)-200}px`);
			$('.source-mobile-bg div.active.bg3').css('background-position', `10px ${src*(-0.05)-100}px`);
			if($(window).scrollTop()<2200){
				$(".bg1").addClass("active")
				$(".bg1").siblings().removeClass("active")
			}
			
			if($(window).scrollTop()>2200&&$(window).scrollTop()<4700){
				$(".bg2").addClass("active")
				$(".bg2").siblings().removeClass("active")
			}
			if($(window).scrollTop()>4700){
				$(".bg3").addClass("active")
				$(".bg3").siblings().removeClass("active")
			}
		}
		$(".bg1").addClass("active")

    if($('.l-trigger').length) {
			let scroll_top = $(window).scrollTop();
			let trigger_offset = $('.l-trigger').offset().top;
			$('.js-parallax').each(function() {
				let el = $(this);
				let offset = el.offset().top;
				if( +(offset - scroll_top) < trigger_offset - scroll_top) {
					el.addClass('animate');
					(function(elem) {
						setTimeout(function() { elem.addClass('animated') }, 1000);
					})(el);
				}
			});
		}
	};

	tid = null;

	componentDidUpdate(prevProps, prevState) {
		clearTimeout(this.tid);
		$(".count5 .counter-curent").html(this.state.value7+1)
		$(".count8 .counter-curent").html(this.state.value8+1)
		$(".count11 .counter-curent").html(this.state.value9+1)
		$(".count14 .counter-curent").html(this.state.value6+1)
		$(".count17 .counter-curent").html(this.state.value2+1)
		$(".count20 .counter-curent").html(this.state.value3+1)
		$(".count23 .counter-curent").html(this.state.value4+1)
		$(".count26 .counter-curent").html(this.state.value1+1)
		// $(".count29 .counter-curent").html(this.state.value6+1)
		$(".count29 .counter-curent").html(this.state.value5+1)
		this.tid = setTimeout(() => {
			this.refs.slider1.slickGoTo(this.state.value1);
			this.refs.slider2.slickGoTo(this.state.value2);
			this.refs.slider3.slickGoTo(this.state.value3);
			this.refs.slider4.slickGoTo(this.state.value4);
			this.refs.slider5.slickGoTo(this.state.value5);
			this.refs.slider6.slickGoTo(this.state.value6);
			this.refs.slider7.slickGoTo(this.state.value7);
			this.refs.slider8.slickGoTo(this.state.value8);
			this.refs.slider9.slickGoTo(this.state.value9);
		}, 0);
	}

	componentWillUnmount() {
		$("body").removeClass("page-sources hash-mobile fadepage tags-expanded");
		window.removeEventListener("scroll", this.scroll);
		clearTimeout(this.tid);
	}

	handleChange1 = (event, value) => {
		setTimeout(() => {
			this.setState({ value1: value });
		}, "100");
	};

	handleChange2 = (event, value) => {
		this.setState({ value2: value });
	};

	handleChange3 = (event, value) => {
		this.setState({ value3: value });
	};

	handleChange4 = (event, value) => {
		this.setState({ value4: value });
	};

	handleChange5 = (event, value) => {
		this.setState({ value5: value });
	};

	handleChange6 = (event, value) => {
		this.setState({ value6: value });
	};

	handleChange7 = (event, value) => {
		this.setState({ value7: value });
	};
	handleChange8 = (event, value) => {
		this.setState({ value8: value });
	};

	handleChange9 = (event, value) => {
		this.setState({ value9: value });
	};
	prev = (el, max) => {
		const sl = "slider" + el;
		let value = null;
		if (sl === "slider1") {
			value = this.state.value1;
		}
		if (sl === "slider2") {
			value = this.state.value2;
		}
		if (sl === "slider3") {
			value = this.state.value3;
		}
		if (sl === "slider4") {
			value = this.state.value4;
		}
		if (sl === "slider5") {
			value = this.state.value5;
		}
		if (sl === "slider6") {
			value = this.state.value6;
		}
		if (sl === "slider7") {
			value = this.state.value7;
		}
		if (sl === "slider8") {
			value = this.state.value8;
		}
		if (sl === "slider9") {
			value = this.state.value9;
		}

		value = value - 1;

		if (value < 0) {
			value = max;
		}
		if (sl === "slider1") {
			this.setState({ value1: value });
		}
		if (sl === "slider2") {
			this.setState({ value2: value });
		}
		if (sl === "slider3") {
			this.setState({ value3: value });
		}
		if (sl === "slider4") {
			this.setState({ value4: value });
		}
		if (sl === "slider5") {
			this.setState({ value5: value });
		}
		if (sl === "slider6") {
			this.setState({ value6: value });
		}
		if (sl === "slider7") {
			this.setState({ value7: value });
		}
		if (sl === "slider8") {
			this.setState({ value8: value });
		}
		if (sl === "slider9") {
			this.setState({ value9: value });
		}
	};

	next = (el, max) => {
		const sl = "slider" + el;
		let value = null;
		if (sl === "slider1") {
			value = this.state.value1;
		}
		if (sl === "slider2") {
			value = this.state.value2;
		}
		if (sl === "slider3") {
			value = this.state.value3;
		}
		if (sl === "slider4") {
			value = this.state.value4;
		}
		if (sl === "slider5") {
			value = this.state.value5;
		}
		if (sl === "slider6") {
			value = this.state.value6;
		}
		if (sl === "slider7") {
			value = this.state.value7;
		}
		if (sl === "slider8") {
			value = this.state.value8;
		}
		if (sl === "slider9") {
			value = this.state.value9;
		}

		value = value + 1;

		if (value > max) {
			value = 0;
		}

		if (sl === "slider1") {
			this.setState({ value1: value });
		}
		if (sl === "slider2") {
			this.setState({ value2: value });
		}
		if (sl === "slider3") {
			this.setState({ value3: value });
		}
		if (sl === "slider4") {
			this.setState({ value4: value });
		}
		if (sl === "slider5") {
			this.setState({ value5: value });
		}
		if (sl === "slider6") {
			this.setState({ value6: value });
		}
		if (sl === "slider7") {
			this.setState({ value7: value });
		}
		if (sl === "slider8") {
			this.setState({ value8: value });
		}
		if (sl === "slider9") {
			this.setState({ value9: value });
		}
	};

	render() {
		const settings = {
			dots: false,
			fade: true,
			infinite: true,
			speed: 2,
			slidesToShow: 1,
			slidesToScroll: 1,
			draggable: false,
			afterChange: (event, slick, currentSlide, nextSlide) =>
				console.log(event),
			beforeChange: (current, next) => this.setState({ slideIndex: next }),
		};

		return (
			<div>
				<div className="source-mobile-bg">
					<div 
						style={{ backgroundImage: `url(/sources/img/mobile/mobile1.jpg)` }}
						className="active bg1"
					></div>
					<div
					className="bg2"
						style={{ backgroundImage: `url(/sources/img/mobile/mobile2.jpg)` }}
					></div>
					<div
					className="bg3"
						style={{ backgroundImage: `url(/sources/img/mobile/mobile3.jpg)` }}
					></div>
				</div>
				<section id="smooth-wrapper" className="intro sources sources_mobile">
					<Helmet>
						<title>Apps</title>
					</Helmet>
					<div id="smooth-content" className="case">
						<div className="case-content">
							<div className="source-mobile-bg2">
								<span data-speed="0.8"></span>
								<span className="left top0" data-speed="0.9"></span>
								<span className="right" data-speed="0.7"></span>
								<span className="right top1" data-speed="0.7"></span>
								<span className="left top1" data-speed="0.9"></span>
								<span className="right top2" data-speed="0.7"></span>
								<span className="left top2" data-speed="0.9"></span>
								<span className="right top3" data-speed="0.7"></span>
								<span className="left top3" data-speed="0.9"></span>
								<span className="right top4" data-speed="0.7"></span>
								<span className="left top4" data-speed="0.9"></span>
							</div>
							<div>
								<section className="mob-section">
									<h1 className="js-parallax fade-in-up">Разработка <br/>приложений <br/>для iOS и Android </h1>
									{/* <h1 className="js-parallax fade-in-up">Apps</h1> */}
									<div className="text js-parallax fade-in-up">
										Разработка нативных приложений – еще одна наша компетенция,
										в которой мы двигаемся вперед семимильными шагами. Мы делаем
										удобные и красивые проекты, с которыми человеку будет трудно
										расстаться.
										<br />
										<br />
										Приложения дают нам возможность реализовать то, что
										невозможно сделать средствами web-сайта. Быстрота,
										дополненная реальность, работа без интернета, нативность и
										не только.
									</div>
									<div className="mobile-icons js-parallax fade-in-up">
										<div className="mobile-icon ios">
											<div>
												<span>iOS</span>development
											</div>
										</div>
										<div className="mobile-icon android">
											<div>
												<span>Android</span>development
											</div>
										</div>
									</div>
									<div className="mobile-scroll-arrow">
										<i></i>
										<i></i>
									</div>
								</section>
								<section className="mob-section slider-overlay">
									<div className="mobile-slider widch-0  ">
										<span className="js-check" data-id="1"></span>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">ПИК. Места</div>
											<div className="mobile-slider-text">
												Квартралы в которых всё рядом. Лёгкий поиск ближайших
												магазинов, кафе, фитнесс-центров и прочего
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider2"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-1.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-3.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-4.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-5.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-6.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-7.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-8.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-9.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(2, 7)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(2, 7)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value2}
												onChange={this.handleChange2}
												min={0}
												max={7}
												step={1}
											/>
										</div>
									</div>
									<div data-speed="0.8" className="mobile-slider slide-all slider-10 ">
										<span className="js-check" data-id="1"></span>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">ПИК. Места</div>
											<div className="mobile-slider-text">
												Квартралы в которых всё рядом. Лёгкий поиск ближайших
												магазинов, кафе, фитнесс-центров и прочего
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider7"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide7-1.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide7-2.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide7-3.png`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide7-4.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide7-5.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide7-6.png)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(7, 6)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(7, 6)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value7}
												onChange={this.handleChange7}
												min={0}
												max={5}
												step={1}
											/>
										</div>
									</div>
									<div
										data-speed="0.8"
										className="mobile-slider slide-all slide-2  arrows-pos-left slide-right "
									>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">
												Главстрой.<br/>  УК Культура
											</div>
											<div className="mobile-slider-text">
												Удобное и быстое решение проблем в вашей квартире, доме
												или дворе, при помощи управляющей компании “Культура”
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider8"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-1.png`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-2.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-3.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-4.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-5.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-6.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-7.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-8.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide8-9.png)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(8, 8)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(8, 8)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value8}
												onChange={this.handleChange8}
												min={0}
												max={8}
												step={1}
											/>
										</div>
									</div>
									<div data-speed="0.8" className="mobile-slider slide-all slide-3 ">
										<span className="js-check" data-id="0"></span>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">Триал–Спорт</div>
											<div className="mobile-slider-text">
												Все товары, одного из самых больших спортивных
												магазинов,всегда под рукой
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider9"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-1.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-2.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-3.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-4.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-5.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-6.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-7.png)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slide9-8.png)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(9, 7)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(9, 7)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value9}
												onChange={this.handleChange9}
												min={0}
												max={7}
												step={1}
											/>
										</div>
									</div>
									<div
										data-speed="0.8"
										className="mobile-slider  arrows-pos-left slide-right slide-4"
									>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">X5 Retail Group.<br/>
Food Drive</div>
											<div className="mobile-slider-text">
												Приложение, которое помогает делать добрые дела.
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider6"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider6-6.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider6-5.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider6-4.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider6-3.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider6-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider6-1.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(6, 5)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(6, 5)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value6}
												onChange={this.handleChange6}
												min={0}
												max={5}
												step={1}
											/>
										</div>
									</div>
									<div
										data-speed="0.8"
										className="mobile-slider arrows-pos-left slide-5 "
									>
										<span className="js-check" data-id="1"></span>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">Ефимов Бор</div>
											<div className="mobile-slider-text">
												Красивое и вдохновляющее приложение для лесной гостиницы
												«Ефимов Бор». Модуль бронирования интегрирован с CRM и
												платежным шлюзом Московского Индустриального Банка.
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider2"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-1.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-3.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-4.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-5.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-6.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-7.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-8.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider2-9.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(2, 7)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(2, 7)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value2}
												onChange={this.handleChange2}
												min={0}
												max={8}
												step={1}
											/>
										</div>
									</div>
									<div data-speed="0.8" className="mobile-slider counter-right slide-6 ">
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">PSN Group. I’M</div>
											<div className="mobile-slider-text">
												Дополненная реальность для нового проекта PSN Group -
												I`m. Тверская. Возможность не только познакомиться с
												информацией о проекте, но и посмотреть, как выглядит
												будущий дом в окружении существующей застройки.
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider3"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-1.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-3.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-4.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-5.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-6.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider3-7.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(3, 5)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(3, 5)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value3}
												onChange={this.handleChange3}
												min={0}
												max={6}
												step={1}
											/>
										</div>
									</div>
									<div
										data-speed="0.8"
										className="mobile-slider mobile-large arrows-pos-left mobile-ipad  slide-7"
									>
										<span className="js-check" data-id="2"></span>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">PSN Group. I’M</div>
											<div className="mobile-slider-text">
												Еще один инструмент для проекта PSN Group - I`m.
												Тверская. Приложение для Ipad, позволяющее не только
												управлять интерактивным макетом в офисе продаж в части
												подбора апартаментов, но и демонстрировать виды из окон
												вашей будущей квартиры.
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider4"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider4-1.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider4-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider4-3.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider4-5.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider4-6.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider4-7.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(4, 5)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(4, 5)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value4}
												onChange={this.handleChange4}
												min={0}
												max={5}
												step={1}
											/>
										</div>
									</div>
									<div data-speed="0.8" className="mobile-slider counter-right slide-8 ">
										<span className="js-check" data-id="0"></span>
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">Имеритинский</div>
											<div className="mobile-slider-text">
												Приложение для курортного района «Имеритинский». Вся
												жизнь района, включая бронирование услуг.
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider1"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-1.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-3.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-5.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-6.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-7.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-8.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider1-9.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(1, 6)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(1, 6)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value1}
												onChange={this.handleChange1}
												min={0}
												max={7}
												step={1}
											/>
										</div>
									</div>
									<div data-speed="0.8" className="mobile-slider slide-right-2 slide-9 ">
										<div className="mobile-slider-block">
											<div className="mobile-slider-title">
											М.Видео.<br/>
Футбольный менеджер
											</div>
											<div className="mobile-slider-text">
												Футбольный менеджер для М.Видео. В преддверии чемпионата
												Мира более чем актуальная история!
											</div>
										</div>
										<div className="mobile-slider-in">
											<SliderSlick
												ref="slider5"
												{...settings}
												className="js-mobile-slider"
											>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider5-1.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider5-2.jpg)`,
														}}
													></div>
												</div>
												<div>
													<div
														className="mobile-item"
														style={{
															backgroundImage: `url(/sources/img/mobile/slider/slider5-3.jpg)`,
														}}
													></div>
												</div>
											</SliderSlick>

											<div
												className="cslick-prev"
												onClick={() => this.prev(5, 2)}
											></div>
											<div
												className="cslick-next"
												onClick={() => this.next(5, 2)}
											></div>
										</div>
										<div className="mobile-track">
											<Slider
												vertical
												value={this.state.value5}
												onChange={this.handleChange5}
												min={0}
												max={2}
												step={1}
											/>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SourcesMobile);
