import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Polyanka extends Component{

	state = {
		preview: true
	}

	componentDidMount(){

		if(this.props.pathname == '/projects/polyanka/'){
			this.setState({preview: false});
			this.props.setProjectName('Полянка 44');
			$('body').addClass('case-expanded hash-polyanka');
			$(window).scrollTop($('#case-polyanka').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-polyanka');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-polyanka');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="content narrow">
            <div className="image image1"><div style={{backgroundImage: `url('/cases/img/polyanka/img7.webp')`}}></div></div>
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <b>Перед нами стояла задача по разработке корпоративного сайта объекта.</b>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/polyanka/img1.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/polyanka/img2.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/polyanka/img3.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/polyanka/img4.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right-bottom text2 parallax-front">
                <div className="in">
                  <p><b>Визуальная концепция проекта.</b></p>
                  <p>Мы посетили объект, почувствовали дух аристократии, ознакомились с архитектурными решениями объекта. После этого мы разработали стилистику визуальной подачи проекта.</p>
                  <p>В прототипе сайта мы детально проработали логику и процессы, предложили разные варианты решений, большая часть из которых и вошла в рабочую версию.</p>
                </div>
              </div>
              <div className="screenshot screenshot5"><div style={{backgroundImage: `url('/cases/img/polyanka/img6.webp')`}}></div></div>
              <div className="screenshot screenshot6"><div style={{backgroundImage: `url('/cases/img/polyanka/img5.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/polyanka/img8.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  Основа для сайта – минимализм и простота подачи информации. Всё наполнение всегда в видимой области браузера.
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/polyanka/img9.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble left-top text2 parallax-front">
                <div className="in">
                  <b>Перед нами стояла задача по разработке корпоративного сайта объекта.</b>
                </div>
              </div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/polyanka/img10.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/polyanka/img11.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right-bottom text3 parallax-front">
                <div className="in">
                  <p><b>3D визуализации</b></p>
                  <p>Мы разработали 3D-визуализации для всего комплекса, каждого особняка, сада и вида из окон.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/polyanka/img12.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/polyanka/img13.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right-bottom text1 parallax-front">
                <div className="in">
                  <p><b>Система подбора квартир</b></p>
                  <p>Это удобно. Каждый особняк можно осмотреть с разных сторон и выбрать этаж на интерактивном макете здания.</p>
                </div>
              </div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/polyanka/img14.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/polyanka/img15.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/polyanka/img16.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p><b>Отображение на мобильных устройствах</b></p>
                  <p>Сайт адаптируется для всех типов устройств: монитора компьютера, планшетов и смартфонов.</p>
                </div>
              </div>
              <div className="screenshot-mob screenshot1"><div style={{backgroundImage: `url('/cases/img/polyanka/img17.webp')`}}></div></div>
              <div className="screenshot-mob screenshot2"><div style={{backgroundImage: `url('/cases/img/polyanka/img18.webp')`}}></div></div>
              <div className="screenshot-phone screenshot3"><div style={{backgroundImage: `url('/cases/img/polyanka/img19.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/polyanka/img20.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p><b>Интерактивная touch-система</b></p>
                  <p>Для офиса продаж мы разработали интерактивную сенсорную систему с функционалом подбора квартир.</p>
                  <p>Система работает на большом дисплее и дополнительно может управляться через iPad.</p>
                </div>
              </div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/polyanka/img21.webp')`}}></div></div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/polyanka/img22.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/polyanka/img23-1.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/polyanka/img23-2.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/polyanka/img23-3.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/polyanka/img23-4.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/polyanka/img23-5.webp')`}}></div></div>
                </div>
              </div>
              <div className="text bubble right-bottom text2 parallax-front">
                <div className="in">
                  Для неё мы подготовили отдельные дизайн-макеты. Здесь мы проработали структуру и навигацию проекта, удобную компоновку информации как для большого экрана, так и для iPad. 
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/polyanka/img24.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/polyanka/img25.webp')`}}></div></div>
              <div className="text bubble left text3 parallax-front">
                <div className="in">
                  Touch-система стала незаменимым инструментом продаж для менеджеров.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <p><b>Рекламная кампания</b></p>
                  <p>Мы запустили рекламные кампании<br/>по направлениям:</p>
                  <ul className="b30">
                    <li>медиа</li>
                    <li>контекстные показы</li>
                    <li>социальные сети</li>
                    <li>PR-поддержка</li>
                    <li>RTB-платформы</li>
                    <li>тематические ресурсы</li>
                  </ul>
                  <p>Результаты:</p>
                  <ul>
                    <li>оптимизация бюджета</li>
                    <li>увеличение первичных обращений в 2 раза</li>
                    <li>рост узнаваемости бренда</li>
                    <li>увеличение конверсии на сайте за счет использования форм обратной связи</li>
                  </ul>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/polyanka/img26.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/polyanka/img27.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/polyanka/img28.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/polyanka/img29.webp')`}}></div></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-polyanka">
				<Link to="/projects/polyanka/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/polyanka/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/polyanka/logo.svg')`}}></div></div>
						<div className="text middle">
							<div className="in">
								<div className="title">Полянка 44.<br/>Людям мира<br/>от людей мира.</div>
								<p>Комплекс элитных особняков<br/>в историческом центре Москвы</p>
							</div>
						</div>
					</div>
				</Link>
				{this.more()}
			</div>



     <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/greenpolza/"}
				title= { this.props.next? this.props.next.name: "Зеленая польза" } 
				img={ this.props.next? this.props.next.imgCaseBottom: "/cases/img/next/polza.webp" }
			/>
      <HelmetSite title="Полянка 44" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Полянка 44</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Polyanka)