import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Torpedo extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/torpedo/'){
			this.setState({preview: false});
			this.props.setProjectName('Torpedo');
			$('body').addClass('case-expanded hash-torpedo');
			$(window).scrollTop($('#case-torpedo').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-torpedo');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-torpedo');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-torpedo">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/torpedo/bg1.webp')`}}></div>
          </div>
        </div>
        <div className="screen screen2">
          <img src="/cases/img/torpedo/bg2-1.webp" className="img1" />
          <img src="/cases/img/torpedo/bg2-2.webp" className="img2" />
          <img src="/cases/img/torpedo/bg3-2.webp" className="img3" />
          <div className="text bubble left parallax-front text1">
            <div className="in">
              <p>Сайт для легендарного футбольного клуба “Торпедо” Москва</p>
            </div>
          </div>
          <div className="text bubble right parallax-front text2">
            <div className="in">
              <p>На сайте самая актуальная информация по матчам, видео, ленты соц. сетей, информация о турнирах</p>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <img src="/cases/img/torpedo/bg4.webp" />
          <div className="text bubble right parallax-front">
            <div className="in">
              <p>У каждого игрока команды своя собственная страница с полной информацией о его карьере</p>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/torpedo/bg5-1.webp')`}}></div>
          </div>
          <img src="/cases/img/torpedo/bg5-2.webp" />
          <div className="text bubble left parallax-front">
            <div className="in">
              <p>Сайт полностью адаптируется под любые мобильные устройства</p>
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/torpedo/bg6-1.webp')`}}></div>
          </div>
        </div>
        <div className="screen screen7">
          <img src="/cases/img/torpedo/bg6-2.webp" />
          <div className="text bubble right parallax-front">
            <div className="in">
              <p>В сайт встроена удобная система покупки билетов</p>
            </div>
          </div>
        </div>
			</div>

 
      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/generalinvest/"  }
				title= { this.props.next ? this.props.next.name: "General Invest"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/generalinvest.webp" }
			/>

      <HelmetSite title="Торпедо" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Torpedo</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Torpedo)