import React, { Component } from 'react';
import $ from 'jquery';
import 'jquery-touchswipe';
import {Helmet} from 'react-helmet';

import mousePageXY from '../helpers/mousePageXY';


class AboutMobile extends Component{

	componentDidMount(){

		const body = $('body');

		const About = {
			isInited: false,
			isAnim: false,
			isMouseDown: false,
			isDragging: false,
			offsetPosition: 0,
			offsetDirection: 0,
			currentSlide: -1,
			init: function() {
				About.section = $('section.intro.about');

				About.section.swipe('destroy');
				
				About.slider = About.section.find('.slider');
				About.slides = About.section.find('.slide');

				About.createSlider();
				About.createCursor();
				
				About.setStart();
			},
			createSlider: function() {
				
				About.slides.each(function(ind) {
					var slide = $(this);
					var color = slide.data('color');

					var pos = About.slides.first().data('image-pos-mob') * About.section.height() / 100;
					var txt = slide.data('text-pos-mob');
					slide.find('.text').css({'top': txt + '%'});

					if(!slide.find('.title').parent('.bg').length){
						slide.find('.title').wrap('<div class="bg" />');
					}

					slide.attr('data-id', ind).find('.bg').css({'background-color': color, '-webkit-transform': 'translateY(' + pos + 'px)', 'transform': 'translateY(' + pos + 'px)'}).prepend('<div class="image"><div></div></div>');
				});

				About.section.unbind('swipe').swipe({
					swipeStatus: function(event, phase, direction, distance, duration, fingers, fingerData, currentDirection) {
						var dir = direction == 'left' ? 1 : direction == 'right' ? -1 : 0;
						var id = dir > 0 && About.currentSlide < About.slides.length - 1 ? About.currentSlide + 1 : dir < 0 && About.currentSlide > 0 ? About.currentSlide - 1 : -1;
						About.isAnim = false;
						if (phase == 'start') {
							body.addClass('draggable');
							About.isDragging = true;
						}
						else if (phase == 'move') {
							About.setSlide(id, distance * dir);
						}
						else if (phase == 'end' && id != -1) {
							body.removeClass('draggable');
							About.isDragging = false;
							About.setSlide(id);
						}
          },
          allowPageScroll: "vertical",
					excludedElements: 'a, input',
					threshold: 1
				});
			},
			createCursor: function() {
				About.section.find('.cursor').remove();

				About.section.append('<div class="cursor"><div class="next"></div><div class="prev"></div></div>');
				About.cursor = About.section.find('.cursor');
				About.cursor.addClass('forward');
				About.cursor.show();
			},
			followCursor: function (e) {
				var t = $(e.target).closest('.label');
				var pos = mousePageXY(e);
				var ww = $(window).width();
				About.cursor.css({'left': pos.x + 'px', 'top': pos.y + 'px'});
				body.toggleClass('left-side', pos.x <= ww * 0.3 || t.length == 1 && About.currentSlide > t.data('id')).toggleClass('right-side', pos.x >= ww * 0.7 || t.length == 1 && About.currentSlide < t.data('id'));
			},
			setStart: function() {
				About.currentSlide = -1;
				About.isAnim = true;
				About.section.addClass('starting');

				About.setImages(function() {
					About.section.removeClass('starting');
					About.isAnim = false;
					About.setSlide(About.slides.first().data('id'));
				});
			},
			setImages: function(callback) {
				var imgArr = [];
				About.slides.each(function() {
					var slide = $(this);
					var img = slide.data('image-mob');
					if (img != '') {
						imgArr.push(img);
					}
				});
				
				About.slides.each(function() {
					var slide = $(this);
					var img = slide.data('image-mob');
					if (img != '') {
						slide.find('.image div').css({'background-image': 'url(' + img + ')'});
						slide.removeAttr('data-image').removeAttr('data-image-mob');
					}
				});
				About.section.data('loaded', true);
				if (typeof(callback) == 'function') {
					callback();
				}
			},
			setSlide: function(id, distance) {
				var isDragging = About.isDragging;
				if ((About.currentSlide != id || isDragging) && id != -1 && !About.isAnim) {
					About.isAnim = true;

					var slideOld = About.slides.filter('[data-id=' + About.currentSlide + ']');
					var slide = About.slides.filter('[data-id=' + id + ']');
					var offset = id > 0 ? - id * 100 : 0;
					About.cursor.toggleClass('begin', id == About.slides.first().attr('data-id')).toggleClass('end', id == About.slides.last().attr('data-id'));

					var w = About.section.width();
					var h = About.section.height();
					var posOld = slideOld.data('image-pos-mob') || 0;
					var pos = slide.data('image-pos-mob');
					var speed = 1000;
					if (isDragging && typeof(distance) != 'undefined') {
						var coeff = Math.max(Math.min(distance / w, 1), -1);
						offset = Math.min((- About.currentSlide - coeff) * 100, 0);
						pos = posOld + (pos - posOld) * Math.abs(coeff);
						speed = Math.max(speed * (1 - Math.abs(coeff)), 250);
						About.slider.add(About.slider.find('.bg')).css({'-webkit-transition': 'all ' + speed + 'ms ease', 'transition': 'all ' + speed + 'ms ease'});
					}
					offset = Math.round(offset * w / 100);
					pos = Math.round(pos * h / 100);
					About.slider.css({'-webkit-transform': 'translateX(' + offset + 'px)', 'transform': 'translateX(' + offset + 'px)'})
						.find('.bg').css({'-webkit-transform': 'translateY(' + pos + 'px)', 'transform': 'translateY(' + pos + 'px)'});
					body.toggleClass('fixed', About.currentSlide != -1);
					About.cursor.toggleClass('forward', id == 0);
					if (!isDragging) {
						About.currentSlide = id;
					}
					setTimeout(function() {
						About.isAnim = false;
					}, !isDragging ? speed + 100 : 0);
				}
			},
			setPosition: function(percent) {
				if ((About.currentSlide > 0 || percent > 0) && (About.currentSlide < About.slides.length - 1 || percent < 0)) {
					var id = About.currentSlide + (percent > 0 ? 1 : percent < 0 ? -1 : 0);
					var slideOld = About.slides.filter('[data-id=' + About.currentSlide + ']');
					var slide = About.slides.filter('[data-id=' + id + ']');
					var backOld = About.backs.filter('[data-id=' + About.currentSlide + ']');
					var back = About.backs.filter('[data-id=' + id + ']');
					var posOld = slideOld.data('image-pos') || 0;
					var pos = slide.data('image-pos');
					var deg = About.degree * (About.currentSlide + percent);
					var offset = ((About.currentSlide > 0 ? - About.currentSlide : 0) - percent) * 100;
					var offset2 = offset + offset;
					var abs = Math.abs(percent);
					About.labels.css({'-webkit-transform': 'rotate(' + deg + 'deg)', 'transform': 'rotate(' + deg + 'deg)'});
					About.backs.addClass('invisible');
					backOld.removeClass('invisible');
					back.removeClass('invisible');
					if (id > About.currentSlide) {
						if (abs <= 0.3) {
							var l1 = 0,
								r1 = 70 - posOld,
								l2 = posOld + 30,
								r2 = 0;
						}
						else if (abs <= 0.5) {
							var l1 = 0,
								r1 = 70 - pos,
								l2 = pos + 30,
								r2 = 0;
						}
						else {
							var l1 = 0,
								r1 = 70 - pos,
								l2 = pos,
								r2 = 0;
							backOld.removeClass('active');
							back.addClass('active');
						}
					}
					else {
						if (abs <= 0.3) {
							var l1 = posOld,
								r1 = 0,
								l2 = 0,
								r2 = 100 - posOld;
						}
						else if (abs <= 0.5) {
							var l1 = 30,
								r1 = 0,
								l2 = 0,
								r2 = 70;
						}
						else {
							var l1 = pos + 30,
								r1 = 0,
								l2 = 0,
								r2 = 70 - pos;
							backOld.removeClass('active');
							back.addClass('active');
						}
					}
					backOld.css({'left': l1 + '%', 'right': r1 + '%'});
					back.css({'left': l2 + '%', 'right': r2 + '%'});
					About.slider.css({'-webkit-transform': 'translateX(' + offset + '%)', 'transform': 'translateX(' + offset + '%)'});
					About.texts.css({'-webkit-transform': 'translateY(' + offset2 + '%)', 'transform': 'translateY(' + offset2 + '%)'});
					About.offsetPosition = percent > 0.3 ? 1 : percent < -0.3 ? -1 : 0;
					About.offsetDirection = percent > 0 ? 1 : percent < 0 ? -1 : 0;
				}
				else {
					About.offsetPosition = 0;
				}
			}
		};

		About.init();
		//$(window).resize(About.init);
	}

	render(){
		return(

			<section className="intro about">
        <Helmet>
          <title>О нас ' Amio</title>
        </Helmet>
				
				<div className="slider">
					<div className="slide" data-label="1" data-color="#ff3c04" data-image="/img/about-slide1.jpg" data-image-pos="16" data-text-pos="53" data-image-mob="/img/mobile/about-slide1.jpg" data-image-pos-mob="0" data-text-pos-mob="50">
						<div className="title"><div>Мы не <b>роботы</b></div></div>
						<div className="text"><div>
							а люди,
							<br/>но профессиональные и ответственные!
							<br/>Мы можем ошибаться, что свойственно людям,
							<br/>но готовы признавать свои ошибки и оперативно их исправлять.
						</div></div>
					</div>
					<div className="slide" data-label="2" data-color="#ff83a3" data-image="/img/about-slide2.jpg" data-image-pos="26" data-text-pos="53" data-image-mob="/img/mobile/about-slide2.jpg" data-image-pos-mob="11" data-text-pos-mob="58">
						<div className="title"><div>Мы не <b>звёзды</b></div></div>
						<div className="text"><div>
							Блеск кубков и грамот,
							<br/>полученных на рекламных
							<br/>фестивалях не ослепил нас.
							<br/>Мы такие же простые, как раньше!
						</div></div>
					</div>
					<div className="slide" data-label="3" data-color="#ffbd83" data-image="/img/about-slide3.jpg" data-image-pos="35" data-text-pos="53" data-image-mob="/img/mobile/about-slide3.jpg" data-image-pos-mob="19" data-text-pos-mob="64">
						<div className="title"><div>Мы не <b>плюшевые</b></div></div>
						<div className="text"><div>
							Мы имеем свою позицию и уверенность в том, что делаем. Но мы готовы слушать, и слушаем наших клиентов.
						</div></div>
					</div>
					<div className="slide" data-label="4" data-color="#7cbfd0" data-image="/img/about-slide4.jpg" data-image-pos="49" data-text-pos="4" data-image-mob="/img/mobile/about-slide4.jpg" data-image-pos-mob="34" data-text-pos-mob="7">
						<div className="title"><div>Мы не <b>иностранцы</b></div></div>
						<div className="text"><div>
							Хотя у нас, кроме россиян, работают и испанцы, и латыши, и итальянец. Но мы работаем в Москве, говорим с вами на одном языке.
						</div></div>
					</div>
					<div className="slide" data-label="5" data-color="#9a7ccc" data-image="/img/about-slide5.jpg" data-image-pos="59" data-text-pos="10" data-image-mob="/img/mobile/about-slide5.jpg" data-image-pos-mob="42" data-text-pos-mob="14">
						<div className="title"><div>Мы не <b>страшные</b></div></div>
						<div className="text"><div>
							А наоборот, даже очень милые и человечные. Не верите?!
							<br/>Приезжайте к нам в офис! 
							<br/>И мы утомим вас своим гостеприимством!
						</div></div>
					</div>
				</div>
			</section>
	
		)
	}
}

export default AboutMobile