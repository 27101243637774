import React, { Component, Fragment } from 'react';
import {Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import SourcesIndex from './SourcesIndex';
import SourcesMobile from './SourcesMobile';
import SourcesMobileNew from './SourcesMobileNew.js';
import SourcesMarketing from './SourcesMarketing';
import Sources404 from './Sources404';
import SourcesCcp from './SourcesCcp';
import SourcesReality from './SourcesReality';
import SourcesBanksNew from './SourcesBanksNew';
import SourcesAudit from './SourcesAudit';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import Error from '../Error';


const Source = {
	isInited: false,
	init: function() {
		$('.js-parallax').removeClass('animated');

		Source.parallax();
		$(window).unbind('scroll').scroll(Source.parallax);

		$('.js-scrollto').unbind('click').click(function(){
			$('html, body').animate({scrollTop: $('.c-sect--idx2').offset().top - 60},600)
		});
	},
	parallax: function(e) {
		if($('.l-trigger').length) {
			let scroll_top = $(window).scrollTop();
			let trigger_offset = $('.l-trigger').offset().top;
			$('.js-parallax').each(function() {
				let el = $(this);
				let offset = el.offset().top;
				if( +(offset - scroll_top) < trigger_offset - scroll_top) {
					el.addClass('animate');
					(function(elem) {
						setTimeout(function() { elem.addClass('animated') }, 1000);
					})(el);
				}
      });
		}
	}
};
class Sources extends Component{

	componentDidMount() {
		Source.init();

		setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentWillUnmount() {
		$('body').removeClass('fadepage');
	}

	componentDidUpdate(){
		Source.init();
	}

	render(){
		return(
			<Fragment>
        <Switch>
          <Route exact path="/accents/" component={SourcesIndex} />
					<Route path="/accents/mobileNew/" component={SourcesMobileNew} />
          <Route path="/accents/marketing/" component={SourcesMarketing} />
          <Route path="/accents/404/" component={Sources404} />
          <Route path="/accents/ccp/" component={SourcesCcp} />
          <Route path="/accents/reality/" component={SourcesReality} />
          <Route path="/accents/banks/" component={SourcesBanksNew} />
          <Route path="/accents/audit/" component={SourcesAudit} />
          <Route component={Error} />
        </Switch>
			</Fragment>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sources)