import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Emotion extends Component {
	state = {
		preview: true,
    play: false
	}

	componentDidMount(){
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);

    window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

    window.ScrollSmoother.create({
			smooth: 1,
			effects: true,
		});
	}
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

  render(){
    return(
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <div className='case'>
            <div className="case-content">
              <div className="emotion">
                <div className="emotion__block1" data-lazy="/cases/img/emotion/img1.webp"></div>
                <div className="emotion__block2">
                  <div>
                    <img data-lazy="/cases/img/emotion/img2.svg" />
                    <div className="text bubble right parallax-front parallax-up">
                      <div className="in">
                        Брендинг одного из амбициозных проектов компании «Основа», ультрасовременного комплекса в&nbsp;Большом Сити, EMOTION.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="emotion__block3">
                  <img className="emotion__block3-img1" data-lazy="/cases/img/emotion/img16.webp" />
                  <div className="text bubble left-bottom parallax-front parallax-up">
                    <div className="in">
                      Логотип разработан с&nbsp;учетом акцента на динамике архитектурных линий и&nbsp;выражении различных эмоций. Отличается своей динамичностью и&nbsp;одновременно строгим стилем. В&nbsp;нем сочетаются угловатость и&nbsp;округлость, создавая интересное визуальное впечатление.
                    </div>
                  </div>
                </div>
                <div className="emotion__block4">
                  <img className="emotion__block4-img1" data-lazy="/cases/img/emotion/img22.svg" />
                  <img className="emotion__block4-img2" data-lazy="/cases/img/emotion/img6.webp" />
                  <img className="emotion__block4-img3" data-lazy="/cases/img/emotion/img7.webp" />
                </div>
                <div className="emotion__block5" data-lazy="/cases/img/emotion/img14.webp"></div>
                <div className="emotion__block6">
                  <img data-lazy="/cases/img/emotion/img8.webp" />
                </div>
                <div className="emotion__block7" data-lazy="/cases/img/emotion/img15.webp">
                </div>
                <div className="emotion__block8">
                  <div>
                    <img data-lazy="/cases/img/emotion/img9.webp" />
                  </div>
                </div>
                <div className="emotion__block9" data-lazy="/cases/img/emotion/img10.webp">
                  <img data-lazy="/cases/img/emotion/img11.webp" />
                </div>
                <div className="emotion__block10">
                  <div>
                    <img data-lazy="/cases/img/emotion/img17.webp" />
                  </div>
                </div>
              </div>
            </div>



            <CaseFooter 
              to="/projects/plus/" 
              title="Plus Development" 
              img="/cases/img/next/plus.webp" 
            />
            <HelmetSite title={`Emotion`} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Emotion)