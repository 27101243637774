import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Aresidence extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/aresidence/'){
			this.setState({preview: false});
			this.props.setProjectName('Aresidence');
			$('body').addClass('case-expanded hash-aresidence');
			$(window).scrollTop($('#case-aresidence').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-aresidence');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-aresidence');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-aresidence">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <img className="img1" src="/cases/img/aresidence/bg1-2.webp" />
            <img className="img2" src="/cases/img/aresidence/bg1-3.webp" />
            <div style={{backgroundImage: `url('/cases/img/aresidence/bg1-1.webp')`}}></div>
            </div>
          <div className="content narrow">
            <div className="text bubble left parallax-front">
              <div className="in">
                <p>Ансамбль клубных резиденций на Садовнической набережной </p>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <img src="/cases/img/aresidence/bg2.webp" className="mobile" />
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/aresidence/bg2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble right parallax-front text1">
              <div className="in">
                <p>Дизайн выполнен в светлых тонах, ассоциирующихся с солнечным утром.</p>
                <p>Яркими элементами являются бронзовые детали, повторяющие отделку фасадов зданий.</p>
              </div>
            </div>
            <div className="text bubble right parallax-front text2">
              <div className="in">
                <p>Удобный подбор апартаментов возможен на 3D-плане, схеме или в табличном отображении</p>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/aresidence/bg3-1.webp')`}}>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/CW3HGlKevbQ?rel=0&amp;showinfo=0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <img src="/cases/img/aresidence/bg4.webp" className="mobile" />
          <div className="bg"><div style={{backgroundImage: `url('/cases/img/aresidence/bg4.webp')`}}></div></div>
          <div className="content narrow">
            <div className="text bubble right parallax-front">
              <div className="in">
                <p>Все страницы наполнены визуальными образами и представляют из себя мини-лендинги с множеством полезной информации</p>
              </div>
            </div>
          </div>
        </div>
			</div>


      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/kakumae/" }
				title= { this.props.next ? this.props.next.name: "Haven Kakumäe"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/kakumae.webp"}
			/>

      <HelmetSite title="A-Residence" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Aresidence</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Aresidence)