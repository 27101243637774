import React, { Component } from 'react';
import { IMaskInput } from 'react-imask';
import $ from 'jquery';
import ym from 'react-yandex-metrika';

class SourcesMarketingForm extends Component{

  state = {
    name: '',
    phone: '',
    submit: false,
    requisites: false
  }

  tid = null

  componentWillUnmount() {
    clearTimeout(this.tid)
  }

  getDate(){
    var today = new Date(new Date().getTime() + 1000 * 60 * 60 * 24)

    var m = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ]

    var date = today.getDate()
    var month =  today.getMonth()

    return date + " " + m[month]
  }

  check(){
    if(this.state.name.length && this.state.phone.length == 16){
      return true;
    }

    return false;
  }

  submit(){
    if(this.check()){
      clearTimeout(this.tid)
      this.setState({submit: true})

      setTimeout(() => {
        var b = $('.marketing-form').outerHeight()

        if(b >= window.innerHeight){
          $('html, body').animate({scrollTop: $('.marketing-form').offset().top})
        }
        else{

          var pd = (window.innerHeight - b)/2

          $('html, body').animate({scrollTop: $('.marketing-form').offset().top - pd})
        }

      }, 100)


      $.ajax({
        url: '/ajax/call_request.php',
        method: 'post',
        data: this.props.callback ? {...this.state, formid: 'callback'} : {...this.state, formid: 'contacts'},
        success:(data) => {
          this.setState({submit: true})

          if(this.props.close){
            this.tid = setTimeout(() => {
              this.props.close();
            }, 2000);
          }
        },
        error:() => {
          this.setState({submit: true})

          if(this.props.close){
            this.tid = setTimeout(() => {
              this.props.close();
            }, 2000);
          }
        }
      });

      ym('reachGoal', 'click_on_give_number');
      return true;
    }
  }

	render(){

    this.getDate()

    return(
      <section id="marketing-form" className="marketing-form js-parallax fade-in-up marketing-form-requisites">
        <div>
          {!this.state.submit && (
            <div>
              <div className="marketing-title2">
                Оставьте номер<br />
                и мы вам перезвоним
              </div>
              <div className="marketing-form__dsc">До<span>{this.getDate()} 3099</span> года это бесплатно!</div>
            </div>
          )}

          {this.state.submit && (
            <div>
              <div className="marketing-title2">
              Спасибо!
              </div>
              <div className="marketing-form__dsc">Ожидайте звонка.</div>
            </div>
          )}

          {!this.state.submit && (
            <div className="marketing-form__frm">
              <div>
                <input
                  type="text"
                  placeholder="Имя"
                  value={this.state.name}
                  onChange={e => this.setState({name: e.target.value})}
                  onFocus={() => {
                    $('body').addClass('frm-fixed')
                  }}
                  onBlur={() => {
                    $('body').removeClass('frm-fixed')
                  }}
                />
              </div>
              <div>
                <IMaskInput
                  mask="+{7}(000)000-00-00"
                  placeholder="Номер телефона"
                  value={this.state.phone}
                  onAccept={(value, mask) => this.setState({phone: value})}
                  onFocus={() => {
                    $('body').addClass('frm-fixed')
                  }}
                  onBlur={() => {
                    $('body').removeClass('frm-fixed')
                  }}
                />
              </div>
              <div>
                <div className={this.check() ? 'marketing-form__btn' : 'marketing-form__btn disabled'} onClick={() => this.submit()}>Отправить</div>
              </div>
            </div>
          )}
        </div>
        
        {!this.state.submit && (
          <svg width="48" height="56" viewBox="0 0 48 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.3333 2V52" stroke="black" strokeWidth="3" strokeLinecap="square"/>
            <path d="M3 32L24 53L45 32" stroke="black" strokeWidth="3" strokeLinecap="square"/>
          </svg>
        )}
        
        {window.location.pathname == '/advertising/' && (
          <div>
            <div className={this.state.requisites ? 'requisites-lnk active' : 'requisites-lnk'} onClick={() => this.setState({requisites: !this.state.requisites})}>
              Наши реквизиты
              <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.292893 15.3642C-0.0976311 15.7547 -0.0976311 16.3879 0.292893 16.7784C0.683418 17.1689 1.31658 17.1689 1.70711 16.7784L0.292893 15.3642ZM16.1421 1.92915C16.1421 1.37687 15.6944 0.929153 15.1421 0.929153L6.14214 0.929154C5.58985 0.929154 5.14214 1.37687 5.14214 1.92915C5.14214 2.48144 5.58985 2.92915 6.14214 2.92915L14.1421 2.92915L14.1421 10.9292C14.1421 11.4814 14.5899 11.9292 15.1421 11.9292C15.6944 11.9292 16.1421 11.4814 16.1421 10.9292L16.1421 1.92915ZM1.70711 16.7784L15.8492 2.63626L14.435 1.22205L0.292893 15.3642L1.70711 16.7784Z" fill="black"/>
              </svg>
            </div>
            {this.state.requisites && (
              <div className="requisites-dsc">
                Общество с ограниченной ответственностью «Амио» 
                (ООО «Амио») • ИНН 7718888890 • КПП 771801001 • ОГРН 1127746430712 • 
                107061, г. Москва, Преображенская площадь, д.6, пом. XXIII • +74959214170 • amio@amio.ru
              </div>
            )}
          </div>
        )}

        {window.location.pathname == '/projects/all/' && (
            <div>
              <div className={this.state.requisites ? 'requisites-lnk active' : 'requisites-lnk'} onClick={() => this.setState({requisites: !this.state.requisites})}>
                Наши реквизиты
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.292893 15.3642C-0.0976311 15.7547 -0.0976311 16.3879 0.292893 16.7784C0.683418 17.1689 1.31658 17.1689 1.70711 16.7784L0.292893 15.3642ZM16.1421 1.92915C16.1421 1.37687 15.6944 0.929153 15.1421 0.929153L6.14214 0.929154C5.58985 0.929154 5.14214 1.37687 5.14214 1.92915C5.14214 2.48144 5.58985 2.92915 6.14214 2.92915L14.1421 2.92915L14.1421 10.9292C14.1421 11.4814 14.5899 11.9292 15.1421 11.9292C15.6944 11.9292 16.1421 11.4814 16.1421 10.9292L16.1421 1.92915ZM1.70711 16.7784L15.8492 2.63626L14.435 1.22205L0.292893 15.3642L1.70711 16.7784Z" fill="black"/>
                </svg>
              </div>
              {this.state.requisites && (
                <div className="requisites-dsc">
                Общество с ограниченной ответственностью «Мультилайн Интернет Решения» (ООО ««Мультилайн Интернет Решения») • 
                ИНН 9718039113 • КПП 771801001 • ОГРН 5167746420122 • 107061, г. Москва, Преображенская площадь, д.6, пом. XXIII, к.11 • +74959214170 • amio@amio.ru
                </div>
              )}
            </div>
          )}
      </section>
    )
  }
}

export default SourcesMarketingForm