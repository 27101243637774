import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../../node_modules/swiper/swiper-bundle.min.css";
import { useSwiper } from "swiper/react";

class Metropolia extends Component {
	videoRef = React.createRef();
	audioRef = React.createRef();
	swiperRef = React.createRef();
	state = {
		preview: true,
		play: false,
		mobile: window.innerWidth <= 1000
	};

	resize = () => this.setState({mobile: window.innerWidth <= 1000})



	componentDidMount() {

		window.addEventListener('resize', this.resize);

		$(".ahead-img1").on("mousemove", function(e) {
			var x = (e.pageX * 100) / $(window).width();
			var y = (e.pageY * 100) / $(window).height();
			$(".ahead-ya1,.ahead-ya2").css({ left: x + "%", top: y + "%" });
			$(".ahead-hn1,.ahead-hn2").css({
				transform: "translate(-" + x / 10 + "%," + y / 8 + "%)",
			});
			// $('.ahead-ya1,.ahead-ya2').css({'transform': 'translate(-'+(x/2)+'%,'+(y/2)+'%)'});
		});

		if (this.props.pathname == "/projects/ahead/") {
			this.setState({ preview: false });
			this.props.setProjectName("Ahead");
			$("body").addClass("case-expanded hash-ahead");
			$(window).scrollTop($("#case-ahead").offset().top);
			this.props.changeCase(null);
		} else {
			this.setState({ preview: true });
			$("body").removeClass("hash-ahead");
		}
		window.scrollTo(0, 0);
		hit(window.location.pathname);

		setTimeout(() => {
			$("body").addClass("fadepage");
		}, 100);

		window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		// create the smooth scroller FIRST!
		var smoother = window.ScrollSmoother.create({
			smooth: 2,
			effects: true,
			smoothTouch: false,
			normalizeScroll: true,
		});

		if(window.innerWidth <= 1000){
      smoother.kill();
    }

		window.gsap.set(".square", { transformOrigin: "center	" });
		window.gsap.set(".square1", { transformOrigin: "center	" });
		window.gsap.set(".square2", { transformOrigin: "botton	" });
		let numOfRotations = 1.1;
		let numOfRotations1 = 1.1;
		let numOfRotations2 = 1.1;

		const line = window.gsap.utils.toArray(".line");

		line.forEach((line) => {
			window.gsap.to(line, {
				scrollTrigger: {
					invalidateOnRefresh: true,
					trigger: line,
					scrub: true,
					start: "0 100%",
					end: "200% 0",
				},
				left: -1200,
			});
		});

		const ai = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".mw-wrap-flex-1",
				pin: false,
				start: this.state.mobile ?  "top 740px" : "top 130px",
				scrub: true,
				end: "+=" + this.state.mobile ? 0 : 1500,
			},
		});

		ai.to(
			".me-box-in2-c1",
			{
				duration: 0.5,
				width: 1000,
				height: 1000,
			},
			0
		);

		ai.to(
			".me-box-in2-c2",
			{
				duration: 1,
				width: 900,
				height: 900,
			},
			0
		);

		let arch2 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-2-in-img",
				start: "top bottom-=" + window.innerHeight / 5,
				end: "+=" + window.innerHeight / 5,
				scrub: 0.5,
			},
		});

		window.ScrollTrigger.create({
			trigger: ".me-box-home",
			pin: true,
			start: "top top",
			end: "+=" + window.innerHeight /5,
		});

		let about1 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-home",
				start: this.state.mobile? window.innerHeight / 5 : window.innerHeight / 2,
				end: window.innerHeight +  this.state.mobile? window.innerHeight/5 : window.innerHeight / 2 ,
				scrub: 0.5,
			},
		});

		let arch1 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-home-img2",
				start: "top bottom-=" + window.innerHeight,
				end: "+=" + window.innerHeight / 10,
				scrub: 0.5,
			},
		});

		arch1.to(".me-box-home-img2", { top: this.state.mobile? 130 : 0 }, 0);

		arch1.to(".me-box-home-count-in", { top: 0 }, 0);
		arch1.to(".me-box-home-count-s", { top: 0 }, 0);

		let arch7 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-4-in",
				start: "top bottom-=" + window.innerHeight / 5,
				end: "+=" + window.innerHeight,
				scrub: 0.5,
			},
		});

		arch7.to(".me-box-4-in-a", { width: this.state.mobile ? 400 : 1260 }, 0);

		let arch3 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-2-in-img",
				start: "top bottom-=" + window.innerHeight / 2,
				end: "+=" + window.innerHeight / 2,
				scrub: 0.5,
			},
		});

		let arch4 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-3-wrap",
				start: "top bottom-=" + window.innerHeight / 5,
				end: "+=" + window.innerHeight / 5,
				scrub: 0.5,
			},
		});

		let arch5 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-3-wrap",
				start: "top bottom-=" + window.innerHeight / 3,
				end: "+=" + window.innerHeight / 2,
				scrub: 0.5,
			},
		});

		let arch6 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".mw-wrap-flex-in",
				start: "top bottom-=" + window.innerHeight / 3,
				end: "+=" + window.innerHeight / 2,
				scrub: 0.5,
			},
		});

		let arch8 = window.gsap.timeline({
			scrollTrigger: {
				trigger: ".me-box-4-in ",
				start: "top bottom-=" + window.innerHeight / 5,
				end: "+=" + window.innerHeight / 2,
				scrub: 0.5,
			},
		});
		arch8.to(".me-box-4-in img", { translateY: 0 }, 0);

		arch6.to(".mw-wrap-flex-in", { width: 1000 }, 0);

		arch4.to(".me-box-3-wrap img", { translateY: 0 }, 0);
		arch2.to(".me-box-2-in-img img", { translateY: 0 }, 0);
		arch3.to(".me-box-2-in-wrap", { width: 500 }, 0);
		arch5.to(".me-box-3-wrap-in", { width: 500 }, 0);
		var rotate = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square", {
				rotation: () => 30 * numOfRotations,
				duration: 2,
				ease: "none",
			});

		var rotate1 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper1",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square1", {
				rotation: () => 40 * numOfRotations1,
				duration: 2,
				ease: "none",
			});

		var rotate2 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper2",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square2", {
				rotation: () => -60 * numOfRotations2,
				duration: 2,
				ease: "none",
			});

		window.addEventListener("scroll", this.scroll);
	}

	sp = 0;

	scroll = () => {
		if(this.swiperRef.current.swiper){

		const y = window.scrollY;
		if (y < this.sp) {
			this.swiperRef.current.swiper.slidePrev(2000);
		} else {
			this.swiperRef.current.swiper.slideNext(2000);
		}
		this.sp = y;
	}

	
	};

	componentDidUpdate() {
		if (this.props.pathname == "/projects/") {
			$("body").removeClass("case-expanded hash-ahead");
		}

		if (this.state.play) {
			if (this.audioRef.current) {
				this.audioRef.current.play();

				this.audioRef.current.onended = () => {
					this.setState({ play: false });
				};
			}
		} else {
			this.audioRef.current.pause();
		}

		

	}

	componentWillUnmount() {
		$("body").removeClass("fadepage");
		window.removeEventListener('resize', this.resize);
	}

	render() {
		return (
			<div id="smooth-wrapper" className="case me">
				<div id="smooth-content" className="case-content " ref={(el) => (this.scene = el)}>
					<div className="ahead-img1" style={{ backgroundImage: "url('/cases/img/metropolia/h11.webp')" }}>
						<img src="/cases/img/metropolia/logo.webp" />
					</div>
					<div className="me-sec2">
						<Swiper slidesPerView={this.state.mobile ? 5 : 11} centeredSlides={true} spaceBetween={this.state.mobile ? 50:30} className="mySwiper" ref={this.swiperRef}>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl1.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl2.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl3.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl4.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl5.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl6.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl7.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl8.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl9.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl10.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl11.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl1.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl2.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl3.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl4.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl5.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl6.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl7.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl8.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl9.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl10.svg" />
							</SwiperSlide>
							<SwiperSlide>
								<img src="/cases/img/metropolia/sl11.svg" />
							</SwiperSlide>
						</Swiper>
					</div>
					<div className="mw-wrap-flex">
						<div className="text bubble right parallax-front parallax-up">
							<div className="in">
								Метрополия - жилой комплекс,
								<br /> который воплощает дух различных
								<br /> городов мира. Наш сайт приветствует
								<br /> вас на разных языках и, в своей лёгкой
								<br /> и ненавязчивой манере, поддерживает
								<br /> идею настроения разных городов.
							</div>
						</div>
						<img className="shadow me-box-img1 " src="/cases/img/metropolia/2.webp" />
						<div className="me-box-in1">
							<img className="me-box-img2 " src="/cases/img/metropolia/logmini.webp" />
							<div className="me-box-in1-text">
								Победить премии WOW Awards 2022
								<br /> в номинации{" "}
								<a href="https://wowawards.ru/works/2022/33637/" target="_blank">
									“Сайт проекта”
								</a>
							</div>
						</div>
					</div>
					<div className="mw-wrap-flex mw-wrap-flex-1">
						<img className="shadow me-box-img3 " src="/cases/img/metropolia/3.webp" />
						<div className="me-box-in2">
							<div className="text bubble bubble2 left-top parallax-front parallax-up">
								<div className="in">
									Сайт приглашает вас окунуться
									<br />
									в атмосферу разнообразных городов,
									<br /> предлагая не только визуальное
									<br /> представление, но и возможность <br />
									насладиться звуками различных
									<br /> мегаполисов. Хотите услышать как
									<br /> звучит Париж?
								</div>
							</div>
							<div className="me-box-in2-c1"></div>
							<div className="me-box-in2-c2"></div>
							<div className="wave-wrap">
								<div className={this.state.play ? "wawe-wrap-in active" : "wawe-wrap-in"}>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
								<div className="me-box-in2-text">PAR</div>
							</div>
							<div onClick={() => this.setState({ play: !this.state.play })} className="me-box-link">
								Послушать город
							</div>
							<audio ref={this.audioRef} controls src="/video/1.mp3">
								<a href="/video/1.mp3">Download audio</a>
							</audio>
						</div>
					</div>
					<div className="me-box-2" style={{ backgroundImage: "url('/cases/img/metropolia/4.webp')" }}>
						<div className="me-box-2-in-img">
							<img src="/cases/img/metropolia/t1-o.png" />
							<div className="me-box-2-in-wrap">
								<img src="/cases/img/metropolia/t1-o-1.png" />
							</div>

							{/* <div className="me-box-2-in-img-l"></div> */}
						</div>
					</div>
					<div className="mw-wrap-flex me-1 ">
						<div className="me-box-3" style={{ backgroundImage: "url('/cases/img/metropolia/5.webp')" }}>
							<div className="me-box-3-wrap">
								<img src="/cases/img/metropolia/st2.png" />
								<div className="me-box-3-wrap-in">
									<img src="/cases/img/metropolia/st3.png" />
								</div>
							</div>
						</div>
					</div>
					<div className="mw-wrap-flex me2 ">
						<img src="/cases/img/metropolia/6.webp" />
						<div className="mw-wrap-flex-in">
							<img src="/cases/img/metropolia/st5.svg" />
						</div>
					</div>
					<div className="mw-wrap-flex me3 ">
						<img src="/cases/img/metropolia/7.webp" />
					</div>
					<div className="me-box-home">
						<div className="me-box-home-count">
							<div>
								28<span className="me-box-home-count-s">24</span>{" "}
							</div>
							<div>
								этаж <span>27</span> <span className="me-box-home-count-in">23</span>
							</div>
							<div>
								26 <span className="me-box-home-count-s me-box-home-count-l">22</span>{" "}
							</div>
						</div>
						<img className="me-box-home-img1" src="/cases/img/metropolia/h1.webp" />
						<img className="me-box-home-img2" src="/cases/img/metropolia/h2.webp" />
					</div>
					<div className="me-box-4" style={{ backgroundImage: "url('/cases/img/metropolia/8.webp')" }}>
						<div className="me-box-4-in">
							<img src="/cases/img/metropolia/st7.png" />
							<div className="me-box-4-in-a">
								<img src="/cases/img/metropolia/st6.svg" />
							</div>
						</div>
					</div>
					<div className="ahead-imgs">
						<div>
							<img src="/cases/img//metropolia/b1.webp" />
							<div className="text bubble bubble3 right parallax-front parallax-up">
								<div className="in">
								Так выглядит сайт в смартфоне.<br/> Сайт адаптируется под любое<br/> устройство.
								</div>
							</div>
						</div>
						<div className="crimea-midl-inm">
							<img src="/cases/img//metropolia/b2.webp" />
							<img src="/cases/img//metropolia/b3.webp" />
						</div>
						<div>
							<img src="/cases/img//metropolia/b4.webp" />
						</div>
					</div>
					{/*<CaseFooter to="/projects/metropolia/" title="Метрополия" img="/cases/img/next/me.webp" />*/}
      

           <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/detali/"   }
				title= { this.props.next ? this.props.next.name: "ЖК Детали"   } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/detali_next.webp"}
			/>
				</div>

				<HelmetSite title={`Metropolia`} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Metropolia);
