import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Video extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.match.path.includes("video")){

			this.setState({preview: false});
			this.props.setProjectName('Видео');
			$('body').addClass('case-expanded hash-video');
			$(window).scrollTop($('#case-video').offset().top);
			this.props.changeCase(null);

			$('.video').find('.play').unbind('click').bind('click', function() {
				$(this).closest('.controls').toggleClass('paused');
				var v = $(this).closest('.video').find('video').get(0);
				if ($(this).closest('.controls').hasClass('paused')) {
					v.pause();
				}
				else {
					v.play();
				}
			});

			$('.video').find('.sound').unbind('click').bind('click', function(e) {
				$(this).toggleClass('muted').attr('title', $(this).hasClass('muted') ? 'Включить звук' : 'Выключить звук');
				var v = $(this).closest('.video').find('video').get(0);
				v.muted = $(this).hasClass('muted');
				return false;
			});
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-video');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-video');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div case-video="case-video" className="screen screen1">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/video/img2.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear screen1_clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                Добро может сделать каждый. Насколько это просто, мы показали в анимационном фильме про то, как работает «Корзина доброты». Главное – кратко и легко рассказать о такой серьёзной и большой теме. Сценарий, дизайн, анимация, монтаж – также дело наших рук.
                </div>
              </div>
              <div className="video video1">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Z6gI6QR3j7o?rel=0&amp;showinfo=0"></iframe>
              </div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/video/img3.webp')`}}></div></div>
            </div>
            <div className="clear screen2_clear">
              <div className="text bubble left parallax-front">
                <div className="in">
                Кто такой волонтёр? Как стать волонтёром? Чем может помочь волонтёр? На эти вопросы получили ответы сотрудники компании Х5 из анимационного видео. Мы работали над созданием сценария, дизайном кадров в стиле аппликации, затем анимировали и смонтировали всё это в добрый ролик.
                </div>
              </div>
              <div className="video video2">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/xqzdBIPiIW0?rel=0&amp;showinfo=0"></iframe>
              </div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/video/img4.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/video/img5.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/video/img6.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear screen3_clear">
              <div className="text bubble right parallax-front">
                <div className="in">
                Мы создали забавный мультфильм о кредитных опциях банка «Открытие». Казалось бы, серьёзная тема, но если её подать в лёгкой мультяшной форме, восприятие работает лучше.
                </div>
              </div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/video/img7.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/video/img8.webp')`}}></div></div>
              <div className="video video1">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/bQV7KaFiFkE?rel=0&amp;showinfo=0"></iframe>
              </div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/video/img9.webp')`}}></div></div>
            </div>
            <div className="clear screen4_clear">
              <div className="text bubble right parallax-front">
                <div className="in">
                Новогоднее поздравление для клиентов банка «Открытие». Это очередная серия с теми же героями и забавной анимацией.
                </div>
              </div>
              <div className="video video2">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2B6GomzrjcM?rel=0&amp;showinfo=0"></iframe>
              </div>
              <div className="image image4"><div style={{backgroundImage: `url('/cases/img/video/img10.webp')`}}></div></div>
            </div>
            <div className="clear screen5_clear">
              <div className="text bubble left parallax-front">
                <div className="in">
                Даешь анимэ! В этом видео мы рассказали про супермаркет одобренных кредитов «Всегда Да!», визуально обыграли ситуации, чтобы донести условия компании просто и понятно.
                </div>
              </div>
              <div className="video video3">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eWcRepzHbGA?rel=0&amp;showinfo=0"></iframe>
              </div>
              <div className="image image5"><div style={{backgroundImage: `url('/cases/img/video/img11.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="image image6"><div style={{backgroundImage: `url('/cases/img/video/img12.webp')`}}></div></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                Нас не догонят! Видео в поддержку сборной России на Олимпийских Играх.
                </div>
              </div>

              {/*<div className="video video4">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/uxPxwimO2YA?rel=0&amp;showinfo=0"></iframe>
              </div>*/}

              <div className="video video4" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/seria_1.webp')`}}></div>
                <video muted id="video-vid4" poster="/cases/img/video/seria_1.webp">
                  <source type="video/mp4" src="/cases/img/video/seria_1.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>


              <div className="image image7"><div style={{backgroundImage: `url('/cases/img/video/img13.webp')`}}></div></div>
              <div className="image image8"><div style={{backgroundImage: `url('/cases/img/video/img14.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/video/img15.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in center">
                  Включите звук!
                </div>
              </div>
              <div className="video video1" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/vid1.webp')`}}></div>
                <video muted id="video-vid1" poster="/cases/img/video/vid1.webp">
                  <source type="video/mp4" src="/cases/img/video/vid1.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/video/img12.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/video/img5.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right text2 parallax-front">
                <div className="in center">
                Ёмко и лаконично! Промо-видео для <br/> Рост Банка.
                </div>
              </div>
              <div className="video video2" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/vid2.webp')`}}></div>
                <video muted id="video-vid2" poster="/cases/img/video/vid2.webp">
                  <source type="video/mp4" src="/cases/img/video/vid2.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/video/img16.webp')`}}></div></div>
              <div className="image image4"><div style={{backgroundImage: `url('/cases/img/video/img4.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="text bubble right text3 parallax-front">
                <div className="in">
                Смело и ярко! В ролике для М.Видео мы использовали <br/> 8-битный стиль телевизионных приставок 90-х. Ностальгия и агрессия в видео по сценарию РА "Инстинкт".
                </div>
              </div>
              <div className="video video3" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/vid3.webp')`}}></div>
                <video muted id="video-vid3" poster="/cases/img/video/vid3.webp">
                  <source type="video/mp4" src="/cases/img/video/vid3.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>
            </div>
            <div className="clear screen6_clear">
              <div className="text bubble left parallax-front">
                <div className="in">
                Роботы вместо продавцов – это реально! Рекламный видеоролик для Магазина Будущего М.Видео.
                </div>
              </div>
              <div className="image image5" data-image=""><div style={{backgroundImage: `url('/cases/img/video/img17.webp')`}}></div></div>
              <div className="video video4" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/vid4.webp')`}}></div>
                <video muted id="video-vid4" poster="/cases/img/video/vid4.webp">
                  <source type="video/mp4" src="/cases/img/video/vid4.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>
              <div className="image image6" data-image=""><div style={{backgroundImage: `url('/cases/img/video/img8.webp')`}}></div></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="content narrow">
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/video/img18.webp')`}}></div></div>
              <div className="image image2"><div style={{backgroundImage: `url('/cases/img/video/img19.webp')`}}></div></div>
              <div className="image image3"><div style={{backgroundImage: `url('/cases/img/video/img10.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                Драйв и скорость! Дерзкий динамичный ролик в новом «оторванном» стиле GT.
                </div>
              </div>
              <div className="video video1" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/vid5.webp')`}}></div>
                <video muted id="video-vid5" poster="/cases/img/video/vid5.webp">
                  <source type="video/mp4" src="/cases/img/video/vid5.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="image image4"><div style={{backgroundImage: `url('/cases/img/video/img9.webp')`}}></div></div>
              <div className="image image5"><div style={{backgroundImage: `url('/cases/img/video/img20.webp')`}}></div></div>
              <div className="image image6"><div style={{backgroundImage: `url('/cases/img/video/img13.webp')`}}></div></div>
              <div className="text bubble left text2 parallax-front">
                <div className="in">
                Понятно и легко! Видеоролик про удобный и простой конструктор сайтов InternetMe. Совместно с LETO AG.
                </div>
              </div>
              <div className="video video2" data-movie="1">
                <div className="video-bg" style={{backgroundImage: `url('/cases/img/video/vid6.webp')`}}></div>
                <video muted id="video-vid6" poster="/cases/img/video/vid6.webp">
                  <source type="video/mp4" src="/cases/img/video/vid6.mp4"/>
                </video>
                <div className="controls paused">
                  <div className="play"></div>
                  <div className="sound muted" title="Включить звук"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-video">
				<Link to="/projects/video/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/video/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="text logo">
							<div className="image image0"><div style={{backgroundImage: `url('/cases/img/video/img1.webp')`}}></div></div>
							<div className="title">Видео</div>
						</div>
						<div className="text text1">
            У нас есть видеопродакшн, и там ребята творят чудеса. Они рисуют мультфильмы, делают обучающие ролики, занимаются озвучкой и монтируют всё, что попадает в их руки.<br/><br/>
            Берите попкорн и посмотрите, что они наделали. 
						</div>
					</div>
				</Link>
				{this.more()}
			</div>

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/x5/" }
				title= { this.props.next ? this.props.next.name: "Корзина доброты"} 
				img={ this.props.next ? this.props.next.img: "/cases/img/next/x5korz.webp"}
			/>
      <HelmetSite title="Видео" />

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Видео</div>
				<div className="tags"><span>медиа</span><span>видео</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Video)