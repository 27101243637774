import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Ingrad extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/ingrad/'){
			this.setState({preview: false});
			this.props.setProjectName('Ingrad');
			$('body').addClass('case-expanded hash-ingrad');
			$(window).scrollTop($('#case-ingrad').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-ingrad');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-ingrad');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-ingrad">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/ingrad/bg1-1.webp')`}} />
          </div>
        </div>
        <div className="screen screen2">
          <img src="/cases/img/ingrad/bg2-1.webp" className="img1 shadow" />
          <img src="/cases/img/ingrad/bg2-2.webp" className="img2 shadow" />
          <img src="/cases/img/ingrad/bg2-3.webp" className="img3 shadow" />

          <div className="text bubble left parallax-front text1">
            <div className="in">
            Мы сделали сайт для ГК «Инград», где презентовали построенные объекты. На главной странице мы сразу приводим пользователя к выбору недвижимости удобным способом: из списка комплексов или на карте.
            </div>
          </div>

          <div className="text bubble right parallax-front text2">
            <div className="in">
            На странице объекта исчерпывающая информация о комплексе, включая интерактивный генплан и блок с типовыми планировками, сгруппированными по комнатности.
            </div>
          </div>

          <div className="text bubble left parallax-front text3">
            <div className="in">
            Удобный поиск по параметрам с ключевой информацией по каждой квартире. Здесь сразу можно посмотреть планировку, а также переключаться между корпусами. 
            </div>
          </div>
        </div>
        <div className="screen screen3" />
        <div className="screen screen4">
          <img src="/cases/img/ingrad/bg4-1.webp" className="img1 shadow" />
          <img src="/cases/img/ingrad/bg4-2.webp" className="img2 shadow" />
          <img src="/cases/img/ingrad/bg4-3.webp" className="img3" />

          <div className="text bubble right parallax-front text1">
            <div className="in">
            Внутренние страницы сопровождены имиджами с людьми в обычных условиях и с настоящими эмоциями, чтобы передать настроение и атмосферу доверия, уверенности.
            </div>
          </div>

          <div className="text bubble right parallax-front text2">
            <div className="in">
            Так выглядит сайт на мобильных устройствах
            </div>
          </div>
        </div>
			</div>

  

    <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/westgarden/"  }
				title= { this.props.next ? this.props.next.name: `ЖК "West Garden"`   } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/westgarden.webp" }
			/>
      <HelmetSite title="Инград" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Ingrad</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Ingrad)