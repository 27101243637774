import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Afimall extends Component {
	state = {
		preview: true
	}

  handleScroll(){
    $('.animate-scroll-element').each(function(){
      const top = $(this).offset().top - window.innerHeight/2;
      const scr = $(window).scrollTop();

      if(scr >= top){
        $(this).addClass('active');
      }
      else{
        $(this).removeClass('active');
      }
    });
  }

	componentDidMount(){
		if(this.props.pathname == '/projects/afimall/'){
			this.setState({preview: false});
			this.props.setProjectName('bsg');
			$('body').addClass('case-expanded hash-afimall');
			$(window).scrollTop($('.afimall').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('afimall');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);

    window.addEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-afimall');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
    window.removeEventListener('scroll', this.handleScroll);
  }

  render(){
    return(
      <div className='case'>
        <div className="case-content">
          <div className="afimall">
            <div className="afimall-img1"></div>

            <div className="afimall-box">
              <img className="shadow afimall-box-img1 shadow" src="/cases/img/afimall/img2.webp" />
              <img className="shadow afimall-box-img2" src="/cases/img/afimall/img3.webp" />
              <img className="shadow afimall-box-img3" src="/cases/img/afimall/img4.webp" />

              <div className="text text1 bubble left parallax-front parallax-up">
                <div className="in">Маша и Медведь забрались в ТРЦ Афимолл Сити и хотят, чтобы про них сняли фильм.<br /><br />Мы сделали специальный сайт, где каждый может попробовать себя в роли режиссёра.</div>
              </div>
              <div className="text text2 bubble left parallax-front parallax-up">
                <div className="in">Лучших режиссёров ждёт множество подарков в специальном магазине</div>
              </div>

              <img className="afimall-box-dec1" src="/cases/img/afimall/img22.webp" />
              <img className="afimall-box-dec2" src="/cases/img/afimall/img23.webp" />
            </div>

            <div className="afimall-slider-wrap">
              <div className="text bubble right-top parallax-front parallax-up">
                <div className="in">В веселом стикерпаке найдётся картинка для любого случая</div>
              </div>

              <div className="slider parallax-slider afimall-slider">
                <div className="line">
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img11.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img12.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img13.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img14.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img15.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img16.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img17.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img18.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img19.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img20.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/afimall/img21.webp')`}}></div></div>
                </div>
              </div>
            </div>

            <div className="afimall-box2">
              <img className="shadow afimall-box2-img1" src="/cases/img/afimall/img5.webp" />
              <img className="shadow afimall-box2-img2" src="/cases/img/afimall/img6.webp" />

              <div className="text text1 bubble left parallax-front parallax-up">
                <div className="in">На сайте всегда есть чем заняться: посмотреть новые серии “Маша и Медведь”, попытать удачу, сыграть в игры и многое другое</div>
              </div>

              <div className="afimall-box2-in">
                <div className="text text2 bubble right parallax-front parallax-up">
                  <div className="in">Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство</div>
                </div>

                <img className="afimall-box2-in-img1" src="/cases/img/afimall/img7.webp" />
                <img className="afimall-box2-in-img2" src="/cases/img/afimall/img8.webp" />
                <img className="afimall-box2-in-img3" src="/cases/img/afimall/img9.webp" />
              </div>

              <img className="afimall-box2-dec1" src="/cases/img/afimall/img24.webp" />
              <img className="afimall-box2-dec2" src="/cases/img/afimall/img25.webp" />
              <img className="afimall-box2-dec3" src="/cases/img/afimall/img26.webp" />
              <img className="afimall-box2-dec4" src="/cases/img/afimall/img27.webp" />
            </div>

          </div>
        </div>
				
    

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/cto"  }
				title= { this.props.name ? this.props.next.name: "X5 СТО"  } 
				img={ this.props.name ? this.props.next.name:"/cases/img/next/cto_next.webp"}
			/>
        <HelmetSite title={`Афимолл Сити + Маша и Медведь`} />
      </div>
    )
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Afimall)