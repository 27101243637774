import React, { useEffect, useState } from 'react';
import './alts.css'
import $ from 'jquery'
import Alt1 from './Alt1';
import Alt2 from './Alt2';
import Alt3 from './Alt3';
import Alt4 from './Alt4';
import Alt5 from './Alt5';

const Alts = () => {

  const [item, setItem] = useState(null)

  useEffect(() => {
    $('.main a').hover(
      function(index){
        setItem($(this).index() + 1)
        $('body').addClass('alts-show')
      },
      function(){
        setItem(null)
        $('body').removeClass('alts-show')
      }
    );

    return () => {
      $('body').removeClass('alts-show')
    }

  },[]);

  var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

  if(supportsTouch) return null;

  return(
    <>
      {item == 1 && <Alt1 />}
      {item == 3 && <Alt5 />}
      <Alt2 active={item == 4} />
      {item == 5 && <Alt3 />}
      {item == 2 && <Alt4 />}
    </>
  )
}

export default Alts;