import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

import SourcesMarketingForm from "./SourcesMarketingForm";
import mapStateToProps from "../../store/mapStateToProps";
import mapDispatchToProps from "../../store/mapDispatchToProps";
import { default as SliderSlick } from "react-slick";
import "slick-carousel/slick/slick.css";
import $ from "jquery";
import "jquery.transit";
import { hit } from "../metrics";
import ym from "react-yandex-metrika";

class SourcesMarketing extends Component {

	state = {
    popup: false
	}

	sliderLogo = React.createRef();

	h = 1500;

	componentDidMount() {
		this.props.setPageId("sources-marketing");
		$("body").addClass("page-sources tags-expanded hash-marketing");
		window.scrollTo(0, 0);
		hit(window.location.pathname);

		window.addEventListener("scroll", this.scroll);

		if (this.sliderLogo) {
			setTimeout(() => {
        //this.sliderLogo.slickNext();
			}, 1000);
		}

		//$('.marketing-footer-height').height(this.h)
	}

	scroll = () => {
		var top = $(".marketing-work-detect").offset().top;
		var scr =
			$(window).scrollTop() +
			$("header").outerHeight() +
			$(".sources-tags").outerHeight();

		if ($(window).scrollTop() > 0) {
			$("body").addClass("fixed");
		} else {
			$("body").removeClass("fixed");
		}

		if (scr > top) {
			$(".marketing-work__img").addClass("active");
		}

		/*var element = $('.marketing-blocks');

    if(scr > top){
      var range = scr - top;
      var p = this.h/6

      if(range >= this.h){
        element.css({y: this.h})
        $('.marketing-work').attr('data-type', 6)
      }
      else{
        element.css({y: parseInt(range)})

        if(range > p){
          $('.marketing-work').attr('data-type', 2)
        }
        if(range > p*2){
          $('.marketing-work').attr('data-type', 3)
        }
        if(range > p*3){
          $('.marketing-work').attr('data-type', 4)
        }
        if(range > p*4){
          $('.marketing-work').attr('data-type', 5)
        }
        if(range > p*5){
          $('.marketing-work').attr('data-type', 6)
        }
      }
    }
    else{
      element.css({y: 0})
      $('.marketing-work').attr('data-type', 1)
    }*/
	};

	slider2Change = (currentSlide) => {
		const track = $(".track");
		const track_inner = track.find("span");
		const trackWidth = track.width();
		const slidesLen = $(".main-slider-wrap .slick-slide").length;
		let pos = ((trackWidth - 58) * currentSlide) / (slidesLen - 1);
		track_inner.css({
			transform: "translate3d(" + pos + "px, 0px, 0px)",
			transition: "transform 50ms",
		});
	};

	slider3Change = (currentSlide) => {
		const size = $(".nmark__slider-wrap path").length;
		const slides = $(".nmark__slider-wrap .nmark__slider-item").length - 1;
		const step = $(".nmark__slider-buttons > span").width() / slides;
		$(".nmark__slider-wrap path").attr("fill", "#000000");

		for (let i = 0; i < 10; i++) {
			const rand = parseInt(Math.random() * size);
			$('.nmark__slider-wrap path[data-id="' + rand + '"]').attr(
				"fill",
				"#ffffff"
			);
		}
		$(".nmark__slider-buttons > span > span").css({ x: currentSlide * step });
	};

	next = () => {
		this.slider.slickNext();
	};
	previous = () => {
		this.slider.slickPrev();
	};

	componentWillUnmount() {
		$("body").removeClass("page-sources tags-expanded hash-marketing");
		$(window).unbind("resize");
		window.removeEventListener("scroll", this.scroll);
	}

	render() {
		const settings1 = {
			dots: false,
			infinite: true,
			speed: 3000,
			autoplaySpeed: 3000,
			autoplay: true,
			slidesToShow: 4,
			slidesToScroll: 4,
			initialSlide: 0,
			responsive: [
				{
					breakpoint: 2000,
					settings: {
						slidesToShow: 8,
						slidesToScroll: 8,
					},
				},
				{
					breakpoint: 1800,
					settings: {
						slidesToShow: 7,
						slidesToScroll: 7,
					},
				},
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 6,
					},
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5,
					},
				},
				{
					breakpoint: 950,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
					},
				},
				{
					breakpoint: 700,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					},
				},
				{
					breakpoint: 420,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					},
				},
			],
		};

		const settings2 = {
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			infinite: false,
			afterChange: this.slider2Change,
		};

		const settings3 = {
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			infinite: false,
			afterChange: this.slider3Change,
			variableWidth: true,
		};

		const bg1 = () => (
			<div className="bg-wr">
				<div className="parallax-section pos1" data-speed="0.3">
					<span className="moving-block type1"></span>
				</div>
				<div className="parallax-section pos2" data-speed="0.3">
					<span className="moving-block type2">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos3" data-speed="0.3">
					<span className="moving-block type3">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos4" data-speed="0.3">
					<span className="moving-block type4">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos5" data-speed="0.3">
					<span className="moving-block type1"></span>
				</div>
				<div className="parallax-section pos6" data-speed="0.3">
					<span className="moving-block type1 scale"></span>
				</div>
				<div className="parallax-section pos7" data-speed="0.3">
					<span className="moving-block type4">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos8" data-speed="0.3">
					<span className="moving-block type5">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos9" data-speed="0.3">
					<span className="moving-block type1"></span>
				</div>
				<div className="parallax-section pos10" data-speed="0.3">
					<span className="moving-block type3">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos11" data-speed="0.3">
					<span className="moving-block type6">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos12" data-speed="0.3">
					<span className="moving-block type7">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos13" data-speed="0.3">
					<span className="moving-block type2">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos14" data-speed="0.3">
					<span className="moving-block type4">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos15" data-speed="0.3">
					<span className="moving-block type5 type8">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos16" data-speed="0.3">
					<span className="moving-block type10">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos17" data-speed="0.3">
					<span className="moving-block type5">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos18" data-speed="0.3">
					<span className="moving-block type11">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos19" data-speed="0.3">
					<span className="moving-block type10">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos20" data-speed="0.3">
					<span className="moving-block type5">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos21" data-speed="0.3">
					<span className="moving-block type6">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos22" data-speed="0.3">
					<span className="moving-block type6">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos23" data-speed="0.3">
					<span className="moving-block type5 type12">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos24" data-speed="0.3">
					<span className="moving-block type6">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos25" data-speed="0.3">
					<span className="moving-block type1 scale"></span>
				</div>
				<div className="parallax-section pos26" data-speed="0.3">
					<span className="moving-block type10">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos27" data-speed="0.3">
					<span className="moving-block type5 type9">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos28" data-speed="0.3">
					<span className="moving-block type1 scale"></span>
				</div>
				<div className="parallax-section pos29" data-speed="0.3">
					<span className="moving-block type10">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos30" data-speed="0.3">
					<span className="moving-block type1"></span>
				</div>
				<div className="parallax-section pos31" data-speed="0.3">
					<span className="moving-block type3">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos32" data-speed="0.3">
					<span className="moving-block type1"></span>
				</div>
				<div className="parallax-section pos33" data-speed="0.3">
					<span className="moving-block type7">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos34" data-speed="0.3">
					<span className="moving-block type1"></span>
				</div>
				<div className="parallax-section pos35" data-speed="0.3">
					<span className="moving-block type3">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos36" data-speed="0.3">
					<span className="moving-block type3">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos37" data-speed="0.3">
					<span className="moving-block type4">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos38" data-speed="0.3">
					<span className="moving-block type2">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos39" data-speed="0.3">
					<span className="moving-block type10">
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos40" data-speed="0.3">
					<span className="moving-block type5 type9">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos41" data-speed="0.3">
					<span className="moving-block type3">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos42" data-speed="0.3">
					<span className="moving-block type4">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos43" data-speed="0.3">
					<span className="moving-block type2">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos44" data-speed="0.3">
					<span className="moving-block type5 type9">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos45" data-speed="0.3">
					<span className="moving-block type12">
						<i></i>
					</span>
				</div>
				<div className="parallax-section pos46" data-speed="0.3">
					<span className="moving-block type5 type9">
						<b></b>
						<i></i>
						<i></i>
					</span>
				</div>
			</div>
		);

		return (
			<section className="intro sources sources_marketing">
				<div className={this.state.popup ? 'contacts-call-me-popup active' : 'contacts-call-me-popup'}>
          <div><SourcesMarketingForm close={() => this.setState({popup: false})} /></div>

          <div className="contacts-call-me-popup__close" onClick={() => this.setState({popup: false})}></div>
        </div>
				<Helmet>
					<title>Маркетинг в недвижимости ' Акценты ' Amio</title>
				</Helmet>
				<div className="case-content marketing-content">
					{bg1()}
					<section className="first-child js-parallax fade-in-up">
						<div className="marketing-title">
							Комплексный маркетинг <br />
							в&nbsp;недвижимости
						</div>
						<div className="marketing-text-block">
							Когда деревья были большие, а домов в Москве <br /> было намного
							меньше, чем сейчас, мы уже начали заниматься
							<br /> созданием и рекламой проектов недвижимости в<br />{" "}
							интернет-пространстве.
						</div>
					</section>
					<section>
						<SliderSlick
							{...settings1}
							ref={(slider) => (this.sliderLogo = slider)}
							className="logo-slider js-parallax fade-in-up"
						>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln2.png"
											style={{ width: "132px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln2.png"
											style={{ width: "132px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln3.png"
											style={{ width: "116px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln3.png"
											style={{ width: "116px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln4.png"
											style={{ width: "230px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln4.png"
											style={{ width: "230px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln5.png"
											style={{ width: "124px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln5.png"
											style={{ width: "124px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln6.png"
											style={{ width: "172px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln6.png"
											style={{ width: "172px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln7.png"
											style={{ width: "186px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln7.png"
											style={{ width: "186px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln8.png"
											style={{ width: "115px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln8.png"
											style={{ width: "115px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln9.png"
											style={{ width: "131px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln9.png"
											style={{ width: "131px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln10.png"
											style={{ width: "105px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln10.png"
											style={{ width: "105px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln1.png"
											style={{ width: "88px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln1.png"
											style={{ width: "88px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
							<div>
								<div className="slick-item">
									<i className="first-child">
										<img
											src="/sources/img/marketing/logos/ln11.png"
											style={{ width: "137px" }}
											alt=""
										/>
									</i>
									<i>
										<img
											src="/sources/img/marketing/logos/ln11.png"
											style={{ width: "137px" }}
											alt=""
										/>
									</i>
								</div>
							</div>
						</SliderSlick>
					</section>
					<div className="gray-bg" id="js-gray"></div>
					<section className="main-slider-wrap js-parallax fade-in-up">
						<SliderSlick
							{...settings2}
							ref="jsMainSlider"
							className="main-slider js-main-slider"
						>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img
											src="/sources/img/marketing/ahed_logo.svg"
											alt=""
										/>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/ahed_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/ahed_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
									   Многофункциональный комплекс в&nbsp;центре Кутузовского проспекта
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/ahed_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/ahed_img2.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img
											src="/sources/img/marketing/genesis_logo.svg"
											alt=""
										/>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/genesis_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/genesis_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
									Девелопер нового поколения
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/genesis_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/genesis_img2.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img
											src="/sources/img/marketing/westgarden_logo.svg"
											alt=""
										/>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/westgarden_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/westgarden_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Создание сайта проекта для компании&nbsp;"Интеко"
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/westgarden_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/westgarden_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img
											src="/sources/img/marketing/repinskoe_logo.svg"
											alt=""
										/>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/repinskoe_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/repinskoe_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Клубный посёлок. Загородные дома haute&nbsp;couture
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/repinskoe_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/repinskoe_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/f92_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/f92_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/f92_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Самый высокий офис в сверхвысоком небоскрёбе Европы
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/f92_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/f92_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/armani_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/armani_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/armani_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Armani / Casa Эксклюзивные резиденции
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/armani_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/armani_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/kmost_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/kmost_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/kmost_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Kuznetsky Most 12. Пентхаусы
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/kmost_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/kmost_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/estatet_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/estatet_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/estatet_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Агентство недвижимости Est-a-tet
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/estatet_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/estatet_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img
											src="/sources/img/marketing/sadkvartal_logo.svg"
											alt=""
										/>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/sadkvartal_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/sadkvartal_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Элитные квартиры в Хамовниках
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/sadkvartal_img1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/sadkvartal_img2.jpg')`,
										}}
									></div>
								</div>
							</div>

							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/7_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/7_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/7_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Сайт для современного бизнес-центра в Санкт-Петербурге
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/7.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/7_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/8_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/8_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/8_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Дизайн корпоративного сайта для интеллигентного девелопера
										Москвы
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/8.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/8_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/9_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/9_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/9_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Сайт для зонтичного бренда компании «Лидер Инвест»
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/9.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/9_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/10_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/10_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/10_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Видео-сайт для жилого квартала Искра Парк
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/10.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/10_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/11_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/11_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/11_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Мечта каждого, кто влюблён в Санкт–Петербург
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/11.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/11_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/12_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/12_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/12_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Сайт ансамбля клубных резиденций
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/12.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/12_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/13_logo.png" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/13_logo.png')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/13_logo.png')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Сайт уникальной марины в Финском заливе
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/13.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/13_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/14_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/14_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/14_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Корпоративный сайт для швейцарского инвестиционного холдинга
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/14.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/14_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/15_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/15_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/15_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Зажигательный мини-сайт для яркого проекта от компании
										Донстрой
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/15.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/15_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/17_logo.png" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/17_logo.png')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/17_logo.png')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Создание дизайна корпоративного сайта
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/17.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/17_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/sources/img/marketing/16_logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/16_logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/sources/img/marketing/16_logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Сайт для элитного квартала в Санкт-Петербурге
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/16.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/16_1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo js-parallax fade-in-up">
										<img src="/cases/img/polyanka-logo.svg" alt="" />
										<i
											className="first-child"
											style={{
												backgroundImage: `url('/cases/img/polyanka-logo.svg')`,
											}}
										></i>
										<i
											style={{
												backgroundImage: `url('/cases/img/polyanka-logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info js-parallax fade-in-up">
										Разработка сайта комплекса элитных особняков «Полянка/44»
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/1.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/1-1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/cases/img/tushino-logo.png" />
										<i
											style={{
												backgroundImage: `url('/cases/img/tushino-logo.png')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/cases/img/tushino-logo.png')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Разработка сайта квартала «Тушино–2018»
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/2.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/2-2.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/cases/img/comcity-logo-black.png" alt="" />
										<i
											style={{
												backgroundImage: `url('/cases/img/comcity-logo-black.png')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/cases/img/comcity-logo-black.png')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Разработка сайта для офисного парка класса А «Comcity»
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/3.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/3-1.jpg')`,
										}}
									></div>
								</div>
							</div>
							<div className="main-slider__item js-slick-item">
								<div className="main-slider__text">
									<div className="main-slider__text-logo">
										<img src="/cases/img/parkmira-logo.svg" alt="" />
										<i
											style={{
												backgroundImage: `url('/cases/img/parkmira-logo.svg')`,
											}}
											className="first-child"
										></i>
										<i
											style={{
												backgroundImage: `url('/cases/img/parkmira-logo.svg')`,
											}}
										></i>
									</div>
									<div className="main-slider__text-info">
										Live & Work. Web-сайт бизнес парка с апартаментами для
										компании Sminex.
									</div>
								</div>
								<div
									className="main-slider__image"
									style={{
										backgroundImage: `url('/sources/img/marketing/6.jpg')`,
									}}
								>
									<div
										className="main-slider__image-in"
										style={{
											backgroundImage: `url('/sources/img/marketing/6-1.jpg')`,
										}}
									></div>
								</div>
							</div>
						</SliderSlick>
						<div className="track">
							<span></span>
						</div>
					</section>
					<section className="sect-last">
						<div className="marketing-title2 js-parallax fade-in-up">
							Комплексный <br />
							подход
						</div>
						<div className="nmark__slider-wrap js-parallax fade-in-up">
							<svg
								width="231pt"
								height="231pt"
								viewBox="0 0 231 231"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="#000000ff">
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 85.24 3.18 C 86.28 0.28 90.82 0.09 92.15 2.86 C 92.94 5.14 92.56 7.61 92.67 9.97 C 98.98 13.16 105.31 16.31 111.64 19.44 C 113.48 20.34 115.75 21.58 115.45 24.01 C 115.55 35.34 115.54 46.67 115.46 58.00 C 115.86 61.09 111.96 63.23 109.52 61.40 C 107.64 60.27 107.88 57.84 107.78 55.96 C 107.83 46.04 107.80 36.12 107.82 26.20 C 101.48 23.01 95.13 19.85 88.78 16.68 C 82.42 19.86 76.05 23.04 69.69 26.22 C 69.69 36.15 69.68 46.08 69.72 56.01 C 69.59 57.76 69.94 60.08 68.17 61.15 C 63.79 63.87 58.96 65.79 54.43 68.24 C 54.32 77.83 54.68 87.44 54.28 97.02 C 54.08 101.21 47.12 101.24 46.85 97.09 C 46.42 87.74 46.85 78.36 46.65 69.00 C 46.80 66.84 46.24 63.87 48.53 62.65 C 52.89 60.11 57.55 58.17 62.00 55.80 C 62.02 45.52 61.97 35.24 62.00 24.96 C 61.88 23.11 62.43 21.09 64.30 20.32 C 71.09 16.71 78.07 13.47 84.90 9.93 C 84.94 7.68 84.75 5.39 85.24 3.18 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 186.27 24.22 C 188.80 22.76 192.24 24.96 191.89 27.89 C 191.94 52.33 192.04 76.79 191.83 101.24 C 201.16 104.99 210.69 108.21 220.01 111.96 C 222.61 112.75 222.53 115.75 222.53 117.95 C 222.44 130.95 222.58 143.95 222.47 156.95 C 222.92 159.83 219.76 162.38 217.09 161.05 C 215.01 160.31 214.75 157.91 214.80 156.03 C 214.81 143.41 214.81 130.80 214.81 118.19 C 205.82 114.81 196.82 111.45 187.83 108.09 C 186.03 107.50 184.12 106.23 184.31 104.09 C 184.15 82.80 184.30 61.51 184.25 40.23 C 171.52 43.40 158.79 46.59 146.07 49.77 C 145.90 55.51 146.40 61.27 145.85 66.99 C 145.11 70.71 138.95 70.44 138.55 66.66 C 138.08 61.12 138.53 55.54 138.33 49.99 C 138.35 47.53 137.99 43.81 141.15 43.13 C 155.43 39.30 169.85 35.94 184.18 32.25 C 184.47 29.58 183.37 25.80 186.27 24.22 Z"
									/>
									<path
										data-id="7"
										fill="#000000"
										opacity="1.00"
										d=" M 78.47 32.46 C 80.56 30.24 84.88 31.79 84.88 34.90 C 85.11 38.15 85.36 41.52 84.54 44.71 C 82.96 48.21 77.03 46.85 77.32 42.94 C 77.41 39.52 76.28 35.38 78.47 32.46 Z"
									/>
									<path
										data-id="8"
										fill="#000000"
										opacity="1.00"
										d=" M 94.23 32.06 C 96.48 30.41 100.22 32.06 100.15 34.96 C 100.35 38.20 100.60 41.55 99.81 44.74 C 98.24 48.16 92.36 46.91 92.63 43.01 C 92.81 39.45 91.34 34.80 94.23 32.06 Z"
									/>
									<path
										data-id="10"
										fill="#000000"
										opacity="1.00"
										d=" M 95.40 54.47 C 97.28 53.94 99.61 55.05 100.01 57.05 C 100.47 60.31 100.47 63.67 100.02 66.93 C 99.43 70.16 94.44 70.65 93.04 67.74 C 92.16 64.59 92.49 61.23 92.64 58.00 C 92.61 56.35 93.80 54.85 95.40 54.47 Z"
									/>
									<path
										data-id="35"
										fill="#000000"
										opacity="1.00"
										d=" M 156.47 54.48 C 158.36 53.93 160.75 55.04 161.14 57.07 C 161.59 60.34 161.59 63.69 161.14 66.96 C 160.51 70.17 155.58 70.63 154.17 67.76 C 153.28 64.61 153.62 61.24 153.77 58.01 C 153.73 56.38 154.90 54.87 156.47 54.48 Z"
									/>
									<path
										data-id="36"
										fill="#000000"
										opacity="1.00"
										d=" M 171.52 54.56 C 173.54 53.79 176.21 55.09 176.46 57.33 C 176.78 60.65 176.92 64.07 176.31 67.37 C 175.08 71.05 168.91 70.02 169.04 66.11 C 169.26 62.39 167.31 56.58 171.52 54.56 Z"
									/>
									<path
										data-id="9"
										fill="#000000"
										opacity="1.00"
										d=" M 78.44 55.40 C 80.57 53.14 84.94 54.74 84.89 57.90 C 85.09 61.14 85.38 64.51 84.52 67.68 C 82.97 71.08 77.19 69.82 77.35 66.05 C 77.38 62.57 76.30 58.40 78.44 55.40 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 107.81 74.95 C 107.63 72.76 108.41 70.07 110.92 69.68 C 114.15 69.82 117.24 70.93 120.37 71.66 C 137.70 76.00 155.03 80.34 172.36 84.65 C 173.92 85.12 175.97 85.61 176.38 87.46 C 176.84 89.93 176.61 92.47 176.65 94.98 C 176.56 133.67 176.72 172.36 176.57 211.05 C 176.80 214.00 173.05 215.94 170.70 214.25 C 168.84 213.19 168.98 210.84 168.91 209.01 C 168.97 169.93 168.92 130.85 168.94 91.77 C 151.13 87.32 133.32 82.86 115.51 78.41 C 115.51 104.28 115.48 130.15 115.52 156.02 C 115.64 158.03 115.19 160.58 112.90 161.19 C 110.28 162.19 107.48 159.73 107.85 157.02 C 107.75 129.66 107.85 102.30 107.81 74.95 Z"
									/>
									<path
										data-id="11"
										fill="#000000"
										opacity="1.00"
										d=" M 64.42 77.53 C 66.48 76.63 69.26 77.99 69.52 80.30 C 69.84 83.62 70.00 87.05 69.34 90.34 C 68.20 93.52 62.93 93.26 62.23 89.93 C 61.78 86.96 61.86 83.94 62.08 80.96 C 62.05 79.45 63.00 78.04 64.42 77.53 Z"
									/>
									<path
										data-id="12"
										fill="#000000"
										opacity="1.00"
										d=" M 79.48 77.62 C 81.81 76.42 84.98 78.24 84.90 80.92 C 85.09 84.12 85.38 87.46 84.52 90.59 C 82.96 94.02 77.16 92.73 77.34 88.92 C 77.54 85.27 75.77 79.94 79.48 77.62 Z"
									/>
									<path
										data-id="13"
										fill="#000000"
										opacity="1.00"
										d=" M 95.35 77.40 C 97.26 76.83 99.65 77.99 100.02 80.03 C 100.46 83.29 100.47 86.64 100.01 89.89 C 99.34 93.44 93.55 93.48 92.79 89.96 C 92.35 87.00 92.43 83.99 92.63 81.02 C 92.59 79.35 93.74 77.80 95.35 77.40 Z"
									/>
									<path
										data-id="14"
										fill="#000000"
										opacity="1.00"
										d=" M 79.43 100.57 C 81.45 99.49 84.42 100.72 84.77 103.06 C 85.20 106.29 85.23 109.61 84.74 112.83 C 84.07 116.14 78.82 116.46 77.64 113.33 C 76.93 110.30 77.15 107.14 77.31 104.06 C 77.33 102.62 78.06 101.16 79.43 100.57 Z"
									/>
									<path
										data-id="15"
										fill="#000000"
										opacity="1.00"
										d=" M 95.32 100.33 C 97.25 99.72 99.72 100.94 100.03 103.03 C 100.46 106.30 100.48 109.67 99.99 112.94 C 99.18 116.35 93.60 116.35 92.81 112.93 C 92.34 109.97 92.43 106.94 92.63 103.96 C 92.60 102.32 93.69 100.72 95.32 100.33 Z"
									/>
									<path
										data-id="22"
										fill="#000000"
										opacity="1.00"
										d=" M 125.46 100.48 C 127.80 99.38 130.86 101.26 130.74 103.90 C 130.90 107.09 131.18 110.40 130.36 113.52 C 128.56 117.28 122.39 115.23 123.13 111.12 C 123.43 107.66 121.68 102.48 125.46 100.48 Z"
									/>
									<path
										data-id="23"
										fill="#000000"
										opacity="1.00"
										d=" M 140.48 100.60 C 142.50 99.48 145.56 100.67 145.87 103.06 C 146.20 106.55 146.47 110.21 145.58 113.63 C 143.95 116.88 138.36 115.63 138.47 111.93 C 138.67 108.27 136.93 103.05 140.48 100.60 Z"
									/>
									<path
										data-id="24"
										fill="#000000"
										opacity="1.00"
										d=" M 156.28 100.38 C 158.25 99.66 160.85 100.89 161.16 103.06 C 161.58 106.31 161.60 109.66 161.11 112.91 C 160.35 116.36 154.72 116.35 153.93 112.92 C 153.47 109.96 153.55 106.93 153.76 103.95 C 153.73 102.36 154.74 100.83 156.28 100.38 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 16.42 110.33 C 17.07 107.92 19.88 107.75 21.95 107.79 C 35.97 107.83 50.00 107.82 64.02 107.79 C 65.66 107.90 67.69 107.72 68.81 109.21 C 69.89 110.51 69.59 112.35 69.72 113.93 C 69.61 146.28 69.79 178.64 69.63 210.98 C 69.86 213.92 66.20 215.94 63.80 214.28 C 61.89 213.25 62.02 210.88 61.97 209.03 C 62.04 177.86 61.98 146.68 62.00 115.50 C 49.29 115.50 36.59 115.50 23.88 115.50 C 23.75 122.04 24.12 128.59 23.74 135.12 C 23.62 139.00 17.42 139.64 16.41 135.94 C 15.77 131.64 16.24 127.28 16.13 122.96 C 16.24 118.75 15.76 114.50 16.42 110.33 Z"
									/>
									<path
										data-id="1"
										fill="#000000"
										opacity="1.00"
										d=" M 33.47 123.54 C 35.51 122.37 38.65 123.61 38.93 126.06 C 39.32 129.33 39.37 132.69 38.84 135.95 C 37.98 139.13 32.89 139.28 31.78 136.19 C 31.13 133.16 31.35 130.01 31.50 126.93 C 31.50 125.55 32.18 124.16 33.47 123.54 Z"
									/>
									<path
										data-id="2"
										fill="#000000"
										opacity="1.00"
										d=" M 49.29 123.31 C 51.27 122.57 53.87 123.80 54.20 125.98 C 54.65 129.27 54.69 132.66 54.13 135.95 C 53.23 139.26 47.88 139.24 46.97 135.93 C 46.45 132.67 46.48 129.31 46.90 126.04 C 47.04 124.74 48.11 123.72 49.29 123.31 Z"
									/>
									<path
										data-id="16"
										fill="#000000"
										opacity="1.00"
										d=" M 79.40 123.50 C 81.45 122.39 84.46 123.66 84.78 126.04 C 85.19 129.31 85.25 132.69 84.69 135.95 C 83.81 139.08 78.87 139.29 77.65 136.29 C 76.92 133.24 77.15 130.04 77.32 126.94 C 77.32 125.51 78.07 124.10 79.40 123.50 Z"
									/>
									<path
										data-id="17"
										fill="#000000"
										opacity="1.00"
										d=" M 94.40 123.65 C 96.43 122.32 99.72 123.51 100.04 126.01 C 100.45 129.27 100.48 132.62 99.98 135.88 C 99.07 139.69 92.63 139.01 92.67 135.02 C 92.82 131.34 91.21 126.34 94.40 123.65 Z"
									/>
									<path
										data-id="25"
										fill="#000000"
										opacity="1.00"
										d=" M 125.31 123.46 C 127.36 122.41 130.33 123.70 130.63 126.07 C 131.00 129.28 131.05 132.57 130.57 135.78 C 129.86 139.22 124.25 139.31 123.39 135.93 C 122.89 132.95 123.00 129.91 123.18 126.91 C 123.18 125.47 123.95 124.03 125.31 123.46 Z"
									/>
									<path
										data-id="26"
										fill="#000000"
										opacity="1.00"
										d=" M 140.40 123.56 C 142.44 122.36 145.60 123.59 145.88 126.04 C 146.26 129.32 146.28 132.68 145.80 135.95 C 144.94 139.12 139.89 139.29 138.75 136.21 C 138.06 133.19 138.28 130.04 138.44 126.97 C 138.46 125.58 139.12 124.19 140.40 123.56 Z"
									/>
									<path
										data-id="27"
										fill="#000000"
										opacity="1.00"
										d=" M 156.25 123.32 C 158.22 122.56 160.86 123.80 161.16 125.98 C 161.58 129.27 161.61 132.65 161.10 135.93 C 160.23 139.25 154.82 139.25 153.95 135.93 C 153.46 132.67 153.48 129.32 153.88 126.05 C 153.97 124.74 155.07 123.73 156.25 123.32 Z"
									/>
									<path
										data-id="37"
										fill="#000000"
										opacity="1.00"
										d=" M 186.43 123.47 C 188.46 122.42 191.43 123.68 191.74 126.03 C 192.08 129.51 192.39 133.19 191.40 136.59 C 189.78 139.74 184.27 138.60 184.35 134.95 C 184.57 131.25 182.70 125.84 186.43 123.47 Z"
									/>
									<path
										data-id="38"
										fill="#000000"
										opacity="1.00"
										d=" M 201.47 123.59 C 203.49 122.36 206.66 123.54 206.99 125.98 C 207.39 129.29 207.41 132.68 206.92 135.98 C 205.98 139.24 200.68 139.24 199.78 135.94 C 199.28 133.01 199.36 130.00 199.56 127.04 C 199.58 125.66 200.18 124.23 201.47 123.59 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 3.89 154.84 C 7.49 149.13 14.28 145.61 21.03 146.07 C 28.33 146.35 35.15 151.30 37.77 158.10 C 40.59 164.98 38.89 173.39 33.60 178.61 C 31.01 181.41 27.42 182.87 23.89 184.07 C 23.86 196.86 23.88 209.64 23.88 222.43 C 51.86 222.44 79.84 222.44 107.81 222.43 C 107.81 217.28 107.82 212.13 107.80 206.99 C 102.96 205.56 98.33 202.89 95.63 198.50 C 91.68 192.56 91.53 184.37 95.25 178.28 C 98.83 172.16 106.02 168.39 113.10 169.02 C 120.67 169.45 127.61 174.92 129.87 182.16 C 132.25 189.19 130.02 197.46 124.43 202.34 C 121.97 204.70 118.72 205.88 115.58 207.00 C 115.55 212.15 115.57 217.29 115.57 222.43 C 147.38 222.44 179.19 222.43 211.00 222.44 C 211.00 214.71 211.01 206.98 210.99 199.26 C 205.16 197.27 200.12 192.32 199.61 185.95 C 198.33 177.13 206.17 168.53 215.05 168.96 C 223.82 168.81 231.33 177.38 230.03 186.05 C 229.48 192.36 224.47 197.25 218.70 199.25 C 218.68 206.98 218.69 214.71 218.69 222.44 C 221.83 222.53 225.08 222.01 228.14 222.89 C 231.69 224.58 230.07 230.61 226.06 230.07 C 153.03 230.22 79.99 230.05 6.95 230.16 C 5.11 230.05 2.75 230.33 1.58 228.55 C -0.12 226.43 1.31 222.71 4.09 222.57 C 8.09 222.21 12.11 222.50 16.12 222.44 C 16.12 209.65 16.14 196.86 16.11 184.07 C 11.16 182.62 6.43 179.82 3.76 175.28 C -0.13 169.19 -0.07 160.88 3.89 154.84 M 15.45 154.69 C 10.45 156.79 7.46 162.73 8.94 167.99 C 10.31 173.95 17.04 177.95 22.93 176.19 C 28.91 174.75 32.84 167.95 30.98 162.05 C 29.34 155.60 21.50 151.82 15.45 154.69 M 107.31 177.54 C 102.56 179.44 99.51 184.79 100.40 189.86 C 101.20 195.70 107.07 200.23 112.94 199.43 C 119.32 198.88 124.27 192.26 122.90 185.98 C 121.82 179.10 113.69 174.61 107.31 177.54 M 211.36 177.52 C 206.37 179.82 205.84 187.54 210.50 190.48 C 214.91 193.89 222.05 190.56 222.38 185.02 C 223.21 179.31 216.37 174.61 211.36 177.52 Z"
									/>
									<path
										data-id="3"
										fill="#000000"
										opacity="1.00"
										d=" M 48.48 146.63 C 50.51 145.19 53.89 146.41 54.22 148.96 C 54.64 152.25 54.69 155.63 54.13 158.90 C 53.09 162.55 46.97 161.92 46.83 158.08 C 46.92 154.38 45.38 149.40 48.48 146.63 Z"
									/>
									<path
										data-id="18"
										fill="#000000"
										opacity="1.00"
										d=" M 79.32 146.46 C 81.37 145.28 84.46 146.55 84.78 148.96 C 85.19 152.27 85.27 155.68 84.66 158.97 C 83.70 162.00 78.93 162.17 77.67 159.27 C 76.90 156.24 77.17 153.03 77.31 149.94 C 77.32 148.53 77.99 147.08 79.32 146.46 Z"
									/>
									<path
										data-id="19"
										fill="#000000"
										opacity="1.00"
										d=" M 94.37 146.59 C 96.43 145.20 99.81 146.47 100.06 149.05 C 100.44 152.31 100.49 155.66 99.96 158.91 C 99.03 162.16 93.73 162.16 92.84 158.89 C 92.33 155.94 92.43 152.92 92.62 149.96 C 92.63 148.63 93.16 147.25 94.37 146.59 Z"
									/>
									<path
										data-id="28"
										fill="#000000"
										opacity="1.00"
										d=" M 125.35 146.37 C 127.44 145.32 130.41 146.69 130.65 149.09 C 130.98 152.27 131.06 155.53 130.57 158.69 C 129.86 162.11 124.36 162.25 123.41 158.92 C 122.87 155.67 122.94 152.31 123.29 149.04 C 123.39 147.84 124.29 146.85 125.35 146.37 Z"
									/>
									<path
										data-id="29"
										fill="#000000"
										opacity="1.00"
										d=" M 140.37 146.50 C 142.40 145.26 145.60 146.50 145.88 148.96 C 146.25 152.27 146.30 155.66 145.78 158.96 C 144.83 162.05 139.94 162.16 138.76 159.18 C 138.05 156.14 138.28 152.97 138.44 149.89 C 138.46 148.52 139.09 147.12 140.37 146.50 Z"
									/>
									<path
										data-id="30"
										fill="#000000"
										opacity="1.00"
										d=" M 155.48 146.60 C 157.52 145.22 160.86 146.42 161.17 148.97 C 161.58 152.27 161.62 155.67 161.08 158.96 C 159.97 162.59 153.82 161.88 153.80 157.99 C 153.94 154.31 152.35 149.33 155.48 146.60 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 186.28 146.47 C 188.35 145.27 191.48 146.57 191.76 149.03 C 192.14 152.30 192.20 155.66 191.64 158.92 C 190.74 161.95 186.00 162.21 184.68 159.36 C 183.87 156.30 184.18 153.05 184.30 149.92 C 184.29 148.51 184.96 147.09 186.28 146.47 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 201.42 146.53 C 203.47 145.24 206.75 146.49 207.01 149.01 C 207.38 152.29 207.42 155.64 206.92 158.91 C 205.99 162.14 200.79 162.15 199.80 158.95 C 199.27 156.01 199.36 152.99 199.56 150.03 C 199.58 148.64 200.13 147.19 201.42 146.53 Z"
									/>
									<path
										data-id="4"
										fill="#000000"
										opacity="1.00"
										d=" M 48.41 169.58 C 50.46 168.08 53.95 169.34 54.23 171.95 C 54.64 175.24 54.70 178.64 54.10 181.91 C 52.98 185.47 46.98 184.81 46.83 181.01 C 46.91 177.32 45.41 172.41 48.41 169.58 Z"
									/>
									<path
										data-id="20"
										fill="#000000"
										opacity="1.00"
										d=" M 79.36 169.36 C 81.43 168.19 84.52 169.53 84.80 171.96 C 85.17 175.26 85.28 178.66 84.65 181.93 C 83.66 184.88 79.05 185.09 77.72 182.29 C 76.97 179.94 77.21 177.41 77.19 174.98 C 77.25 172.97 77.18 170.33 79.36 169.36 Z"
									/>
									<path
										data-id="31"
										fill="#000000"
										opacity="1.00"
										d=" M 140.32 169.45 C 142.38 168.13 145.69 169.46 145.90 171.99 C 146.24 175.29 146.31 178.66 145.77 181.94 C 144.75 184.94 140.01 185.08 138.78 182.16 C 138.13 179.81 138.32 177.33 138.31 174.92 C 138.39 172.98 138.29 170.48 140.32 169.45 Z"
									/>
									<path
										data-id="32"
										fill="#000000"
										opacity="1.00"
										d=" M 155.38 169.58 C 157.43 168.06 160.93 169.35 161.19 171.97 C 161.57 175.26 161.62 178.63 161.07 181.90 C 160.05 185.02 155.07 185.06 153.99 181.97 C 153.45 178.99 153.55 175.92 153.74 172.91 C 153.76 171.62 154.21 170.27 155.38 169.58 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 186.32 169.37 C 188.40 168.17 191.53 169.55 191.77 172.01 C 192.13 175.30 192.22 178.68 191.61 181.95 C 190.60 184.88 186.00 185.08 184.68 182.29 C 183.88 179.23 184.18 176.00 184.30 172.88 C 184.29 171.45 184.96 169.98 186.32 169.37 Z"
									/>
									<path
										data-id="5"
										fill="#000000"
										opacity="1.00"
										d=" M 33.29 192.41 C 35.80 190.74 39.46 192.98 39.07 195.98 C 39.14 201.30 39.35 206.66 38.93 211.97 C 38.42 215.59 32.57 215.82 31.70 212.30 C 31.07 207.88 31.59 203.38 31.40 198.94 C 31.52 196.72 30.96 193.71 33.29 192.41 Z"
									/>
									<path
										data-id="6"
										fill="#000000"
										opacity="1.00"
										d=" M 48.42 192.50 C 50.90 190.68 54.74 192.88 54.37 195.96 C 54.45 201.30 54.69 206.67 54.22 211.99 C 53.65 215.77 47.40 215.74 46.90 211.94 C 46.40 207.65 46.79 203.31 46.65 199.00 C 46.80 196.81 46.24 193.89 48.42 192.50 Z"
									/>
									<path
										data-id="21"
										fill="#000000"
										opacity="1.00"
										d=" M 79.28 192.32 C 81.78 190.83 85.23 193.01 84.93 195.91 C 85.01 201.26 85.24 206.65 84.78 211.98 C 84.24 215.45 78.80 215.84 77.61 212.60 C 76.81 208.14 77.42 203.55 77.21 199.05 C 77.33 196.75 76.79 193.58 79.28 192.32 Z"
									/>
									<path
										data-id="33"
										fill="#000000"
										opacity="1.00"
										d=" M 140.32 192.37 C 142.80 190.79 146.39 192.96 146.02 195.92 C 146.06 201.26 146.29 206.63 145.88 211.96 C 145.40 215.56 139.62 215.82 138.67 212.37 C 137.98 207.96 138.53 203.46 138.34 199.02 C 138.46 196.75 137.90 193.66 140.32 192.37 Z"
									/>
									<path
										data-id="34"
										fill="#000000"
										opacity="1.00"
										d=" M 155.33 192.53 C 157.77 190.68 161.66 192.81 161.32 195.89 C 161.38 201.24 161.61 206.63 161.18 211.97 C 160.65 215.77 154.37 215.75 153.87 211.95 C 153.41 207.65 153.80 203.31 153.65 198.99 C 153.80 196.82 153.20 193.94 155.33 192.53 Z"
									/>
									<path
										fill="#000000"
										opacity="1.00"
										d=" M 186.29 192.30 C 188.79 190.85 192.20 193.03 191.89 195.92 C 191.95 201.26 192.17 206.63 191.75 211.96 C 191.24 215.45 185.75 215.85 184.58 212.58 C 183.81 208.11 184.43 203.52 184.21 199.00 C 184.32 196.71 183.75 193.52 186.29 192.30 Z"
									/>
								</g>
							</svg>

							<div className="nmark__slider-buttons">
								<div onClick={this.previous} />
								<span>
									<span className="nmark__slider-circle"></span>
								</span>
								<div onClick={this.next} />
							</div>

							<SliderSlick
								ref={(c) => (this.slider = c)}
								{...settings3}
								className="nmark__slider"
							>
								<div className="nmark__slider-item" data-id="1">
									<div className="nmark__slider-item-title">
										<span>Реклама</span>
									</div>
									<div className="nmark__slider-item-ico">
										<img src="/sources/img/marketing/mico1.svg" />
									</div>
								</div>
								<div className="nmark__slider-item" data-id="2">
									<div className="nmark__slider-item-title">
										<span>Сайты</span>
									</div>
									<div className="nmark__slider-item-ico">
										<img src="/sources/img/marketing/mico2.svg" />
									</div>
								</div>
								<div className="nmark__slider-item" data-id="3">
									<div className="nmark__slider-item-title">
										<span>
											Фирменный <br />
											Стиль
										</span>
									</div>
									<div className="nmark__slider-item-ico">
										<img src="/sources/img/marketing/mico3.svg" />
									</div>
								</div>
								<div className="nmark__slider-item" data-id="4">
									<div className="nmark__slider-item-title">
										<span>Приложения</span>
									</div>
									<div className="nmark__slider-item-ico">
										<img src="/sources/img/marketing/mico4.svg" />
									</div>
								</div>
								<div className="nmark__slider-item" data-id="5">
									<div className="nmark__slider-item-title">
										<span>Игры</span>
									</div>
									<div className="nmark__slider-item-ico">
										<img src="/sources/img/marketing/mico5.svg" />
									</div>
								</div>
								<div className="nmark__slider-item" data-id="6">
									<div className="nmark__slider-item-title">
										<span>Мультимедиа</span>
									</div>
									<div className="nmark__slider-item-ico">
										<img src="/sources/img/marketing/mico6.svg" />
									</div>
								</div>
							</SliderSlick>
						</div>
					</section>

					<section className="marketing-complex">
						{bg1()}
						<div className="marketing-title2 js-parallax fade-in-up">
							Работаем
							<br />
							на результат
						</div>

						<div className="marketing-complex__list js-parallax fade-in-up">
							<div className="marketing-complex__item marketing-complex__item1">
								<img src="/sources/img/marketing/complex90.svg" />
								клиентов ежегодно
								<br />
								продлевают
								<br />
								контракт
							</div>
							<div className="marketing-complex__item-sep" />
							<div className="marketing-complex__item marketing-complex__item2">
								<img src="/sources/img/marketing/complex50000.svg" />
								подтвержденных
								<br />
								целевых звонков
								<br />
								для наших клиентов
							</div>
							<div className="marketing-complex__item-sep" />
							<div className="marketing-complex__item marketing-complex__item3">
								<img src="/sources/img/marketing/complex400.svg" />
								кампаний создаем
								<br />
								ежегодно
							</div>
							<a target="_blank" href="/digital/A%20M%20I%20O_Digital_Realty.pdf">
								<div className="digital_link-wrap">
									<div className="digital_link-img-wrap">
                    <div className="digital_link-dots dots-1"></div>
                    <div className="digital_link-dots dots-2"></div>
                    <div className="digital_link-dots dots-3"></div>
                    <div className="digital_link-dots dots-4"></div>
                    <div className="digital_link-dots dots-5"></div>
										<img src="/sources/img/rocket.svg" />
									</div>
                  <div className="digital_link-link">Получите презентацию</div>
								</div>
							</a>
						</div>
					</section>

					<SourcesMarketingForm />

					<section className="marketing-hon">
						<div className="marketing-title2 js-parallax fade-in-up">
							Честность
							<br />
							и долгосрочные
							<br />
							отношения
						</div>

						<div className="marketing-hon__list js-parallax fade-in-up">
							<div className="marketing-hon__item marketing-hon__item1">
								<div>
									<img src="/sources/img/marketing/h1_1.svg?1" />
									<img src="/sources/img/marketing/h1_2.svg?1" />
								</div>
								Профессиональная настройка
							</div>
							<div className="marketing-hon__item marketing-hon__item2">
								<div>
									<img src="/sources/img/marketing/h2.svg?1" />
									<img src="/sources/img/marketing/h2_2.svg?1" />
								</div>
								Доступ к&nbsp;кабинетам 24/7
							</div>
							<div className="marketing-hon__item marketing-hon__item3">
								<div>
									<svg
										width="135"
										height="134"
										viewBox="0 0 135 134"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M67.6665 90.4375C63.031 90.4375 58.4996 89.0629 54.6453 86.4876C50.7911 83.9122 47.787 80.2518 46.0131 75.9692C44.2392 71.6865 43.775 66.974 44.6794 62.4276C45.5837 57.8812 47.8159 53.705 51.0937 50.4272C54.3715 47.1494 58.5477 44.9172 63.0941 44.0129C67.6405 43.1085 72.353 43.5727 76.6357 45.3466C80.9183 47.1205 84.5787 50.1246 87.1541 53.9788C89.7294 57.8331 91.104 62.3645 91.104 67C91.104 73.216 88.6347 79.1774 84.2393 83.5728C79.8439 87.9682 73.8825 90.4375 67.6665 90.4375ZM67.6665 48.25C63.9581 48.25 60.333 49.3497 57.2496 51.41C54.1662 53.4702 51.7629 56.3986 50.3438 59.8247C48.9246 63.2508 48.5533 67.0208 49.2768 70.658C50.0003 74.2951 51.786 77.636 54.4083 80.2583C57.0305 82.8805 60.3714 84.6663 64.0086 85.3897C67.6457 86.1132 71.4157 85.7419 74.8418 84.3228C78.2679 82.9036 81.1963 80.5004 83.2566 77.417C85.3168 74.3335 86.4165 70.7084 86.4165 67C86.4165 62.0272 84.4411 57.2581 80.9248 53.7418C77.4085 50.2255 72.6393 48.25 67.6665 48.25Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M52.0455 35.418L42.1783 11.5938C41.9405 11.0111 41.944 10.3577 42.1879 9.77754C42.4319 9.19735 42.8963 8.73783 43.4791 8.50007C44.0618 8.2623 44.7152 8.26578 45.2953 8.50972C45.8755 8.75366 46.3351 9.21809 46.5728 9.80085L56.44 33.6251C56.6778 34.2 56.6774 34.8459 56.4389 35.4206C56.2005 35.9953 55.7435 36.4517 55.1685 36.6895C54.5935 36.9273 53.9476 36.9269 53.373 36.6884C52.7983 36.45 52.3418 35.993 52.1041 35.418H52.0455Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M88.8193 124.199L78.9521 100.374C78.7822 99.813 78.8289 99.2083 79.083 98.6797C79.3372 98.1511 79.7803 97.737 80.3249 97.5192C80.8694 97.3014 81.4759 97.2956 82.0245 97.5031C82.5731 97.7106 83.0239 98.1163 83.288 98.64L93.1552 122.464C93.393 123.039 93.3926 123.685 93.1541 124.26C92.9157 124.834 92.4587 125.291 91.8837 125.529C91.3087 125.766 90.6629 125.766 90.0882 125.528C89.5135 125.289 89.057 124.832 88.8193 124.257V124.199Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M97.9829 54.4486C97.8654 54.1603 97.8066 53.8516 97.8097 53.5403C97.8128 53.2291 97.8779 52.9215 98.0012 52.6357C98.1245 52.3499 98.3034 52.0915 98.5277 51.8756C98.7519 51.6597 99.0169 51.4906 99.3071 51.3782L123.131 41.5111C123.706 41.2733 124.352 41.2737 124.927 41.5121C125.502 41.7506 125.958 42.2076 126.196 42.7825C126.434 43.3575 126.433 44.0034 126.195 44.5781C125.956 45.1528 125.499 45.6092 124.924 45.847L101.042 55.7142C100.468 55.9511 99.824 55.9509 99.2506 55.7136C98.6772 55.4764 98.2214 55.0214 97.9829 54.4486Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M9.2017 91.222C9.08259 90.9373 9.02085 90.632 9.02003 90.3235C9.0192 90.0149 9.07931 89.7093 9.1969 89.424C9.31449 89.1387 9.48724 88.8795 9.70525 88.6612C9.92326 88.4428 10.1822 88.2697 10.4673 88.1517L34.2915 78.2845C34.8665 78.0467 35.5124 78.0471 36.0871 78.2856C36.6618 78.524 37.1182 78.981 37.356 79.556C37.5938 80.131 37.5934 80.7768 37.3549 81.3515C37.1165 81.9262 36.6595 82.3827 36.0845 82.6204L12.2603 92.4876C11.6868 92.7245 11.0427 92.7243 10.4694 92.487C9.89601 92.2498 9.44013 91.7948 9.2017 91.222Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M80.2286 36.6829C79.9439 36.566 79.685 36.3941 79.4667 36.1771C79.2484 35.9601 79.075 35.7022 78.9564 35.4182C78.8378 35.1342 78.7763 34.8296 78.7754 34.5218C78.7745 34.214 78.8342 33.909 78.9512 33.6243L91.959 2.2298C92.0768 1.9451 92.2494 1.68637 92.4671 1.4684C92.6848 1.25042 92.9434 1.07746 93.2279 0.95939C93.5125 0.841321 93.8175 0.780458 94.1256 0.780274C94.4337 0.78009 94.7388 0.840589 95.0235 0.958317C95.3082 1.07604 95.5669 1.2487 95.7849 1.46641C96.0029 1.68413 96.1758 1.94265 96.2939 2.22721C96.412 2.51177 96.4728 2.8168 96.473 3.12488C96.4732 3.43297 96.4127 3.73807 96.295 4.02277L83.2872 35.4173C83.0487 35.9902 82.5928 36.4451 82.0195 36.6824C81.4461 36.9196 80.8021 36.9198 80.2286 36.6829Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M40.3152 133.046C40.0291 132.929 39.7689 132.757 39.5497 132.539C39.3304 132.321 39.1564 132.062 39.0377 131.777C38.919 131.491 38.8579 131.185 38.8579 130.876C38.8579 130.567 38.9191 130.261 39.0379 129.976L52.0457 98.64C52.2834 98.065 52.7399 97.608 53.3146 97.3695C53.8893 97.1311 54.5352 97.1307 55.1101 97.3685C55.6851 97.6062 56.1421 98.0627 56.3805 98.6374C56.619 99.2121 56.6194 99.8579 56.3816 100.433L43.3738 131.827C43.1266 132.392 42.667 132.836 42.0946 133.064C41.5221 133.292 40.8829 133.286 40.3152 133.046Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M130.643 95.6282L99.3069 82.6204C98.732 82.3827 98.275 81.9262 98.0365 81.3515C97.7981 80.7768 97.7977 80.131 98.0355 79.556C98.2732 78.981 98.7297 78.524 99.3044 78.2856C99.8791 78.0471 100.525 78.0467 101.1 78.2845L132.494 91.2923C132.779 91.41 133.038 91.5827 133.256 91.8004C133.474 92.0181 133.647 92.2766 133.765 92.5612C133.883 92.8458 133.944 93.1508 133.944 93.4589C133.944 93.767 133.884 94.0721 133.766 94.3568C133.648 94.6415 133.476 94.9002 133.258 95.1182C133.04 95.3361 132.782 95.5091 132.497 95.6272C132.212 95.7452 131.907 95.8061 131.599 95.8063C131.291 95.8065 130.986 95.746 130.701 95.6282H130.643Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
										<path
											d="M34.2913 55.7142L2.89679 42.7064C2.61209 42.5886 2.35337 42.416 2.13539 42.1983C1.91741 41.9806 1.74445 41.722 1.62638 41.4375C1.50831 41.1529 1.44745 40.8479 1.44727 40.5398C1.44708 40.2317 1.50758 39.9266 1.62531 39.6419C1.74304 39.3572 1.91569 39.0985 2.1334 38.8805C2.35112 38.6625 2.60964 38.4896 2.8942 38.3715C3.17876 38.2534 3.48379 38.1926 3.79188 38.1924C4.09996 38.1922 4.40506 38.2527 4.68976 38.3704L36.0843 51.3782C36.369 51.496 36.6277 51.6686 36.8457 51.8863C37.0637 52.1041 37.2366 52.3626 37.3547 52.6471C37.4728 52.9317 37.5336 53.2367 37.5338 53.5448C37.534 53.8529 37.4735 54.158 37.3558 54.4427C37.2381 54.7274 37.0654 54.9861 36.8477 55.2041C36.63 55.4221 36.3715 55.595 36.0869 55.7131C35.8023 55.8312 35.4973 55.892 35.1892 55.8922C34.8811 55.8924 34.576 55.8319 34.2913 55.7142Z"
											fill="#F2994A"
											stroke="#F2994A"
											strokeWidth="1.5"
										/>
									</svg>
								</div>
								Прозрачная, информативная отчетность
							</div>
							<div className="marketing-hon__item marketing-hon__item4">
								<div>
									<img src="/sources/img/marketing/h4_1.svg?1" />
									<img src="/sources/img/marketing/h4_2.svg?1" />
								</div>
								Отвечаем за результат&nbsp;– работаем по&nbsp;KPI
							</div>
						</div>
					</section>

					<div className="marketing-work-detect" />

					<section className="marketing-work" data-type="6">
						<div className="marketing-title2 js-parallax fade-in-up">
							Этапы работы
						</div>

						<div className="marketing-work__in js-parallax fade-in-up">
							<div className="marketing-work__img">
								{/*<img src="/sources/img/marketing/work_01.svg" />
                  <img src="/sources/img/marketing/work_02.svg" />
                  <img src="/sources/img/marketing/work_03.svg" />
                  <img src="/sources/img/marketing/work_04.svg" />
                  <img src="/sources/img/marketing/work_05.svg" />*/}
								<img src="/sources/img/marketing/work_p1.svg" />
								<img src="/sources/img/marketing/work_p2.svg?1" />
							</div>
							<div>
								<div className="marketing-work__item marketing-work__item1">
									<span>01</span>
									<p>
										разрабатываем <br />
										индивидуальную <br />
										стратегию <br />
										продвижения
									</p>
								</div>
								<div className="marketing-work__item marketing-work__item2">
									<span>02</span>
									<p>
										создаем качественные <br />
										рекламные кампании
									</p>
								</div>
								<div className="marketing-work__item marketing-work__item3">
									<span>03</span>
									<p>
										внедряем сквозную <br />
										аналитику
									</p>
								</div>
								<div className="marketing-work__item marketing-work__item4">
									<span>04</span>
									<p>
										автоматизируем <br />
										процессы
									</p>
								</div>

								<div className="marketing-work__item marketing-work__item5">
									<span>05</span>
									<p>
										Вы получаете
										<br />
										звонки и заявки
									</p>
								</div>
							</div>
						</div>
            <a target="_blank" href="/digital/A%20M%20I%20O_Digital_Realty.pdf">
								<div className="digital_link-wrap">
									<div className="digital_link-img-wrap">
                    <div className="digital_link-dots dots-1"></div>
                    <div className="digital_link-dots dots-2"></div>
                    <div className="digital_link-dots dots-3"></div>
                    <div className="digital_link-dots dots-4"></div>
                    <div className="digital_link-dots dots-5"></div>
										<img src="/sources/img/rocket.svg" />
									</div>
                  <div className="digital_link-link">Получите презентацию</div>
								</div>
							</a>
					</section>

					<section className="marketing-prof">
						{bg1()}
						<div className="marketing-prof__in">
							<div className="marketing-title2 js-parallax fade-in-up">
								Доказанный
								<br />
								профессионализм
							</div>

							<div className="marketing-prof__row js-parallax fade-in-up">
								<img src="/sources/img/marketing/logos/p1.png" />
								<img src="/sources/img/marketing/logos/p2.png" />
								<img src="/sources/img/marketing/logos/p3.png" />
							</div>

							<div className="marketing-prof__row js-parallax fade-in-up">
								<img src="/sources/img/marketing/logos/p4.png" />
								<img src="/sources/img/marketing/logos/p5.png" />
								<img src="/sources/img/marketing/logos/p6.png" />
								<img src="/sources/img/marketing/logos/p7.png" />
							</div>
						</div>
					</section>

					<section className="marketing-technology">
						<div className="marketing-title2 js-parallax fade-in-up">
							Лучшие технологии
							<br />
							digital–рынка
							<br />
							для увеличения
							<br />
							ваших продаж
						</div>

						<div className="marketing-technology__row js-parallax fade-in-up">
							<div>
								Собственная разработка для управления контекстной рекламой
							</div>
							<div>
								<img src="/sources/img/marketing/logos/t1.png" />
							</div>
						</div>
						<div className="marketing-technology__row js-parallax fade-in-up">
							<div>Подсчет и аналитика звонков</div>
							<div>
								<img src="/sources/img/marketing/logos/t2.png" />
								<img src="/sources/img/marketing/logos/t3.png" />
								<img src="/sources/img/marketing/logos/t4.png" />
							</div>
						</div>
						<div className="marketing-technology__row js-parallax fade-in-up">
							<div>CRM</div>
							<div>
								<img src="/sources/img/marketing/logos/t5.png" />
							</div>
						</div>
						<div className="marketing-technology__row js-parallax fade-in-up">
							<div>Инструменты веб-аналитики</div>
							<div>
								<img src="/sources/img/marketing/logos/t6.png" />
								<img src="/sources/img/marketing/logos/t7.png" />
							</div>
						</div>
						<div className="marketing-technology__row js-parallax fade-in-up">
							<div>Визуализация данных</div>
							<div>
								<img src="/sources/img/marketing/logos/t8.png" />
								<img src="/sources/img/marketing/logos/t9.png" />
								<img src="/sources/img/marketing/logos/t10.png" />
							</div>
						</div>
					</section>

					{window.location.hash == "#zero" && (
						<div className="marketing-zero-wrap">
							{bg1()}

							<section className="marketing-zero js-parallax fade-in-up">
								<div className="marketing-zero-img">
									<img src="/sources/img/marketing/zero_bg.svg" />
									<div className="marketing-zero-img__in">
										<div />
										<div />
										<div />
										<div />
										<div />
										<div />
										<div />
									</div>
								</div>
								<div className="marketing-zero-dsc">
									<div className="marketing-zero__title">
										комиссии
										<br />
										за первый месяц
										<br />
										рекламной компании
									</div>
									<div
										className="marketing-zero__button"
										onClick={() => {
											$("html, body").animate({
												scrollTop: $(".marketing-footer-wrap").offset().top,
											});
											ym("reachGoal", "click_on_nul");
											return true;
										}}
									>
										<div>
											Получить
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M3 12L21 12"
													stroke="#333333"
													strokeWidth="2"
													strokeLinecap="round"
												/>
												<path
													d="M15 18L21 12L15 6"
													stroke="#333333"
													strokeWidth="2"
													strokeLinecap="round"
												/>
											</svg>
										</div>
									</div>
								</div>
							</section>
						</div>
					)}

					<div className="marketing-footer-wrap">
						<section className="marketing-footer-new js-parallax fade-in-up">
							<div className="marketing-footer-new__in">
								<div>
									<div>
										Приглашаем <br />в гости
									</div>
									<Link
										to="/contacts"
										onClick={() => {
											ym("reachGoal", "click_on_guests");
											return true;
										}}
									>
										Мы рядом
									</Link>
								</div>
								<div>
									<div>
										Ждём <br />
										звонка
									</div>
									<a
										href="tel: +7 (495) 921-41-70"
										onClick={() => {
											ym("reachGoal", "click_on_call_me");
											return true;
										}}
									>
										+7 (495) 921-41-70
									</a>
								</div>
								<div>
									<div>
										Перезвоним <br />
										сами
									</div>
									<span
										onClick={() => {
											$("html, body").animate({
												scrollTop: $("#marketing-form").offset().top,
											});
											this.setState({popup: true})
											ym("reachGoal", "click_on_callback");
											return true;
										}}
									>
										Быстро
									</span>
								</div>
							</div>
						</section>
					</div>

					<div className="marketing-footer-height"></div>
				</div>
			</section>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SourcesMarketing);
