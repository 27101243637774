import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import {hit} from '../metrics';
import {Helmet} from 'react-helmet';

class SourcesBanksNew extends Component{
	componentWillUnmount(){
		$('body').removeClass('page-sources hash-banks tags-expanded');
    window.removeEventListener('scroll', this.scroll);
	}

	componentDidMount(){
		this.props.setPageId('sources-banks');
		$('body').addClass('page-sources hash-banks tags-expanded');
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    let startAnimate = true;
    $('body').mousemove(() => {
      if(startAnimate){
        startAnimate = false;
        if(timer){
          clearTimeout(timer);
        }
        let timer = setTimeout(() => {
          const rand = Math.floor(Math.random() * 9);
          $('.banksnew__pin span').eq(rand).addClass('active').siblings().removeClass('active');
          startAnimate = true;
        }, 500);
      }
    });

    window.addEventListener('scroll', this.scroll);
	}

  scroll = () => {
    if($(window).scrollTop() > 0){
      $('body').addClass('fixed');
    }
    else{
      $('body').removeClass('fixed');
    }
  }

  render(){
    return(
      <div className="banksnew">
        <Helmet>
          <title>Банки ' Акценты ' Amio</title>
        </Helmet>
        <div>
          <div className="banksnew__title">Скоро</div>
          <div className="banksnew__pin-title">Access Denied</div>
          <div className="banksnew__pin">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span className="active" />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SourcesBanksNew)