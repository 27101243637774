import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Target extends Component {
	videoRef = React.createRef();

	state = {
		preview: true,
		play: false,
	};

	componentDidMount() {
		$(".ahead-img1").on("mousemove", function(e) {
			var x = (e.pageX * 100) / $(window).width();
			var y = (e.pageY * 100) / $(window).height();
			$(".ahead-ya1,.ahead-ya2").css({ left: x + "%", top: y + "%" });
			$(".ahead-hn1,.ahead-hn2").css({
				transform: "translate(-" + x / 10 + "%," + y / 8 + "%)",
			});
			// $('.ahead-ya1,.ahead-ya2').css({'transform': 'translate(-'+(x/2)+'%,'+(y/2)+'%)'});
		});

		if (this.props.pathname == "/projects/ahead/") {
			this.setState({ preview: false });
			this.props.setProjectName("Ahead");
			$("body").addClass("case-expanded hash-ahead");
			$(window).scrollTop($("#case-ahead").offset().top);
			this.props.changeCase(null);
		} else {
			this.setState({ preview: true });
			$("body").removeClass("hash-ahead");
		}
		window.scrollTo(0, 0);
		hit(window.location.pathname);

		setTimeout(() => {
			$("body").addClass("fadepage");
		}, 100);

		window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		// create the smooth scroller FIRST!
		let smoother = window.ScrollSmoother.create({
			smooth: 2,
			effects: true,
			smoothTouch: false,
			normalizeScroll: true,
		});

		window.gsap.set(".square", { transformOrigin: "center	" });
		window.gsap.set(".square1", { transformOrigin: "center	" });
		window.gsap.set(".square2", { transformOrigin: "botton	" });
		let numOfRotations = 1.1;
		let numOfRotations1 = 1.1;
		let numOfRotations2 = 1.1;

		const line = window.gsap.utils.toArray(".line");

		line.forEach((line) => {
			window.gsap.to(line, {
				scrollTrigger: {
					invalidateOnRefresh: true,
					trigger: line,
					scrub: true,
					start: "0 100%",
					end: "200% 0",
				},
				left: -1200,
			});
		});

		var rotate = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square", {
				rotation: () => 30 * numOfRotations,
				duration: 2,
				ease: "none",
			});

		var rotate1 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper1",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square1", {
				rotation: () => 40 * numOfRotations1,
				duration: 2,
				ease: "none",
			});

		var rotate2 = window.gsap
			.timeline({
				scrollTrigger: {
					trigger: ".wrapper2",
					scrub: 0.2,
					start: "top center",
					end: "bottom top",
				},
			})
			.to(".square2", {
				rotation: () => -60 * numOfRotations2,
				duration: 2,
				ease: "none",
			});
	}

	componentDidUpdate() {
		if (this.props.pathname == "/projects/") {
			$("body").removeClass("case-expanded hash-ahead");
		}

		if (this.state.play) {
			if (this.videoRef.current) {
				this.videoRef.current.play();

				this.videoRef.current.onended = () => {
					this.setState({ play: false });
				};
			}
		}
	}

	componentWillUnmount() {
		$("body").removeClass("fadepage");
	}

	render() {
		return (
			<div id="smooth-wrapper" className="case ahead crimea dellin target">
				<div id="smooth-content" className="case-content " ref={(el) => (this.scene = el)}>
					<div className="ahead-img1" style={{ backgroundImage: "url('/cases/img/target/img1.webp')" }}></div>
					<div className="target-box1">
						<div className="text bubble right parallax-front parallax-up">
							<div className="in">
								Логотип <br />и фирменный стиль, сайт
								<br /> для Стрелкового клуба
								<br /> с мировым масштабом.
							</div>
						</div>
						<div className="text bubble bubble1 left-bottom parallax-front parallax-up">
							<div className="in">
								Оформление
								<br />
								выставочного стенда
							</div>
						</div>
						<img className="shadow target-box-img1" src="/cases/img/target/img2.webp" />
						<img className="shadow target-box-img3" src="/cases/img/target/img4.webp" />
						<img data-speed="0.9" className="target-box-img4" src="/cases/img/target/img5.webp" />
						<img data-speed="0.9" className="target-box-img2" src="/cases/img/target/img3.webp" />
					</div>
					<div className="target-box2" style={{ backgroundImage: "url('/cases/img/target/img6.webp')" }}></div>
					<div className="target-box3">
						<img className="shadow target-box-img5 " src="/cases/img/target/img7.webp" />
						<img className="shadow target-box-img7 " src="/cases/img/target/img9.webp" />
						<img className="shadow target-box-img8 " src="/cases/img/target/img10.webp" />
						<img className="target-box-img6 square" src="/cases/img/target/img8.webp" />
						<img className="target-box-img9" src="/cases/img/target/img11.webp" />
						<div className="text bubble  left-bottom parallax-front parallax-up">
							<div className="in">
								Огромная площадь
								<br />и статусность клуба.
							</div>
						</div>
					</div>
					<div style={{ backgroundImage: "url('/cases/img/target/img12.webp')" }} className="target-box4"></div>
					<div className="ahead-imgs">
						<div>
							<img src="/cases/img/target/img13.webp" />
						</div>
						<div className="crimea-midl-inm">
							<img src="/cases/img/target/img14.webp" />
							<img src="/cases/img/target/img15.webp" />
						</div>
						<div>
							<img src="/cases/img/target/img16.webp" />
						</div>
						<img data-speed="0.9" className="target-img-b " src="/cases/img/target/img17.webp" />
						<div className="text bubble  right parallax-front parallax-up">
							<div className="in">
								Так выглядит сайт в смартфоне.
								<br /> Адаптация происходит под
								<br /> любое устройство
							</div>
						</div>
					</div>

 

		  <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/metropolia"  }
				title= { this.props.next ? this.props.next.name: "Метрополия"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom:"/cases/img/next/metropolia.webp"}
			/>
				</div>

				<HelmetSite title={`Тир - Target Point`} />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Target);
