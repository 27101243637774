import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Tushino extends Component{

	state = {
		preview: true
	}

	componentDidMount(){
		if(this.props.pathname == '/projects/tushino/'){
			this.setState({preview: false});
			this.props.setProjectName('Тушино 2018');
			$('body').addClass('case-expanded hash-tushino');
			$(window).scrollTop($('#case-tushino').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-tushino');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-tushino');
		}
  }
  
  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	more = () => {
    return(
      <Fragment>
        <div className="screen screen1">
          <div className="content narrow">
            <div className="clear">
              <div className="text middle text1">
                <div className="in">
                  <div className="title">Тушино 2018</div>
                  <p>«Город на реке Тушино-2018» – выдающийся жилищный комплекс в Москве. Площадь застройки – 160 гектаров.</p>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/tushino/img1.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/tushino/img2.webp')`}}></div></div>
              <div className="text bubble right text2 parallax-front">
                <div className="in">
                  <p>Перед нами стояла задача разработать сайт для жилого комплекса.</p>
                  <p>Для представления концепции проекта мы подготовили презентацию, где проработали:</p>
                  <ul>
                    <li>Референсы</li>
                    <li>Настроение</li>
                    <li>Цветовые решения</li>
                    <li>Логику проекта</li>
                    <li>Схемы</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/tushino/img3.webp')`}}></div></div>
            </div>
            <div className="clear">
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/tushino/img4.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/tushino/img5.webp')`}}></div></div>
              <div className="graph graph1"></div>
              <div className="graph graph2"></div>
            </div>
          </div>
        </div>
        <div className="screen screen2">
          <div className="content narrow">
            <div className="clear">
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/tushino/img6.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/tushino/img7.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/tushino/img8.webp')`}}></div></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  <b>Скетчи для лучшего представления структуры проекта и визуальной концепции в целом.</b>
                </div>
              </div>
              <div className="graph graph1"></div>
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/tushino/img13.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="graph graph1"></div>
              <div className="graph graph2"></div>
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  Сайт информационно наполнен и эмоционально настроен, чтобы погрузить пользователя в атмосферу современного и экологичного города на реке.
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/tushino/img9.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/tushino/img10.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/tushino/img11.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/tushino/img12.webp')`}}></div></div>
              <div className="graph graph3"></div>
              <div className="graph graph4"></div>
              <div className="graph graph5"></div>
              <div className="graph graph6"></div>
              <div className="graph graph7"></div>
              <div className="graph graph8"></div>
            </div>
          </div>
        </div>
        <div className="screen screen4">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p><b>3D визуализация</b></p>
                  <p>Cделали имиджевые, навигационные трёхмерные схемы. Базовые планировки квартир – тоже в 3D.</p>
                </div>
              </div>
            </div>
            <div className="clear">
              <div className="slider parallax-slider">
                <div className="line">
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/tushino/img14-1.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/tushino/img14-2.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/tushino/img14-3.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/tushino/img14-4.webp')`}}></div></div>
                  <div className="item"><div style={{backgroundImage: `url('/cases/img/tushino/img14-5.webp')`}}></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/tushino/img15.webp')`}}></div></div>
          <div className="content narrow">
            <div className="clear">
              <div className="graph graph0"></div>
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <b>2 системы подбора квартир с несколькими способами интеграции с CRM</b>
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/tushino/img16.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/tushino/img17.webp')`}}></div></div>
              <div className="screenshot screenshot3"><div style={{backgroundImage: `url('/cases/img/tushino/img18.webp')`}}></div></div>
              <div className="screenshot screenshot4"><div style={{backgroundImage: `url('/cases/img/tushino/img19.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/tushino/img20.webp')`}}></div></div>
              <div className="graph graph1"></div>
              <div className="graph graph2"></div>
              <div className="graph graph3"></div>
              <div className="graph graph4"></div>
              <div className="graph graph5"></div>
              <div className="graph graph6"></div>
              <div className="graph graph7"></div>
            </div>
          </div>
        </div>
        <div className="screen screen6">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble left text1 parallax-front">
                <div className="in">
                  <p><b>Мобильное отображение</b></p>
                  <p>Мы адаптировали сайт под планшеты и смартфоны.</p>
                </div>
              </div>
              <div className="graph graph1"></div>
              <div className="graph graph2"></div>
            </div>
            <div className="clear">
              <div className="screenshot-phone screenshot1"><div style={{backgroundImage: `url('/cases/img/tushino/img21.webp')`}}></div></div>
              <div className="screenshot-mob screenshot2"><div style={{backgroundImage: `url('/cases/img/tushino/img22.webp')`}}></div></div>
              <div className="screenshot-mob screenshot3"><div style={{backgroundImage: `url('/cases/img/tushino/img23.webp')`}}></div></div>
              <div className="graph graph3"></div>
              <div className="graph graph4"></div>
            </div>
          </div>
        </div>
        <div className="screen screen7">
          <div className="content narrow">
            <div className="clear">
              <div className="text bubble right text1 parallax-front">
                <div className="in">
                  Для рекламных кампаний мы разрабатываем посадочные страницы. 
                  <br/>Следуем тем же принципам, логике, цветовым решениям.
                </div>
              </div>
              <div className="screenshot screenshot1"><div style={{backgroundImage: `url('/cases/img/tushino/img24.webp')`}}></div></div>
              <div className="screenshot screenshot2"><div style={{backgroundImage: `url('/cases/img/tushino/img25.webp')`}}></div></div>
              <div className="image image1"><div style={{backgroundImage: `url('/cases/img/tushino/img26.webp')`}}></div></div>
              <div className="graph graph1"></div>
              <div className="graph graph2"></div>
              <div className="graph graph3"></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
	}

	render(){
		
		return(
		<div className={this.state.preview ? 'case' : 'case'}>
			<div className="case-content" id="case-tushino">
				<Link to="/projects/tushino/" className="screen screen0 h100">
					<div className="bg parallax-back"><div style={{backgroundImage: `url('/cases/img/tushino/img0.webp')`}}></div></div>
					<div className="content narrow">
						<div className="logo"><div style={{backgroundImage: `url('/cases/img/tushino/logo.webp')`}}></div></div>
					</div>
				</Link>
				{this.more()}
			</div>

			<div className="case-info">
				{/*<div className="next-title">Следующий проект</div>*/}
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Тушино 2018</div>
				<div className="tags"><span>сайт</span><span>недвижимость</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Tushino)