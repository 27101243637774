import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import mapStateToProps from "../../../store/mapStateToProps";
import mapDispatchToProps from "../../../store/mapDispatchToProps";
import { hit } from "../../metrics";
import CaseFooter from "../CaseFooter";
import HelmetSite from "../HelmetSite";
import "./style.css";

class Scandypakk extends Component {
  state = {
    preview: true,
    naxt: this.props.next
  };

  handleScroll() {
    $(".animate-scroll-element").each(function () {
      const top = $(this).offset().top - window.innerHeight / 2;
      const scr = $(window).scrollTop();

      if (scr >= top) {
        $(this).addClass("active");
      } else {
        $(this).removeClass("active");
      }
    });
  }

  componentDidMount() {
    if (this.props.pathname == "/projects/scandipakk/") {
      this.setState({ preview: false });
      this.props.setProjectName("bsg");
      $("body").addClass("case-expanded hash-scandi");
      $(window).scrollTop($(".vsn").offset().top);
      this.props.changeCase(null);
    } else {
      this.setState({ preview: true });
      $("body").removeClass("scandi");
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
      $("body").addClass("fadepage");
    }, 100);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    if (this.props.pathname == "/projects/") {
      $("body").removeClass("case-expanded hash-scandi");
    }
  }

  componentWillUnmount() {
    $("body").removeClass("fadepage");
    window.removeEventListener("scroll", this.handleScroll);
    if (window.parallaxInstance) {
      window.parallaxInstance.disable();
    }
  }

  render() {
    return (
      <div className="case scandi">
        <div className="case-content " ref={(el) => (this.scene = el)}>
          <div className="scandi-block scandi-block1">
            {/* <img className="scandi-img1" src="/cases/img/scandipakk/img1.webp" /> */}
            <div className="scandi-block1__in">
              <img className="scandi-img1" src="/cases/img/scandipakk/logo.svg" />
              <img className="scandi-img2" src="/cases/img/scandipakk/title.svg" />
            </div>
          </div>

					<div className="scandi-block scandi-block2">
            <img className="scandi-img4 scandi-desktop" src="/cases/img/scandipakk/img2_full.webp" />
            <img className="scandi-img4 scandi-mobile" src="/cases/img/scandipakk/mobile/img2_full.webp" />

            <div className="text text1 bubble right parallax-front parallax-up">
              <div className="in">
								Разработанный логотип
								вместе с фирменным 
								паттерном уверенно 
								транслируют статусность 
								компании, современный стиль
              </div>
            </div>
          </div>

					<div className="scandi-block scandi-block0">
						<img className="scandi-img5 scandi-desktop" src="/cases/img/scandipakk/img3.webp" />
						<img className="scandi-img5 scandi-mobile" src="/cases/img/scandipakk/mobile/img3.webp" />
					</div>

          <div className="scandi-block scandi-block3">
            <img className="scandi-img6 shadow scandi-desktop" src="/cases/img/scandipakk/img5.webp" />
            <img className="scandi-img6 shadow scandi-mobile" src="/cases/img/scandipakk/mobile/img5.webp" />
            <img className="scandi-img7" src="/cases/img/scandipakk/img6.svg" />
            <div className="scandi-img8 scandi-desktop"><img src="/cases/img/scandipakk/img4.webp" /></div>
            <div className="scandi-img8 scandi-mobile"><img src="/cases/img/scandipakk/mobile/img4.webp" /></div>
          </div>

          <div className="scandi-block scandi-block4">
            <img className="scandi-img9 shadow scandi-desktop" src="/cases/img/scandipakk/img6.webp" />
            <img className="scandi-img9 shadow scandi-mobile" src="/cases/img/scandipakk/mobile/img6.webp" />
						<div className="text text2 bubble left parallax-front parallax-up">
              <div className="in">
								Из всей палитры оттенков синего 
								выбран самый яркий, и он точно
								не&nbsp;оставит без внимания 
								визитную карточку!
              </div>
            </div>
            <img className="scandi-img10 shadow scandi-desktop" src="/cases/img/scandipakk/img7.webp" />
            <img className="scandi-img10 shadow scandi-mobile" src="/cases/img/scandipakk/mobile/img7.webp" />
            <img className="scandi-img11" src="/cases/img/scandipakk/img9.svg" />
            <img className="scandi-img12 scandi-desktop" src="/cases/img/scandipakk/img8-2.webp" />
            <img className="scandi-img12 scandi-mobile" src="/cases/img/scandipakk/mobile/img8-2.webp" />
						<div className="scandi-img12-1 scandi-desktop"><img src="/cases/img/scandipakk/img8-1.webp" /></div>
						<div className="scandi-img12-1 scandi-mobile"><img src="/cases/img/scandipakk/mobile/img8-1.webp" /></div>
          </div>

          <div className="scandi-block scandi-block5">
            <img className="scandi-img13 scandi-desktop" src="/cases/img/scandipakk/img10.webp" />
            <img className="scandi-img13 scandi-mobile" src="/cases/img/scandipakk/mobile/img10.webp" />
						<div className="text text3 bubble right parallax-front parallax-up">
              <div className="in">
								Подобран определённый стиль 
								фотографий, который наиболее
								точно отразит экологичность 
								продукции.
              </div>
            </div>
          </div>

          <div className="scandi-block scandi-block6">
            <img className="scandi-img14 scandi-desktop" src="/cases/img/scandipakk/img11.webp" />
            <img className="scandi-img14 scandi-mobile" src="/cases/img/scandipakk/mobile/img11.webp" />
						<div className="text text4 bubble right parallax-front parallax-up">
              <div className="in">
								Стиль во всём,
								даже в мелочах!
              </div>
            </div>
          </div>

          <div className="scandi-block scandi-block7">
            <img className="scandi-img15 scandi-desktop" src="/cases/img/scandipakk/img12.webp" />
            <img className="scandi-img15 scandi-mobile" src="/cases/img/scandipakk/mobile/img12.webp" />
						<div className="scandi-img16 scandi-desktop"><img src="/cases/img/scandipakk/img12-1.webp" /></div>
						<div className="scandi-img16 scandi-mobile"><img src="/cases/img/scandipakk/mobile/img12-1.webp" /></div>
          </div>

					<div className="scandi-block scandi-block8">
						<img className="scandi-img17 scandi-desktop" src="/cases/img/scandipakk/img13.webp" />
						<img className="scandi-img17 scandi-mobile" src="/cases/img/scandipakk/mobile/img13.webp" />
          </div>

          <div className="scandi-block scandi-block9">
            <img className="scandi-img18 scandi-desktop" src="/cases/img/scandipakk/img14.webp" />
            <img className="scandi-img18 scandi-mobile" src="/cases/img/scandipakk/mobile/img14.webp" />
						<div className="text text5 bubble left parallax-front parallax-up">
              <div className="in">
								Даже, повседневной
								упаковке хочется быть слегка
								нарядной&nbsp;:&rang;
              </div>
            </div>
          </div>

					<div class="scandi-block scandi-block10"></div>

					<div className="scandi-block scandi-block11">
            <img className="scandi-img19 scandi-desktop" src="/cases/img/scandipakk/img16.webp" />
            <img className="scandi-img19 scandi-mobile" src="/cases/img/scandipakk/mobile/img16.webp" />
						<div className="text text6 bubble left parallax-front parallax-up">
              <div className="in">
								Шаблон для презентации.
								Учтено всё: лёгкость заполнения, 
								акцентные блоки, перебивки
								с&nbsp;фирменным узором, 
								подобраны сочетаемые цвета!
              </div>
            </div>
          </div>

					<div className="scandi-block scandi-block12">
            <img className="scandi-img20 scandi-desktop" src="/cases/img/scandipakk/img18.webp" />
            <img className="scandi-img20 scandi-mobile" src="/cases/img/scandipakk/mobile/img18.webp" />
						<img className="scandi-img21" src="/cases/img/scandipakk/img6.svg" />
          </div>

					<div className="scandi-block scandi-block13">
            <img className="scandi-img22" src="/cases/img/scandipakk/img19.svg" />
						<img className="scandi-img23 scandi-desktop" src="/cases/img/scandipakk/img20.webp" />
						<img className="scandi-img23 scandi-mobile" src="/cases/img/scandipakk/mobile/img20.webp" />
						<div className="text text7 bubble right parallax-front parallax-up">
              <div className="in">
								Сувенирная продукция
								запоминающаяся, всегда 
								заметна.
              </div>
            </div>
          </div>

					<div className="scandi-block scandi-block14">
						{/* стакан */}
            <img className="scandi-img24" src="/cases/img/scandipakk/img21.svg" />
						<img className="scandi-img25 shadow scandi-desktop" src="/cases/img/scandipakk/img22.webp" />
						<img className="scandi-img25 shadow scandi-mobile" src="/cases/img/scandipakk/mobile/img22.webp" />
						<div className="text text8 bubble right parallax-front parallax-up">
              <div className="in">
								Корпоративный сайт.
								Лёгкость восприятия
								информации, современный 
								стиль, всё реализовано!
              </div>
            </div>
          </div>

					<div className="scandi-block scandi-block15">
						<img className="scandi-img26 shadow scandi-desktop" src="/cases/img/scandipakk/img23.webp" />
						<img className="scandi-img26 shadow scandi-mobile" src="/cases/img/scandipakk/mobile/img23.webp" />
					</div>

					<div className="scandi-block scandi-block16">
            <img className="scandi-img27 shadow scandi-desktop" src="/cases/img/scandipakk/img25.webp" />
            <img className="scandi-img27 shadow scandi-mobile" src="/cases/img/scandipakk/mobile/img25.webp" />
						<div className="scandi-img28">
							<img class="scandi-desktop" src="/cases/img/scandipakk/img25-1.webp" />
							<img class="scandi-mobile" src="/cases/img/scandipakk/mobile/img25-1.webp" />
						</div>
            <img className="scandi-img29 scandi-desktop" src="/cases/img/scandipakk/img24.webp" />
            <img className="scandi-img29 scandi-mobile" src="/cases/img/scandipakk/mobile/img24.webp" />
          </div>

					<div className="scandi-block scandi-block17">
            <img className="scandi-img30" src="/cases/img/scandipakk/img26.svg" />
          </div>
        </div>
   

       <CaseFooter 
				to={ this.state.next ? this.state.next.url: "/projects/afimall"  }
				title= { this.state.next ? this.state.next.name: "Афимолл Сити + Маша и Медведь"  } 
				img={ this.state.next ? this.state.next.imgCaseBottom:"/cases/img/next/afimall.jpg"}
			/>
        <HelmetSite title={`Scandipakk`} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scandypakk);
