import React from "react";
import CaseFooter from "../CaseFooter";

const Content = () => {
  return(
    <>
      <div className="profit-top">
        <img className="profit-top__img1" src="/cases/img/profit/img1.webp" />
        <img className="profit-top__img2" src="/cases/img/profit/img1_title.svg" />
        <img className="profit-top__img3" src="/cases/img/profit/img1_arrow.svg" />
        <div className="profit-top__text">
          Разработка нейминга, <br />
          фирменного стиля, <br />
          лендинга
        </div>

        <div className="profit-top__c1"><div /></div>
        <div className="profit-top__c2"><div /></div>
        <div className="profit-top__c3"><div /></div>
        <div className="profit-top__c4"><div /></div>
        <div className="profit-top__c5"><div /></div>
      </div>

      <div className="profit-2-box">
        <div className="profit-2-box__text1">
        Ipoteka Profit — <strong>онлайн-платформа</strong> для профессионалов ипотечного бизнеса, которая позволяет проводить сделки <strong>в режиме одного окна</strong> и получать <span>комиссионное вознаграждение за выданный кредит.</span>
        </div>

        <div className="profit-2-box__steps">
          <div>
            <div className="profit-2-box__q">
              <div>?</div>
              <div>?</div>
              <div>?</div>
              <div>?</div>
              <div>?</div>
              <div>?</div>
            </div>
          </div>
          <div>
            <img className="profit-2-box__arrow" src="/cases/img/profit/b2_arrow.svg" />
          </div>
          <div>
            <img className="profit-2-box__circle" src="/cases/img/profit/b2_circle.svg" />
          </div>
        </div>

        <img className="profit-2-box__title" src="/cases/img/profit/b2_title.svg" />

        <div className="profit-2-box__wimg">

          <div className="text">
            <div className="bubble right-top parallax-front parallax-up">
              <div className="in">
                <p>Сложный и непредсказуемый процесс становится прозрачным и прибыльным. Эту мысль мы отразили в названии.</p>
              </div>
            </div>
          </div>

          <img src="/cases/img/profit/b2_img.webp" />
        </div>

        <div className="profit-2-box__cb-wrap">
          <div className="text">
            <div className="bubble right-top parallax-front parallax-up">
              <div className="in">
                <p>Тех же принципов придерживается логотип: просто, понятно, положительно. Зёлёный круг или точка, символизирует эти смыслы.</p>
              </div>
            </div>
          </div>

          <div className="profit-2-box__cb">
            <div>
              <span>Круг</span>
              Профит<br />
              Простота<br />
              Точка
            </div>
            <div>
              <img src="/cases/img/profit/b2_c.svg" />
            </div>
            <div>
              Одобрено<br />
              Деньги<br />
              Спокойствие

              <span>Зеленый<br />
              цвет</span>
            </div>
          </div>
        </div>

      </div>

      <div className="profit-3-box">
        <div>
          <img src="/cases/img/profit/b3_s1.webp" />
          <img src="/cases/img/profit/b3_s2.webp" />
          <img src="/cases/img/profit/b3_s3.webp" />
        </div>
      </div>

      <div className="profit-4-box-first">
        <div>
          <img className="profit-4-box-first-img1" src="/cases/img/profit/b4_img1.webp" />
          <img className="profit-4-box-first-img3" src="/cases/img/profit/b4_img3.webp" />
        </div>
        <div>
          <div className="text">
            <div className="bubble left parallax-front parallax-up">
              <div className="in">
                <p>Фирменный стиль лёгкий, с минимумом цветов и простыми графическими решениями</p>
              </div>
            </div>
          </div>
          <img className="profit-4-box-first-img2" src="/cases/img/profit/b4_img2.webp" />
        </div>
      </div>

      <div className="profit-4-box-second">
        <div>
          <img src="/cases/img/profit/b4_img4.webp" className="shadow" />
        </div>
        <div>
          <img src="/cases/img/profit/b4_img5.webp" className="shadow" />

          <div className="text">
            <div className="bubble left parallax-front parallax-up">
              <div className="in">
                <p>Дизайн лендинга придерживается основных принципов: понятность и минимализм, обеспечивая максимальную ясность и лёгкость восприятия</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="profit-4-box-last">
        <img src="/cases/img/profit/b4_img6.webp" className="shadow" />
        <img src="/cases/img/profit/b4_img7.webp" />
      </div>

      <div className="profit-5">
        <video muted playsInline loop autoPlay>
          <source src="/cases/img/profit/video.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="profit-6">
        <div>
          <div className="text">
            <div className="bubble right parallax-front parallax-up">
              <div className="in">
                <p>Так выглядит сайт в смартфоне. Адаптация происходит под любое устройство</p>
              </div>
            </div>
          </div>
          <img src="/cases/img/profit/b5_img1n.webp" />
        </div>
        <div>
          <img src="/cases/img/profit/b5_img2n.webp" />
          <img src="/cases/img/profit/b5_img3n.webp" />
        </div>
        <div>
          <img src="/cases/img/profit/b5_img4n.webp" />
        </div>
      </div>

      <CaseFooter to="/projects/target/" title="Target Point" img="/cases/img/next/target_point.jpg" />
    </>
  )
}

export default Content