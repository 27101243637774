import React, { useEffect, useState } from 'react';

const Alt2 = ({active}) => {

  const [rnd1, setRnd1] = useState(0)
  const [rnd2, setRnd2] = useState(1)
  const [rnd3, setRnd3] = useState(2)
  const [rnd4, setRnd4] = useState(3)
  const [rnd5, setRnd5] = useState(4)
  const [rnd6, setRnd6] = useState(5)
  const [rnd7, setRnd7] = useState(6)

  const data = [
    "/img/alts/f1.png",
    "/img/alts/f2.png",
    "/img/alts/f3.png",
    "/img/alts/f4.png",
    "/img/alts/f5.png",
    "/img/alts/f6.png",
    "/img/alts/f7.png",
    "/img/alts/f8.png",
    "/img/alts/f9.png",
    "/img/alts/f10.png"
  ]

  const getRandom = () => {
    return parseInt(Math.random() * data.length)
  }
  
  useEffect(() => {

    const timer1 = setInterval(() => {setRnd1(getRandom())},1100);
    const timer2 = setInterval(() => {setRnd2(getRandom())},1200);
    const timer3 = setInterval(() => {setRnd3(getRandom())},1000);
    const timer4 = setInterval(() => {setRnd4(getRandom())},900);
    const timer5 = setInterval(() => {setRnd5(getRandom())},1300);
    const timer6 = setInterval(() => {setRnd6(getRandom())},800);
    const timer7 = setInterval(() => {setRnd7(getRandom())},1400);

    return () => {
      if(timer1){clearInterval(timer1)}
      if(timer2){clearInterval(timer2)}
      if(timer3){clearInterval(timer3)}
      if(timer4){clearInterval(timer4)}
      if(timer5){clearInterval(timer5)}
      if(timer6){clearInterval(timer6)}
      if(timer7){clearInterval(timer7)}
    }
  },[]);

  return(
    <div className={active ? 'alt-robot-wrap active' : 'alt-robot-wrap'}>
      <div className="alt-robot">
        <div><div style={{ backgroundImage: `url('${data[rnd1]}')` }} /></div>
        <div><div style={{ backgroundImage: `url('${data[rnd2]}')` }} /></div>
        <div><div style={{ backgroundImage: `url('${data[rnd3]}')` }} /></div>
        <div><div style={{ backgroundImage: `url('${data[rnd4]}')` }} /></div>
        <div><div style={{ backgroundImage: `url('${data[rnd5]}')` }} /></div>
        <div><div style={{ backgroundImage: `url('${data[rnd6]}')` }} /></div>
        <div><div style={{ backgroundImage: `url('${data[rnd7]}')` }} /></div>
      </div>
    </div>
  )
}

export default Alt2