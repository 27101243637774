import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

class Notfound extends Component{

  componentDidMount(){
    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }

	render(){
		return(
      <div className="casenotfound">
        <div className="casenotfound__popup">
          <div>Скоро здесь появится кейс.</div>
          <Link to="/projects/">Назад, в проекты</Link>
        </div>
      </div>
		)
	}
}

export default Notfound