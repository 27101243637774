import React, { useEffect } from "react";
import $ from "jquery";
import Content from './Content'

const Desctope = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      $('.profit-top').addClass('active')
    }, 300);

    window.gsap.registerPlugin(window.ScrollTrigger, window.ScrollSmoother);

		let smoother = window.ScrollSmoother.create({
			smooth: 2,
			effects: true,
			smoothTouch: false,
			normalizeScroll: true,
		});

    window.gsap.to(".profit-top__c1", {
      scrollTrigger: {
        trigger: '.profit-top',
        start: 'top top',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '10vh',
    });

    window.gsap.to(".profit-top__c2", {
      scrollTrigger: {
        trigger: '.profit-top',
        start: 'top top',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '-10vh',
    });

    window.gsap.to(".profit-top__c3", {
      scrollTrigger: {
        trigger: '.profit-top',
        start: 'top top',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '10vh',
    });

    window.gsap.to(".profit-top__c4", {
      scrollTrigger: {
        trigger: '.profit-top',
        start: 'top top',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '-10vh',
    });

    window.gsap.to(".profit-top__c5", {
      scrollTrigger: {
        trigger: '.profit-top',
        start: 'top top',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '-10vh',
    });

    window.gsap.to(".profit-2-box__q div:nth-child(1)", {
      scrollTrigger: {
        trigger: '.profit-2-box__steps',
        start: 'center bottom',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '-50%',
    });

    window.gsap.to(".profit-2-box__q div:nth-child(3)", {
      scrollTrigger: {
        trigger: '.profit-2-box__steps',
        start: 'center bottom',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '70%',
    });

    window.gsap.to(".profit-2-box__q div:nth-child(4)", {
      scrollTrigger: {
        trigger: '.profit-2-box__steps',
        start: 'center bottom',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '30%',
    });

    window.gsap.to(".profit-2-box__q div:nth-child(5)", {
      scrollTrigger: {
        trigger: '.profit-2-box__steps',
        start: 'center bottom',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '-100%',
    });
    
    window.gsap.to(".profit-2-box__q div:nth-child(6)", {
      scrollTrigger: {
        trigger: '.profit-2-box__steps',
        start: 'center bottom',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      y: '20%',
    });

    window.gsap.to(".profit-2-box__circle", {
      scrollTrigger: {
        trigger: '.profit-2-box__steps',
        start: 'center bottom',
        end: '+=' + window.innerHeight,
        scrub: true,
      },
      duration: .6,
      scale: 1,
    });

    window.gsap.to(".profit-2-box__cb img", {
      scrollTrigger: {
        trigger: '.profit-2-box__cb',
        start: 'top bottom',
        end: '+=' + window.innerHeight*2,
        scrub: true,
      },
      duration: .6,
      rotate: '360deg',
    });

    window.gsap.to(".profit-3-box div", {
      scrollTrigger: {
        trigger: '.profit-3-box',
        start: 'top center',
        end: '+=' + window.innerHeight/2,
        scrub: true,
      },
      duration: .6,
      x: '-70%',
    });

    return () => {
      if(timer){
        clearTimeout(timer)
      }
    }

  }, []);

  return(
    <div id="smooth-wrapper" className="case profit">
      <div id="smooth-content" className="case-content">
        <Content />
      </div>
    </div>
  )
}

export default Desctope