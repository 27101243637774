import React, { Component } from 'react';
import { connect } from 'react-redux';

import mapStateToProps from '../../store/mapStateToProps';
import mapDispatchToProps from '../../store/mapDispatchToProps';
import SourcesAuditDesctope from './SourcesAuditDesctope'
import SourcesAuditMobile from './SourcesAuditMobile';

class SourcesAudit extends Component{

  state = {
    mobile: window.innerWidth <= 1000
  }

  resize = () => this.setState({mobile: window.innerWidth <= 1000})

	componentDidMount(){
    window.addEventListener('resize', this.resize);
	}

	componentWillUnmount(){
    window.removeEventListener('resize', this.resize);
	}

	render(){
    if(this.state.mobile) return <SourcesAuditMobile />

		return <SourcesAuditDesctope />
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SourcesAudit)