import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { connect } from 'react-redux';
import mapStateToProps from '../../../store/mapStateToProps';
import mapDispatchToProps from '../../../store/mapDispatchToProps';
import {hit} from '../../metrics';
import CaseFooter from '../CaseFooter';
import HelmetSite from '../HelmetSite';
import './style.css';

class Gals extends Component{

	componentDidMount(){
		if(this.props.pathname == '/projects/gals/'){
			this.setState({preview: false});
			this.props.setProjectName('Галс');
			$('body').addClass('case-expanded hash-gals');
			$(window).scrollTop($('#case-gals').offset().top);
			this.props.changeCase(null);
		}
		else{
			this.setState({preview: true});
			$('body').removeClass('hash-gals');
    }
    window.scrollTo(0, 0);
    hit(window.location.pathname);

    setTimeout(() => {
			$('body').addClass('fadepage');
    }, 100);
	}

	componentDidUpdate(){
		if(this.props.pathname == '/projects/'){
			$('body').removeClass('case-expanded hash-gals');
		}
  }

  componentWillUnmount() {
    $('body').removeClass('fadepage');
  }
  
	render(){
		return(
		<div className='case'>
			<div className="case-content" id="case-gals">

        <div className="screen screen1">
          <div className="bg parallax-back">
            <div style={{backgroundImage: `url('/cases/img/gals/bg1.webp')`}} />
          </div>
        </div>
        <div className="screen screen2">
          <img src="/cases/img/gals/bg2-1.webp" className="img1 shadow" />
          <img src="/cases/img/gals/bg2-2.webp" className="img2 shadow" />
          <img src="/cases/img/gals/bg2-4.webp" className="img3 shadow" />
          <img src="/cases/img/gals/bg2-3.webp" className="img4 shadow" />

          <div className="text bubble right parallax-front text1">
            <div className="in">
            Атмосфера света, движения и лёгкости...<br/><br/>
            На главной странице мы использовали видеофрагменты, дизайн выполнен в  минималистичном премиальном стиле. 
            </div>
          </div>

          <div className="text bubble left parallax-front text2">
            <div className="in">
            Чёткий и однозначный акцент – на ценностях компании, чтобы раскрыть застройщика изнутри, дать понимание того, чем «живёт» и руководствуется девелопер.
            </div>
          </div>

          <div className="text bubble right-top parallax-front text3">
            <div className="in">
            На сайте тщательно продуманные алгоритмы поиска и навигация: удобный подбор недвижимости, интуитивное меню. 
            </div>
          </div>
        </div>
        <div className="screen screen3">
          <div className="mac">
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Br98luZej2w" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </div>
        </div>
        <div className="screen screen4">
          <img src="/cases/img/gals/bg4-2.webp" className="img1 shadow" />
          <img src="/cases/img/gals/bg4-1.webp" className="img2 shadow" />

          <div className="text bubble left parallax-front text1">
            <div className="in">
            Каждый объект представлен отдельным лендингом
            </div>
          </div>

          <div className="text bubble right-bottom parallax-front text2">
            <div className="in">
            Удобный поиск лотов по нужным параметрам доступен пользователю с каждой страницы
            </div>
          </div>
        </div>
        <div className="screen screen5">
          <div className="bg">
            <div style={{backgroundImage: `url('/cases/img/gals/bg5-1.webp')`}} />
          </div>
        </div>
        <div className="screen screen6">
          <img src="/cases/img/gals/bg5-2.webp" className="img1" />

          <div className="text bubble left parallax-front text1">
            <div className="in">
            Так сайт выглядит на мобильных устройствах
            </div>
          </div>
        </div>
			</div>

      {/*<CaseFooter to="/projects/iskra/" title="Искра-Парк" img="/cases/img/next/iskra.webp" />*/}

      <CaseFooter 
				to={ this.props.next ? this.props.next.url: "/projects/fabcab/" }
				title= { this.props.next ? this.props.next.name: "FabCab"  } 
				img={ this.props.next ? this.props.next.imgCaseBottom: "/cases/img/next/fabcab.webp" }
			/>

      <HelmetSite title="Галс Девелопмент" />

			<div className="case-info">
				<Link to="/projects/" className="all-title">Все проекты</Link>
				<div className="title">Happy X5</div>
				<div className="tags"><span>сайт</span><span>финансы</span></div>
			</div>
		</div>
		)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Gals)